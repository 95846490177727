/**
 * @returns true in author mode | false if in view as published, or on publisher
 * 
 * EXAMPLE:
 * import { isAuthorMode } from "....../utils/isAuthorMode";
 * 
 * if (isAuthorMode()) {
 *  // return out of unwanted function
 */

export const isAuthorMode = () => {
  if (!Object.hasOwn(window, 'Granite')) {
    return false;
  }
  return Object.hasOwn(window.Granite, 'author');
}


