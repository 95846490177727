import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';

import bananaPhoneSvg from "../../svg/general/icons/bananaPhone";
bananaPhoneSvg().addToClass("#banana-phone-cord");

import contactUsSvg from "../../svg/general/contact_us/contact_us";
contactUsSvg().addToClass(".c-contact-address")


const changeBackgroundColor = () => {
  gsap.registerPlugin(ScrollTrigger);

  const getBackgroundColor = (selector) => {
    const container = document.querySelector(`${selector} > .cmp-container`);
    if (!container) return '#FFFFFF';
    return container.style.backgroundColor;
  }

  const sectionPhone = document.querySelector('.c-contact-phone');
  const sectionEmail = document.querySelector('.c-contact-email');
  const sectionAddress = document.querySelector('.c-contact-address');

  if (sectionPhone) {
    ScrollTrigger.create({
      trigger: '.c-contact-phone',
      start: "top 100%",
      end: "bottom center",

      onEnter: () => {
        gsap.to('.c-contact-section', { duration: 0, backgroundColor: getBackgroundColor('.c-contact-phone') });
      },
      onEnterBack: () => {
        gsap.to('.c-contact-section', { duration: 1.0, backgroundColor: getBackgroundColor('.c-contact-phone') });
      },
    });
  }

  if (sectionEmail) {
    ScrollTrigger.create({
      trigger: '.c-contact-email',
      start: "top 50%",
      end: "bottom center",

      onEnter: () => {
        gsap.to('.c-contact-section', { duration: 1.0, backgroundColor: getBackgroundColor('.c-contact-email') });
      },
      onEnterBack: () => {
        gsap.to('.c-contact-section', { duration: 1.0, backgroundColor: getBackgroundColor('.c-contact-email') });
      },
    });
  }

  if (sectionAddress) {
    ScrollTrigger.create({
      trigger: '.c-contact-address',
      start: "top 40%",
      end: "bottom center",

      onEnter: () => {
        gsap.to('.c-contact-section', { duration: 1.0, backgroundColor: getBackgroundColor('.c-contact-address') });
      },
      onEnterBack: () => {
        gsap.to('.c-contact-section', { duration: 1.0, backgroundColor: getBackgroundColor('.c-contact-address') });
      },
    });
  }
}


const contactUsSvgAnimation = () => {

  if (
    !document.querySelector('.c-contact-address-path')
  ) {
    return;
  }
  const contactUsAnimationStart = () => {
    gsap.to(".c-contact-address-path", {
      strokeDashoffset: 0,
      strokeDasharray: "1791.6px, 1800px",
      duration: 1.5,
    });
  }

  const contactUsAnimationReset = () => {
    gsap.set(".c-contact-address-path", {
      strokeDashoffset: 1791.6,
      strokeDasharray: "0px, 999999px",
    });
  }

  gsap.timeline({
    scrollTrigger: {
      trigger: ".c-contact-address-path",
      start: "top center",
      end: "bottom 20%",
      scrub: true,
      onEnter: contactUsAnimationStart,
      onLeave: contactUsAnimationReset,
      onEnterBack: contactUsAnimationStart,
      onLeaveBack: contactUsAnimationReset,
    },
  });
}


const onDocumentReady = () => {
  changeBackgroundColor();
  contactUsSvgAnimation();
}

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
