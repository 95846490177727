import { SVG } from '@svgdotjs/svg.js';

const generateRandomPath = (elementWidth) => {
  const space = ' ';
  const mAttrtibute = 'M5 ';
  const qAttribute = ' Q ';
  const moveYMin = 5;
  const curveYMin = 5;
  const endYMin = 5;
  const moveYMax = 10;
  const curveXMin = 10;
  const endYMax = 10;
  const curveXMax = elementWidth * 0.05;
  const curveYMax = elementWidth * 0.05;
  const moveY = Math.floor(Math.random() * (moveYMax - moveYMin)) + moveYMin;
  const curveX = Math.floor(Math.random() * (curveXMax - curveXMin)) + curveXMin;
  const curveY = Math.floor(Math.random() * (curveYMax - curveYMin)) + curveYMin;
  const endY = Math.floor(Math.random() * (endYMax - endYMin)) + endYMin;
  return ''.concat(
    mAttrtibute,
    moveY,
    qAttribute,
    curveX,
    space,
    curveY,
    space,
    elementWidth,
    space,
    endY
  );
}

const generateUnderLineSvg = (elementWidth) => {
  const svgWidth = elementWidth + 8;
  const underlineSvg = SVG();

  underlineSvg
    .width(svgWidth)
    .height(24)
    .viewbox(0, 0, svgWidth, 24)
    .addClass('o-underline__svg');

  const path = underlineSvg.path(generateRandomPath(elementWidth));
  const strokeLength = path.length() + 10;
  path.stroke({
    'width': 4,
    'linecap': 'round',
  });

  path.css ({
    'stroke-dasharray': strokeLength,
    'stroke-dashoffset' : strokeLength
  })
  path.fill('transparent');

  underlineSvg.css({
    'focusable': false
  });

  return underlineSvg;
};

const underlineSvg = () => {

  const addToId = (selector) => {
    const clientWidth = document.querySelector(selector).clientWidth;
    const svg = generateUnderLineSvg(clientWidth);
    svg.addTo(selector);
  }

  const addToClass = (selector) => {
    const classItems = document.querySelectorAll(selector);
    classItems.forEach((classItem) => {
      const clientWidth = classItem.clientWidth;
      const svg = generateUnderLineSvg(clientWidth);
      svg.addTo(classItem);
    });
  }

  const addToVueRef = (selector, clientWidth) => {
    const svg = generateUnderLineSvg(clientWidth);
    svg.addTo(selector);
  }

  return Object.assign({}, { addToId, addToClass, addToVueRef });
}

export default underlineSvg;
