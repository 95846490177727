<template>
  <div class="product-title-root">
    <h3 class="product-title">{{ productTitle }}</h3>
  </div>
</template>

<script>
export default {
  name: 'ProductSlideTitle',
  props: {
    productTitle: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-title {
      font-size: 28px;
      line-height: 34px;
  }

  .product-title-root {
    max-width: 480px;
    margin: 0 auto;
  }
</style>