<template>
  <div class="activity-container">
    <div class="activity-cards">
      <activity-card v-for="(card, cardIndex) in getCurrentSeasonRandomCards" :key="cardIndex"
      :card="card"
      :sesaonActive="getCurrentSeason" 
      :allCardsShown="showAllCards"
      :translations="getTranslations"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivityCard from '../components/kids/ActivityCard';

export default {
  name: 'KidsActivityStepPage',
  components: {
    ActivityCard
  },
  props: {
    query: {
      type: String,
      required: true
    },
    folder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      storeName: 'activityCard',
      cardsNumber: 3,
      showAllCards: true
    }
  },
  created () {
    this.$store.dispatch('performApiQuery', { query: this.query, store: this.storeName, folder: this.folder });
  },
  computed: {
    ...mapGetters({
      cardItems: 'activityCard/getCardItems',
      translationItems: 'activityCard/getTranslationItems'
    }),
    getTranslations () {
      if (!this.translationItems.length) return {};
      return this.translationItems[0];
    },
    getCurrentSeason () {
      return this.translationItems.length >= 1 ? this.translationItems[0]['currentSeason'] : '';
    },
    getCurrentSeasonRandomCards () {
      if (this.cardItems.length === 0) return [];

      let currentSeasonCards = this.cardItems.filter(cardItem => cardItem.season === this.getCurrentSeason);
      let randomArr = [];
      for (let i= 0; i < this.cardsNumber; i++) {
        let randomCardIndex = Math.floor(Math.random() * currentSeasonCards.length);
        randomArr.push(currentSeasonCards[randomCardIndex])
        currentSeasonCards.splice(randomCardIndex, 1);
      }
      return randomArr;
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-container {
  max-width: 1400px;  
  padding: 0 20px;
  margin: 0 auto;
}

.activity-cards {
  margin-top: 50px;
  margin-bottom: 50px;
    @media only screen and (min-width: 1023px) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 90px;
      margin-bottom: 90px;
    }
    .card-container {
      @media only screen and (min-width: 1023px) {
        margin-left: 15px;
        margin-right: 15px;
        flex-basis: calc((100% / 3) - 30px);
        max-width: calc((100% / 3) - 30px);
        display: flex;
        flex-direction: column;
      }
    }
}
</style>