<template>
  <li 
    class="product-nav-dot-root"
    :class="{'active-dot': isActiveDot, 'not-last-dot': !isLastDot}"
    @click="dotClickAction"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <path 
        d="M3 14.47a8.13 8.13 0 007 1.22 8.51 8.51 0 005.32-4.59 8.06 8.06 0 00-.08-6.84A7.94 7.94 0 009.76.14 8.26 8.26 0 000 8.06a8 8 0 003 6.41" 
        fill-rule="evenodd" 
        :style="{'fill': dotFill }"
      >
      </path>
    </svg>
  </li>
</template>

<script>
export default {
  name: 'ProductNavDot',
  props: {
    dotFill: {
      type: String,
      required: true
    },
    dotIndex: {
      type: Number,
      required: true
    },
    activeDotIndex: {
      type: Number,
      required: true
    },
    isLastDot: {
      type: Boolean,
      required: true
    }
  },
  emits: ['updatedot'],
  computed: {
    isActiveDot () {
      return this.dotIndex === this.activeDotIndex;
    }
  },
  methods: {
    dotClickAction () {
      this.$emit('updatedot', this.dotIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-nav-dot-root {
  &.not-last-dot{
    margin-bottom: .75rem
  }

  & svg {
    transform: scale(.9);
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    cursor: pointer;
  }

  &:hover svg {
    transform: scale(1.1)
  }

  &.active-dot svg {
    transform: scale(1.3)
  }
}

</style>