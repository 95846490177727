import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const swiperInnocentBulletSvg = () => {
  const swiperInnocentBullet = SVG();

  swiperInnocentBullet.svg('<path d="M6.45 10.66A5.73 5.73 0 009 9.37a5.32 5.32 0 001.57-2.29 5.32 5.32 0 00.25-2.76A5.51 5.51 0 009.57 1.8 5.57 5.57 0 007.14.39 5.49 5.49 0 004.35.07a5.47 5.47 0 00-2.5 1.29A5.16 5.16 0 00.23 3.61a5 5 0 00-.08 2.73 5.25 5.25 0 001.07 2.55 5.5 5.5 0 002.39 1.52 5.72 5.72 0 002.84.25" fill-rule="evenodd"></path>');
  swiperInnocentBullet.height('11px').width('11px')

  return svgHelper(swiperInnocentBullet);
}

export default swiperInnocentBulletSvg;