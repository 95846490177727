<template>
  <div 
    class="product-slide-root"
    :style="{'background-color': currentSlideBgColour, 'background-image': getBackgroundImage }"
  >
    <div class="slide-title">
      <all-products-title
        :title="slideItem.slideTitle"
        :titleColor="slideItem.slideTitleColour"
      />
    </div>
    <div class="slide-button">
      <all-products-button
        :ctaText="slideItem.ctaText"
        :ctaPath="getCtaLink"
        :ctaAppearance="slideItem.ctaAppearance"
        :buttonColour="slideItem.buttonColour"
        :buttonTextColour="slideItem.buttonTextColour"
      />
    </div>
    <div
      v-for="(image, imageIndex) in slideItem.productImage"
      :key="imageIndex"
      class="slide-images"
    >
      <all-products-image
        :imagePath="getImagePath(imageIndex)"
        :imageAltText="getImageAltText(imageIndex)"
        :imageIndex="imageIndex"
        :imagePosition="slideItem.productImagePositioning"
      />
    </div>
    <div class="bottom-wave">
      <wave 
        :isBottomWave="true"
        :waveFill="waveDefaultColor"
      />
    </div>
  </div>
</template>

<script>
import AllProductsTitle from './AllProductsTitle';
import AllProductsImage from './AllProductsImage';
import AllProductsButton from './AllProductsButton';
import Wave from '../../core/Wave';

export default {
  name: 'AllProductsSlide',
  components: {
    AllProductsTitle,
    AllProductsImage,
    AllProductsButton,
    Wave
  },
  props: {
    slideItem: {
      type: Object,
      required: true
    },
    currentSlideBgColour: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      waveDefaultColor: '#FFF'
    }
  },
  computed: {
    getBackgroundImage () {
      if (!this.slideItem.slideBackgroundImage) return '';
      return `url(${this.slideItem.slideBackgroundImage._path})`;
    },
    getCtaLink () {
      if (!this.slideItem.ctaLink) return '';
      return this.slideItem.ctaLink._path;
    }
  },
  methods: {
    getImageAltText (index) {
      if (this.slideItem.productImageAltText.length < index + 1) return '';
      return this.slideItem.productImageAltText[index];
    },
    getImagePath (index) {
      if (this.slideItem.productImage.length < index + 1) return '';
      if (!this.slideItem.productImage[index]._path) return '';
      return this.slideItem.productImage[index]._path;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-slide-root {
  background-position: 50%;
  background-repeat: no-repeat;
  height: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
  transition: background-color 2s;
  opacity: 0;
}

.bottom-wave {
  position: absolute;
}
.swiper-slide-prev,
.is-prev {
  .slide-title,
  .slide-button {
    transform: translate(-100vw);
  }
}

.swiper-slide-next,
.is-next {
  .slide-title,
  .slide-button {
    transform: translate(100vw); 
  }
}


.swiper-slide-active {
  .slide-title,
  .slide-button {
    transform: translate(0);
    z-index: 4;
  }
}



.slide-title,
.slide-button { 
  transition: transform 1.5s cubic-bezier(0.86, 0.01, 0.19, 0.96);
}
</style>