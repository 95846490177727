<template>
  <nav 
    class="product-nav-root"
    :class="{'is-unstick': isUnstick}" 
    :isFlipped="isFlipped" 
  >
    <svg class="product-nav-top not-flipped" v-show="!isFlipped" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29px" height="13px" viewBox="0 0 29 13" version="1.1">
		  <title>Rectangle Copy 2</title>
      <g id="Page-Designs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Smoothies---sustainability" transform="translate(-59.000000, -691.000000)" fill="#FFFFFF">
          <path d="M59,691.881897 C62.6895694,691.572008 65.2062056,691.572008 66.5499086,691.881897 C73.086071,693.389283 77.7698149,693.389283 81.1747124,694.476403 C88.7371424,696.890944 87,697.700951 87,703.381975 C82.1243901,703.373038 72.7910568,703.373038 59,703.381975 L59,691.881897 Z" id="Rectangle-Copy-2"/>
        </g>
      </g>
	  </svg>
    <svg class="product-nav-top flipped" v-show="isFlipped" xmlns="http://www.w3.org/2000/svg" width="28.17" height="11.73" viewBox="0 0 28.17 11.73">
      <path d="M28.17,12.38q-20.68,0-28,0C.17,6.7-1.57,5.89,6,3.48,9.4,2.39,14.09,2.39,20.62.88a29.12,29.12,0,0,1,7.55,0Z" transform="translate(0 -0.65)" style="fill: #fff;fill-rule: evenodd"/>
    </svg>
    <ul>
     <product-nav-dot 
      v-for="(productDotFill, index) in productDotFills"
      :key="index"
      :dotFill="productDotFill"
      :dotIndex="index"
      :activeDotIndex="currentTileIndex"
      :isLastDot="isLastDot(index)"
      @updatedot="updateNavPosition"
     />
    </ul>
    <svg class="product-nav-bottom not-flipped" v-show="!isFlipped" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="11px" viewBox="0 0 28 11" version="1.1">
      <title>Rectangle Copy 7</title>
      <g id="Page-Designs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Smoothies---sustainability" transform="translate(-59.000000, -870.000000)" fill="#FFFFFF">
          <path d="M59.0034393,872.197783 C60.1000594,871.035787 64.9189642,871.608297 67.103488,871.29221 C74.1274311,870.27589 78.1986485,871.29221 82.369459,871.29221 C87.0036788,871.29221 87.0036788,874.516759 87.0036788,880.197783 C85.8164654,880.196763 76.4830522,880.196763 59.0034393,880.197783 C58.9988536,875.951776 58.9988536,873.28511 59.0034393,872.197783 Z" id="Rectangle-Copy-7" transform="translate(73.001839, 875.519148) scale(1, -1) translate(-73.001839, -875.519148) "/>
        </g>
      </g>
    </svg>
    <svg class="product-nav-bottom flipped" v-show="isFlipped" xmlns="http://www.w3.org/2000/svg" width="28" height="9.36" viewBox="0 0 28 9.36">
      <path d="M28,.84H0C0,6.52,0,9.75,4.63,9.75c4.18,0,8.25,1,15.27,0,2.18-.32,7,.25,8.1-.91Z" transform="translate(0 -0.84)" style="fill: #fff;fill-rule: evenodd"/>
    </svg>
  </nav>
</template>

<script>
import ProductNavDot from './ProductNavDot';

export default {
  name: 'ProductNav',
  components: {
    ProductNavDot
   
  },
  props: {
    productDotFills: {
      type: Array,
      required: true
    },
    isFlipped: {
      type: Boolean,
      required: true
    },
    currentTileIndex: {
      type: Number,
      required: true
    },
    isUnstick: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      activeDotIndex: 0
    }
  },
  emits: ['updatenav'],
  methods: {
    updateNavPosition (event) {
      this.$emit('updatenav', event);
    },
    isLastDot (index) {
      return index >= this.productDotFills.length -1;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-nav-root {
  padding: .375rem 0;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  background: #fff;

  &[isFlipped=true] {
    right: 0;
    left: initial;
  }
  
  & ul {
    padding: 0;
    margin: 0;
    list-style: none;
  } 
  &.is-unstick {
    position: absolute;
    top: auto;
    bottom: 50vh;
  }
}

.product-nav-bottom {
  width: 28px;
  height: 11px;
  position: absolute;
  bottom: 2px;
  left: 0;
  transform: translateY(100%);
}

.product-nav-top {
  width: 29px;
  height: 13px;
  position: absolute;
  top: 2px;
  left: 0;
  transform: translateY(-100%);
}
</style>