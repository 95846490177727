<template>
  <p
    data-cy-form="description"
  >{{ descriptionText }}</p>
</template>

<script>
export default {
  name: 'SubscriptionFormDescription',
  props: {
    descriptionText: {
      type: String,
      required: true  
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  text-align: center;
  color: #fff; 
}
</style>
