<template>
  <div class="product-tile-image-container">
    <div class="product-tile-images">
      <img 
        :src="desktopImagePath" 
        :alt="desktopImageAltText"
        class="desktop-image"
        loading="lazy"
      >
      <img
        v-show="isFlipped"
        :src="bopImagePath" 
        :alt="bopImageAltText"
        class="bop-image"
        loading="lazy"
      >
    </div>
    <div class="product-image-svg">
      <svg class="product__bottle-circle" xmlns="http://www.w3.org/2000/svg" width="298" height="298"
								 viewBox="0 0 298 298">
        <path
          d="M279,73.12c-11.9-20.44-31.5-38.9-56.7-53.39A150.42,150.42,0,0,0,147.42,0C119,0,92.9,6.4,71.83,18.52,48.39,32,28.51,51.39,15.83,73.12,1.8,97.27,0,126.16,0,148.63c0,31.89,5.25,55.75,16.52,75.12,11.76,20.2,30.8,38,56.59,52.79C97.87,290.78,122.86,298,147.42,298c29.64,0,54.8-12.29,73.41-23,22.24-12.78,44.66-27.79,58.12-50.9C291.41,202.69,298,176.59,298,148.63s-6.58-54.15-19-75.51"
          transform="translate(0 0)"
          :style="{'fill': imageBackgroundColor}"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductTileImage',
  props: {
    desktopImagePath: {
      type: String,
      required: true
    },
    desktopImageAltText: {
      type: String,
      required: true
    },
    bopImagePath: {
      type: String,
      required: true
    },
    bopImageAltText: {
      type: String,
      required: true
    },
    imageBackgroundColor: {
      type: String,
      required: true
    },
    isFlipped: {
      type: Boolean,
      required: true
    } 
  }
}
</script>

<style lang="scss" scoped> 
.product-tile-image-container {
  position: relative;
  height: 100%;

  .product-tile-images {
    position: relative;
    max-height: 100%;
    max-width: 90%;
    z-index: 2;
    margin: auto;
    top: 50%;

    &:hover {
      .bop-image {
        transform: scale(1.1) translate(-50%, -50%) scaleX(-1);
      }
    }

    .bop-image {
      transform: translate(-50%, -50%) scaleX(-1);
      transition: transform 0.3s cubic-bezier(0.63, -0.01, 0.26, 1.49);
      width: min(100%, 350px);
      max-width: initial;
      
      @media only screen and (max-width: 1023px) {
        width: min(100%, 300px);
      }
    }

    img {
      max-width: 280px;
			opacity: 1;
      z-index: 2;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: opacity 1s ease;

      @media only screen and (max-width: 1023px) {
        max-width: 180px;
      }
    }
  }

}

.product-image-svg {
    transform: scale(1.2) translate(-50%, -50%);
    transform-origin: left top;
    position: absolute;
    top: 50%;
    left: 50%;
    @media only screen and (max-width: 1023px) {
      transform: scale(0.8) translate(-50%, -50%);
    }
}


</style>