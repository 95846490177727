<template>
    <button
       @click="seasonClicked"
       :class ="{'button-active': isActive, 'button-non-active': !isActive}"
       :data-cy-single-selector="seasonType"
      >
      <span class="button-text">{{ seasonText }}</span>
    </button>
</template>

<script>
export default {
  name: 'ActivitySingleSelector',
  props: {
    seasonType: {
      type: String,
      required: true
    },
    seasonText: {
      type: String,
      required: true 
    },
    sesaonActive: {
      type: String,
      required: true
    }
  },
  emits: ['update-season'],
  computed: {
    isActive () {
      return this.seasonType === this.sesaonActive;
    }
  },
  methods: {
    seasonClicked () {
      this.$emit('updateSeason', this.seasonType);
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  background: none;
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
  display: inline-block;
  border-radius: 60px;
  padding: 10px 25px;
  position: relative;
  cursor: pointer;

    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 60px;
      transition: background-color .3s ease-in-out;
    }

    &:before {
      background-color: #a2d45e;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:after {
      top: 6px;
      left: 6px;
    }
    
  &:focus {
    outline: none;
  }
  .button-text {
    font-size: 22px;
    line-height: 1;
    position: relative;
    z-index: 3;
    color: rgb(33, 39, 33);
  }
}

.button-non-active {
  &:before {
      background-color: #DDE5ED;
    }
}

.button-active {
  &:hover {
      &:after {        
        background-color: #74aa50;
      }
    }
}
</style>