<template>
    <div>
      <img  
        :src="imageSuccessUrl"
        :alt="imageSuccessUrlAltText"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'SubscriptionFormImageSuccess',
    props: {
        imageSuccessUrl: {
        type: String,
        required: true 
      },
      imageSuccessUrlAltText: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  img {
    width: 200px;
    display: block;
    margin: 0 auto;
  }
  </style>
  