import swiperInnocentBulletSvg from "../../svg/general/icons/swiperInnocentBullet";

import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper.css";
Swiper.use([Navigation, Pagination]);

const swiperInnocent = () => {
  const swiper = new Swiper(".c-innocent-slider", {
    centeredSlides: true,
    initialSlide: 2,
    slidesPerView: 2,
    slideToClickedSlide: true,
    spaceBetween: 15,

    breakpoints: {
      768: {
        shortSwipes: false,
        slidesPerView: 5,
        spaceBetween: 30,
      },
    },

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  swiperInnocentBulletSvg().addToClass(".c-innocent-slider .swiper-pagination-bullet");
};

const onDocumentReady = () => {
  swiperInnocent();
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
