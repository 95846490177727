import landscapeTopSvg from "../../svg/rewild/wild-area/landscapeTopSvg";
import landscapeLeftSvg from "../../svg/rewild/wild-area/landscapeLeftSvg";
import landscapeBottomSvg from "../../svg/rewild/wild-area/landscapeBottomSvg";
import landscapeRightSvg from "../../svg/rewild/wild-area/landscapeRightSvg";
import portraitBottomSvg from "../../svg/rewild/wild-area/portraitBottomSvg";
import portraitTopSvg from "../../svg/rewild/wild-area/portraitTopSvg";
import portraitLeftSvg from "../../svg/rewild/wild-area/portraitLeftSvg";
import portraitRightSvg from "../../svg/rewild/wild-area/portraitRightSvg";



landscapeTopSvg().addToClass('.t-wild-area .cmp-teaser'); 
landscapeLeftSvg().addToClass('.t-wild-area .cmp-teaser');   
landscapeBottomSvg().addToClass('.t-wild-area .cmp-teaser');  
landscapeRightSvg().addToClass('.t-wild-area .cmp-teaser');  
portraitBottomSvg().addToClass('.t-wild-area .cmp-teaser');
portraitTopSvg().addToClass('.t-wild-area .cmp-teaser');
portraitLeftSvg().addToClass('.t-wild-area .cmp-teaser');
portraitRightSvg().addToClass('.t-wild-area .cmp-teaser');