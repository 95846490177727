const productStore = {
  namespaced: true,
  state: {
    apiError: false,
    pathError: false,
    dataError: false,
    dataName: 'productsData',
    productItems: [],
    translationItems: []
  },
  mutations: {
    setApiError: (state, status) => {
      state.apiError = status;
    },
    setPathError: (state, status) => {
      state.pathError = status;
    },
    setDataError: (state, status) => {
      state.dataError = status;
    },
    setProductItems: (state, payload) => {
      state.productItems = payload;
    },
    setTranslationItems: (state, payload) => {
      state.translationItems = payload;
    },
  },
  getters: {
    hasError: state => state.apiError || state.pathError || state.dataError,
    getProductItems: state => state.productItems,
    getTranslationItems: state => state.translationItems,
    getFirstTranslationItem: state => state.translationItems[0],
    getDataName: state => state.dataName
  },
  actions: {
    loadResults: ({ commit }, { items, translationItems } ) => {
      commit('setProductItems', items);
      commit('setTranslationItems', translationItems);
    }
  }
};

export default productStore;