import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const buttonLinkListSvg = () => {
  const buttonLinkList = SVG();

  buttonLinkList.svg('<path d="M23.3,8.59c-2.82,0-5.08-.06-6.75-.17-3.24-.22-8.28-.22-15,0A1.54,1.54,0,0,1,0,7,1.5,1.5,0,0,1,1.45,5.42c6.82-.22,12-.22,15.3,0s8.8.22,16.53,0a1.5,1.5,0,1,1,.08,3Q27.51,8.58,23.3,8.59Z"></path><path d="M28,13.93a1.45,1.45,0,0,1-.92-.32,1.5,1.5,0,0,1-.26-2.11c.67-.84,1.35-1.62,2-2.32.49-.51,1.24-1.26,2.26-2.23-.93-.86-1.69-1.59-2.27-2.2a13.7,13.7,0,0,0-2.39-2A1.5,1.5,0,1,1,28.12.25,17.14,17.14,0,0,1,31,2.68c.76.8,1.86,1.85,3.29,3.12a1.53,1.53,0,0,1,.5,1.1A1.5,1.5,0,0,1,34.35,8c-1.58,1.5-2.7,2.59-3.33,3.25a26.2,26.2,0,0,0-1.82,2.1A1.5,1.5,0,0,1,28,13.93Z"></path>');
  buttonLinkList.width(34.82).height(13.93).viewbox(0, 0, 34.82, 13.93).addClass('arrow');

  return svgHelper(buttonLinkList);
}

export default buttonLinkListSvg;