<template>
  <div
    v-if="showMsg"
    v-html="htmlMessage"
    class="recaptcha-msg">
  </div>
</template>
<script>
export default {
  name: 'SubscriptionRecaptchaMsg',
  props: {
    showMsg: {
      type: Boolean,
      required: true
    },
    htmlMessage: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
</style>