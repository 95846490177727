import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const blockBlobSvg = () => {
  const blockBlog = SVG();

  blockBlog.svg('<path d="M37.31,170.65C96.35,58.73,218.07-25.61,344.7,7.14c74.79,19.34,139.64,75.23,158.2,151.33,21.31,87.35-25.64,184-63,264.25C398.18,512.24,305,566.86,207.09,549.44,106.9,531.62,23.1,454,4.34,356c-11.63-60.79,2.11-126.83,33-185.33" transform="translate(-0.23 0)" style="fill-rule: evenodd"></path>');
  blockBlog.width(508).height(552.67).viewbox(0, 0, 508, 552.67);


  return svgHelper(blockBlog);
}

export default blockBlobSvg;

