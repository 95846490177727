<template>
  <div class="history-timeline-card-root">
    <div class="history-timeline-card">
      <div class="history-timeline-card__wrapper">
        <div class="history-timeline-card__content">
          <div class="history-timeline-card__image">
            <img :src="slideItem.slideImage._path" alt="" />
          </div>
          <div class="history-timeline-card__date">{{ slideItem.year }}</div>
          <h3 class="history-timeline-card__title">{{ slideItem.title }}</h3>
          <p class="history-timeline-card__description">
            {{ slideItem.description.plaintext }}
          </p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="history-timeline-card__steps"
          width="188"
          height="162"
          viewBox="0 0 188 162"
        >
          <path
            d="M34.52,162H1.5a1.5,1.5,0,0,1,0-3H33V105.27a1.5,1.5,0,0,1,1.5-1.5h52.6V53.22a1.5,1.5,0,0,1,1.5-1.5h56.76V1.5a1.5,1.5,0,0,1,1.5-1.5H186.5a1.5,1.5,0,0,1,0,3H148.38V53.22a1.5,1.5,0,0,1-1.5,1.5H90.12v50.55a1.5,1.5,0,0,1-1.5,1.5H36V160.5A1.5,1.5,0,0,1,34.52,162Z"
            style="fill: #e03e52"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryDecadeCard',
  props: {
    slideItem: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.history-timeline-card-root {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  user-select: none;
}

.history-timeline-card {
  height: 640px;
}

.history-timeline-card__wrapper {
  position: relative;
}

.history-timeline-card__content {
  box-sizing: content-box;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding-block: 2.5rem;
  padding-inline: 4.5rem;
  overflow-wrap: break-word;

  @media only screen and (min-width: 1200px) {
    padding-inline: 1.875rem;
  }
}

.history-timeline-card__image {
  display: inline-block;
  position: relative;
  margin-bottom: 0.625rem;

  img {
    max-width: 160px;
    max-height: 130px;
    @media only screen and (min-width: 1200px) {
      max-width: 180px;
    }
  }
}

.history-timeline-card__date {
  font-weight: 600;
  font-style: normal;
  color: #e03e52;
}

.history-timeline-card__title {
  font-size: 1.875rem;
  line-height: 2.375rem;
  margin: 0.625rem 0;
}

.history-timeline-card__description {
  font-size: 0.938rem;
  line-height: 1.438rem;
}
.history-timeline-card__steps {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%);

  .is-last-slide & {
    display: none;
  }
}
</style>
