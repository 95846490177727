<template>
  <div class="wave-container">
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink" 
      viewBox="8 0 2048 25" 
      preserveAspectRatio="none"
      class="svg-wave"
      :class="{'wave-bottom': isBottomWave, 'wave-top': !isBottomWave}"
    >
      <path 
        d="M1833.1,1.6c-146.3,0-360.7,22.4-527,22.4s-274-10.6-274-10.6S955.4,1.6,809.1,1.6S448.4,24,282,24 S8,13.4,8,13.4V25h1024h1024V13.4C2056,13.4,1979.4,1.6,1833.1,1.6z"
        :style="{'fill': waveFill}"
      >
      </path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Wave',
  props: {
    isBottomWave: {
      type: Boolean,
      required: true
    },
    waveFill: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
 .wave-container {
    overflow: hidden;
  }

.svg-wave {
  animation: wave 16s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 200vw;
  display: block; 
  bottom: -5px;
  height: 20px;

  path {
    transition: all 300ms ease;
  }
}

.wave-bottom {
  bottom: 0;
}

.wave-top {
  bottom: 0;
}

@keyframes wave {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw); 
    }
}
</style>