<template>
  <div class="wave-container">
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink" 
      x="0px"
      y="0px"
      viewBox="8 0 2048 25"
      preserveAspectRatio="none"
      class="svg-wave"
      :class="{'wave-bottom': isBottomWave, 'wave-top': !isBottomWave}"
    >
      <path 
        d="M2048 11.6V0H0v11.6S107.6 1 274 1s380.8 22.4 527.1 22.4 223-11.8 223-11.8S1131.8 1 1298.1 1s380.7 22.4 527 22.4S2048 11.6 2048 11.6z"
        :style="{'fill': waveFill}"
      >
      </path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ReverseWave',
  props: {
    isBottomWave: {
      type: Boolean,
      required: true
    },
    waveFill: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
 .wave-container {
    overflow: hidden;
    background: transparent;
  }

.svg-wave {
  animation: wave 16s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 200vw;
  display: block; 
  bottom: -5px;
  height: 20px;
  path {
    transition: all 300ms ease;
  }
}

.wave-bottom {
  bottom: 0;
}

.wave-top {
  bottom: 0;
}

@keyframes wave {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw); 
    }
  }
</style>