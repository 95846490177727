const addSwiperClasses  = () => { 

    const swiperSimpleElements = document.querySelectorAll('.c-teaser-slider');
        swiperSimpleElements.forEach((element) => {
            element.classList.add('swiper');
    }); 

   if (document.querySelector('.c-innocent-slider')) {
       document.querySelector('.c-innocent-slider').classList.add('swiper');
   }

   if (document.querySelector('.c-olympics-slider')) {
    document.querySelector('.c-olympics-slider').classList.add('swiper');
    }

    const sliderElements = document.querySelectorAll('.c-kids-teaser-slider');
    sliderElements.forEach((element) => {
        element.classList.add('swiper');
    });

   if (document.querySelector('.c-kids-social-feed')) {
        document.querySelector('.c-kids-social-feed').classList.add('swiper');
   }

   if (document.querySelector('.c-simple-carousel')) {
        document.querySelector('.c-simple-carousel').classList.add('swiper');
    }

    document.querySelectorAll(".swiper .cmp-container").forEach((el) => {
        el.classList.add('swiper-wrapper');
    });

    document.querySelectorAll(".swiper-wrapper > div:not(.text)").forEach((el) => {
        el.classList.add('swiper-slide');
    });

    document.querySelectorAll(".swiper").forEach((el) => {
        const pagination = document.createElement('div');
        const buttonNext = document.createElement('div');
        const buttonPrev = document.createElement('div');

        pagination.classList.add('swiper-pagination');
        buttonNext.classList.add('swiper-button-next');
        buttonPrev.classList.add('swiper-button-prev');
        el.appendChild(pagination);
        el.appendChild(buttonNext);
        el.appendChild(buttonPrev);
    });
}

const onDocumentReady = () => {
    addSwiperClasses();
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
