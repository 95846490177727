import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const markerLeftSvg = () => {
  const markerLeft = SVG();

  markerLeft.svg('<g><path d="M8.23,65.71l.06-.15.15-.09c1-.62,2.11-1.24,3.34-1.89.68-.36,1.39-.74,2.11-1.14a14.54,14.54,0,0,0,1.59-1.12c1.72-1.3,4.07-3.09,6.07-1.58l.14.16c.94,1.65-.47,2.63-1.41,3.28l-.41.29a26,26,0,0,1-3.42,2.12l-1.52.86a17.45,17.45,0,0,1-6.38,2.66l-.36,0L8,68.92c-1.08-1.07-.44-2.07,0-2.73a4.2,4.2,0,0,0,.28-.48" transform="translate(-1 -0.74)" style="fill-rule: evenodd"></path><path d="M18.69,9.32l-.18.09a2.88,2.88,0,0,1-1.35.33A5,5,0,0,1,14.85,9a4.11,4.11,0,0,0-.42-.21C12.69,8,11,7.28,9.28,6.62,8,6.1,6.68,5.57,5.35,5l-.21-.14A4,4,0,0,0,4,4.08C3.12,3.61,2,3,2,1.8c0-.44.36-.79,1-1A2.4,2.4,0,0,1,4.87,1L8.11,2.3c2.32.91,5,1.94,7.14,2.89l.61.25c1.55.63,3.9,1.58,2.9,3.73Z" transform="translate(-1 -0.74)" style="fill-rule: evenodd"></path><path d="M1.29,32.39c.68-.76,2.27-.67,3.55-.61l.63,0a59.93,59.93,0,0,1,9.59.77l.26.05.13.19c.63.9.72,1.62.26,2.15a3.57,3.57,0,0,1-2.7.77,29.38,29.38,0,0,1-4.13-.41L8,35.2c-.19,0-.55,0-1,0-2.81-.08-5.37-.25-5.85-1.32a1.26,1.26,0,0,1,.12-1.45" transform="translate(-1 -0.74)" style="fill-rule: evenodd"></path></g>');
  markerLeft.viewbox(0, 0, 21, 68.41).addClass('title-marker-left');


  return svgHelper(markerLeft);
}

export default markerLeftSvg;