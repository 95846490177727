<template>
  <div class="product-tabs-root">
    <button
      @click="tabSelected"
      @mouseover="updateHover(true)"
      @mouseleave="updateHover(false)"
      :class="{ 'button-active': isActive, 'button-non-active': !isActive }"
    >
      <svg
        v-if="tabName === 'about'"
        class="about-svg"
        width="170"
        height="49"
        viewBox="0 0 170 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M133.893 14.2513C24.8945 -4.74854 -24.232 37.2171 16.3943 43.2516C52.286 48.5828 170.658 50.7515 168.171 27.2516C167.011 16.2883 139.894 -1.7484 96.3929 3.25137"
          stroke="#212721"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>

      <svg
        v-if="tabName === 'ingredients'"
        class="ingredients-svg"
        width="111"
        height="6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 4c53.008-2.706 88.593-2.706 106.756 0"
          stroke="#212721"
          stroke-width="3"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
        ></path>
      </svg>
      <svg
        v-if="tabName === 'nutrition'"
        class="nutrition-svg"
        width="46"
        height="13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.337 2.124c-4.926-.218-10-.247-15.679.74C3.28 3.973 1.593 5.67 2.374 7.396 3.508 9.904 16.174 11 23.109 11c7.383 0 22.015-.93 20.599-5.33-.758-2.356-4.74-2.436-10.789-3.07"
          stroke="#212721"
          stroke-width="3"
          fill="none"
          stroke-linecap="round"
        ></path>
      </svg>
      <svg
        v-if="tabName === 'sustainability'"
        class="sustainability-svg"
        width="123"
        height="8"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 3.886c5.645 2.185 10.356 2.185 14.134 0C21.802.61 21.817 7.312 27.997 5.054c6.18-2.257 5.447-3.381 10.495-1.168C43.541 6.1 50.066 6.222 53.6 5.054 57.132 3.886 61.047.49 67.322 2.771c6.275 2.283 12.64 4.566 16.456 2.283C87.593 2.771 95.54 1.673 100.4 3.886c4.86 2.214 6.131 2.223 10.078 1.168 3.947-1.054 8.005-2.336 10.276-1.168"
          stroke="#212721"
          stroke-width="3"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
        ></path>
      </svg>

      <span 
        class="btn-text"
        :style="{ 'color': getTextColor }"
      >{{ translatedTitle }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "ProductTileTab",
  props: {
    tabName: {
      type: String,
      required: true,
    },
    translatedTitle: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      hoverActive: false
    }
  },
  emits: ["update-tab"],
  computed: {
    isActive () {
      return this.tabName === this.activeTab || this.hoverActive;
    },
    getTextColor () {
      return this.tabName === this.activeTab ? '#FFF' : this.textColor;
    }
  },
  methods: {
    tabSelected () {
      this.$emit("updateTab", this.tabName);
    },
    updateHover (event) {
      this.hoverActive = event
    }
  },
};
</script>

<style lang="scss" scoped>
.product-tabs-root .btn-text {
  cursor: pointer;
  font-family: Innocent Bold;
  font-size: 20px;
  font-weight: 700;
  white-space:nowrap;
  color: rgb(33, 39, 33);
}
.button-active .btn-text {
  color: white;
}
.product-tabs-root {
  position: relative;
  margin: 5px 25px;

  @media only screen and (max-width: 768px) {
    flex: 0 0 50%; 
    max-width: 50%;
    margin: 0 0 30px 0; 
    text-align: center;
  }

  button {
    padding: 10px;
    svg path {
      -webkit-transition: stroke-dashoffset 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      transition: stroke-dashoffset 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
      stroke: white;
    }
  }
  .button-active svg path {
    stroke-dashoffset: 0;
  }
  .about-svg path {
    stroke-dashoffset: 398.965576171875px;
    stroke-dasharray: 398.965576171875px;
  }

  .ingredients-svg path {
    stroke-dashoffset: 108.87654876708984px;
    stroke-dasharray: 108.87654876708984px;
  }

  .nutrition-svg path {
    stroke-dashoffset: 83.21174621582031px;
    stroke-dasharray: 83.21174621582031px;
  }
  .sustainability-svg path {
    stroke-dashoffset: 125.54161071777344px;
    stroke-dasharray: 125.54161071777344px;
  }
  .about-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -webkit-calc(-50% - 2px)); 
    transform: translate(-50%, calc(-50% - 2px));
  }

  .ingredients-svg {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -5px);
    transform: translate(-50%, -5px);
  }

  .nutrition-svg {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
  }
  .sustainability-svg {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -7px);
    transform: translate(-50%, -7px);
  }
}
</style>