<template>
  <div class="product-tile-title-container">
    <div class="marker left-marker">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 68.41">
        <g>
          <path
            d="M8.23,65.71l.06-.15.15-.09c1-.62,2.11-1.24,3.34-1.89.68-.36,1.39-.74,2.11-1.14a14.54,14.54,0,0,0,1.59-1.12c1.72-1.3,4.07-3.09,6.07-1.58l.14.16c.94,1.65-.47,2.63-1.41,3.28l-.41.29a26,26,0,0,1-3.42,2.12l-1.52.86a17.45,17.45,0,0,1-6.38,2.66l-.36,0L8,68.92c-1.08-1.07-.44-2.07,0-2.73a4.2,4.2,0,0,0,.28-.48"
            transform="translate(-1 -0.74)" style="fill-rule: evenodd"/>
          <path
            d="M18.69,9.32l-.18.09a2.88,2.88,0,0,1-1.35.33A5,5,0,0,1,14.85,9a4.11,4.11,0,0,0-.42-.21C12.69,8,11,7.28,9.28,6.62,8,6.1,6.68,5.57,5.35,5l-.21-.14A4,4,0,0,0,4,4.08C3.12,3.61,2,3,2,1.8c0-.44.36-.79,1-1A2.4,2.4,0,0,1,4.87,1L8.11,2.3c2.32.91,5,1.94,7.14,2.89l.61.25c1.55.63,3.9,1.58,2.9,3.73Z"
            transform="translate(-1 -0.74)" style="fill-rule: evenodd"/>
          <path
            d="M1.29,32.39c.68-.76,2.27-.67,3.55-.61l.63,0a59.93,59.93,0,0,1,9.59.77l.26.05.13.19c.63.9.72,1.62.26,2.15a3.57,3.57,0,0,1-2.7.77,29.38,29.38,0,0,1-4.13-.41L8,35.2c-.19,0-.55,0-1,0-2.81-.08-5.37-.25-5.85-1.32a1.26,1.26,0,0,1,.12-1.45"
            transform="translate(-1 -0.74)" style="fill-rule: evenodd"/>
        </g>
      </svg>
    </div>
    <div class="title">
      {{ productTitle }}
    </div>
    <div class="marker right-marker">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 68.94">
        <g>
          <path
            d="M6.14,6.06c.58-.29,1.13-.55,1.6-.83.74-.44,1.46-.89,2.19-1.35,1.42-.88,2.89-1.8,4.4-2.58l.17-.09h.21a2.23,2.23,0,0,1,1.68.61,1.8,1.8,0,0,0,.24.19l.29.17-.05.26a3.94,3.94,0,0,1-1.73,2.3,5.1,5.1,0,0,0-1.06,1L14,5.86l-.16.06a30,30,0,0,0-2.71,1.56c-.52.32-1.07.66-1.67,1-.33.2-.68.43-1,.66C7.17,10,5.57,11,4,10.94a3.31,3.31,0,0,1-1.74-.58L2,10.22V10c0-2,2.22-3,4.14-3.95"
            transform="translate(-0.92 -1.21)" style="fill-rule: evenodd"/>
          <path
            d="M2.31,37.35l.29,0a57.74,57.74,0,0,0,8-1.35c2.07-.45,4.22-.92,6.56-1.2l.19,0,.18.06a3.73,3.73,0,0,1,2.14.73.77.77,0,0,1,.2.76c-.42,1.31-2.68,2-5.37,2.72-1.93.51-4.45,1.06-6.27,1.35a21.84,21.84,0,0,1-4.38.37,4.71,4.71,0,0,1-2.78-.84l-.17-.15v-.19a2.88,2.88,0,0,1,1.2-2Z"
            transform="translate(-0.92 -1.21)" style="fill-rule: evenodd"/>
          <path
            d="M3.73,60.44,4,60.26l.37.06c.51.08.72.1,1.11.14l1,.12,2,1.11,3.88,2.2,1.35.74c2,1.1,4.34,2.34,5.13,3.82l.32.6-.54,0c0,.08,0,.15,0,.21l-.13.47-.68-.06a1.06,1.06,0,0,0-.16.15l-.3.31-.4,0a6.09,6.09,0,0,1-3.24-1.34c-.25-.18-.5-.35-.75-.5l-.27-.15a65.61,65.61,0,0,1-9.46-6.3l-1-.89L3.35,61a.81.81,0,0,1,.38-.59"
            transform="translate(-0.92 -1.21)" style="fill-rule: evenodd"/>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ProductTileTitle',
    props: {
      productTitle: {
        type: String,
        required: true
      }
    }
  }
</script>
<style lang="scss" scoped>
.product-tile-title-container{
  display: inline-grid;
	grid-template-columns: 21px auto 21px;
	gap: 15px;
	align-items: center;
  color: #FFF;
  fill: #FFF;
  margin-bottom: 20px;

  & .title {
    text-align: center;
    font-size: 3rem;
    line-height: 3.375rem;
  }
}

</style>
