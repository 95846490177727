import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const videoPlayButtonBlackSvg = () => {
  const playButton = SVG();
  playButton
  .x(0)
  .y(0)
  .viewbox('0 0 84.4 75.3')
  .addClass('video-button-black');

  const groupOne = playButton.group();
  groupOne.path('M4.3,16.1c-2.4,4.2-2.8,8.3-3.6,16.5C0,39.4-0.5,44,0.9,49.8c0.7,2.9,2.7,10.6,9.2,16.6c6,5.6,12.7,6.7,20.9,8.2c0.8,0.1,17.6,2.9,34.6-3.6c1.3-0.5,1.9-0.7,2.8-1.2c9-4.8,11.8-15.4,13.7-22.8c1.6-6.1,2.6-9.9,2.1-15.3C84,28.3,82.6,17,73.6,9.1c-6.7-5.9-14-6.8-25.1-8.2C40.9,0,33.2-1,23.9,2.1C19.2,3.7,9.4,6.9,4.3,16.1z')
    .addClass('video-potato');
  groupOne.path('M56.6,34c-6.3-3.4-11.6-6.2-12.6-6.8c-4.8-2.7-12.6-8.5-15.1-9.3c-0.3-0.1-1.1-0.4-1.7,0c-0.5,0.3-0.9,0.8-0.9,1.5v35.2c0,1.3,1.4,2.1,2.6,1.5c0.3-0.2,0.7-0.4,1.1-0.7c5.8-3.4,14-8.6,21.9-12.8c3.2-1.7,5.8-3.2,7.4-4.1c0.4-0.3,0.8-0.8,0.8-1.4C60.2,35.9,58.6,35.1,56.6,34z')
    .addClass('video-button');

  return svgHelper(playButton);
}

export default videoPlayButtonBlackSvg;
