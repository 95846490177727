import Swiper, { Navigation, Pagination } from "swiper";
import swiperSimpleBulletSvg from "../../svg/general/icons/swiperSimpleBullet";
import "swiper/swiper.css";

Swiper.use([Navigation, Pagination]);

const swiperSimple = (element) => {
  const swiper = new Swiper(element, {
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,
    autoHeight: true,

    pagination: {
      el: element.querySelector(".swiper-pagination"),
      clickable: true,
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const simpleArrowContainer = document.createElement("div");
  const swiperArrowNext = element.querySelector(".swiper-button-next");
  const swiperArrowPrev = element.querySelector(".swiper-button-prev");

  simpleArrowContainer.classList.add("swiper-navigation");

  simpleArrowContainer.appendChild(swiperArrowPrev);
  simpleArrowContainer.appendChild(swiperArrowNext);

  element.insertBefore(
    simpleArrowContainer,
    element.querySelector(".swiper-pagination")
  );
};

const setupTeaserSwipers = () => {
  const teaserElements = document.querySelectorAll(".c-teaser-slider");

  teaserElements.forEach((element) => {
    swiperSimple(element);
  });

  swiperSimpleBulletSvg().addToClass(
    ".c-teaser-slider .swiper-pagination-bullet"
  );
};

const onDocumentReady = () => {
  setupTeaserSwipers();
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
