import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const portraitBottomSvg = () => {
  const portraitBottom = SVG();
  portraitBottom.width(375)
    .height(247)
    .viewbox('0 0 375 247')
    .addClass('portrait-bottom')
    .fill('none');
  
  const styleOne = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };

  const styleTwo = {
    'stroke': '#222721',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };

  const groupOne = portraitBottom.group();
  groupOne.css(styleOne);
  groupOne.path('M455.812 260.351C453.622 252.64 449.952 245.668 446.626 238.218C444.696 233.998 442.214 230.052 439.246 226.485C426.529 211.802 415.83 197.406 403.713 182.391C400.157 178.465 396.608 174.533 393.224 170.358C391.826 168.647 390.032 167.019 388.756 165.065C383.906 159.193 378.471 153.831 372.534 149.061C365.563 143.137 358.668 137.64 351.288 132.15');
  groupOne.path('M357.232 190.054C357.232 190.054 376.256 182.259 424.48 208.771');
  groupOne.path('M370.3 172.375C370.3 172.375 374.296 180.827 394.124 194.719');
  groupOne.path('M314.683 103.428C315.749 104.277 316.292 110.323 316.752 111.887C317.65 114.965 318.74 117.984 320.014 120.927C322.567 126.73 325.938 132.232 330.477 136.688C335.98 142.089 343.756 147.822 350.778 141.534C352.588 139.82 354.147 137.857 355.406 135.705C364.082 121.661 354.768 114 351.806 112.404C348.844 110.808 338.285 107.725 328.562 106.403C323.856 105.827 319.212 104.832 314.683 103.428Z').fill('#A3D380');
  groupOne.path('M316.458 105.096C316.458 105.096 332.118 124.516 355.4 135.739');
  groupOne.path('M347.375 142.551C347.375 142.551 347.592 160.623 352.118 169.095C356.645 177.566 363.431 191.598 372.547 176.296C372.547 176.296 382.301 176.455 383.31 168.405C384.319 160.355 378.484 149.203 365.435 145.672C352.386 142.142 347.375 142.551 347.375 142.551Z').fill('#A3D380');
  groupOne.path('M347.375 142.552C347.375 142.552 365.071 162.923 372.528 176.329');
  groupOne.path('M387.772 93.3365C386.088 96.4312 384.151 99.382 381.982 102.159C378.337 106.845 363.846 126.763 372.228 135.17C380.61 143.578 384.185 140.635 384.185 140.635C384.185 140.635 393.416 146.489 397.138 134.462C400.859 122.434 397.718 109.309 393.69 102.082C389.662 94.8559 387.772 93.3365 387.772 93.3365Z').fill('#A3D380');
  groupOne.path('M372.074 249.046C372.074 249.046 371.691 237.127 378.497 229.448C385.302 221.768 394.667 215.607 407.939 218.167C421.211 220.727 417.866 230.546 417.866 230.546C417.866 230.546 424.888 242.273 417.866 249.589C410.844 256.905 395.414 251.3 395.414 251.3C395.414 251.3 379.991 243.69 372.074 249.046Z').fill('#A3D380');
  groupOne.path('M372.074 249.048C372.074 249.048 387.032 228.773 417.866 230.535');
  groupOne.path('M332.309 220.791C332.309 220.791 331.332 206.453 337.48 193.838C343.628 181.224 346.794 177.559 353.197 179.015C359.6 180.471 360.507 189.363 360.507 189.363C360.507 189.363 374.034 201.729 366.323 211.707C364.893 213.632 363.037 215.2 360.901 216.288C358.765 217.377 356.406 217.957 354.008 217.982C350.356 218.065 346.692 217.657 343.047 218.04C339.402 218.423 335.859 219.814 332.309 220.791Z').fill('#A3D380');
  groupOne.path('M332.309 220.791C332.309 220.791 345.875 200.911 360.475 189.363');
  groupOne.path('M78.3386 269.321C85.6099 265.944 91.9044 261.22 98.7671 256.764C102.632 254.195 106.138 251.123 109.192 247.628C121.685 232.754 134.217 219.922 147.131 205.584C150.451 201.46 153.771 197.33 157.346 193.333C158.808 191.68 160.129 189.624 161.865 188.099C166.902 182.385 171.341 176.171 175.112 169.553C179.862 161.74 184.209 154.066 188.467 145.933');
  groupOne.path('M187.35 179.519C187.35 179.519 169.986 181.785 151.6 199.87');
  groupOne.path('M157.965 232.065C157.965 232.065 139.93 231.861 106.766 250.036');
  groupOne.path('M101.787 222.789C101.787 222.789 101.148 250.137 85.8269 265.044');
  groupOne.path('M132.193 160.899C132.193 160.899 142.886 178.455 124.309 230.254');
  groupOne.path('M151.702 171.015C151.702 171.015 143.991 176.327 133.425 198.032');
  groupOne.path('M211.022 105.223C210.383 106.41 204.472 107.904 202.997 108.6C200.098 109.973 197.288 111.525 194.583 113.247C189.169 116.594 184.446 120.945 180.666 126.066C176.197 132.342 171.767 140.928 179.083 146.871C181.068 148.39 183.258 149.62 185.588 150.523C200.807 156.907 206.942 146.469 208.027 143.296C209.113 140.124 210.517 129.207 210.287 119.395C210.116 114.659 210.361 109.916 211.022 105.223Z').fill('#509689');
  groupOne.path('M209.662 107.241C209.662 107.241 192.949 125.754 185.569 150.524');
  groupOne.path('M177.544 143.674C177.544 143.674 159.733 146.732 152.079 152.541C144.425 158.351 131.65 167.224 148.185 173.832C148.185 173.832 149.57 183.484 157.678 183.216C165.785 182.948 175.878 175.428 177.308 161.977C178.738 148.526 177.544 143.674 177.544 143.674Z').fill('#509689');
  groupOne.path('M177.544 143.674C177.544 143.674 160.218 164.358 148.178 173.832');
  groupOne.path('M232.484 175.815C229.165 174.635 225.947 173.185 222.864 171.481C217.661 168.621 195.707 157.436 188.729 167.057C181.751 176.677 185.211 179.722 185.211 179.722C185.211 179.722 180.889 189.758 193.351 191.539C205.812 193.32 218.248 188.149 224.785 183.029C231.322 177.909 232.484 175.815 232.484 175.815Z').fill('#509689');
  groupOne.path('M230.422 176.276C230.422 176.276 202.192 172.79 185.211 179.723');
  groupOne.path('M206.795 230.521C206.795 230.521 202.167 230.796 187.612 224.341C173.056 217.887 161.502 212.442 157.141 228.172C152.781 243.902 167.279 244.202 171.097 244.131C183.43 244.061 194.704 234.868 206.795 230.521Z').fill('#006571');
  groupOne.path('M155.903 231.697C155.903 231.697 185.645 228.939 203.584 231.256');
  groupOne.path('M76.2766 184.849C76.2766 184.849 87.9783 182.596 96.6348 188.105C105.291 193.614 112.856 201.862 112.422 215.396C111.988 228.93 101.761 227.155 101.761 227.155C101.761 227.155 91.2851 235.927 82.9542 230.156C74.6232 224.385 77.7002 208.246 77.7002 208.246C77.7002 208.246 82.8073 191.82 76.2766 184.849Z').fill('#006571');
  groupOne.path('M76.2766 184.85C76.2766 184.85 98.6202 196.424 101.761 227.156');
  groupOne.path('M97.9117 141.126C97.9117 141.126 111.912 137.935 125.362 141.982C138.813 146.029 142.905 148.615 142.478 155.165C142.05 161.715 133.406 163.987 133.406 163.987C133.406 163.987 123.326 179.309 112.256 173.257C110.131 172.147 108.292 170.562 106.881 168.624C105.47 166.686 104.526 164.448 104.123 162.085C103.485 158.491 103.287 154.807 102.336 151.232C101.385 147.657 99.4055 144.478 97.9117 141.126Z').fill('#509689');
  groupOne.path('M97.9117 141.126C97.9117 141.126 119.674 151.391 133.381 163.987');

  const portraitBottomFlowerGroup = portraitBottom.group();
  portraitBottomFlowerGroup.css(styleOne);
  portraitBottomFlowerGroup.addClass('portrait-bottom-flower');
  portraitBottomFlowerGroup.path('M244.639 95.4818C245.38 95.169 238.657 82.4331 228.188 80.8882C217.718 79.3433 218.803 87.7956 223.132 91.8749C227.46 95.9542 236.289 98.9674 244.639 95.4818Z').fill('#D25582');
  portraitBottomFlowerGroup.path('M232.944 100.819C232.586 100.819 221.453 100.532 221.14 110.35C220.827 120.169 237.298 117.258 242.047 109.603C246.797 101.949 247.461 100.666 247.186 99.2232C246.912 97.7804 232.944 100.819 232.944 100.819Z').fill('#D25582');
  portraitBottomFlowerGroup.path('M247.359 98.2732C246.752 96.7538 246.127 97.1241 244.933 96.5815C237.221 93.0257 231.444 91.8063 225.373 92.6043C214.52 94.0215 219.506 100.942 222.474 101.542C226.138 102.135 229.846 102.404 233.557 102.346C237.579 102.391 243.267 100.367 246.899 97.9157C247.908 97.2071 247.844 99.5053 247.359 98.2732Z').fill('#D25582');
  portraitBottomFlowerGroup.path('M246.484 99.2868C246.484 99.2868 231.552 111.212 231.201 117.213C230.85 123.214 237.336 119.007 241.671 112.623C246.005 106.239 248.348 102.9 248.105 98.2398C248.105 98.2398 247.09 97.9653 246.484 99.2868Z').fill('#222721');
  portraitBottomFlowerGroup.path('M246.49 99.0832C246.49 99.0832 233.02 85.5493 233.32 79.5612C233.62 73.5731 239.609 78.4632 243.209 85.3067C246.81 92.1502 248.75 95.7125 247.991 100.315C247.991 100.315 246.95 100.475 246.49 99.0832Z').fill('#222721');
  portraitBottomFlowerGroup.path('M299.272 208.623C298.819 209.262 311.402 216.246 321.029 211.815C330.656 207.385 325.083 200.893 319.229 199.858C313.374 198.824 304.329 201.122 299.272 208.623Z').fill('#D25582');
  portraitBottomFlowerGroup.path('M306.116 197.739C306.429 197.56 315.883 191.674 310.757 183.292C305.631 174.91 293.457 186.388 293.687 195.421C293.916 204.455 294.057 205.859 295.085 206.912C296.113 207.966 306.116 197.739 306.116 197.739Z').fill('#D25582');
  portraitBottomFlowerGroup.path('M295.461 207.781C296.802 208.72 297.121 208.062 298.417 207.871C306.818 206.594 312.315 204.455 316.93 200.459C325.229 193.303 317.256 190.245 314.466 191.419C311.076 192.931 307.827 194.741 304.756 196.827C301.373 198.991 297.734 203.804 296.029 207.852C295.589 208.975 294.382 207.015 295.461 207.781Z').fill('#D25582');
  portraitBottomFlowerGroup.path('M295.64 206.452C295.64 206.452 301.545 188.284 298.577 183.081C295.608 177.878 292.467 184.945 292.346 192.657C292.225 200.368 292.097 204.448 294.855 208.208C294.855 208.246 295.857 207.888 295.64 206.452Z').fill('#222721');
  portraitBottomFlowerGroup.path('M295.736 206.619C295.736 206.619 314.434 210.545 317.441 215.71C320.448 220.874 312.781 220.082 306.027 216.348C299.272 212.613 295.678 210.692 293.789 206.427C293.814 206.421 294.593 205.693 295.736 206.619Z').fill('#222721');
  portraitBottomFlowerGroup.path('M249.465 99.4252C249.465 99.4252 269.951 102.113 283.651 133.375C297.351 164.637 275.684 184.223 275.684 184.223C275.684 184.223 269.409 192.317 234.948 204.53C200.488 216.742 180.787 230.021 175.438 246.829C170.088 263.638 174.474 283.645 188.671 300.837C202.869 318.029 216.761 322.115 222.659 339.14C228.558 356.166 225.066 366.853 214.475 373.684');
  portraitBottomFlowerGroup.path('M295.736 206.619C295.736 206.619 273.641 224.583 236.615 219.936C236.615 219.936 206.489 215.429 192.234 225.528');
  portraitBottomFlowerGroup.path('M293.591 99.8219C293.591 99.8219 276.182 103.327 279.208 123.838C279.208 123.838 284.04 127.854 287.022 118.654C290.003 109.455 288.164 104.655 293.591 99.8219Z').fill('#009D70');
  portraitBottomFlowerGroup.path('M287.124 164.301C289.269 161.342 291.561 158.493 293.993 155.765C298.379 150.773 308.197 143.757 312.723 143.087C312.723 143.087 303.518 167.901 287.124 164.301Z').fill('#009D70');
  portraitBottomFlowerGroup.path('M250.902 157.29C250.902 157.29 259.954 146.131 274.835 154.098C289.716 162.065 286.664 164.836 286.664 164.836C286.664 164.836 285.388 170.307 278.57 166.981C271.752 163.655 266.664 159.416 261.154 156.479C255.773 153.6 250.902 157.29 250.902 157.29Z').fill('#009D70');
  portraitBottomFlowerGroup.path('M267.825 200.12C267.825 200.12 268.208 215.384 248.757 220.229C248.757 220.229 248.016 214.937 255.396 210.564C260.046 207.734 264.237 204.213 267.825 200.12Z').fill('#009D70');
  portraitBottomFlowerGroup.path('M267.487 243.768C267.487 243.768 274.586 230.744 250.206 220.735C250.206 220.735 244.135 224.092 255.109 232.526C266.083 240.959 267.487 243.768 267.487 243.768Z').fill('#009D70');
  portraitBottomFlowerGroup.path('M186.335 200.057C186.335 200.057 196.026 218.168 183.111 233.126C183.111 233.126 175.246 229.379 182.754 213.904C182.754 213.904 186.016 204.928 186.335 200.057Z').fill('#009D70');
  portraitBottomFlowerGroup.path('M224.804 237.216C224.804 237.216 200.603 220.81 182.862 234.548C182.862 234.548 190.044 247.539 204.982 242.209C219.921 236.878 224.804 237.216 224.804 237.216Z').fill('#009D70');
  portraitBottomFlowerGroup.path('M250.468 126.787C250.468 126.787 255.173 116.369 268.24 119.51C281.308 122.65 279.521 125.415 279.521 125.415C279.521 125.415 279.636 129.883 273.724 128.702C267.813 127.521 263.018 125.274 258.199 124.119C253.379 122.963 250.468 126.787 250.468 126.787Z').fill('#009D70');

  const groupTwo = portraitBottom.group();
  const groupTwoFill = '#B42673';
  groupTwo.css(styleTwo);
  groupTwo.path('M9.50757 57.1919C11.1149 55.3479 12.3506 53.2105 13.1464 50.8974C14.3529 46.9968 11.8696 43.409 8.03927 42.528C5.66446 41.9854 3.79398 43.607 2.68318 45.554C2.01769 46.6851 1.58368 47.9372 1.40641 49.2375C1.06167 52.1103 2.23631 54.8554 3.67269 57.2749C4.24724 58.2452 4.85371 59.7199 6.15602 59.6816C7.45834 59.6433 8.75427 58.0792 9.50757 57.1919Z').fill(groupTwoFill);
  groupTwo.path('M26.8462 55.4042C28.4701 55.1251 30.1379 55.2556 31.6986 55.784C33.2592 56.3124 34.6634 57.222 35.7837 58.4301C39.6715 63.001 34.6729 68.0826 30.3957 69.3402C26.8663 70.3767 23.4338 71.7181 20.1368 73.3493C20.424 73.2089 19.6452 67.8719 19.6324 67.2144C19.5877 65.4333 19.4218 63.384 19.9452 61.7178C20.8071 58.9344 23.8203 56.017 26.8462 55.4042Z').fill(groupTwoFill);
  groupTwo.path('M2.68953 52.1485C2.26181 50.278 1.48936 48.3628 -0.0683155 47.2456C-2.06009 45.822 -5.92873 45.4071 -7.96519 46.9009C-10.3464 48.631 -10.4549 52.2123 -9.88036 54.8297C-9.28028 57.7216 -7.22466 60.1411 -4.77325 61.8137C-3.20688 62.8437 -1.53293 63.6999 0.218958 64.3672C2.0639 65.1142 2.77252 65.5674 3.05341 63.3522C3.41091 59.6687 3.51943 55.7937 2.68953 52.1485Z').fill(groupTwoFill);
  groupTwo.path('M8.52426 50.7759C9.03783 49.6197 9.83223 48.6103 10.8352 47.8393C12.2014 46.9136 13.9697 46.837 15.5976 47.086C17.95 47.4615 20.1648 48.4403 22.0262 49.9268C23.469 51.0759 24.7266 52.6655 24.8032 54.5041C24.867 55.9724 24.1648 57.3577 23.4434 58.6345C22.8736 59.7112 22.163 60.7073 21.3304 61.5966C19.9642 62.9564 18.1384 63.7352 16.5424 64.7885C12.7887 67.291 10.9438 71.0894 8.28168 74.5367C5.51107 73.5472 5.44084 66.5249 5.39616 64.3225C5.30342 59.6178 6.37816 54.9636 8.52426 50.7759Z').fill(groupTwoFill);
  groupTwo.path('M39.3586 70.4955C40.5807 71.3192 41.5051 72.5145 41.9951 73.9045C42.2922 75.1136 42.2658 76.3796 41.9185 77.5752C41.5492 79.2523 40.7482 80.8037 39.5948 82.0759C38.3758 83.2268 36.9205 84.0982 35.3304 84.6294C32.4962 85.8148 29.3855 86.1776 26.3546 85.6764C24.8416 85.3189 23.3665 84.8165 21.9497 84.1762L12.476 80.2629C12.7505 80.3778 17.3533 75.7047 17.8959 75.2515C19.2453 74.1024 20.6747 73.0507 22.1732 72.1042C25.6077 69.9912 28.7741 67.744 33.0258 68.1015C35.3176 68.2978 37.51 69.1266 39.3586 70.4955Z').fill(groupTwoFill);
  groupTwo.path('M39.2946 87.7381C40.9225 89.832 41.3119 93.0367 39.6649 95.1179C39.0391 95.831 38.2609 96.3941 37.3878 96.7655C36.5148 97.1368 35.5693 97.3068 34.6216 97.2629C32.7372 97.1592 30.8905 96.6931 29.1825 95.8903C25.9906 94.5625 22.8816 92.8069 20.6983 90.1448C20.5012 89.9636 20.3555 89.7336 20.2759 89.478C20.1963 89.2224 20.1857 88.9503 20.2451 88.6893C20.3899 88.4179 20.6121 88.1957 20.8834 88.0509C22.639 86.9401 24.4584 85.9761 26.2842 84.993C27.312 84.4312 28.1994 83.7162 29.3996 83.5694C32.7447 83.1864 37.239 85.0824 39.2946 87.7381Z').fill(groupTwoFill);
  groupTwo.path('M13.4591 78.5899C19.1344 79.3113 25.1927 80.3965 29.2528 84.4248C30.7339 85.893 31.9149 87.8593 31.7681 89.9404C31.6021 92.4365 29.5082 94.4922 27.1334 95.2902C24.7586 96.0881 22.1603 95.8264 19.7089 95.3285C16.5985 94.7139 13.5899 93.6652 10.7715 92.2131C9.04812 91.4003 7.49651 90.2648 6.20058 88.868C4.51524 86.8889 2.37025 82.9182 4.11305 80.4285C6.04099 77.6642 10.6693 78.2324 13.4591 78.5899Z').fill(groupTwoFill);
  groupTwo.path('M10.497 66.7459C12.3164 64.7286 14.6274 62.8645 17.3406 62.7369C19.1919 62.6539 20.973 63.3753 22.6073 64.2626C24.1011 65.0542 25.5949 66.0246 26.4376 67.4993C27.5867 69.5421 27.1782 72.217 25.8631 74.1577C24.4494 76.0492 22.5214 77.494 20.3091 78.3199C15.202 80.4777 9.45646 80.6692 3.92801 80.8224C2.71507 80.8607 5.49206 74.3108 5.58144 74.1321C6.85376 71.4451 8.50958 68.957 10.497 66.7459Z').fill(groupTwoFill);
  groupTwo.path('M12.6739 67.3415C13.2612 65.2859 13.6378 63.026 12.8015 61.0597C11.9652 59.0935 10.0309 57.7848 8.07746 56.8719C6.37934 56.0739 4.48971 55.4802 2.64476 55.7994C-0.745086 56.3804 -2.97307 60 -3.01137 63.4601C-3.04967 66.9202 -1.40263 70.1249 0.467851 73.0359C1.6361 74.8234 2.80436 76.6492 4.0173 78.4048C5.08979 79.9497 5.98992 79.8348 7.20925 78.4048C9.81388 75.3022 11.5886 71.1527 12.6739 67.3415Z').fill(groupTwoFill);
  groupTwo.path('M1.73189 69.9592C0.428286 67.3915 -1.1561 64.9764 -2.99219 62.7581C-4.83774 60.5339 -7.27089 58.8727 -10.0145 57.9638C-11.3222 57.481 -12.7664 57.5245 -14.0427 58.0851C-15.1727 58.6852 -15.8621 59.8471 -16.475 60.9706C-17.2162 62.1267 -17.6835 63.4368 -17.8411 64.801C-17.9369 67.3545 -15.9643 69.5506 -13.9661 71.1849C-10.1723 74.3135 -5.83284 76.7138 -1.1664 78.2646C0.474264 78.8136 2.61287 79.4712 4.31099 78.8647C4.11843 75.7423 3.23771 72.7012 1.73189 69.9592Z').fill(groupTwoFill);
  groupTwo.path('M10.0309 80.6644C10.0908 81.1255 10.0292 81.5943 9.85217 82.0242C9.66045 82.3804 9.38682 82.686 9.05385 82.9158C8.72088 83.1455 8.33809 83.2928 7.93701 83.3456C7.12483 83.4283 6.30436 83.3369 5.53028 83.0775C3.42129 82.4738 1.46346 81.4318 -0.215229 80.0197C-0.464456 79.8352 -0.669447 79.5974 -0.815312 79.3238C-1.12812 78.6216 -0.655714 77.8427 -0.176922 77.2171C-0.0132393 76.9603 0.204652 76.7424 0.461467 76.5787C0.886238 76.4138 1.35442 76.3981 1.78931 76.534C3.56158 76.9136 5.27792 77.5186 6.89643 78.3343C8.26897 78.9535 9.80749 78.7237 10.0309 80.6644Z').fill('#1F2720');
  groupTwo.path('M23.3542 86.9546C17.0785 86.9433 10.9256 85.2147 5.56226 81.956');
  groupTwo.path('M20.3857 68.1521C17.3288 73.6586 12.4178 77.9023 6.52625 80.1283');
  groupTwo.path('M7.08799 63.8684C5.61064 66.0017 4.75529 68.5037 4.61742 71.0949C4.48988 73.676 4.68937 76.2629 5.21112 78.7939');
  groupTwo.path('M-8.73767 66.5879C-5.88483 70.8307 -2.06158 74.3323 2.41499 76.8022');
  groupTwo.path('M76.4235 68.931C78.0287 67.0854 79.264 64.9484 80.0623 62.6364C81.2689 58.7359 78.7855 55.1417 74.9552 54.2672C72.5868 53.7245 70.7163 55.346 69.6055 57.2931C68.94 58.4242 68.506 59.6763 68.3287 60.9766C67.9776 63.8494 69.1586 66.5945 70.5886 69.014C71.1632 69.9843 71.7696 71.459 73.0783 71.4207C74.387 71.3824 75.6638 69.8183 76.4235 68.931Z').fill(groupTwoFill);
  groupTwo.path('M93.7429 67.1626C95.3668 66.8812 97.0353 67.0106 98.5963 67.5391C100.157 68.0677 101.561 68.9785 102.68 70.1885C106.574 74.7594 101.576 79.8346 97.2987 81.0986C93.7693 82.1352 90.3368 83.4765 87.0398 85.1077C87.3271 84.9673 86.5482 79.6303 86.5354 78.9728C86.4908 77.1853 86.3248 75.1425 86.8483 73.4763C87.7037 70.6737 90.7169 67.7499 93.7429 67.1626Z').fill(groupTwoFill);
  groupTwo.path('M69.5862 63.8875C69.1585 62.017 68.3861 60.0955 66.8284 58.9783C64.8366 57.5611 60.968 57.1461 58.9251 58.6336C56.5503 60.37 56.4418 63.9514 57.01 66.5624C57.61 69.4543 59.6657 71.8802 62.1171 73.5847C63.6812 74.6146 65.353 75.4708 67.1029 76.1382C68.9542 76.8851 69.6948 77.3384 69.9437 75.1232C70.3076 71.4077 70.4161 67.5327 69.5862 63.8875Z').fill(groupTwoFill);
  groupTwo.path('M75.4212 62.5151C75.9345 61.3571 76.7289 60.3455 77.7322 59.5722C79.0919 58.6529 80.8666 58.5763 82.4945 58.8253C84.8459 59.1923 87.0613 60.1647 88.9231 61.6469C90.3659 62.796 91.6235 64.3793 91.7001 66.2242C91.7639 67.6925 91.0617 69.0714 90.3403 70.3482C89.7722 71.4279 89.0614 72.4264 88.2273 73.3167C86.8611 74.6701 85.0353 75.4553 83.4394 76.5087C79.6856 79.0175 77.8407 82.8159 75.1786 86.2632C72.408 85.2673 72.3378 78.2515 72.2931 76.049C72.2019 71.3486 73.2766 66.6988 75.4212 62.5151Z').fill(groupTwoFill);
  groupTwo.path('M106.255 82.2297C107.475 83.0594 108.398 84.2556 108.892 85.6451C109.189 86.852 109.163 88.116 108.815 89.3094C108.442 90.9756 107.641 92.5158 106.492 93.7781C105.275 94.9321 103.819 95.8039 102.227 96.3317C99.3905 97.5081 96.2799 97.862 93.2514 97.3531C91.7383 96.9956 90.2633 96.4932 88.8465 95.8529L79.3728 91.9396C79.6473 92.0481 84.2501 87.3815 84.7927 86.9282C86.1421 85.7792 87.5715 84.7274 89.0699 83.781C92.5045 81.6679 95.6645 79.4208 99.9226 79.7783C102.22 79.9911 104.413 80.8399 106.255 82.2297Z').fill(groupTwoFill);
  groupTwo.path('M106.191 99.4712C107.819 101.572 108.209 104.776 106.562 106.857C105.935 107.569 105.157 108.132 104.284 108.503C103.411 108.874 102.466 109.045 101.518 109.002C99.6242 108.901 97.7669 108.437 96.0474 107.636C92.8555 106.308 89.7465 104.553 87.5632 101.891C87.2249 101.476 86.9249 100.933 87.11 100.442C87.2529 100.169 87.4756 99.9461 87.7484 99.8032C89.4976 98.6924 91.3234 97.7284 93.1492 96.7453C94.177 96.1835 95.0643 95.4685 96.2645 95.3217C99.6416 94.9195 104.136 96.8219 106.191 99.4712Z').fill(groupTwoFill);
  groupTwo.path('M80.356 90.3301C86.0313 91.0515 92.0832 92.1367 96.1434 96.1586C97.6244 97.6333 98.8054 99.5995 98.665 101.681C98.4926 104.177 96.3987 106.232 94.0303 107.024C91.6618 107.816 89.0572 107.567 86.6058 107.069C83.496 106.452 80.4877 105.403 77.6684 103.953C75.9584 103.132 74.4219 101.99 73.1422 100.589C71.4568 98.61 69.3118 94.6392 71.0546 92.1495C72.9379 89.3981 77.5279 89.9726 80.356 90.3301Z').fill(groupTwoFill);
  groupTwo.path('M77.3938 78.4885C79.2132 76.4712 81.5242 74.6007 84.2373 74.4794C86.0823 74.3964 87.8697 75.1178 89.4976 76.0051C90.9979 76.7968 92.4917 77.7671 93.328 79.2418C94.4771 81.2846 94.0685 83.9531 92.7534 85.9002C91.3397 87.7917 89.4117 89.2366 87.1994 90.0625C82.0923 92.2203 76.3468 92.4054 70.8184 92.565C69.6054 92.5969 72.3824 86.0534 72.4654 85.8747C73.7427 83.1874 75.4027 80.6995 77.3938 78.4885Z').fill(groupTwoFill);
  groupTwo.path('M79.5707 79.0805C80.158 77.0249 80.5347 74.7651 79.6984 72.7988C78.8621 70.8326 76.9278 69.5175 74.9743 68.611C73.2762 67.813 71.3866 67.2193 69.5416 67.5321C66.1518 68.1194 63.9238 71.7391 63.8855 75.1928C63.8472 78.6465 65.4878 81.8639 67.3647 84.7686C68.533 86.5561 69.6948 88.3755 70.9141 90.1375C71.9866 91.6824 72.8868 91.5674 74.1061 90.1375C76.7107 87.0413 78.4791 82.8917 79.5707 79.0805Z').fill(groupTwoFill);
  groupTwo.path('M68.6096 81.6986C67.3077 79.13 65.7232 76.7147 63.8856 74.4975C62.04 72.2733 59.6069 70.6121 56.8633 69.7032C55.5536 69.222 54.1089 69.2631 52.8287 69.8181C51.7051 70.4565 51.0092 71.5865 50.3964 72.71C49.6589 73.8673 49.1939 75.1771 49.0366 76.5404C48.9345 79.0939 50.9135 81.29 52.9053 82.9243C56.7037 86.0494 61.0447 88.4493 65.7114 90.004C67.352 90.553 69.4906 91.2106 71.1887 90.5977C70.9953 87.4775 70.1146 84.4387 68.6096 81.6986Z').fill(groupTwoFill);
  groupTwo.path('M76.2448 91.8884C76.2928 92.3507 76.2179 92.8176 76.0278 93.2417C75.8317 93.5983 75.551 93.9013 75.2104 94.1239C74.8697 94.3466 74.4796 94.4821 74.0743 94.5185C73.2626 94.588 72.4455 94.479 71.6803 94.1993C69.5893 93.5456 67.6597 92.4579 66.0178 91.0074C65.7738 90.8174 65.5753 90.5753 65.4369 90.2988C65.1432 89.5966 65.6284 88.8241 66.0753 88.2112C66.2486 87.9514 66.4776 87.7333 66.7456 87.5728C67.1782 87.4259 67.6472 87.4259 68.0798 87.5728C69.858 87.9872 71.5748 88.631 73.1869 89.488C74.5467 90.1456 76.0916 89.9477 76.2448 91.8884Z').fill('#1F2720');
  groupTwo.path('M90.2446 98.7111C83.9761 98.6878 77.8329 96.9528 72.4783 93.6934');
  groupTwo.path('M87.2762 79.893C84.2201 85.4011 79.3056 89.6435 73.4104 91.8628');
  groupTwo.path('M73.9847 75.6075C72.5074 77.7408 71.6521 80.2428 71.5142 82.8341C71.383 85.4131 71.5825 87.9983 72.1079 90.5267');
  groupTwo.path('M58.1592 78.3288C61.0121 82.5716 64.8353 86.0731 69.3119 88.543');
  groupTwo.path('M69.0181 93.7644C58.8039 107.675 -18.2561 204.085 -102 310.178');
  groupTwo.path('M23.2839 150.066C7.25397 132.472 0.0848619 107.179 4.48975 83.7627');
  groupTwo.path('M43.2271 70.5079C43.2271 70.5079 31.4872 98.8652 31.3084 113.829C31.1297 128.793 38.037 137.743 38.037 137.743C38.037 137.743 46.9745 126.393 47.5235 106.666C47.7986 94.4715 46.3522 82.2987 43.2271 70.5079Z').fill('#A3D380');
  groupTwo.path('M97.9627 121.974C97.9627 121.974 57.1888 116.598 38.7968 137.097C38.7968 137.097 45.985 141.496 62.4172 138.374C78.8493 135.252 95.3836 125.025 97.9627 121.974Z').fill('#A3D380');
  groupTwo.path('M96.0858 122.876L38.7968 137.099');
  groupTwo.path('M42.8952 72.3406L38.0179 137.744');

  const groupThree = portraitBottom.group();
  const groupThreeFill = '#FFF8CF';
  groupThree.css(styleOne);
  groupThree.path('M67.7158 116.191C67.7158 116.191 65.3473 144.963 48.5322 167.198C31.717 189.434 8.27532 198.09 8.27532 198.09C8.27532 198.09 1.32964 183.618 12.0418 170.001C22.754 156.384 45.9531 139.869 52.8987 134.411C59.8444 128.953 66.2858 118.987 67.7158 116.191Z').fill('#009D70');
  groupThree.path('M104.557 149.29C104.557 149.29 74.8723 197.98 47.1853 214.087C19.4984 230.193 -10.2314 232.6 -27.0594 223.662C-27.0594 223.662 -9.50368 195.222 18.6046 180.712C46.7129 166.201 74.719 159.645 75.7469 159.134C76.7747 158.623 101.263 151.99 104.557 149.29Z').fill('#009D70');
  groupThree.path('M28.8442 116.607C26.4056 117.501 25.6523 121.714 24.8862 123.853C20.7687 135.474 17.8134 147.475 16.0637 159.679C14.4485 171.125 13.8038 184.742 8.98392 195.429C4.94931 204.366 -17.905 226.914 -27.0595 223.665C-36.214 220.415 -33.1115 192.818 -10.2763 157.694C12.5589 122.569 28.8442 116.607 28.8442 116.607Z').fill('#009D70');
  groupThree.path('M-27.8511 222.72C-27.8511 222.72 0.876454 230.681 34.6728 207.335C68.4691 183.989 86.6377 176.233 101.589 150.703');
  groupThree.path('M8.07751 197.7C21.3496 188.973 36.3965 179.966 47.4406 176.033C73.206 166.86 92.6514 161.35 98.5501 154.966');
  groupThree.path('M-10.3975 216.758C9.94163 209.889 43.1634 197.287 60.9553 183.077C85.7695 163.255 96.7562 158.18 96.7562 158.18');
  groupThree.path('M66.6562 120.225C66.6562 120.225 39.1352 162.729 15.4893 172.101');
  groupThree.path('M13.5677 182.493C13.5677 182.493 40.7695 171.717 66.2221 123.193');
  groupThree.path('M27.7782 117.825C27.7782 117.825 23.8521 120.883 19.0067 128.677C11.8185 140.219 1.44465 160.156 -5.77554 188.003C-5.77554 188.003 -12.3509 210.525 -24.4803 220.816');
  groupThree.path('M16.7851 132.33C16.7851 132.33 13.5931 138.427 9.91602 158.172C8.35903 166.344 6.28218 174.408 3.69811 182.316C0.940266 190.8 -3.471 198.627 -8.43129 206.019C-12.8298 212.588 -17.7326 220 -25.6104 222.617');
  groupThree.path('M-10.2443 172.784C-10.2443 172.784 -3.73269 145.493 14.5827 126.82');
  groupThree.path('M12.8399 180.796C8.87545 182.315 4.43226 183.349 0.174206 182.89C-7.25026 182.111 -15.6387 174.54 -10.9657 166.611C-9.47857 164.316 -7.21075 162.638 -4.58179 161.887C0.525322 160.221 6.47511 160.221 11.4928 162.576C14.6848 164.064 24.0436 171.309 21.3624 175.542C19.7025 178.108 15.5658 179.711 12.8399 180.796Z').fill(groupThreeFill);
  groupThree.path('M-0.183228 172.55C-0.183228 172.55 9.7182 175.742 20.558 173.482L-0.183228 172.55Z').fill(groupThreeFill);
  groupThree.path('M-0.183228 172.55C-0.183228 172.55 9.7182 175.742 20.558 173.482');
  groupThree.path('M21.956 171.495C21.956 171.495 5.1472 156.812 7.18367 144.989C7.82206 141.427 9.50102 137.699 13.3697 136.926C20.5388 135.496 23.7307 142.844 25.2884 148.481C26.3328 152.342 26.576 156.375 26.0029 160.333C25.4298 164.291 24.0525 168.089 21.956 171.495Z').fill(groupThreeFill);
  groupThree.path('M24.2861 172.918C24.2861 172.918 45.4041 176.282 50.0707 163.202C54.7373 150.121 49.1386 143.961 40.9162 144.331C32.6937 144.701 25.2182 150.153 24.2861 172.918Z').fill(groupThreeFill);
  groupThree.path('M22.6072 176.097C22.6072 176.097 29.1443 191.233 47.4533 190.295C47.4533 190.295 61.0957 187.684 51.1943 174.603C41.2928 161.523 24.2861 172.918 24.2861 172.918L22.6072 176.097Z').fill(groupThreeFill);
  groupThree.path('M22.6073 174.414C22.6073 174.414 9.34153 180.957 11.9462 196.655C12.6675 200.964 14.5955 206.02 19.4664 206.799C27.4973 208.076 30.8169 200.237 31.8065 193.706C33.013 185.681 29.5402 178.608 22.6073 174.414Z').fill(groupThreeFill);
  groupThree.path('M22.0966 170.96C22.0966 170.96 22.7925 159.284 14.7551 150.684L22.0966 170.96Z').fill(groupThreeFill);
  groupThree.path('M22.0966 170.96C22.0966 170.96 22.7925 159.284 14.7551 150.684');
  groupThree.path('M22.4158 172.171C22.4158 172.171 35.6815 167.504 39.7927 154.985L22.4158 172.171Z').fill(groupThreeFill);
  groupThree.path('M22.4158 172.171C22.4158 172.171 35.6815 167.504 39.7927 154.985');
  groupThree.path('M42.7676 181.256C37.6066 177.894 31.7207 175.807 25.5949 175.166L42.7676 181.256Z').fill(groupThreeFill);
  groupThree.path('M42.7676 181.256C37.6066 177.894 31.7207 175.807 25.5949 175.166');
  groupThree.path('M20.7368 194.968C20.7368 194.968 24.1011 191.233 23.9287 175.178L20.7368 194.968Z').fill(groupThreeFill);
  groupThree.path('M20.7368 194.968C20.7368 194.968 24.1011 191.233 23.9287 175.178');
  groupThree.path('M19.4025 176.162C19.8455 176.925 20.5103 177.536 21.3085 177.912C22.1067 178.288 23.0005 178.413 23.8712 178.269C24.8411 177.999 25.7082 177.446 26.3609 176.679C27.2331 175.767 27.8152 174.616 28.0335 173.372C28.1368 172.753 28.1065 172.119 27.9447 171.512C27.7828 170.906 27.4931 170.34 27.095 169.855C25.5629 168.093 21.4836 167.595 19.6833 169.216C17.8831 170.838 18.33 174.413 19.4025 176.162Z').fill('white');

  return svgHelper(portraitBottom);
};

export default portraitBottomSvg;
