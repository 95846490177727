<template>
  <div class="history-timeline-controls-root">
    <div class="history-timeline-controls">
      <div 
        class="history-timeline-controls__go-to-slide"
        data-cy-go-to-slide="start"
        >
        <svg
          @click="goToSlide(0)"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="37px"
          height="35px"
          viewBox="0 0 37 35"
          version="1.1"
        >
          <title>Oval</title>
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
              transform="translate(-1111.000000, -908.000000)"
              stroke="#E03E52"
              stroke-width="3"
            >
              <g
                transform="translate(1129.500000, 926.326667) scale(-1, 1) translate(-1129.500000, -926.326667) translate(1105.000000, 901.826667)"
              >
                <path
                  d="M23.3856667,8.03333333 C28.0533584,8.03333333 32.1856689,10.0048117 35.1880101,13.065414 C38.3226223,16.260854 40.2343364,20.6440886 40.2343364,25.2380631 C40.2343364,29.6761019 38.9803997,33.2640448 36.5105831,35.8171136 C34.0168379,38.3949177 30.32544,39.890142 25.6308714,40.2655241 C21.0643058,40.630671 16.9260377,38.8697549 13.8859582,35.9452421 C10.6837594,32.8647729 8.68666667,28.4926044 8.68666667,23.9026768 C8.68666667,19.2725511 10.0694464,15.1587344 12.758773,12.2751096 C15.2462707,9.60789551 18.8429202,8.03333333 23.3856667,8.03333333 Z"
                  id="Oval"
                  transform="translate(24.460502, 24.173333) rotate(123.000000) translate(-24.460502, -24.173333) "
                ></path>
              </g>
            </g>
          </g>
        </svg>
        1
      </div>
      <div class="swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55.67"
          height="52.23"
          viewBox="0 0 55.67 52.23"
        >
          <path
            d="M5.62,38.87a26,26,0,0,0,16.81,11.3,27.62,27.62,0,0,0,20.42-3.78c5.9-3.83,9.56-8.61,10.82-14.09s.11-11.69-3.44-18.23A23.89,23.89,0,0,0,34.38,2.2,26.66,26.66,0,0,0,14,5.67C7.84,9.65,3.61,15,2.1,21.07c-1.4,5.61-.41,11.75,3.52,17.8Z"
            style="
              fill: none;
              stroke: #212721;
              stroke-width: 2.9999999853734174px;
            "
          ></path>
          <g>
            <path
              d="M43.9,25.78q-10.2-.33-15.14,0c-3.31.22-8.86.22-16.68,0"
              style="
                fill: none;
                stroke: #212721;
                stroke-linecap: round;
                stroke-width: 3px;
              "
            ></path>
            <path
              d="M18.11,20.36a15,15,0,0,0-2.65,2.22q-1.17,1.23-3.38,3.2,2.41,2.29,3.38,3.3a29,29,0,0,1,1.92,2.21"
              style="
                fill: none;
                stroke: #212721;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 3px;
              "
            ></path>
          </g>
        </svg>
        <span>back</span>
      </div>
    </div>
    <div class="history-timeline__slide-counter">
      <span>{{ slideCounter }}</span>
    </div>
    <div class="history-timeline-controls">
      <div 
        class="history-timeline-controls__go-to-slide"
        data-cy-go-to-slide="end"
        >
        <svg
          @click="goToSlide(lastSlide)"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="37px"
          height="35px"
          viewBox="0 0 37 35"
          version="1.1"
        >
          <title>Oval</title>
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
              transform="translate(-1111.000000, -908.000000)"
              stroke="#E03E52"
              stroke-width="3"
            >
              <g
                transform="translate(1129.500000, 926.326667) scale(-1, 1) translate(-1129.500000, -926.326667) translate(1105.000000, 901.826667)"
              >
                <path
                  d="M23.3856667,8.03333333 C28.0533584,8.03333333 32.1856689,10.0048117 35.1880101,13.065414 C38.3226223,16.260854 40.2343364,20.6440886 40.2343364,25.2380631 C40.2343364,29.6761019 38.9803997,33.2640448 36.5105831,35.8171136 C34.0168379,38.3949177 30.32544,39.890142 25.6308714,40.2655241 C21.0643058,40.630671 16.9260377,38.8697549 13.8859582,35.9452421 C10.6837594,32.8647729 8.68666667,28.4926044 8.68666667,23.9026768 C8.68666667,19.2725511 10.0694464,15.1587344 12.758773,12.2751096 C15.2462707,9.60789551 18.8429202,8.03333333 23.3856667,8.03333333 Z"
                  id="Oval"
                  transform="translate(24.460502, 24.173333) rotate(123.000000) translate(-24.460502, -24.173333) "
                ></path>
              </g>
            </g>
          </g>
        </svg>
        {{ lastSlide }}
      </div>
      <div class="swiper-button-next">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55.67"
          height="52.23"
          viewBox="0 0 55.67 52.23"
        >
          <path
            d="M50.05,38.87a26,26,0,0,1-16.81,11.3,27.61,27.61,0,0,1-20.42-3.78C6.92,42.56,3.26,37.78,2,32.3S1.89,20.61,5.43,14.07A23.89,23.89,0,0,1,21.29,2.2,26.66,26.66,0,0,1,41.7,5.67c6.13,4,10.36,9.36,11.87,15.4,1.39,5.61.4,11.75-3.52,17.8Z"
            style="
              fill: none;
              stroke: #212721;
              stroke-width: 2.9999999853734174px;
            "
          ></path>
          <g>
            <path
              d="M11.77,25.78q10.18-.33,15.14,0t16.67,0"
              style="
                fill: none;
                stroke: #212721;
                stroke-linecap: round;
                stroke-width: 3px;
              "
            ></path>
            <path
              d="M37.55,20.36a15.07,15.07,0,0,1,2.66,2.22c.78.82,1.91,1.89,3.37,3.2-1.6,1.53-2.73,2.63-3.37,3.3a25.18,25.18,0,0,0-1.92,2.21"
              style="
                fill: none;
                stroke: #212721;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 3px;
              "
            ></path>
          </g>
        </svg>
        <span>next</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryControls',
  props: {
    currentSlide: {
      type: Number,
      required: true,
    },
    lastSlide: {
      type: Number,
      required: true,
    },
  },
  emits: ["go-to-slide"],
  computed: {
    slideCounter() {
      return this.currentSlide + ' / ' + this.lastSlide
    }
  },
  methods: {
    goToSlide(slide) {
      this.$emit("go-to-slide", slide);
    },
  },
};
</script>

<style lang="scss" scoped>
.history-timeline-controls-root {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  outline: none;
  width: 100%;
  max-width: 1210px;
  @media only screen and (min-width: 1200px) {
    justify-content: flex-end;
  }
}

.history-timeline-controls {
  position: relative;
  padding-top: 25px;
  cursor: pointer;

  div {
    transition: transform 0.3s cubic-bezier(0.63, -0.01, 0.26, 1.49),
      -webkit-transform 0.3s cubic-bezier(0.63, -0.01, 0.26, 1.49);
    &:hover {
      transform: scale(0.9);
      transform-origin: center center;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.history-timeline-controls__go-to-slide {
  font-size: 0.875rem;
  position: absolute;
  top: 9px;
  right: -8px;
  font-weight: 700;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #212721;
  text-align: center;

  svg {
    position: absolute;
    cursor: pointer;
  }
}

.history-timeline__slide-counter {
  font-weight: 700;
  font-style: normal;
  margin: 0 1.875rem;
  font-size: 1.75rem;
  line-height: 52px;
}
</style>
