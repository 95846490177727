<template>
  <li 
    :style="{'color': navigationTitleColour}"
    @click="updateActiveTitle"
    class="nav-title"
  >
      {{ navigationTitle }}
    <div
      ref="title-underline"
      :style="{'stroke': navigationTitleColour }"
      :class="{'active': isActive, 'non-active': !isActive}"
      class="svg-selector" 
      ></div>
  </li>
</template>

<script>
import underlineSvg from '../../../../svg/general/underline/underline'; 

export default {
  name: 'AllProductsNavigationTitle',
  props: {
    navigationTitle: {
      type: String,
      required: true
    },
    navigationTitleColour: {
      type: String,
      required: true
    },
    navigationIndex: {
      type: Number,
      required: true
    },
    currentSlide: {
      type: Number,
      required: true
    }
  },
  emits: ['update-title'],
  mounted () {
    const clientWidth = this.$refs['title-underline'].clientWidth;
    underlineSvg().addToVueRef(this.$refs['title-underline'], clientWidth);
  },
  computed: {
    isActive () {
      return this.currentSlide === this.navigationIndex
    }
  },
  methods: {
    updateActiveTitle () {
      if (this.isActive) return;
      this.$emit('updateTitle', this.navigationIndex);
    }
  }
}
</script>

<style lang="scss" scoped>

.nav-title:first-child {
  margin-left: auto;
}

.nav-title:last-child {
  margin-right: auto;
}

.nav-title:not(:last-child) {
  margin-right: 3rem;
}

.nav-title {
  margin-top: 1.5rem;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 28px;
  line-height: 1.75rem;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  .svg-selector {
    cursor: default;
    &.active:deep .o-underline__svg path {
      stroke-dashoffset: 0 !important;
      stroke: #fff;
    }
  }
}

  .nav-title:hover {
      .non-active:deep .o-underline__svg path {
        stroke-dashoffset: 0 !important;
        stroke: #fff;
      }
  }

  .nav-title {
    :deep .o-underline__svg path {
      transition: stroke-dasharray 0.5s, stroke-dashoffset 0.5s, opacity 0.1s;
    }
  }
</style>