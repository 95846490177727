<template>
    <div class="button-wrapper">
        <svg class="icon-circle" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
         <path d="M30.8435 15.0577C30.9687 16.175 31.0188 17.284 30.9937 18.3679C30.7934 25.7637 22.4647 31 15.4878 31C4.13796 31 -2.47167 20.1022 0.866527 9.63801C3.74572 0.599598 13.3096 -1.73505 21.4632 1.1916C27.2383 3.25942 30.1675 9.17941 30.8519 15.0577H30.8435Z" fill="#EC9BAD"/>
        </svg>

        <svg class="icon-cross" xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
            <path d="M1.05298 1.05298L10 10" stroke="#222721" stroke-width="2" stroke-linecap="round"/>
            <path d="M10 1.05298L1.05298 10" stroke="#222721" stroke-width="2" stroke-linecap="round"/>
        </svg>
    </div>
</template>

<script>
export default {
  name: 'SubscriptionFormButtonClose'
}
</script>
<style lang="scss" scoped>
    .button-wrapper {
        position: absolute;
        width: 31px;
        height: 31px;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    .icon-circle {
        position: absolute;
        top: 0;
        left: 0;
    }

    .icon-cross {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
</style>