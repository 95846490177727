import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper.css";

Swiper.use([Navigation, Pagination]);

const initializeOlympicsTeaserSwiper = () => {
  let currentSlider = '';
  let swiper = null;
  
  const swiperContainer = document.querySelector('.c-olympics-slider');
  if (!swiperContainer) return;

const generateSwiper = () => {
  const isMobile = window.innerWidth < 1024;

  if (isMobile) {
    if (currentSlider == 'mobile') return; //avoids destroy/creating swiper instances on each window resize event
    if (swiper !== null) swiper.destroy(); //destroys the currently active desktop swiper, so we can create a mobile one

    currentSlider = 'mobile';

    swiper = new Swiper(swiperContainer, {
      initialSlide: 1,
      centeredSlides: true,
      slidesPerView: 1,
      slideToClickedSlide: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
  
  else {
    if (currentSlider == 'desktop') return //avoids destroy/creating swiper instances on each window resize event
    if (swiper) swiper.destroy(); //destroys the currently active mobile swiper, so we can create a desktop one

    currentSlider = 'desktop';
  
    swiper = new Swiper(swiperContainer, {
      initialSlide: 1,
      centeredSlides: true,
      slidesPerView: 3,
      enabled: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    })
  }}

  generateSwiper();
  
  window.addEventListener("resize", (e) => {
      generateSwiper();
  })

  const arrowContainer = document.createElement("div");
  const arrowNext = swiperContainer.querySelector(".swiper-button-next");
  const arrowPrev = swiperContainer.querySelector(".swiper-button-prev");

  arrowContainer.classList.add("swiper-navigation");

  arrowContainer.appendChild(arrowPrev);
  arrowContainer.appendChild(arrowNext);

  swiperContainer.insertBefore(
    arrowContainer,
    swiperContainer.querySelector(".swiper-pagination")
  );
};

const onDocumentReady = () => {
  initializeOlympicsTeaserSwiper();;
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
