<template>
  <div class="product-tile-display-container">
    <div class="product-display-image">
      <product-tile-image 
        :desktopImagePath="getImagePath(productData.desktopImage)"
        :desktopImageAltText="productData.desktopImageAltText"
        :bopImagePath="getImagePath(productData.bopDesktopImage)"
        :bopImageAltText="productData.bopMobileImageAltText"
        :imageBackgroundColor="productData.imageBackgroundColor"
        :isFlipped="isFlipped"
      />
    </div>
    <div class="product-icons-container">
      <div 
      v-for="(iconTitle, iconTitleIndex) in getIconTitles"
      :key="iconTitleIndex"
      >
        <product-tile-icon 
          :iconPosition="iconTitleIndex"
          :iconImagePath="getIconImagePath(iconTitleIndex)"
          :iconImageAltText="getIconImageAltText(iconTitleIndex)"
          :iconTitle="iconTitle"
          :hasIcon="hasIconImage(iconTitleIndex)"
          :arrowsColor="productData.arrowsColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductTileImage from './ProductTileImage';
import ProductTileIcon from './ProductTileIcon';

export default {
  name: 'ProductTileDisplay',
  components: {
    ProductTileImage,
    ProductTileIcon
  },
  props: {
    productData: {
      type: Object,
      required: true
    },
    isFlipped: {
      type: Boolean,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    }
  },
  computed: {
    getIconTitles () {
      let key = `${this.activeTab}IconsTitles`
      if (!this.hasData(key)) return [];
      return this.productData[key];
    }
  },
  methods: {
    getIconImageKey () {
      return `${this.activeTab}Icons`;
    },
    getIconAltTextKey () {
      return `${this.activeTab}IconsAltText`;
    },
    hasData (key) {
      if (!Object.hasOwn(this.productData, key)) return false;
      if (!this.productData[key]) return false;
      if (this.productData[key].length === 0) return false;
      return true;
    },
    hasIconData (key, index) {
      if (!this.hasData(key)) return false;
      if (this.productData[key].length < index) return false;
      return !!this.productData[key][index];
    },
    hasIconImage (index) {
      return this.hasIconData(this.getIconImageKey(), index);
    },
    hasIconAltText (index) {
      return this.hasIconData(this.getIconAltTextKey(), index);
    },
    getIconImagePath (index) {
      if (!this.hasIconImage(index)) return '';
      let key = this.getIconImageKey();
      return this.productData[key][index]._path;
    },
    getIconImageAltText (index) {
      if (!this.hasIconAltText(index)) return 'Innocent Product Icon Image';
      let key = this.getIconAltTextKey();
      return this.productData[key][index];
    },
    getImagePath (imageType) {
      if (!imageType) return "";
      return   Object.hasOwn(imageType, '_path') ? imageType._path : "";
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
    transition: opacity 1s ease;
  }

</style>