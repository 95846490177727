<template>
  <div class="card-container" :data-cy-card="card.season">
    <adaptive-image
     :imagePath="getImagePath" 
     :imageAltText="card.desktopImageAltText"
     :isMissingImagePath="!hasImagePath"
     />
    <div class="card-text">
      <div class="card-ranges">
        <activity-range
          rangeType="age"
          rangeIcon="age"
          :rangeText="card.ageRange"
        />
        <activity-range
          rangeType="time"
          rangeIcon="time"
          :rangeText="card.timeRange"
        />
        <activity-range
          rangeType="weather"
          :rangeIcon="card.weatherRange"
          :rangeText="getWeatherRangeTranslation"
        />
      </div>
      <activity-title :titleText="card.title" />
      <div class="card-links">
        <activity-cta
          :ctaPath="getCtaPath"
          :ctaText="card.ctaText"
          :isMissingCtaPath="!hasCtaPath"
        />
        <activity-share 
          :shareText="translations.shareText" 
          :shareCopiedText="translations.shareCopiedText"
          :shareLink="getCtaPath"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdaptiveImage from "../core/AdaptiveImage";
import ActivityRange from "./ActivityRange";
import ActivityTitle from "./ActivityTitle";
import ActivityCta from "./ActivityCta";
import ActivityShare from "./ActivityShare";

export default {
  name: "ActivityCard",
  components: {
    AdaptiveImage,
    ActivityRange,
    ActivityTitle,
    ActivityCta,
    ActivityShare,
  },
  props: {
    card: {
      type: Object,
      required: true,
      default: {},
    },
    sesaonActive: {
      type: String,
      required: true,
    },
    allCardsShown: {
      type: Boolean,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showCard() {
      return this.allCardsShown || this.sesaonActive === this.card.season;
    },
    getWeatherRangeTranslation () {
      const translationKey = `${this.card.weatherRange}Translation`;
      return this.translations[translationKey];
    },
    hasCtaPath () {
     return this.hasPath('ctaPagePath');
    },
    getCtaPath () {
      if (!this.hasCtaPath) return '';
      return this.card.ctaPagePath._path;
    },
    hasImagePath () {
      return this.hasPath('desktopImage');
    },
    getImagePath () {
      if (!this.hasImagePath) return '';
      return this.card.desktopImage._path;
    }
  },
  methods: {
    hasPath(objProperty) {
      if (this.card[objProperty] === null) return false;
      if (!Object.hasOwn(this.card[objProperty], '_path')) return false;
      if (!this.card[objProperty]._path) return false;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  background: #ecf6df;
  border-radius: 20px;
  margin-bottom: 30px;
}

.card-ranges {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

}
.card-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
.card-text {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
