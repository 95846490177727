<template>
    <div class="cta-desktop">
      <div 
        v-if="isMissingCtaPath"
        class="missing-cta-path"
      >
        <p>MISSING DATA: CTA PATH</p>
      </div>
      <a :href="shortCtaPath">
        <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 120 30" fill="none" class="btn-background" preserveAspectRatio="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M97.3509 0C99.9907 0.00318574 102.807 0.0299756 105.799 0.0803525C109.74 0.146696 113.29 1.78388 115.855 4.38811C118.42 6.99235 120 10.5636 120 14.4982V15.3768C120 19.2856 118.44 22.8383 115.902 25.4394C113.363 28.0405 109.846 29.6899 105.932 29.7918C100.766 29.9264 96.7954 29.9958 94.0225 29.9999L93.5591 30C92.0067 29.998 90.8766 29.974 90.1688 29.926C87.3465 29.7346 81.427 29.6644 74.4206 29.6592L72.4319 29.6593C63.4725 29.6671 53.114 29.7655 45.2796 29.84C40.5978 29.8845 36.8184 29.9205 34.7827 29.9233L34.1538 29.9231C30.5242 29.9166 23.863 29.8214 14.17 29.6375C10.2355 29.5629 6.69298 27.9228 4.13411 25.3193C1.57524 22.7158 0 19.1488 0 15.2202C0 11.1515 1.58275 7.52232 4.15176 4.90554C6.73371 2.27558 10.3102 0.670414 14.2547 0.670389C23.6518 0.732881 30.6209 0.671535 35.2654 0.4875C39.5673 0.317035 45.6734 0.457602 52.2949 0.608867C61.6754 0.823165 72.082 1.05868 79.9079 0.485398C84.1881 0.171854 89.6007 0.0105185 96.1441 0.000206278L97.3509 0Z" fill="#A2D45E"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="10" viewBox="0 0 21 10" fill="none" class="btn-icon">
        <path d="M1 5.38441C4.98024 5.24426 7.93664 5.24426 9.8692 5.38441C11.8018 5.52456 15.0559 5.52456 19.6317 5.38441" stroke="#212721" stroke-width="2" stroke-linecap="round"/>
        <path d="M16.1016 1.96997C16.6782 2.38007 17.1961 2.84529 17.6553 3.36563C18.1145 3.88597 18.7736 4.55884 19.6325 5.38424C18.6906 6.34722 18.0316 7.03935 17.6553 7.46063C17.279 7.8819 16.9045 8.34701 16.5317 8.85595" stroke="#212721" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class="ctaText">{{ ctaText }}</span>
      </a>
    </div>
</template>

<script>
import { useIsMobile } from '../../composables/useIsMobile.js';
import { usePathShorten } from '../../composables/usePathShorten.js';

export default {
  name: 'ActivityCta',
  setup () {
    const isMobile = useIsMobile();
    const pathShorten = usePathShorten;
    return { isMobile, pathShorten };
  },
  props: {
    ctaPath: {
      type: String,
      required: true
    },
    ctaText: {
      type: String,
      required: true
    },
    isMissingCtaPath: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    shortCtaPath () {
      return this.pathShorten(this.ctaPath);
    }
  }
}
</script>

<style lang="scss" scoped>
.cta-desktop a {
    min-width: 120px;
    height: 30px;
    position: relative;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 40px;
}
.btn-background {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
}

.btn-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.ctaText {
  position: relative;
  line-height: 30px;
  color:  rgb(33, 39, 33);
}

.missing-cta-path {
  color: red;
}

</style>