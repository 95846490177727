import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const waveBottomSvg = () => {
  const waveBottom = SVG();
  waveBottom
    .viewbox('8 0 2048 25')
    .attr('preserveAspectRatio', 'none')
    .addClass('bottom-wave');
  waveBottom.path('M1833.1,1.6c-146.3,0-360.7,22.4-527,22.4s-274-10.6-274-10.6S955.4,1.6,809.1,1.6S448.4,24,282,24 S8,13.4,8,13.4V25h1024h1024V13.4C2056,13.4,1979.4,1.6,1833.1,1.6z');
  
  return svgHelper(waveBottom);
};

export default waveBottomSvg;