import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../utils/svgHelper';

const olympicsButtonIconSvg = () => {
  const olympicsButtonIcon = SVG();

  olympicsButtonIcon.svg('<path xmlns="http://www.w3.org/2000/svg" d="M2.68994 8.15999H54.4799L44.7699 2.26999" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path xmlns="http://www.w3.org/2000/svg" d="M46.3899 15.03L55.2899 8.64999" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>');
  olympicsButtonIcon.width(58).height(18).viewbox(0, 0, 58, 18);
  olympicsButtonIcon.attr('preserveAspectRatio', 'none');
  olympicsButtonIcon.addClass('button-icon');


  return svgHelper(olympicsButtonIcon);
}

export default olympicsButtonIconSvg;


