<template>
  <div>
    <div
      v-if="showPopUp"
      class="overlay"
      @click="closePopup"
      ></div>
    <subscription-form-logo
      v-if="showInitialLogo"
      :imageUrl="getLogoUrl"
      :imageAltText="getLogoAltText"
      @click="openPopUp"
      class="subscription-form-logo-nav"
      />
    <subscription-form-container 
      v-if="showPopUp"
      :formItems="formItems"
      :imageLogoUrl="getLogoUrl"
      :imageLogoAltText="getLogoAltText"
      :storeName="storeName"
      :isNav="true"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubscriptionFormLogo from "../components/subscriptionForm/SubscriptionFormLogo";
import SubscriptionFormContainer from "../components/subscriptionForm/SubscriptionFormContainer";

export default {
  name: "SubscriptionForm",
  components: {
    SubscriptionFormLogo,
    SubscriptionFormContainer,
  },
  props: {
    query: {
      type: String,
      required: true,
    },
    folder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      storeName: 'subscribeForm'
    }
  },
  created() {
    this.$store.dispatch("subscribeForm/performApiQuery", {
      query: this.query,
      store: this.storeName,
      folder: this.folder,
      isNav: true
    });
  },
  computed: {
    ...mapGetters({
      formItems: 'subscribeForm/getSubsFormItems',
      showPopUp: 'subscribeForm/showPopUp',
      showInitialLogo: 'subscribeForm/showInitialLogo'
    }),
    getLogoUrl() {
      return this.formItems.imageUrl?._path ? this.formItems.imageUrl._path : '';
    },
    getLogoAltText() {
      return this.formItems.imageUrlAltText ? this.formItems.imageUrlAltText : '';
    }
  },
  methods: {
    openPopUp() {
      this.$store.dispatch('subscribeForm/updatePopUp', { popUpStatus: true});
    },
    closePopup() {
      this.$store.dispatch('subscribeForm/updatePopUp', { popUpStatus: false});
    },
  }
};
</script>

<style lang="scss" scoped>
.subscription-form-logo-nav {
  cursor: pointer;
}

.hidden {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.markers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.markers:after,
.markers:before {
  content: "";
  display: block;
  width: 14px;
  height: 28px;
  margin-top: 15px;
}

.markers:before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 14 28" fill="none"><path d="M10.4731 21.1672C8.65604 22.0374 6.79763 22.8271 4.91444 23.5281" stroke="%23EC9BAD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.7959 14.8742C7.71448 15.116 5.59177 14.9709 3.50209 14.4552" stroke="%23EC9BAD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.7209 8.51685C9.04425 7.42102 7.29322 6.438 5.47611 5.57584" stroke="%23EC9BAD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.markers:after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 14 28" fill="none"><path d="M3.52686 6.83279C5.34396 5.96257 7.20237 5.17293 9.08556 4.47192" stroke="%23EC9BAD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M4.2041 13.1258C6.28552 12.884 8.40823 13.0291 10.4979 13.5448" stroke="%23EC9BAD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.27905 19.4832C4.95575 20.579 6.70678 21.562 8.52389 22.4242" stroke="%23EC9BAD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
</style>
