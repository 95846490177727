<template>
  <div 
    class="share-container"
    @click="updateShareLink"
  >
    <div class="share-text" v-show="!hasCopiedShareLink">
      {{ shareText }} 
    </div>
    <div class="share-text-copied" v-show="hasCopiedShareLink">
      {{ shareCopiedText }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { usePathShorten } from '../../composables/usePathShorten.js';

export default {
  name: 'ActivityShare',
  setup () {
    const pathShorten = usePathShorten;
    return { pathShorten }
  },
  props: {
    shareText: {
      type: String,
      required: true
    },
    shareCopiedText: {
      type: String,
      required: true
    },
    shareLink: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentShareLink: 'activityCard/getShareLink',
    }),
    hasCopiedShareLink () {
      return this.shareLink === this.currentShareLink;
    }
  },
  methods: {
    getShortLink () {
      return this.pathShorten(this.shareLink);
    },
    copyShareLinkToClipBoard () {
      const windowOrigin = window.location.origin;
      const shortLink = this.getShortLink();
      const completeLink = ''.concat(windowOrigin, shortLink);
      navigator.clipboard.writeText(completeLink);
    },
    updateShareLink () {
      this.copyShareLinkToClipBoard();
      this.$store.dispatch('activityCard/updateShareLink', { shareLink: this.shareLink })
    }
  }
}
</script>

<style lang="scss" scoped>
  .share-container {
    &:hover {
      cursor: grab;
    }
  }

</style>