import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.css';

Swiper.use([Navigation, Pagination]);

const kidsSocialFeed = () => { 

  const swiper = new Swiper('.c-kids-social-feed', {
    loop: true,
    centeredSlides: true, 
    slidesPerView: 1.5,
    spaceBetween: 10,

      breakpoints: {
        1024: {
          slidesPerView: 3.1,  
          spaceBetween: 25, 
        },

        1400: {
          slidesPerView: 4,  
          spaceBetween: 25, 
        }
      },

      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      }
  });

  if (document.querySelector('.c-kids-social-feed')) {
    const arrowContainer = document.createElement('div');
    const arrowNext = document.querySelector('.c-kids-social-feed .swiper-button-next');
    const arrowPrev = document.querySelector('.c-kids-social-feed .swiper-button-prev');
  
    arrowContainer.classList.add('swiper-navigation');
    
    arrowContainer.appendChild(arrowPrev);
    arrowContainer.appendChild(document.querySelector('.c-kids-social-feed .text'));
    arrowContainer.appendChild(arrowNext);
  
    document.querySelector('.c-kids-social-feed').insertBefore(arrowContainer, document.querySelector('.c-kids-social-feed .swiper-pagination'));
  };

}


const onDocumentReady = () => {
  kidsSocialFeed();
};

if (document.readyState !== "loading") {
onDocumentReady();
} else {
document.addEventListener("DOMContentLoaded", onDocumentReady);
}
