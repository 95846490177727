<template>
  <div>
    <img  
      :src="imageUrl"
      :alt="imageAltText"
    />
  </div>
</template>

<script>
export default {
  name: 'SubscriptionFormLogo',
  props: {
    imageUrl: {
      type: String,
      required: true 
    },
    imageAltText: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 200px;
  display: block;
  margin: 0 auto;
}
</style>
