const YT_IFRAME_API_URL = 'https://www.youtube.com/iframe_api'; 

const createYTscriptTag = () => {
  const scriptElement = document.createElement('script');
  scriptElement.src = YT_IFRAME_API_URL;
  const bodyElement = document.getElementsByTagName('body')[0];
  bodyElement.appendChild(scriptElement);
};

const checkforYTWindowObject = () => { 
  if (!Object.hasOwn(window, 'YT')) return false;
  if (!Object.hasOwn(window.YT, 'loaded')) return false;
  return window.YT.loaded;
}

export const loadYouTubeApi = () => { 
  return new Promise((resolve) => {
    if (checkforYTWindowObject()) {
      resolve();
    } else {
      window.onYouTubeIframeAPIReady = resolve;
    }
    createYTscriptTag();
  });
};
