import buttonBackgroundSvg from "../../svg/general/icons/buttonBackground";
import buttonIconSvg from "../../svg/general/icons/buttonIcon";

import buttonOlympicsSvg from "../../svg/olympics/buttonOlympics";
import olympicsButtonIconSvg from "../../svg/olympics/buttonOlympicsIcon";

buttonBackgroundSvg().addToClass('.btn-default .cmp-button');
buttonIconSvg().addToClass('.btn-default .cmp-button');

buttonOlympicsSvg().addToClass('.btn-olympics .cmp-button');
olympicsButtonIconSvg().addToClass('.btn-olympics .cmp-button');