<template>
  <div 
    class="product-image-root"
    :class="`${imagePosition}`"
    >
    <div
     class="image-container"
     :class="`product-${imageIndex}`"
     >
      <img
       :src="imagePath" 
       :alt="imageAltText"
       loading="lazy"
       >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AllProductsImage',
  props: {
    imagePath: {
      type: String,
      required: true
    },
    imageAltText: {
      type: String,
      required: true
    },
    imageIndex: {
      type: Number,
      required: true
    },
    imagePosition: {
      type: String,
      required: true
    }
  }

}
</script>

<style lang="scss" scoped>
.product-0,
.product-1,
.product-2 {
  transition: transform 1.5s cubic-bezier(0.86, 0.01, 0.19, 0.96); 
}


.swiper-slide-prev,
.is-prev {
  .product-0 {
    transform: translate(-120vw);
  }
  .product-1 {
    transform: translate(-160vw);
  }

  .product-2 {
    transform: translate(-140vw);
  }
}

.swiper-slide-next,
.is-next {
  .product-0 {
    transform: translate(120vw);
  }
  .product-1 {
    transform: translate(160vw);
  }
  .product-2 {
    transform: translate(120vw);
  }
}

.swiper-slide-active {
  .product-0,
  .product-1,
  .product-2 {
    transform: translate(0);
  }
}

.product-image-root {
  z-index: 3;

  & .image-container {
    position: absolute;
    transition: transform 1.5s cubic-bezier(.86, .01, .19, .96);

    img {
      max-height: 200px;
      max-width: 100%;
      width: auto;

      @media only screen and (min-width: 768px) {
        max-height: none; 
      }
    }
   
  }
}

// smoothies
.position-one {
  .product-0 {
    @media screen and (max-width: 61.938em) {
      bottom: 70px;
      left: 6%;
    }

    @media screen and (min-width: 62em) {
      top: 195px;
      left: 4%;
    }
  }

  .product-1 {
    @media screen and (max-width: 61.938em) {
      top: 90px;
      right: 6%;
    }
    @media screen and (min-width: 62em) {
      top: 80px;
      right: 7%;
    }
  }

  .product-2 {
    @media screen and (max-width: 61.938em) {
      bottom: 0;
      right: 8%;
    }

    @media screen and (min-width: 62em) {
      bottom: 5px;
      right: 20%;
    }
  }
}

// super smoothies
.position-two {
  .product-0 {
    @media screen and (max-width: 61.938em) {
      top: 110px;
      left: 1%;
    }

    @media screen and (min-width: 62em) {
      top: 100px;
      left: 5%;
    }
  }

  .product-1 {
    @media screen and (max-width: 61.938em) {
      bottom: 30px;
      right: 8%;
    }

    @media screen and (min-width: 62em) {
      bottom: 0;
      right: 5%;
    }
  }
}

// bubbles
.position-three {
  .product-0 {
    @media screen and (max-width:61.938em) {
      top: 55px;
      left: 5%
    }

    @media screen and (min-width:62em) {
      top: 55px;
      left: 7%
    }
  }

  .product-1 {
    @media screen and (max-width:61.938em) {
      bottom: 0;
      right: 15%
    }

    @media screen and (min-width:62em) {
      bottom: 0;
      right: 8%
    }
  }
}

// juices
.position-four {
  .product-0 {

    @media screen and (max-width: 61.938em) {
      top: 110px;
      left: 1%;
    }

    @media screen and (min-width: 62em) {
      top: 100px;
      left: 10%;
    }
  }

  .product-1 {

    @media screen and (min-width: 62em) {
      bottom: 0;
      right: 10%;
    }

    @media screen and (max-width: 61.938em) {
      bottom: 30px;
      right: 8%;
    }
  }
}

// innocent plus
.position-five {
  .product-0 {
    @media screen and (max-width: 61.938em) {
      bottom: 30px;
      left: 1%
    }

    @media screen and (min-width: 62em) {
      bottom: 0;
      left: 10%;
    }
  }

  .product-1 {
    @media screen and (max-width: 61.938em) {
      top: 110px;
      right: 8%;
    }

    @media screen and (min-width: 62em) {
      top: 200px;
      right: 5%
    }
  }
}

// dairy free
.position-six {
  .product-0 {
    @media screen and (max-width: 61.938em) {
      bottom: 30px;
      left: 1%;
    }

    @media screen and (min-width: 62em) {
      bottom: 0;
      left: 10%
    }
  }

  .product-1 {
    @media screen and (max-width: 61.938em) {
      top: 110px;
      right: 8%;
    }

    @media screen and (min-width: 62em) {
      top: 200px;
      right: 5%;
    }
  }
}

// kids
.position-seven {
  .product-0 {
    @media screen and (max-width: 61.938em) {
      top: 110px;
      left: 1%;
    }

    @media screen and (min-width: 62em) {
      top: 100px;
      left: 10%;
    }
  }

  .product-1 {
    @media screen and (max-width: 61.938em) {
      bottom: 30px;
      right: 8%;
    }

    @media screen and (min-width: 62em) {
      bottom: 0;
      right: 10%;
    }
  }

}

// coconut water
.position-eight {
  .product-0 {
    @media screen and (max-width: 61.938em) {
      bottom: 30px;
      left: 1%;
    }

    @media screen and (min-width: 62em) {
      bottom: 0;
      left: 1%
    }
  }

  .product-1 {
    @media screen and (max-width: 61.938em) {
      top: 110px;
      right: 1%;
    }

    @media screen and (min-width: 62em) {
      top: 110px;
      right: 1%;
    }
  }
}

// juice shots
.position-nine {
  .product-0 {
   @media screen and (max-width: 61.938em) {
    bottom: 30px;
    left: 1%;
   }

   @media screen and (min-width: 62em) {
    bottom: 100px;
    left: 3%;
   }
  }

  .product-1 {
    @media screen and (max-width: 61.938em) {
      top: 110px;
      right: 8%;
    }

    @media screen and (min-width: 62em) {
      top: 200px;
      right: 3%;
    }
  }
}





</style>