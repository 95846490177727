import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const markerRightSvg = () => {
  const markerRight = SVG();

  markerRight.svg('<g><path d="M6.14,6.06c.58-.29,1.13-.55,1.6-.83.74-.44,1.46-.89,2.19-1.35,1.42-.88,2.89-1.8,4.4-2.58l.17-.09h.21a2.23,2.23,0,0,1,1.68.61,1.8,1.8,0,0,0,.24.19l.29.17-.05.26a3.94,3.94,0,0,1-1.73,2.3,5.1,5.1,0,0,0-1.06,1L14,5.86l-.16.06a30,30,0,0,0-2.71,1.56c-.52.32-1.07.66-1.67,1-.33.2-.68.43-1,.66C7.17,10,5.57,11,4,10.94a3.31,3.31,0,0,1-1.74-.58L2,10.22V10c0-2,2.22-3,4.14-3.95" transform="translate(-0.92 -1.21)" style="fill-rule: evenodd"></path><path d="M2.31,37.35l.29,0a57.74,57.74,0,0,0,8-1.35c2.07-.45,4.22-.92,6.56-1.2l.19,0,.18.06a3.73,3.73,0,0,1,2.14.73.77.77,0,0,1,.2.76c-.42,1.31-2.68,2-5.37,2.72-1.93.51-4.45,1.06-6.27,1.35a21.84,21.84,0,0,1-4.38.37,4.71,4.71,0,0,1-2.78-.84l-.17-.15v-.19a2.88,2.88,0,0,1,1.2-2Z" transform="translate(-0.92 -1.21)" style="fill-rule: evenodd"></path><path d="M3.73,60.44,4,60.26l.37.06c.51.08.72.1,1.11.14l1,.12,2,1.11,3.88,2.2,1.35.74c2,1.1,4.34,2.34,5.13,3.82l.32.6-.54,0c0,.08,0,.15,0,.21l-.13.47-.68-.06a1.06,1.06,0,0,0-.16.15l-.3.31-.4,0a6.09,6.09,0,0,1-3.24-1.34c-.25-.18-.5-.35-.75-.5l-.27-.15a65.61,65.61,0,0,1-9.46-6.3l-1-.89L3.35,61a.81.81,0,0,1,.38-.59" transform="translate(-0.92 -1.21)" style="fill-rule: evenodd"></path></g>');
  markerRight.viewbox(0, 0, 21, 68.41).addClass('title-marker-right');


  return svgHelper(markerRight);
}

export default markerRightSvg;