<template>
  <div>
    <div class="nav-bottom-wave">
      <wave
        :isBottomWave="true"
        :waveFill="navBackgroundColour"
      />
    </div>
    <nav 
      :style="getBackgroundColour"
      class="navigation-root"
      >
      <ul>
        <all-products-navigation-title 
          v-for="(navTitle, navIndex) in navTitles"
          :key="navIndex"
          :navigationTitle="navTitle"
          :navigationTitleColour="getNavigationTitleColour(navIndex)"
          :navigationIndex="navIndex"
          :currentSlide="currentSlide"
          @updateTitle="updateNavTitle"
        />
      </ul>
    </nav>
    <div class="nav-top-wave">
      <reverse-wave
        :isBottomWave="false"
        :waveFill="navBackgroundColour"
      />
    </div>
  </div>
</template>

<script>
import AllProductsNavigationTitle from './AllProductsNavigationTitle';
import Wave from '../../core/Wave';
import ReverseWave from '../../core/ReverseWave';

export default {
  name: 'AllProductsNavigation',
  components: {
    AllProductsNavigationTitle,
    Wave,
    ReverseWave
  },
  props: {
    navTitles: {
      type: Array,
      required: true,
      default: []
    },
    navTitleColours: {
      type: Array,
      required: true,
      default: []
    },
    navBackgroundColour: {
      type: String,
      required: true
    },
    currentSlide: {
      type: Number,
      required: true
    }
  },
  emits: ['update-nav'],
  computed: {
    getBackgroundColour () {
      return `background: ${this.navBackgroundColour};`;
    }
  },
  methods: {
    getNavigationTitleColour (index) {
      return this.navTitleColours[index];
    },
    updateNavTitle (event) {
      this.$emit('updateNav', event);
    }
  }
}
</script>

<style lang="scss" scoped>

.navigation-root {
  padding: 0 1.875rem;
  display: flex;
  align-items: center;
  height: 90px;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  overflow-x: auto;
  transition: all 300ms ease;

  ul {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: auto;
  }
}

.nav-bottom-wave {
  svg {
    background: #fff;
  }
}

</style>