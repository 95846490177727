<template>
  <div class="product-description-root">
    <div
    class="product-description"
      v-html="descriptionHtml"
    />
  </div>
</template>

<script>
export default {
  name: 'ProductSlideDescription',
  props: {
    descriptionHtml: { 
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.product-description-root {
  max-width: 600px;
  margin: 0 auto;

  .product-description:deep p {
    font-family:  Innocent Light, sans-serif; 
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px;
  }   
}
</style>