<template>
  <div class="products-title-root">
    <h2 
      class="title"
      :style="{'color': titleColor}"
    >{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'AllProductsTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    titleColor: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

h2.title {
  font-size: 3rem;
  padding: 0 1.875rem;
  line-height: 1;
  margin-bottom: 1.688rem; 
  @media only screen and (min-width: 992px) {
    font-size: 6.875rem;
  }
}




</style>