import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../src/utils/svgHelper';

const olympicsMarkerLeftSvg = () => {
  const olympicsMarkerLeft = SVG();

  olympicsMarkerLeft.svg('<path d="M7.73999 2.08C11.26 1.66 16.86 2.93 23.08 4.82" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/><path d="M2 22.74C5.52 22.32 10.84 22.41 17.23 23.61" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/><path d="M8.59 44.22C12.11 43.8 16.35 41.4 22.26 41.81" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>');
  olympicsMarkerLeft.viewbox(0, 0, 26, 47).addClass('title-marker-left'); 


  return svgHelper(olympicsMarkerLeft);
}

export default olympicsMarkerLeftSvg;