import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const portraitTopSvg = () => {
  const portraitTop = SVG();
  portraitTop.width(375)
    .height(200)
    .viewbox('0 0 375 200')
    .addClass('portrait-top')
    .fill('none');
  
  const commonCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };

  const groupOne = portraitTop.group();
  groupOne.css(commonCss);
  groupOne.path('M32.7946 -82C32.7946 -82 70.8426 -47.9547 79.5949 15.6736');
  groupOne.path('M164.054 129.735C164.054 129.735 144.615 66.0425 124.276 40.7495C103.937 15.4565 80.0227 9.97278 80.0227 9.97278C80.0227 9.97278 80.6994 41.3113 106.988 75.0885C133.277 108.866 164.054 129.735 164.054 129.735Z').fill('#A3D380');
  groupOne.path('M1.37939 119.048C1.37939 119.048 77.0796 71.8072 79.6077 12.1242C79.6077 12.1242 61.5157 14.6777 38.3358 42.5498C15.1558 70.4219 1.48154 110.379 1.37939 119.048Z').fill('#A3D380');
  groupOne.path('M3.28174 114.956L79.6139 12.1242');
  groupOne.path('M162.049 126.23L80.0227 9.97278');
  groupOne.path('M34.2629 42.5245C34.4034 46.7315 35.0992 51.2258 36.8612 55.0816C39.8552 61.6379 47.3499 63.4318 53.4146 60.093C57.1683 58.0246 57.7301 53.7091 56.913 49.8213C56.456 47.5513 55.5534 45.3945 54.2573 43.4757C51.3398 39.2879 46.5966 37.0918 41.8406 35.5597C39.9254 34.9213 37.4166 33.7275 35.744 35.3107C34.0714 36.8939 34.1863 40.4625 34.2629 42.5245Z').fill('#FFE900');
  groupOne.path('M13.5345 65.2454C10.3426 69.5418 3.88844 72.5167 -1.78685 71.7379C-12.2756 70.2887 -11.6627 57.738 -7.53235 51.0604C-4.10638 45.5481 -1.16514 39.7489 1.25827 33.7281C1.05399 34.2516 8.338 40.361 9.12961 41.2101C11.2874 43.5019 13.892 45.9788 15.1752 48.8133C17.3457 53.5055 16.8031 60.8916 13.5345 65.2454Z').fill('#FFE900');
  groupOne.path('M49.1501 41.1512C51.9144 43.111 55.1829 44.7325 58.5472 44.3431C62.8372 43.8835 68.4104 39.8744 69.3488 35.5206C70.4404 30.4518 66.3867 25.6064 62.5946 22.7975C58.4068 19.7013 52.8592 18.9288 47.6882 19.6055C44.4156 20.0831 41.2047 20.9158 38.1124 22.0889C34.7992 23.2763 33.2862 23.5508 35.5588 26.7555C39.37 32.0988 43.7813 37.3336 49.1501 41.1512Z').fill('#FFE900');
  groupOne.path('M43.0854 49.8143C43.6408 51.9657 44.043 54.2448 43.5003 56.3961C42.7853 59.2114 40.5446 61.3947 38.1059 62.9779C34.581 65.255 30.524 66.5751 26.334 66.8083C23.091 66.9934 19.5671 66.3805 17.3008 64.0504C15.5006 62.1863 14.7919 59.5498 14.2493 57.0281C13.7248 54.9421 13.4864 52.7945 13.5407 50.6442C13.7514 47.2544 15.2261 44.088 16.0943 40.8003C18.086 33.0949 16.056 25.9258 15.5133 18.2651C20.3204 16.35 28.6642 25.4725 31.3199 28.3389C36.9574 34.4142 41 41.793 43.0854 49.8143Z').fill('#FFE900');
  groupOne.path('M-20.6579 60.0805C-23.2115 60.3039 -25.9374 60.0805 -28.1335 58.6888C-29.9466 57.4493 -31.3984 55.7508 -32.3404 53.7668C-33.828 51.126 -34.5963 48.141 -34.5684 45.1102C-34.4535 42.1609 -33.3491 39.3264 -32.0149 36.7026C-29.5124 31.9275 -26.0076 27.5354 -21.3793 24.8222C-18.9655 23.5189 -16.4322 22.45 -13.8144 21.6303L3.24977 15.6613C2.75822 15.8337 2.18367 27.3821 1.973 28.6206C1.55595 31.7185 0.915894 34.7822 0.057827 37.7879C-1.97863 44.5995 -3.50438 51.2707 -9.51801 55.7777C-12.7616 58.2152 -16.618 59.7047 -20.6579 60.0805Z').fill('#FFE900');
  groupOne.path('M3.92015 19.0001C-4.37891 24.7456 -13.6419 30.4018 -23.7093 29.8527C-27.3865 29.6612 -31.2487 28.4547 -33.5086 25.55C-36.2218 22.0835 -35.877 16.9126 -33.6682 13.0823C-31.4594 9.25192 -27.7823 6.53876 -23.9647 4.31078C-19.1383 1.45081 -13.9333 -0.715433 -8.50291 -2.12418C-5.27523 -3.05574 -1.89796 -3.35488 1.4432 -3.00516C5.98215 -2.36677 13.4705 0.320846 14.1025 5.64501C14.7728 11.5118 8.0633 16.1529 3.92015 19.0001Z').fill('#FFE900');
  groupOne.path('M21.7313 31.1097C21.7313 35.904 20.8503 41.075 17.4286 44.4202C15.0984 46.7056 11.881 47.8164 8.71456 48.6016C5.81627 49.3166 2.70731 49.7954 -0.12075 48.825C-4.03408 47.4844 -6.62594 43.4881 -7.18134 39.3896C-7.73674 35.2912 -6.54295 31.1289 -4.76184 27.3879C-0.561242 18.5271 6.72916 11.5814 13.86 4.84639C15.4113 3.37809 19.4459 15.2585 19.548 15.5969C21.019 20.6357 21.7543 25.8606 21.7313 31.1097Z').fill('#FFE900');
  groupOne.path('M18.1692 32.8854C19.8098 36.2816 21.9995 39.697 25.3766 41.2994C28.7537 42.9017 32.8777 42.3591 36.5165 41.2994C39.7084 40.3482 42.8685 38.9182 44.9305 36.3263C48.6842 31.5256 47.4074 24.1714 43.3983 19.6005C39.3892 15.0296 33.4522 12.7059 27.6173 11.0972C23.9721 10.1077 20.3014 9.07984 16.637 8.19886C13.4068 7.42641 12.3598 8.63297 12.4364 11.9654C12.5833 19.0451 15.124 26.5909 18.1692 32.8854Z').fill('#FFE900');
  groupOne.path('M29.5324 16.5602C34.3075 18.2775 39.1721 19.8799 44.2153 20.4927C49.2586 21.1056 54.5253 20.697 59.1153 18.5329C61.3114 17.4923 63.4436 15.9282 64.2799 13.6428C65.0524 11.5233 64.5991 9.17407 64.0884 6.98439C63.5394 4.62235 62.8946 2.1837 61.3497 0.313224C58.4514 -3.21069 53.306 -3.74055 48.7479 -3.5618C40.0758 -3.20834 31.5429 -1.26068 23.5762 2.1837C20.7737 3.39664 17.1923 5.04369 15.6666 7.83345C19.5843 11.7345 24.3206 14.7154 29.5324 16.5602Z').fill('#FFE900');
  groupOne.path('M8.46561 6.06562C8.25162 5.27549 8.25162 4.44265 8.46561 3.65251C8.7195 2.98008 9.13362 2.37976 9.67206 1.90362C10.2105 1.42749 10.857 1.08992 11.5554 0.920207C12.9562 0.594545 14.4108 0.574947 15.8199 0.862752C19.6318 1.47285 23.2729 2.87793 26.5065 4.98675C26.9844 5.25184 27.395 5.62313 27.7067 6.07201C28.4025 7.23388 27.7514 8.70217 27.1002 9.90234C26.8712 10.4009 26.5278 10.8383 26.0979 11.1791C25.3911 11.5588 24.577 11.6893 23.787 11.5494C20.5863 11.2734 17.4344 10.5869 14.409 9.50654C11.8491 8.68941 9.19337 9.41717 8.46561 6.06562Z').fill('#222721');
  groupOne.path('M-18.9469 19.581C-10.6633 12.2642 -0.541449 7.34454 10.3296 5.35132');
  groupOne.path('M7.06097 40.8691C4.61753 30.0321 6.10164 18.6795 11.2488 8.83475');
  groupOne.path('M29.5961 30.8871C29.162 26.3609 27.1192 22.0901 24.3549 18.4768C21.5907 14.8635 18.1242 11.8439 14.5365 9.04779');
  groupOne.path('M47.2348 8.72989C38.49 6.48233 29.3348 6.35539 20.531 8.35962');
  groupOne.path('M239.729 -33.9297C243.08 -26.6457 247.772 -20.3321 252.203 -13.4694C254.747 -9.59532 257.796 -6.07677 261.268 -3.00616C276.085 9.55734 288.865 22.14 303.146 35.1121C307.257 38.4509 311.375 41.7896 315.359 45.4029C317.006 46.8712 319.055 48.1991 320.568 49.9482C326.271 55.0055 332.474 59.4695 339.081 63.2714C346.876 68.053 354.53 72.4323 362.663 76.7287');
  groupOne.path('M329.071 75.4971C329.071 75.4971 326.875 58.1265 308.866 39.6835');
  groupOne.path('M276.653 45.8888C276.653 45.8888 276.934 27.8543 258.893 -5.3866');
  groupOne.path('M347.929 20.4163C347.929 20.4163 330.322 31.0391 278.606 12.2385');
  groupOne.path('M337.721 39.8857C337.721 39.8857 332.48 32.1611 310.762 21.4937');
  groupOne.path('M403.265 99.4829C402.077 98.8445 400.609 92.9266 399.92 91.4519C398.568 88.5475 397.035 85.7309 395.33 83.0188C391.933 77.6691 387.739 72.7535 382.562 69.0445C376.299 64.5757 367.738 60.107 361.763 67.3719C360.235 69.3447 358.995 71.5242 358.08 73.8452C351.696 89.0324 362.044 95.2121 365.21 96.3101C368.377 97.4081 379.293 98.8636 389.105 98.6785C393.84 98.5314 398.578 98.8006 403.265 99.4829Z').fill('#509689');
  groupOne.path('M401.254 98.1168C401.254 98.1168 382.804 81.3271 358.067 73.858');
  groupOne.path('M364.955 65.8457C364.955 65.8457 361.974 48.0155 356.196 40.3421C350.419 32.6686 341.571 19.837 334.925 36.3585C334.925 36.3585 325.26 37.6992 325.496 45.8067C325.732 53.9142 333.214 64.0455 346.652 65.5265C360.091 67.0076 364.955 65.8457 364.955 65.8457Z').fill('#509689');
  groupOne.path('M364.955 65.8448C364.955 65.8448 344.341 48.436 334.951 36.3576');
  groupOne.path('M332.582 120.646C333.776 117.34 335.236 114.138 336.949 111.07C339.835 105.88 351.096 83.9764 341.526 76.9541C331.957 69.9319 328.873 73.3855 328.873 73.3855C328.873 73.3855 318.857 69.019 317.018 81.4739C315.18 93.9289 320.306 106.416 325.394 112.94C330.482 119.464 332.582 120.646 332.582 120.646Z').fill('#509689');
  groupOne.path('M332.129 118.581C332.129 118.581 335.736 90.3641 328.873 73.3574');
  groupOne.path('M277.987 94.7266C277.987 94.7266 277.732 90.0983 284.25 75.5749C290.768 61.0516 296.258 49.5159 280.528 45.0919C264.798 40.6678 264.46 55.1465 264.46 58.9768C264.543 71.3041 273.691 82.5972 277.987 94.7266Z').fill('#006571');
  groupOne.path('M277.023 43.8251C277.023 43.8251 279.653 73.5805 277.259 91.5064');
  groupOne.path('M324.213 -35.6031C324.213 -35.6031 326.415 -23.8886 320.868 -15.2512C315.32 -6.61382 307.015 0.880871 293.513 0.414848C280.011 -0.051178 281.798 -10.2909 281.798 -10.2909C281.798 -10.2909 273.072 -20.8244 278.875 -29.1234C284.678 -37.4225 300.803 -34.2306 300.803 -34.2306C300.803 -34.2306 317.21 -29.0915 324.213 -35.6031Z').fill('#006571');
  groupOne.path('M367.841 -13.8016C367.841 -13.8016 371.001 0.242932 366.87 13.6491C362.74 27.0553 360.161 31.1601 353.611 30.7005C347.061 30.2409 344.827 21.5971 344.827 21.5971C344.827 21.5971 329.576 11.4531 335.653 0.408913C336.772 -1.71155 338.365 -3.54519 340.308 -4.94922C342.251 -6.35325 344.492 -7.28978 346.857 -7.68586C350.457 -8.32425 354.141 -8.48385 357.709 -9.42228C361.278 -10.3607 364.483 -12.2695 367.841 -13.8016Z').fill('#509689');
  groupOne.path('M367.84 -13.8016C367.84 -13.8016 357.486 7.9036 344.827 21.5779');

  const portraitTopFlowerGroup = portraitTop.group();
  portraitTopFlowerGroup.addClass('portrait-top-flower');
  portraitTopFlowerGroup.css(commonCss);
  portraitTopFlowerGroup.path('M194.569 30.2481C194.569 30.2481 184.814 2.54205 200.397 -8.75745C200.397 -8.75745 211.32 -1.56918 200.193 17.7166C200.193 17.7166 194.735 26.7306 194.569 30.2481Z').fill('#009D70');
  portraitTopFlowerGroup.path('M77.6351 148.726C77.7755 149.518 63.4309 150.775 56.4533 142.815C49.4757 134.854 57.1747 131.19 62.9521 132.6C68.7296 134.011 76.0391 139.821 77.6351 148.726Z').fill('#D25582');
  portraitTopFlowerGroup.path('M75.7838 136.011C75.5731 135.717 69.3105 126.505 77.3925 120.919C85.4745 115.333 91.9542 130.763 88.0983 138.896C84.2424 147.029 83.5338 148.287 82.1613 148.842C80.7887 149.398 75.7838 136.011 75.7838 136.011Z').fill('#D25582');
  portraitTopFlowerGroup.path('M81.459 149.504C79.8503 149.817 79.8247 149.089 78.7203 148.387C71.5512 143.828 67.3953 139.634 64.7715 134.099C60.073 124.192 68.6019 124.651 70.6894 126.835C73.1751 129.589 75.4118 132.559 77.3733 135.708C79.5949 139.06 80.9739 144.939 80.8845 149.325C80.8589 150.544 82.755 149.242 81.459 149.504Z').fill('#D25582');
  portraitTopFlowerGroup.path('M81.8356 148.215C81.8356 148.215 83.7954 129.198 88.6153 125.661C93.4351 122.124 93.4479 129.836 90.4283 136.948C87.4087 144.06 85.8766 147.8 81.8356 150.15C81.8356 150.15 81.0568 149.448 81.8356 148.215Z').fill('#222721');
  portraitTopFlowerGroup.path('M81.6759 148.35C81.6759 148.35 62.9967 144.367 58.1258 147.865C53.2549 151.363 60.6155 153.745 68.34 153.061C76.0645 152.378 80.0927 152.078 83.5464 148.95C83.5145 148.931 83.0868 147.967 81.6759 148.35Z').fill('#222721');
  portraitTopFlowerGroup.path('M202.325 133.31C202.638 132.569 215.323 139.394 216.791 149.87C218.259 160.346 209.807 159.197 205.76 154.836C201.712 150.476 198.769 141.628 202.325 133.31Z').fill('#D25582');
  portraitTopFlowerGroup.path('M196.893 144.959C196.893 145.317 197.09 156.45 187.272 156.687C177.454 156.923 180.492 140.472 188.179 135.786C195.865 131.1 197.154 130.443 198.603 130.73C200.053 131.017 196.893 144.959 196.893 144.959Z').fill('#D25582');
  portraitTopFlowerGroup.path('M199.555 130.564C201.068 131.202 200.691 131.802 201.234 133.003C204.719 140.746 205.894 146.53 205.064 152.595C203.557 163.447 196.682 158.391 196.126 155.423C195.56 151.755 195.321 148.044 195.411 144.334C195.411 140.312 197.467 134.643 199.944 131.024C200.621 130.021 198.329 130.066 199.555 130.564Z').fill('#D25582');
  portraitTopFlowerGroup.path('M198.533 131.433C198.533 131.433 186.474 146.244 180.492 146.569C174.511 146.895 178.743 140.421 185.165 136.138C191.588 131.854 194.939 129.543 199.593 129.818C199.593 129.837 199.861 130.833 198.533 131.433Z').fill('#222721');
  portraitTopFlowerGroup.path('M198.706 131.427C198.706 131.427 212.112 145.005 218.125 144.75C224.139 144.495 219.268 138.468 212.463 134.817C205.658 131.165 202.102 129.192 197.493 129.914C197.518 129.914 197.352 130.954 198.706 131.427Z').fill('#222721');
  portraitTopFlowerGroup.path('M227.242 17.2068C227.957 16.8429 233.287 30.2491 227.676 39.1865C222.064 48.124 216.332 41.8486 216.038 35.9052C215.744 29.9618 219.172 21.3116 227.242 17.2068Z').fill('#D25582');
  portraitTopFlowerGroup.path('M215.591 22.6369C215.374 22.9242 208.352 31.5744 200.678 25.4395C193.005 19.3046 205.913 8.6626 214.812 10.016C223.711 11.3694 225.122 11.6822 226.048 12.8377C226.973 13.9932 215.591 22.6369 215.591 22.6369Z').fill('#D25582');
  portraitTopFlowerGroup.path('M226.884 13.3246C227.644 14.7737 226.954 15.0099 226.603 16.2676C224.299 24.4453 221.496 29.6354 216.938 33.7403C208.799 41.1009 206.781 32.8019 208.281 30.1589C210.206 26.9924 212.407 24.0017 214.857 21.2215C217.41 18.1317 222.658 15.1185 226.884 13.9438C228.072 13.5991 226.259 12.1563 226.884 13.3246Z').fill('#D25582');
  portraitTopFlowerGroup.path('M225.518 13.338C225.518 13.338 206.756 16.9258 201.961 13.338C197.167 9.75028 204.572 7.50953 212.252 8.3522C219.932 9.19488 223.98 9.57791 227.369 12.7826C227.395 12.7762 226.948 13.7274 225.518 13.338Z').fill('#222721');
  portraitTopFlowerGroup.path('M225.697 13.4518C225.697 13.4518 227.261 32.4886 232.004 36.1465C236.747 39.8045 236.926 32.0672 234.06 24.8981C231.193 17.729 229.731 13.9306 225.761 11.4919C225.741 11.5175 224.944 12.2069 225.697 13.4518Z').fill('#222721');
  portraitTopFlowerGroup.path('M83.572 150.64C83.572 150.64 96.9271 166.402 130.628 160.969C164.328 155.537 169.04 126.707 169.04 126.707C169.04 126.707 172.442 117.048 164.022 81.4707C155.601 45.8932 156.08 22.1387 167.31 8.54104C178.539 -5.05665 197.722 -12.2322 219.868 -9.61475C242.014 -6.99736 252.969 2.45081 270.505 -1.84556C288.042 -6.14192 295.102 -14.8368 295.102 -27.4514');
  portraitTopFlowerGroup.path('M225.697 13.4533C227.433 9.36395 230.093 5.73226 233.468 2.84267C236.843 -0.0469268 240.841 -2.11595 245.149 -3.20233');
  portraitTopFlowerGroup.path('M198.706 131.426C198.706 131.426 201.821 103.113 177.843 74.52C177.843 74.52 157.721 51.6465 158.487 34.1993');
  portraitTopFlowerGroup.path('M107.818 187.51C107.818 187.51 101.332 170.989 120.184 162.396C120.184 162.396 126.185 164.279 120.069 171.774C113.953 179.268 108.935 180.335 107.818 187.51Z').fill('#009D70');
  portraitTopFlowerGroup.path('M158.487 147.125C157.164 150.531 156.014 154.002 155.04 157.524C153.22 163.908 152.639 175.967 154.535 180.142C154.548 180.142 170.412 158.954 158.487 147.125Z').fill('#009D70');
  portraitTopFlowerGroup.path('M132.951 120.484C132.951 120.484 128.483 134.139 143.23 142.342C157.976 150.545 158.71 146.434 158.71 146.434C158.71 146.434 162.617 142.387 156.125 138.473C149.633 134.56 143.3 132.568 137.848 129.536C132.511 126.58 132.951 120.484 132.951 120.484Z').fill('#009D70');
  portraitTopFlowerGroup.path('M178.137 111.495C178.137 111.495 191.173 103.535 184.699 84.5682C184.699 84.5682 179.854 86.8089 180.173 95.3825C180.316 100.826 179.63 106.259 178.137 111.495Z').fill('#009D70');
  portraitTopFlowerGroup.path('M214.627 87.5488C214.627 87.5488 207.535 100.572 185.9 85.5059C185.9 85.5059 185.434 78.5858 198.469 83.2397C211.505 87.8935 214.627 87.5488 214.627 87.5488Z').fill('#009D70');
  portraitTopFlowerGroup.path('M133.909 43.0467C133.909 43.0467 154.382 41.3741 159.949 22.414C159.949 22.414 152.537 17.8303 143.6 32.5325C143.6 32.5325 137.829 40.1357 133.909 43.0467Z').fill('#009D70');
  portraitTopFlowerGroup.path('M185.989 55.2363C185.989 55.2363 159.081 43.79 161.041 21.4272C161.041 21.4272 175.851 20.4249 179.477 35.8676C183.103 51.3102 185.989 55.2363 185.989 55.2363Z').fill('#009D70');
  portraitTopFlowerGroup.path('M107.103 136.654C107.103 136.654 100.904 146.229 110.602 155.55C120.299 164.87 121.684 161.825 121.684 161.825C121.684 161.825 125.514 159.502 121.301 155.173C117.088 150.845 112.612 148.042 109.031 144.608C105.45 141.173 107.103 136.654 107.103 136.654Z').fill('#009D70');

  return svgHelper(portraitTop);
};

export default portraitTopSvg;
