import { gsap } from "gsap";
import waveBottomSvg from "../../svg/general/wave/waveBottom";

waveBottomSvg().addToClass('.cmp-experiencefragment--footer #footer-list-links'); 
waveBottomSvg().addToClass('.cmp-experiencefragment--footer #last-section-footer');

const backToTopAnimation = () => {
    const setupFooter = () => {
        const backToTopElement = document.getElementById("main-footer-back-to-top");
        const dataLayer = backToTopElement.getAttribute("data-cmp-data-layer");
        const hrefUrls = dataLayer.match(/href=\\"([^\\"]+)\\"/g)
            .map((match) => match.replace(/href=\\"([^\\"]+)\\"/, '$1'));

        if (hrefUrls && hrefUrls.length > 0) {
            const random = Math.floor(Math.random() * hrefUrls.length);
            backToTopElement.style.backgroundImage = 'url("' + hrefUrls[random] + '")';

            let shouldStop = false;
            gsap.set(backToTopElement, { backgroundPosition: '0 -21300px' });

            const footerBackToTopAnimation = gsap.from(backToTopElement, {
                duration: 3,
                backgroundPosition: '0 0',
                ease: 'steps(71)',
                repeat: -1,
                onRepeat: checkShouldStop,
                paused: true,
            });

            function checkShouldStop() {
                shouldStop ? footerBackToTopAnimation.pause() : footerBackToTopAnimation.resume();
            }

            backToTopElement.addEventListener('mouseover', () => {
                shouldStop = false;
                footerBackToTopAnimation.play();
            });

            backToTopElement.addEventListener('mouseout', () => {
                shouldStop = true;
                gsap.set(backToTopElement, { backgroundPosition: '0 -21300px' });
            });

            document.querySelector('#main-footer-back-to-top').addEventListener('click', (event) => {
                event.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
        }
    };

    const init = () => {
        const containerBackToTop = document.querySelectorAll('#footer-back-to-top');
        if (containerBackToTop.length) {
            containerBackToTop.forEach((container) => {
                if (!container.Footer) {
                    setupFooter();
                    container.Footer = true;
                }
            });
        }
    };

    init();
};

const addCurrentYear = () => {
    const yearElement = document.querySelector('.cmp-experiencefragment--footer > .cmp-container #last-section-footer #text-container .text i');
    if (yearElement) {
        const currentYear = new Date().getFullYear();
        yearElement.textContent = currentYear;
    }
};

const onDocumentReady = () => {
    backToTopAnimation();
    addCurrentYear();
}
if (document.readyState !== "loading") {
    onDocumentReady();
} else {
    document.addEventListener("DOMContentLoaded", onDocumentReady);
}

