const removeLocationHash = () => {
    const buttons = document.querySelectorAll('.acc-kids .cmp-accordion__button');

    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            window.location.hash;
            window.history.replaceState('', document.title, window.location.pathname);
        });
    });
};

const onDocumentReady = () => {
    removeLocationHash();
}
if (document.readyState != 'loading') {
    onDocumentReady();
} else {
    document.addEventListener("DOMContentLoaded", onDocumentReady);
}