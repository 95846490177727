import Swiper, { Navigation, Pagination } from 'swiper';
import blockBlobSvg from "../../svg/kids/blockBlog";
import 'swiper/swiper.css';

Swiper.use([Navigation, Pagination]);

const initializeKidsTeaserSwiper = (element) => {
  const swiper = new Swiper(element, {
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,

    pagination: {
      el: element.querySelector('.swiper-pagination'),
      clickable: true,
    },

    breakpoints: {
      1024: {
        slidesPerView: 2,
      }
    },

    on: {
      resize: () => {
        const videoHeight = element.querySelector('.cmp-teaser__image svg').clientHeight;
        const swiperNav = element.querySelector('.swiper-navigation');
        swiperNav.style.setProperty("top", videoHeight + 'px');
      },
    },

    navigation: {
      nextEl: element.querySelector('.swiper-button-next'),
      prevEl: element.querySelector('.swiper-button-prev'),
    },
  });

  const arrowContainer = document.createElement('div');
  const arrowNext = element.querySelector('.swiper-button-next');
  const arrowPrev = element.querySelector('.swiper-button-prev');

  arrowContainer.classList.add('swiper-navigation');

  arrowContainer.appendChild(arrowPrev);
  arrowContainer.appendChild(arrowNext);

  element.insertBefore(arrowContainer, element.querySelector('.swiper-pagination'));
};

const setupKidsTeaserSwipers = () => {
  const kidsTeaserElements = document.querySelectorAll('.c-kids-teaser-slider');

  blockBlobSvg().addToClass('.c-kids-teaser-slider .cmp-teaser__image');

  kidsTeaserElements.forEach((element) => {
    initializeKidsTeaserSwiper(element);
  });
};


const onDocumentReady = () => {
  setupKidsTeaserSwipers();
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
