import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const applyTeaserAnimation = () => {
  const teaserImgAnimation = (element) => {
    const adaptiveImg = element.querySelector(".cmp-adaptive-image");

    if (adaptiveImg) {
      const animation = gsap.fromTo(
        adaptiveImg,
        { y: "100px" },
        {
          y: "-20px",
          ease: "power2.out",
        }
      );

      ScrollTrigger.create({
        trigger: adaptiveImg,
        start: "top bottom",
        end: "bottom top",
        scrub: 0.5,
        animation: animation,
      });
    }
  };
  const teaserCommonElements = document.querySelectorAll(".t-common");
  const teaserOlympicsElements = document.querySelectorAll(".t-olympics");
  const teaserListElements = document.querySelectorAll(
    ".c-teaser-list .teaser"
  );

  teaserCommonElements.forEach(teaserImgAnimation);
  teaserOlympicsElements.forEach(teaserImgAnimation);
  teaserListElements.forEach(teaserImgAnimation);
};
const onDocumentReady = () => {
  applyTeaserAnimation();
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
