import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../src/utils/svgHelper';

const olympicsMarkerLeftMobileSvg = () => {
  const olympicsMarkerLeftMobile = SVG();

  olympicsMarkerLeftMobile.svg('<path d="M5.94482 2.05527C8.36261 1.76679 12.2091 2.63911 16.4814 3.9373" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/><path d="M2 16.246C4.41778 15.9575 8.07194 16.0194 12.461 16.8436" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/><path d="M6.52441 31C8.9422 30.7115 11.8545 29.063 15.9139 29.3446" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>');
  olympicsMarkerLeftMobile.viewbox(0, 0, 19, 33).addClass('title-marker-left title-marker-mobile'); 


  return svgHelper(olympicsMarkerLeftMobile);
}

export default olympicsMarkerLeftMobileSvg;