import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const swiperSimpleBulletSvg = () => {
  const swiperSimpleBullet = SVG();

  swiperSimpleBullet.svg('<path d="M19.66,20.17c2.1-1.72,3.17-4.93,3.32-8.3s-.6-6.9-2.71-9S14.73-.07,11.37,0,4.73,1,2.68,3.16-.14,8.66,0,11.84,1.34,18,3.37,19.76s5,2.11,8,2.22,6.16-.11,8.27-1.81" style="fill-rule: evenodd;"></path>');
  swiperSimpleBullet.viewbox(0, 0, 23 ,22).height('22px').width('23px')

  return svgHelper(swiperSimpleBullet);
}

export default swiperSimpleBulletSvg;