const subscribeFormStore = {
    namespaced: true,
    state: {
      apiError: false,
      pathError: false,
      dataError: false,
      dataName: 'subsTranslationData',
      dataReady: false,
      popUpActive: false,
      isIntro: true,
      formSent: false,
      formSuccess: false,
      viewsSuccess: false,
      navFormPresent: false,
      subsFormItems: [],
      useRecaptcha: false,
      recaptchaSiteKey: '',
      recaptchaActionWord: '',
      recaptchaUrl: '',
      recaptchaLoaded: false,
      recaptchaMsgHtml: '',
    },
    mutations: {
      setApiError: (state, status) => {
        state.apiError = status;
      },
      setPathError: (state, status) => {
        state.pathError = status;
      },
      setDataError: (state, status) => {
        state.dataError = status;
      },
      setDataReady: (state, status) => {
        state.dataReady = status;
      },
      setPopUpActive: (state, status) => {
        state.popUpActive = status;
      },
      setSubsFormItems: (state, payload) => {
        state.subsFormItems = payload; 
      },
      setIsIntro: (state, status) => {
        state.isIntro = status;
      },
      setFormSent: (state, status) => {
        state.formSent = status;
      },
      setFormSuccess: (state, status) => {
        state.formSuccess = status;
      },
      setViewsSuccess: (state, status) => {
        state.viewsSuccess = status;
      },
      setNavFormPresent: (state, status) => {
        state.navFormPresent = status;
      },
      setRecaptcha: (state, payload) => {
        if (payload?.enableRecaptcha) {
          state.useRecaptcha = payload.enableRecaptcha;
          state.recaptchaSiteKey = payload.siteKey;
          state.recaptchaActionWord = payload.actionWord;
          state.recaptchaUrl = payload.googleUrl;
          state.recaptchaMsgHtml = payload.recaptchaMessage?.html;
        }
      },
      setRecaptchaLoaded: (state, status) => {
        state.recaptchaLoaded = status;
      }
    },
    getters: {
        hasError: state => state.apiError || state.pathError || state.dataError,
        getSubsFormItems: state => state.subsFormItems,
        getDataName: state => state.dataName,
        getDataReady: state => state.dataReady,
        showInitialLogo: state => state.dataReady && !state.popUpActive,
        showPopUp: state => state.popUpActive,
        showNavIntro: state => state.popUpActive && state.isIntro,
        showNavForm: state => state.popUpActive && !state.isIntro && !state.formSent,
        showNavSuccess: state => state.popUpActive && !state.isIntro && state.formSent && state.formSuccess,
        showNavError: state => state.popUpActive && !state.isIntro && state.formSent && !state.formSuccess,
        showPageIntro: state => state.isIntro,
        showPageForm: state => !state.isIntro && !state.formSent,
        showPageSuccess: state => !state.isIntro && state.formSent && state.formSuccess,
        showPageError: state => !state.isIntro && state.formSent && !state.formSuccess,
        viewsSuccess: state => state.viewsSuccess,
        getNavFormPresent: state => state.navFormPresent,
        initRecaptcha: (state, getters) => !state.recaptchaLoaded && state.useRecaptcha && (getters.showNavForm || getters.showPageForm),
        getRecaptchaSiteKey: state => state.recaptchaSiteKey,
        getRecaptchaActionWord: state => state.recaptchaActionWord,
        getRecaptchaUrl: state => ''.concat(state.recaptchaUrl, state.recaptchaSiteKey),
        getUseRecaptcha: state => state.useRecaptcha,
        getRecaptchaMsgHtml: state => state.recaptchaMsgHtml,
      },
    actions: {
      loadResults: ({ commit }, { items } ) => {
        if (items.length) {
          commit('setSubsFormItems', items[0]);
          commit('setRecaptcha', items[0]);
          commit('setDataReady', true);
          return;
        }
        commit('setDataError', true);
      },
      updatePopUp: ({ commit }, { popUpStatus } ) => {
        commit('setPopUpActive', popUpStatus);
      },
      updateIntro: ({ commit }, { introStatus } ) => {
        commit('setIsIntro', introStatus);
      },
      updateViews: ({ commit }, { status } ) => {
        commit('setViewsSuccess', status);
      },
      updateFormStatus: ({ commit }, { status }) => {
        commit('setFormSuccess', status);
        commit('setFormSent', true);
      },
      resetForm: ({ commit }, {}) => {
        commit('setFormSent', false);
      },
      performApiQuery: ({ dispatch, commit, getters }, { query, store, folder, isNav }) => {
        if (isNav) {
          commit('setNavFormPresent', true);
          dispatch('performApiQuery', { query, store, folder }, { root: true });
          return;
        }

        if (!isNav && !getters['getNavFormPresent']) {
          dispatch('performApiQuery', { query, store, folder }, { root: true });
        }
      },
      loadRecaptcha: ({ commit, getters }, {}) => {
        const headSection = document.head;
        const reCaptchaScript = document.createElement('script');
        reCaptchaScript.src = getters.getRecaptchaUrl;
        headSection.append(reCaptchaScript);
        commit('setRecaptchaLoaded', true);
      },
      sendFormDataWithRecaptcha: ({ dispatch, commit, getters }, { url, store, payload, isViews }) => {
        if (!getters.getUseRecaptcha) {
          dispatch('sendFormData', { url, store, payload, isViews }, { root: true });
          return;
        }

        grecaptcha.enterprise.ready(() => {
          grecaptcha.enterprise.execute(getters.getRecaptchaSiteKey, { action: getters.getRecaptchaActionWord })
            .then((recaptchaResponse) => {
              payload['token'] = recaptchaResponse;
              payload['recaptchaSitekey'] = getters.getRecaptchaSiteKey;
              dispatch('sendFormData', { url, store, payload, isViews }, { root: true });
            })
            .catch((error) => {
              console.error('Recpatcha eroor', error)
              commit('setFormSuccess', false);
              commit('setFormSent', true);
            })
        })
      }
    }
  };

  export default subscribeFormStore;
  