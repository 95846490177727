
const showSecondaryNav = () => {
  window.onscroll = function () {
    const activeNavItem = document.querySelector('.cmp-navigation__item--active');
    if (activeNavItem) {
      if (activeNavItem && window.pageYOffset > 100) {
        document.getElementById("main-header").classList.add("is-scrolled");
      } else {
        document.getElementById("main-header").classList.remove("is-scrolled");
      }
    }
  };
}

const mobileMenu = () => {
  document.querySelectorAll(".cmp-navigation__item--level-0").forEach((elm) => {
    if (elm.children.length > 1) {
      elm.innerHTML += '<span class="navigation-icon-plus"></span>';
    }
  });
  const navigationIcon = document.querySelectorAll(".navigation-icon-plus");

  navigationIcon.forEach((param) => {
    param.addEventListener("click", function () {
      if (param.parentElement.classList.contains("is-open")) {
        param.parentElement.classList.remove("is-open");
        document.querySelectorAll(".cmp-navigation__item--level-0:not(.is-open)").forEach((elm) => {
          elm.classList.remove("hide");
        });
      } else {
        param.parentElement.classList.add("is-open");
        document.querySelectorAll(".cmp-navigation__item--level-0:not(.is-open)").forEach((elm) => {
          elm.classList.add("hide");
        });
      }
    });
  });
};

const toggleMenu = () => {
  const headerTab = document.getElementById("header-tab");
  const mainHeader = document.getElementById("main-header");

  if (headerTab) {
    headerTab.addEventListener("click", function () {
      if (mainHeader.classList.contains("is-open")) {
        mainHeader.classList.remove("is-open");
      } else {
        mainHeader.classList.add("is-open");
      }
    });
  }
};

const subMenuHeadersVisibility = () => {
  const mainHeader = document.getElementById('main-header');
  if (mainHeader) {
    const activeListItemsLevel0 = mainHeader.querySelectorAll('.cmp-navigation__item--level-0.cmp-navigation__item--active');
    const subActiveListItemsLevel1 = mainHeader.querySelectorAll('.cmp-navigation__item--level-1.cmp-navigation__item--active');
    let hasSubLists = false;

    activeListItemsLevel0.forEach(listItem => {
      const subList = listItem.querySelector('ul');
      if (subList) {
        hasSubLists = true;
      }
    });

    if (subActiveListItemsLevel1.length > 0) {
      mainHeader.classList.remove('header-clone');
    } else if (hasSubLists) {
      mainHeader.classList.add('header-clone');
    }
  }
};

const onDocumentReady = () => {
  const mainNav = document.getElementById("main-header");

  if (mainNav) {
    showSecondaryNav();
    toggleMenu();
    mobileMenu();
    subMenuHeadersVisibility();
  }
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}

