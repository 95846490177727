import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const portraitLeftSvg = () => {
  const portraitLeft = SVG();
  portraitLeft.width(79)
    .height(653)
    .viewbox('0 0 79 653')
    .addClass('portrait-left')
    .fill('none');
  
  const styleOne = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };

  const styleTwo = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-miterlimit': 10,
    'stroke-linecap': 'round',
  };

  const styleThree = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-miterlimit': 10,
  };

  const groupOne = portraitLeft.group();
  groupOne.css(styleOne);
  const groupOneFill = '#A3D380';
  groupOne.path('M6.41097 641.448C6.98097 642.433 7.48991 643.75 6.75035 644.638C5.90308 645.621 4.31503 645.047 3.19246 644.406C-2.79881 640.982 -8.69688 637.252 -13.507 632.313C-16.0703 629.718 -18.2259 626.751 -19.9005 623.511C-20.6858 621.973 -24.2274 614.494 -21.1964 615.089C-19.6937 615.382 -17.4523 617.751 -16.2062 618.667C-14.5404 619.883 -12.8979 621.125 -11.2785 622.392C-8.04457 624.9 -4.97476 627.612 -2.08799 630.513C1.18613 633.793 4.0409 637.466 6.41097 641.448Z').fill(groupOneFill);
  groupOne.path('M15.6283 616.001C16.8109 615.41 18.1655 614.026 17.2952 613.012C16.9048 612.638 16.3935 612.416 15.8536 612.385C10.2233 611.482 4.51579 611.153 -1.18101 611.404C-7.06506 611.657 -13.0712 612.582 -18.212 615.459C-18.5488 615.647 -18.9357 615.944 -18.8432 616.326C-18.805 616.449 -18.7425 616.563 -18.6594 616.661C-18.5763 616.759 -18.4744 616.839 -18.3596 616.897C-13.7823 619.955 -7.30581 620.309 -1.92464 620.233C4.16775 620.141 10.1635 618.695 15.6283 616.001Z').fill(groupOneFill);
  groupOne.path('M-6.18443 612.396C-0.789527 610.136 4.13784 606.928 9.03338 603.734C9.87991 603.256 10.6094 602.596 11.1687 601.801C11.4398 601.401 11.5726 600.923 11.5466 600.44C11.5207 599.957 11.3376 599.496 11.0252 599.127C10.3815 598.494 9.38563 598.502 8.49899 598.593C4.00711 598.898 -0.472989 599.34 -4.94131 599.918C-9.85818 600.552 -14.99 601.444 -18.9165 604.471C-21.1113 606.168 -22.7906 608.43 -24.4254 610.671C-27.9917 615.572 -21.1938 615.42 -18.0542 615.259C-13.9631 614.99 -9.94836 614.022 -6.18443 612.396Z').fill(groupOneFill);
  groupOne.path('M1.14347 596.926C2.11842 595.941 3.0756 594.356 2.20498 593.303C1.68897 592.669 0.756055 592.569 -0.0672799 592.595C-4.32429 592.739 -8.2526 594.789 -12.0154 596.78C-15.601 598.581 -19.0568 600.63 -22.3577 602.912C-24.1026 604.203 -30.8375 608.843 -29.6847 611.579C-28.1414 615.211 -11.9476 606.458 -9.49523 605.026C-5.60369 602.813 -2.02574 600.088 1.14347 596.926Z').fill(groupOneFill);
  groupOne.path('M-11.589 596.615C-9.04787 595.112 -6.54742 593.581 -4.08764 592.02C-0.613793 589.826 3.02971 587.306 4.41477 583.439C4.70193 582.657 4.82115 581.641 4.17831 581.11C3.89288 580.939 3.57364 580.832 3.24264 580.796C2.91166 580.76 2.57684 580.797 2.26135 580.903C-8.57457 582.953 -18.4389 588.213 -27.1624 594.772C-29.3576 596.424 -31.4818 598.166 -33.5543 599.971C-35.1008 601.324 -38.7336 603.589 -39.5029 605.51C-41.0355 609.29 -30.5625 605.819 -29.0413 605.276C-22.8987 603.106 -17.173 599.936 -11.589 596.615Z').fill(groupOneFill);
  groupOne.path('M14.0877 633.379C15.3068 633.858 16.6158 634.065 17.9232 633.986C18.5482 633.984 19.1566 633.785 19.6621 633.417C20.1677 633.05 20.5447 632.533 20.7398 631.939C20.9348 631.345 20.9379 630.705 20.7486 630.11C20.5593 629.514 20.1873 628.993 19.6853 628.621C18.8022 627.883 17.7912 627.314 16.7026 626.941C12.963 625.383 9.11101 624.109 5.1795 623.13C2.56326 622.514 -4.18236 620.501 -6.47975 622.142C-1.49348 627.54 7.2 631.17 14.0877 633.379Z').fill(groupOneFill);
  groupOne.path('M0.0827571 497.928C1.27491 498.493 2.65244 499.854 1.7992 500.889C1.41506 501.269 0.907505 501.5 0.368192 501.539C-5.24822 502.538 -10.9513 502.966 -16.654 502.817C-22.5415 502.655 -28.5622 501.831 -33.7501 499.046C-34.09 498.864 -34.4819 498.573 -34.3957 498.19C-34.3602 498.066 -34.2999 497.951 -34.2184 497.851C-34.1369 497.752 -34.0359 497.67 -33.9217 497.611C-29.3959 494.477 -22.9263 494.008 -17.5446 494.002C-11.4503 493.987 -5.42919 495.328 0.0827571 497.928Z').fill(groupOneFill);
  groupOne.path('M-1.74692 480.592C-0.536842 480.09 0.76921 479.861 2.07797 479.922C2.70285 479.914 3.31448 480.102 3.8261 480.461C4.33771 480.82 4.72332 481.331 4.92819 481.921C5.13307 482.512 5.14681 483.152 4.96748 483.75C4.78814 484.349 4.42482 484.876 3.92909 485.256C3.06002 486.015 2.05866 486.608 0.975043 487.005C-2.73737 488.624 -6.56787 489.958 -10.4831 490.994C-13.0887 491.655 -19.7999 493.785 -22.1243 492.183C-17.2287 486.69 -8.59688 482.916 -1.74692 480.592Z').fill(groupOneFill);
  groupOne.path('M12.1897 266.769C12.1897 266.769 -37.8098 287.57 -56.6746 305.998C-75.5394 324.427 -78.062 344.245 -78.062 344.245C-78.062 344.245 -52.7551 341.219 -27.4883 317.272C-2.22147 293.325 12.1897 266.769 12.1897 266.769Z').fill(groupOneFill);
  groupOne.path('M16.4214 399.254C16.4214 399.254 -27.7887 341.75 -76.2987 344.408C-76.2987 344.408 -72.8076 358.851 -48.4101 375.4C-24.0126 391.949 9.39746 399.855 16.4214 399.254Z').fill(groupOneFill);
  groupOne.path('M12.9575 398.044L-76.2986 344.408');
  groupOne.path('M9.5116 268.662L-78.0746 344.245');

  const portraitLeftLeavesGroup = portraitLeft.group();
  portraitLeftLeavesGroup.addClass('portrait-left-leaves');
  portraitLeftLeavesGroup.css(styleOne);
  portraitLeftLeavesGroup.path('M1.78191 334.781C1.78191 334.781 -4.7867 340.224 -8.40348 350.551C-12.0203 360.879 -18.2183 398.546 -45.2796 406.336C-45.2796 406.336 -54.5696 408.495 -52.734 394.339C-50.8983 380.183 -45.6997 358.191 -26.3047 348.236C-6.90963 338.281 1.78191 334.781 1.78191 334.781Z').fill('#509689');
  portraitLeftLeavesGroup.path('M-1.18858 336.848C-1.18858 336.848 -24.6902 356.579 -36.1475 379.07C-36.1475 379.07 -43.4775 391.976 -47.6826 405.251');
  portraitLeftLeavesGroup.path('M-0.674234 395.545C-11.7491 400.56 -23.4451 404.071 -35.4509 405.985C-53.8695 408.999 -72.9046 404.688 -91.0863 401.648C-107.926 398.787 -144.791 385.407 -146.461 355.077');
  portraitLeftLeavesGroup.path('M18.3274 456.383C18.3274 456.383 -6.25767 458.707 -23.2317 448.902C-40.2057 439.097 -48.4618 421.297 -47.5948 408.885C-47.5948 408.885 -39.9906 402.074 -28.0931 410.97C-16.1956 419.866 -7.93262 431.601 -2.60852 440.003C2.71559 448.405 14.1181 455.358 18.3274 456.383Z').fill('#509689');
  portraitLeftLeavesGroup.path('M16.2372 456.074C16.2372 456.074 -17.078 448.831 -29.6717 425.321C-33.6442 418.015 -39.9369 412.241 -47.5563 408.91');
  portraitLeftLeavesGroup.path('M69.7474 357.033C66.048 358.343 62.4971 360.039 59.1536 362.094C53.3352 365.629 50.0852 370.929 45.8627 375.988C42.4229 380.022 38.577 383.691 34.3859 386.937C25.6775 393.783 13.8196 400.374 2.38479 397.201C1.05868 396.834 -0.228371 396.338 -1.45801 395.72C-1.45801 395.72 -3.13155 384.115 8.17399 373.295C19.4795 362.476 37.7173 353.08 69.7474 357.033Z').fill('#509689');
  portraitLeftLeavesGroup.path('M67.1459 357.41C67.1459 357.41 48.7652 358.84 32.3437 372.448C15.9221 386.055 2.84652 395.906 -1.47076 395.712');

  const groupTwo = portraitLeft.group();
  groupTwo.css(styleOne);
  groupTwo.path('M9.06691 67.3226C9.06691 67.3226 -25.621 53.1706 -60.1471 59.2039C-94.6732 65.2371 -118.686 42.6969 -118.686 42.6969C-118.686 42.6969 -113.413 31.1616 -95.0062 28.3142C-76.599 25.4668 -51.3252 23.0538 -13.1333 47.0719C-13.1461 47.072 0.241955 56.3388 9.06691 67.3226Z').fill('#009D70');
  groupTwo.path('M6.46906 65.0902C6.46906 65.0902 -27.8595 47.3154 -59.6274 54.1493C-91.3953 60.9831 -117.112 42.4407 -117.112 42.4407');
  groupTwo.path('M-116.679 41.55C-106.228 39.9563 -96.0712 36.9095 -85.6404 35.2713C-75.0755 33.6383 -64.4557 35.5417 -54.0952 37.7282C-34.3527 41.8922 -11.3262 48.6782 3.07989 63.6631L3.26666 63.8594');

  const portraitLeftFlowerGroup = portraitLeft.group();
  portraitLeftFlowerGroup.addClass('portrait-left-flower');
  portraitLeftFlowerGroup.path('M29.5844 116.275C29.5844 116.275 8.12044 112.381 -5.1715 116.954').css(styleOne);
  portraitLeftFlowerGroup.path('M27.1378 113.035C27.1378 113.035 21.0017 116.744 11.9818 132.952').css(styleOne);
  portraitLeftFlowerGroup.path('M25.0667 111.17C28.0386 110.611 31.0998 110.752 34.0079 111.581C38.969 113.187 43.5932 119.577 39.2531 124.305C37.909 125.655 36.1222 126.472 34.2225 126.607C30.373 127.07 26.4903 126.068 23.3461 123.799C21.4185 122.322 16.0481 116.02 18.4916 113.523C19.9982 111.985 23.0457 111.493 25.0604 111.17').fill('#FFF7CE');
  portraitLeftFlowerGroup.path('M25.0667 111.17C28.0386 110.611 31.0998 110.752 34.0079 111.581C38.969 113.187 43.5932 119.577 39.2531 124.305C37.909 125.655 36.1222 126.472 34.2225 126.607C30.373 127.07 26.4903 126.068 23.3461 123.799C21.4185 122.322 16.0481 116.02 18.4916 113.523C19.9982 111.985 23.0457 111.493 25.0604 111.17').css(styleTwo);
  portraitLeftFlowerGroup.path('M25.0667 111.17C28.0386 110.611 31.0998 110.752 34.0079 111.581C38.969 113.187 43.5932 119.577 39.2531 124.305C37.909 125.655 36.1222 126.472 34.2225 126.607C30.373 127.07 26.4903 126.068 23.3461 123.799C21.4185 122.322 16.0481 116.02 18.4916 113.523C20.0045 111.985 23.0521 111.5 25.0667 111.17Z').css(styleTwo);
  portraitLeftFlowerGroup.path('M32.7645 118.672C28.4382 116.39 23.6425 115.141 18.7529 115.022').css(styleOne);
  portraitLeftFlowerGroup.path('M17.5048 116.167C17.5048 116.167 26.8403 128.653 23.7383 136.41C22.8066 138.748 21.1084 141.048 18.3566 141.013C13.2487 140.947 12.1433 135.466 11.8923 131.395C11.7413 128.608 12.1619 125.819 13.1281 123.2C14.0944 120.582 15.586 118.188 17.5112 116.167').fill('#FFF7CE');
  portraitLeftFlowerGroup.path('M17.5048 116.167C17.5048 116.167 26.8403 128.653 23.7383 136.41C22.8066 138.748 21.1084 141.048 18.3566 141.013C13.2487 140.947 12.1433 135.466 11.8923 131.395C11.7413 128.608 12.1619 125.819 13.1281 123.2C14.0944 120.582 15.586 118.188 17.5112 116.167').css(styleTwo);
  portraitLeftFlowerGroup.path('M17.5049 116.167C17.5049 116.167 26.8403 128.653 23.7384 136.41C22.8066 138.748 21.1085 141.048 18.3566 141.013C13.2488 140.947 12.1434 135.466 11.8924 131.395C11.7405 128.608 12.1601 125.82 13.1252 123.201C14.0904 120.582 15.5808 118.189 17.5049 116.167V116.167Z').css(styleTwo);
  portraitLeftFlowerGroup.path('M16.1212 114.857C16.1212 114.857 2.2039 109.496 -2.87935 117.748C-7.96261 126 -5.0288 131.019 0.635325 131.955C6.29945 132.891 12.183 130.282 16.1212 114.857Z').fill('#FFF7CE');
  portraitLeftFlowerGroup.path('M16.1212 114.857C16.1212 114.857 2.2039 109.496 -2.87935 117.748C-7.96261 126 -5.0288 131.019 0.635325 131.955C6.29945 132.891 12.183 130.282 16.1212 114.857').css(styleTwo);
  portraitLeftFlowerGroup.path('M16.1212 114.857C16.1212 114.857 2.2039 109.496 -2.87935 117.748C-7.96261 126 -5.0288 131.019 0.635325 131.955C6.29945 132.891 12.183 130.282 16.1212 114.857Z').css(styleTwo);
  portraitLeftFlowerGroup.path('M17.7269 112.934C16.8463 109.49 14.9587 106.386 12.3052 104.02C9.65177 101.654 6.35305 100.133 2.83071 99.6517C2.83071 99.6517 -6.84939 99.4387 -1.98785 109.792C2.87369 120.144 16.1213 114.85 16.1213 114.85L17.7269 112.934Z').css(styleTwo).fill('#FFF7CE');
  portraitLeftFlowerGroup.path('M17.7269 112.934C16.8463 109.49 14.9587 106.386 12.3052 104.02C9.65177 101.654 6.35305 100.133 2.83071 99.6517C2.83071 99.6517 -6.84939 99.4387 -1.98785 109.792C2.87369 120.144 16.1213 114.85 16.1213 114.85L17.7269 112.934Z').css(styleTwo);
  portraitLeftFlowerGroup.path('M17.4875 114.098C17.4875 114.098 27.4831 111.563 27.9716 100.47C28.1059 97.4236 27.5258 93.6937 24.317 92.4563C19.0204 90.4129 15.6199 95.2867 13.996 99.6031C12.0036 104.899 13.3631 110.232 17.4875 114.098Z').fill('#FFF7CE');
  portraitLeftFlowerGroup.path('M17.4875 114.098C17.4875 114.098 27.4831 111.563 27.9716 100.47C28.1059 97.4236 27.5258 93.6937 24.317 92.4563C19.0204 90.4129 15.6199 95.2867 13.996 99.6031C12.0036 104.899 13.3631 110.232 17.4875 114.098').css(styleTwo);
  portraitLeftFlowerGroup.path('M17.4875 114.098C17.4875 114.098 27.4831 111.563 27.9716 100.47C28.1059 97.4236 27.5258 93.6937 24.317 92.4563C19.0204 90.4129 15.6199 95.2867 13.996 99.6031C12.0034 104.88 13.3629 110.212 17.4875 114.098Z').css(styleTwo);
  portraitLeftFlowerGroup.path('M17.329 116.518C17.329 116.518 15.1664 124.382 19.4003 131.421').css(styleOne);
  portraitLeftFlowerGroup.path('M17.2896 115.639C17.2896 115.639 7.55796 116.901 2.94461 124.849').css(styleOne);
  portraitLeftFlowerGroup.path('M4.71979 106.486C7.75443 109.528 11.4664 111.807 15.5516 113.138').css(styleOne);
  portraitLeftFlowerGroup.path('M21.7397 100.325C21.7397 100.325 18.9031 102.385 16.7028 113.384').css(styleOne);
  portraitLeftFlowerGroup.path('M19.9202 113.356C19.7302 112.767 19.364 112.251 18.871 111.877C18.3781 111.503 17.7822 111.29 17.164 111.266C16.4694 111.311 15.8044 111.563 15.2548 111.99C14.5294 112.487 13.9667 113.187 13.6372 114.002C13.4755 114.412 13.4034 114.852 13.4258 115.292C13.4482 115.733 13.5645 116.163 13.767 116.555C14.5577 117.978 17.266 118.913 18.7336 118.058C20.2012 117.203 20.4169 114.737 19.9713 113.368').fill('#1E2722');
  portraitLeftFlowerGroup.path('M19.9202 113.356C19.7302 112.767 19.364 112.251 18.871 111.877C18.3781 111.503 17.7822 111.29 17.164 111.266C16.4694 111.311 15.8044 111.563 15.2548 111.99C14.5294 112.487 13.9667 113.187 13.6372 114.002C13.4755 114.412 13.4034 114.852 13.4258 115.292C13.4482 115.733 13.5645 116.163 13.767 116.555C14.5577 117.978 17.266 118.913 18.7336 118.058C20.2012 117.203 20.4169 114.737 19.9713 113.368').css(styleTwo);

  const groupThree = portraitLeft.group();
  groupThree.path('M-179.978 121.961C-169.429 126.84 -161.648 146.605 -154.839 150.876C-148.03 155.148 -143.622 160.889 -136.444 168.835C-129.266 176.781 -119.149 175.771 -113.603 180.168C-108.057 184.565 -103.53 191.537 -100.022 201.084C-96.514 210.631 -85.421 231.785 -77.9057 243.424C-70.3904 255.064 -21.9882 266.305 2.64722 270.033C13.2262 271.63 53.9755 293.029 63.5792 304C65.6675 306.383 71.0385 298.192 68.4952 296.368C64.7636 293.721 61.1747 290.878 57.7437 287.852C47.9145 278.932 -24.3699 260.892 -52.5131 247.618C-80.6563 234.344 -76.7806 229.811 -85.059 210.861C-88.8602 202.115 -90.4538 193.969 -92.4856 186.874C-95.4702 185.718 -64.9068 178.377 -22.8098 188.349C-8.16774 191.815 6.79538 182.466 26.5523 170.74C46.3093 159.013 72.5946 166.143 72.5946 166.143C80.6927 156.55 55.199 153.245 46.007 151.847C36.815 150.448 16.4196 164.472 7.75458 170.392C-9.26435 182.025 -22.2808 175.985 -33.4193 174.667C-48.0022 172.944 -76.5606 174.209 -88.3329 173.439C-93.3156 173.171 -98.1727 171.778 -102.54 169.363C-103.612 168.484 -104.757 167.697 -105.962 167.01C-120.85 158.599 -119.705 157.249 -129.291 150.778C-138.876 144.308 -132.482 130.216 -140.631 118.295').fill('#56352A');
  groupThree.path('M-179.978 121.961C-169.429 126.84 -161.648 146.605 -154.839 150.876C-148.03 155.148 -143.622 160.889 -136.444 168.835C-129.266 176.781 -119.149 175.771 -113.603 180.168C-108.057 184.565 -103.53 191.537 -100.022 201.084C-96.514 210.631 -85.421 231.785 -77.9057 243.424C-70.3904 255.064 -21.9882 266.305 2.64722 270.033C13.2262 271.63 53.9755 293.029 63.5792 304C65.6675 306.383 71.0385 298.192 68.4952 296.368C64.7636 293.721 61.1747 290.878 57.7437 287.852C47.9145 278.932 -24.3699 260.892 -52.5131 247.618C-80.6563 234.344 -76.7806 229.811 -85.059 210.861C-88.8602 202.115 -90.4538 193.969 -92.4856 186.874C-95.4702 185.718 -64.9068 178.377 -22.8098 188.349C-8.16774 191.815 6.79537 182.466 26.5523 170.74C46.3092 159.013 72.5946 166.143 72.5946 166.143C80.6927 156.55 55.199 153.245 46.007 151.847C36.815 150.448 16.4196 164.472 7.75458 170.392C-9.26435 182.025 -22.2808 175.985 -33.4193 174.667C-48.0022 172.944 -76.5606 174.209 -88.3329 173.439C-93.3156 173.171 -98.1727 171.778 -102.54 169.363C-103.612 168.484 -104.757 167.697 -105.962 167.01C-120.85 158.599 -119.705 157.249 -129.291 150.778C-138.876 144.308 -132.482 130.216 -140.631 118.295').css(styleTwo);

  const groupFour = portraitLeft.group();
  groupFour.css(styleOne);
  groupFour.path('M-55.6025 196.309C-55.6025 196.309 -41.677 194.986 -33.77 193.867C-25.863 192.748 -3.88958 185.766 0.597067 175.635C5.08372 165.504 -46.2961 176.951 -55.6025 196.309Z').fill('#A3D380');
  groupFour.path('M-52.9155 194.697C-52.9155 194.697 -16.649 179.941 -0.327117 174.296L-52.9155 194.697Z').fill('#A3D380');
  groupFour.path('M-52.9155 194.697C-52.9155 194.697 -16.649 179.941 -0.327117 174.296');
  groupFour.path('M-0.926655 178.207C-1.17833 177.884 -24.1931 168.684 -35.4342 150.443C-46.6753 132.201 -48.2676 124.221 -48.2676 124.221C-48.2676 124.221 -32.4792 120.451 -14.5848 146.477C3.30958 172.503 -0.926655 178.207 -0.926655 178.207Z').fill('#A3D380');
  groupFour.path('M-47.6048 124.075C-47.6048 124.075 -9.959 166.075 -0.907899 175.084L-47.6048 124.075Z').fill('#A3D380');
  groupFour.path('M-47.6048 124.075C-47.6048 124.075 -9.959 166.075 -0.907899 175.084');
  groupFour.path('M-33.0671 232.28C-32.8975 231.953 -37.918 207.11 -26.5659 191.917C-15.2138 176.724 -8.20638 173.378 -8.20638 173.378C-8.20638 173.378 5.36821 188.178 -9.6812 211.695C-24.7306 235.211 -33.0671 232.28 -33.0671 232.28Z').fill('#A3D380');
  groupFour.path('M17.8582 155.541C20.3949 155.374 22.9387 155.733 25.3301 156.596C26.9629 157.182 28.385 158.524 29.9535 159.06C30.9651 159.403 31.8037 158.91 32.8682 158.716C35.362 158.257 37.9354 158.526 40.2802 159.491C41.3757 159.948 42.5644 160.857 42.3698 162.027C42.2215 162.579 41.9069 163.072 41.4686 163.439C39.7939 165.089 37.7993 166.379 35.6077 167.229L39.9695 172C41.084 173.217 42.2771 174.656 42.1056 176.298C41.8328 178.803 38.7896 179.805 36.3161 180.24C37.525 181.581 38.2362 183.297 38.3305 185.1C38.4248 186.903 37.8966 188.683 36.8342 190.143C35.7719 191.603 34.2401 192.653 32.4956 193.117C30.7511 193.582 28.8999 193.433 27.2523 192.695C27.5028 194.193 27.1934 195.731 26.3831 197.015C25.5728 198.3 24.3182 199.241 22.8584 199.661C21.3985 200.08 19.8355 199.947 18.4671 199.288C17.0987 198.629 16.0205 197.49 15.438 196.087C15.2105 195.598 15.2087 194.621 14.8927 194.234C14.5766 193.847 13.6115 193.708 13.0545 193.522C11.6566 193.021 10.3226 192.357 9.07988 191.544C6.53667 189.851 4.41816 187.595 2.88914 184.95C1.36013 182.306 0.461823 179.344 0.264106 176.295C-0.464059 165.519 7.42594 156.228 17.8582 155.541Z').fill('#FAFBFC');
  groupFour.path('M31.8459 102.66C31.5876 103.07 29.8955 132.93 14.1289 152.354C-1.63765 171.778 -9.73233 176.423 -9.73233 176.423C-9.73233 176.423 -19.5326 159.485 2.28862 129.623C24.1098 99.7618 31.8459 102.66 31.8459 102.66Z').fill('#A3D380');
  groupFour.path('M-10.1341 175.704C-10.1341 175.704 21.9391 117.38 28.4521 103.733L-10.1341 175.704Z').fill('#A3D380');
  groupFour.path('M-10.1341 175.704C-10.1341 175.704 21.9391 117.38 28.4521 103.733');
  groupFour.path('M17.1659 228.025C17.029 227.682 -4.57416 215.502 -8.51019 196.695C-12.4462 177.888 -10.4036 170.191 -10.4036 170.191C-10.4036 170.191 9.27608 169.115 16.9905 196.234C24.7049 223.353 17.1659 228.025 17.1659 228.025Z').fill('#A3D380');
  groupFour.path('M-9.58038 170.151C-9.58038 170.151 12.7825 215.292 18.6866 225.113L-9.58038 170.151Z').fill('#A3D380');
  groupFour.path('M-9.58038 170.151C-9.58038 170.151 12.7825 215.292 18.6866 225.113');
  groupFour.path('M7.0539 273.277C6.49272 273.352 -21.2736 293.827 -50.5982 294.314C-79.9228 294.801 -90.2572 291.14 -90.2572 291.14C-90.2572 291.14 -82.7715 270.105 -39.5365 267.249C3.69848 264.393 7.0539 273.277 7.0539 273.277Z').fill('#009D70');
  groupFour.path('M-89.9326 290.262C-89.9326 290.262 -13.6128 275.421 3.4925 271.097L-89.9326 290.262Z').fill('#009D70');
  groupFour.path('M-89.9326 290.262C-89.9326 290.262 -13.6128 275.421 3.4925 271.097');
  groupFour.path('M-30.749 328.949C-30.5347 328.628 -33.9172 302.692 -20.2393 287.735C-6.56141 272.778 1.38728 269.769 1.38728 269.769C1.38728 269.769 14.9282 285.891 -3.46189 309.193C-21.852 332.495 -30.749 328.949 -30.749 328.949Z').fill('#009D70');
  groupFour.path('M1.95485 270.447C1.95485 270.447 -22.5526 317.351 -27.261 328.453L1.95485 270.447Z').fill('#009D70');
  groupFour.path('M1.95485 270.447C1.95485 270.447 -22.5526 317.351 -27.261 328.453');
  groupFour.path('M42.5345 309.046C42.2958 308.754 16.5068 303.728 6.98081 286.755C-2.54519 269.783 -2.68921 261.689 -2.68921 261.689C-2.68921 261.689 17.2587 254.5 33.0982 278.321C48.9377 302.143 42.5345 309.046 42.5345 309.046Z').fill('#009D70');
  groupFour.path('M-1.85529 261.394C-1.85529 261.394 34.3162 298.076 43.254 305.746L-1.85529 261.394Z').fill('#009D70');
  groupFour.path('M-1.85529 261.394C-1.85529 261.394 34.3162 298.076 43.254 305.746');
  groupFour.path('M73.9724 277.163C73.9724 277.163 57.4427 267.813 47.7874 262.914C38.1321 258.015 8.19392 248.446 -4.80125 255.691C-17.7964 262.937 48.3554 290.483 73.9724 277.163Z').fill('#009D70');
  groupFour.path('M69.7049 276.796C69.7049 276.796 17.7631 264.46 -4.82166 257.83L69.7049 276.796Z').fill('#009D70');
  groupFour.path('M69.7049 276.796C69.7049 276.796 17.7631 264.46 -4.82166 257.83');
  groupFour.path('M3.72875 245.808C0.481159 234.541 7.27094 222.693 18.8941 219.347C22.0021 218.453 24.8192 218.659 27.9791 218.633C28.4989 218.679 29.0198 218.556 29.4637 218.282C30.3716 217.674 30.8341 216.483 31.7221 215.799C32.7009 215.067 33.8728 214.637 35.093 214.563C36.3132 214.49 37.5282 214.775 38.588 215.384C41.5465 217.23 42.6333 221.243 41.806 224.633C45.3339 224.33 48.7327 226.919 49.9478 230.248C51.163 233.576 50.4351 237.4 48.6221 240.473C48.1407 241.088 47.8655 241.838 47.8356 242.618C47.9745 243.25 48.274 243.834 48.7052 244.316C49.9634 246.116 50.77 248.193 51.0571 250.37C51.3441 252.548 51.1031 254.763 50.3543 256.828C50.0281 257.899 49.3641 258.836 48.4614 259.499C46.2071 260.954 43.3132 259.191 41.2306 257.497C41.1653 260.383 41.0628 263.41 39.6217 265.912C38.1806 268.414 34.9197 270.114 32.3554 268.833C29.4834 267.401 28.9449 263.301 26.2743 261.523C26.9612 261.983 25.1717 264.053 24.8546 264.299C23.906 265.031 22.7188 265.385 21.5239 265.29C18.3742 265.01 16.9767 262.015 15.0952 259.937C13.4263 258.08 11.2388 256.821 9.4115 255.113C6.71956 252.575 4.75721 249.361 3.72875 245.808Z').fill('#FAFBFC');
  groupFour.path('M1.04066 273.373C1.19685 272.963 -2.90639 247.11 7.73645 227.243C18.3793 207.377 24.844 201.718 24.844 201.718C24.844 201.718 36.9433 214.149 22.7717 244.189C8.6002 274.23 1.04066 273.373 1.04066 273.373Z').fill('#009D70');
  groupFour.path('M-7.47179 233.223C-7.47179 233.223 -7.66857 243.324 -7.55087 249.024C-7.43317 254.724 -4.7803 270.471 1.04064 273.372C6.86157 276.273 3.59119 239.272 -7.47179 233.223Z').fill('#009D70');
  groupFour.path('M-6.68353 235.123C-6.68353 235.123 -0.339511 260.971 1.92829 272.641L-6.68353 235.123Z').fill('#009D70');
  groupFour.path('M-6.68353 235.123C-6.68353 235.123 -0.339511 260.971 1.92829 272.641');
  groupFour.path('M25.3464 202.242C25.3464 202.242 7.26462 258.732 3.91919 271.745L25.3464 202.242Z').fill('#009D70');
  groupFour.path('M25.3464 202.242C25.3464 202.242 7.26462 258.732 3.91919 271.745');

  const groupFive = portraitLeft.group();
  groupFive.path('M8.96896 175.928C8.9963 179.211 8.0496 182.428 6.24857 185.173C4.44754 187.917 1.87307 190.066 -1.14927 191.348C-4.17162 192.629 -7.50609 192.986 -10.731 192.372C-13.956 191.759 -16.9265 190.203 -19.2671 187.901C-21.6076 185.599 -23.213 182.655 -23.8803 179.44C-24.5475 176.226 -24.2466 172.886 -23.0156 169.843C-21.7847 166.8 -19.6789 164.19 -16.9646 162.343C-14.2504 160.497 -11.0495 159.496 -7.76682 159.469C-3.36488 159.432 0.87134 161.146 4.00991 164.233C7.14848 167.319 8.9323 171.527 8.96896 175.928Z').fill('#FF8C00');
  groupFive.path('M8.96896 175.928C8.9963 179.211 8.0496 182.428 6.24857 185.173C4.44754 187.917 1.87307 190.066 -1.14927 191.348C-4.17162 192.629 -7.50609 192.986 -10.731 192.372C-13.956 191.759 -16.9265 190.203 -19.2671 187.901C-21.6076 185.599 -23.213 182.655 -23.8803 179.44C-24.5475 176.226 -24.2466 172.886 -23.0156 169.843C-21.7847 166.8 -19.6789 164.19 -16.9646 162.343C-14.2504 160.497 -11.0495 159.496 -7.76682 159.469C-3.36488 159.432 0.87134 161.146 4.00991 164.233C7.14848 167.319 8.9323 171.527 8.96896 175.928').css(styleThree);
  groupFive.path('M20.4907 264.663C20.5181 267.945 19.5714 271.162 17.7704 273.907C15.9693 276.652 13.3949 278.801 10.3725 280.082C7.35017 281.364 4.01569 281.72 0.79075 281.107C-2.43419 280.493 -5.40476 278.937 -7.7453 276.635C-10.0858 274.333 -11.6912 271.389 -12.3585 268.175C-13.0257 264.96 -12.7248 261.62 -11.4938 258.577C-10.2629 255.534 -8.1571 252.924 -5.44284 251.077C-2.72857 249.231 0.472283 248.231 3.75497 248.203C8.15691 248.167 12.3931 249.88 15.5317 252.967C18.6703 256.054 20.4541 260.261 20.4907 264.663Z').css(styleThree).fill('#FF8C00');

  return svgHelper(portraitLeft);
};

export default portraitLeftSvg;
