<template>
  <div class="history-timeline-calendar-root">
    <svg
      class="history-timeline-calendar"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="121"
      height="99"
      viewBox="0 0 121 99"
    >
      <path
        d="M31.21,34a1.6,1.6,0,0,1-1.6-1.49C29,24,28.33,9.36,30.36,6.94a1.57,1.57,0,0,1,1.17-.57c8-.32,78.57-3,86.35.11a1.59,1.59,0,0,1,1,1.2c.36,2,3.48,19.68,1.45,24.91a1.61,1.61,0,0,1-2.08.92,1.59,1.59,0,0,1-.92-2.06c1.22-3.17-.18-14.91-1.45-22.24-8.2-1.81-53.17-.85-83.2.31-.69,3-.49,13.62.15,22.77A1.61,1.61,0,0,1,31.32,34h-.11"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M57.76,99c-15.12,0-27.5-.06-28.77-.07a1.63,1.63,0,0,1-1.6-1.62L27,85.2a1.65,1.65,0,0,1,1.56-1.73,1.63,1.63,0,0,1,1.67,1.62l.33,10.5c10.68,0,72.78.27,84.48-.64l.73-23.33a1.62,1.62,0,1,1,3.23.11l-.77,24.78a1.67,1.67,0,0,1-1.38,1.61c-4.74.73-35.3.88-59.09.88"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M89.26,22A10.64,10.64,0,0,1,81,18.78c-3.73-4.08-3-10.48-3-10.75a1.82,1.82,0,0,1,.16-.5C80.73,2.72,83.72.19,87.13,0c5.42-.3,9.44,5.63,9.61,5.88A1.53,1.53,0,0,1,96.27,8a1.63,1.63,0,0,1-2.22-.46c-.88-1.3-3.81-4.64-6.75-4.47-2.62.14-4.72,3-6,5.55-.08,1.08-.24,5.43,2.2,8.07a7.43,7.43,0,0,0,5.81,2.17,1.55,1.55,0,1,1,0,3.1"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M62.26,22A10.64,10.64,0,0,1,54,18.78c-3.73-4.08-3-10.48-3-10.75a1.82,1.82,0,0,1,.16-.5C53.73,2.72,56.72.19,60.13,0c5.41-.31,9.44,5.63,9.61,5.89A1.52,1.52,0,0,1,69.27,8a1.63,1.63,0,0,1-2.22-.46c-.88-1.3-3.8-4.65-6.75-4.47-2.62.15-4.72,3-6,5.55-.08,1.08-.24,5.43,2.2,8.07a7.43,7.43,0,0,0,5.81,2.17,1.55,1.55,0,1,1,0,3.1"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M117.38,75a1.64,1.64,0,0,1-1.61-1.55c-.09-1.23-2-28.35-.38-39.07-12,.23-71.24,1.33-84.5.49-.72,12.66-1.09,30.27.29,32.88A1.69,1.69,0,0,1,30.54,70a1.58,1.58,0,0,1-2.19-.66c-2.42-4.58-.91-31.12-.58-36.39a1.72,1.72,0,0,1,.58-1.18,1.62,1.62,0,0,1,1.23-.37c9,1.17,87-.39,87.77-.41a1.57,1.57,0,0,1,1.31.65,1.7,1.7,0,0,1,.28,1.48c-2.21,8,0,39.76.06,40.08A1.67,1.67,0,0,1,117.5,75h-.12"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M20.66,83.29c1.69,0,37.11,1.12,46-.38l.23,0c.26,0,26.39-.64,38.81.11a11.64,11.64,0,0,0,9.33-3.72c.26-.3.5-.6.73-.92a17.48,17.48,0,0,1-8.88,2.91c-6.21.23-12.43-3-18.5-9.68-5.08-.4-38.67-3-48.52-1.53l-.23,0c-1.26,0-28.38,0-36.39-.63a11.2,11.2,0,0,0,2,6.91c2.7,3.79,7.86,6.12,15.35,6.93M46.5,87c-12.3,0-25.1-.4-26-.43h-.12c-8.53-.9-14.51-3.71-17.77-8.34a14.49,14.49,0,0,1-2.36-11A1.63,1.63,0,0,1,2.13,66c3.38.62,28,.75,37.42.76,11.12-1.54,48.19,1.44,49.77,1.57a1.65,1.65,0,0,1,1.09.55c5.51,6.21,11,9.27,16.34,9.08,6.78-.24,11.13-5.79,11.17-5.85a1.64,1.64,0,0,1,1.86-.54c.66.25-.37,1.24-.4,2-.14,3.13.2,5.56-1.89,7.91a14.66,14.66,0,0,1-12,4.81c-11.87-.71-36.54-.15-38.42-.1C63.2,86.81,55,87,46.5,87"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M108.55,62a1.48,1.48,0,0,1-.64-.14l-3.11-1.4a1.25,1.25,0,0,1-.65-1.74,1.53,1.53,0,0,1,1.94-.58l3.11,1.4a1.24,1.24,0,0,1,.65,1.74,1.48,1.48,0,0,1-1.3.72"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M105.64,48a1.69,1.69,0,0,1-1.48-.75,1.2,1.2,0,0,1,.78-1.73l3.73-1.4a1.83,1.83,0,0,1,2.17.63,1.2,1.2,0,0,1-.78,1.73l-3.73,1.4a2.09,2.09,0,0,1-.69.12"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M112.52,53h-.1l-4-.25A1.43,1.43,0,0,1,107,51.28,1.46,1.46,0,0,1,108.58,50l4,.25A1.43,1.43,0,0,1,114,51.72,1.45,1.45,0,0,1,112.52,53"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M39.55,46a1.48,1.48,0,0,1-.64-.14l-3.11-1.4a1.25,1.25,0,0,1-.65-1.74,1.53,1.53,0,0,1,1.94-.58l3.11,1.4a1.24,1.24,0,0,1,.65,1.74,1.48,1.48,0,0,1-1.3.72"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M35.64,62a1.71,1.71,0,0,1-1.48-.75,1.2,1.2,0,0,1,.78-1.73l3.73-1.4a1.83,1.83,0,0,1,2.17.63,1.2,1.2,0,0,1-.78,1.73l-3.73,1.4a2,2,0,0,1-.69.12"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>
      <path
        d="M37.52,51h-.1l-4-.25A1.42,1.42,0,0,1,32,49.29,1.46,1.46,0,0,1,33.58,48l4,.25A1.43,1.43,0,0,1,39,49.72,1.45,1.45,0,0,1,37.52,51"
        transform="translate(0 0)"
        style="fill: #ad0032; fill-rule: evenodd"
      ></path>

      <g class="history-timeline-year">
        <text id="currentYear">{{ year }}</text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'HistoryCalendar',
  props: {
    year: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.history-timeline-calendar-root {
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 3px solid #b30838;
}
.history-timeline-calendar {
  transform: translateY(-9px);
}
.history-timeline-year {
  font-weight: 700;
  font-style: normal;
  -webkit-transform: translate(43%, 60%);
  transform: translate(43%, 60%);
  fill: #b30838;
  font-size: 20px;
  user-select: none;
}
</style>
