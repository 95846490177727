<template>
  <div>
    <component
      :is="startPage" 
      :query="query"
      :folder="folder"
    />
  </div>
</template>

<script>
export default {
  name:'Root',
  props: {
    startPage: {
      type: String,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    folder: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
</style>