<template>
  <div v-show="sliderLoaded" class="history-timeline">
    <history-calendar :year="currentYear"> </history-calendar>
    <div class="history-timeline-slider-root">
      <div class="swiper" ref="swiper">
        <div class="swiper-wrapper">
          <history-decade-card
            v-for="(slideItem, slideIndex) in slideItems"
            :key="slideIndex"
            :slideItem="slideItem"
            class="swiper-slide"
            :class="{
              'is-last-slide': slideIndex === slideItems.length - 1,
              'is-first-slide': slideIndex === 0 && !contentUpdated,
            }"
          />
        </div>
      </div>
    </div>
    <history-controls
      @go-to-slide="navigateToSlide"
      :lastSlide="lastSlide"
      :currentSlide="currentSlideCounter"
    ></history-controls>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swiper from "swiper";
import "swiper/swiper.scss";
import gsap from "gsap";

import HistoryCalendar from "../components/historyTimeline/HistoryCalendar.vue";
import HistoryDecadeCard from "../components/historyTimeline/HistoryDecadeCard.vue";
import HistoryControls from "../components/historyTimeline/HistoryControls.vue";

export default {
  name: 'HistoryTimeline',
  components: { HistoryCalendar, HistoryDecadeCard, HistoryControls },
  props: {
    query: {
      type: String,
      required: true,
    },
    folder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      storeName: "historyTimeline",
      currentSlide: 0,
      swiperInstance: {},
      contentUpdated: false,
    };
  },
  created() {
    this.$store.dispatch("performApiQuery", {
      query: this.query,
      store: this.storeName,
      folder: this.folder,
    });
  },
  mounted() {
    this.initSwiper();
  },
  updated() {
    if (!this.contentUpdated) {
      this.$nextTick(() => {
        this.moveFirstSlide();
      });
    }
  },
  computed: {
    ...mapGetters({
      slideItems: "historyTimeline/getSlideItems",
    }),
    sliderLoaded() {
      if (this.slideItems.length) return true;
      return false;
    },
    currentYear() {
      if (!this.sliderLoaded) return 0;
      return this.slideItems[this.currentSlide].year;
    },
    lastSlide() {
      return this.slideItems.length;
    },
    currentSlideCounter() {
      return this.currentSlide + 1;
    },
  },
  methods: {
    initSwiper() {
      const swiperContainer = this.$refs.swiper;

      new Swiper(swiperContainer, {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 15,
        preventClicks: false,
        preventClicksPropagation: false,
        speed: 1000,
        allowTouchMove: true,
        breakpoints: {
          1200: {
            slidesPerView: 3,
            allowTouchMove: false,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionStart: (swiper) => {
            this.currentSlide = swiper.realIndex;
            this.animateSlides(this.swiperInstance.slides);
          },
        },
      });
      this.swiperInstance = this.$refs.swiper.swiper;
    },
    navigateToSlide(slide) {
      this.swiperInstance.slideTo(slide);
    },
    calculateTopOffset(slide) {
      const slideOuterHeight = slide.clientHeight,
        slideInnerHeight = slide.firstChild.firstChild.clientHeight,
        calculatedTopOffset = (((slideOuterHeight - slideInnerHeight) / 2) / slideOuterHeight) * 100;

      return calculatedTopOffset;
    },
    animateSlides(slides) {
      //slides
      const activeSlide = slides[this.currentSlide],
        prevSlide = slides[this.currentSlide - 1],
        beforePrevSlide = slides[this.currentSlide - 2],
        nextSlide = slides[this.currentSlide + 1],
        afterNextSlide = slides[this.currentSlide + 2];

      //moves the active slide an all visible slides around it down with a specific amount
      this.createSlideAnimation(activeSlide, this.calculateTopOffset(activeSlide));
      this.createSlideAnimation(prevSlide, 50);
      this.createSlideAnimation(beforePrevSlide, 90);
      this.createSlideAnimation(nextSlide, 0);
      this.createSlideAnimation(afterNextSlide, -25);
    },
    createSlideAnimation(slide, value) {
      if (slide) {
        gsap.to(slide.firstChild.firstChild, {
          duration: 1,
          y: value + "%",
        });
      }
    },
    moveFirstSlide() {
      const firstSlide = document.querySelector(".is-first-slide");

      if (firstSlide) {
        gsap.set(firstSlide.firstChild.firstChild, {
          y: this.calculateTopOffset(firstSlide) + "%",
        });
      }

      this.contentUpdated = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.history-timeline-slider-root {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
</style>
