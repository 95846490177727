import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const landscapeRightSvg = () => {
  const landscapeRight = SVG();
  const commonCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };
  const miterLimitCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-miterlimit': 10,
  };
  landscapeRight.width(253)
    .height(907)
    .viewbox('0 0 253 907')
    .addClass('landscape-right')
    .fill('none');
  
  const groupOne = landscapeRight.group();
  groupOne.path('M388.583 906.01C377.583 889.63 342.403 881.85 333.433 871.57C324.463 861.29 313.653 855.44 298.433 845.57C283.213 835.7 282.203 818.43 273.353 810.3C264.503 802.17 251.613 796.45 234.673 793.11C217.733 789.77 179.303 776.89 157.813 767.33C136.323 757.77 104.573 679.7 91.8127 639.4C86.3127 622.09 39.5826 559.5 18.6726 546.32C14.1326 543.46 26.3926 532.32 30.1526 536.05C35.5678 541.601 41.2725 546.862 47.2427 551.81C64.8027 565.91 114.243 682.26 144.043 725.9C173.843 769.54 180.363 761.82 214.363 770.65C229.963 774.7 244.043 775.18 256.473 776.7C259.213 781.4 263.373 728.22 235.473 660.31C225.753 636.69 237.473 609.12 251.843 572.88C266.213 536.64 247.283 494.49 247.283 494.49C261.193 478.35 273.523 520.2 278.283 535.24C283.043 550.28 265.003 588.24 257.393 604.3C242.393 635.91 256.013 656.13 261.193 674.45C267.963 698.45 273.443 746.63 277.883 766.14C279.643 774.417 283.261 782.186 288.463 788.86C290.219 790.429 291.844 792.138 293.323 793.97C311.393 816.68 313.323 814.41 326.743 828.75C340.163 843.09 362.093 828.59 384.243 839.07').fill('#56352A');
  groupOne.path('M388.583 906.01C377.583 889.63 342.403 881.85 333.433 871.57C324.463 861.29 313.653 855.44 298.433 845.57C283.213 835.7 282.203 818.43 273.353 810.3C264.503 802.17 251.613 796.45 234.673 793.11C217.733 789.77 179.303 776.89 157.813 767.33C136.323 757.77 104.573 679.7 91.8127 639.4C86.3127 622.09 39.5826 559.5 18.6726 546.32C14.1326 543.46 26.3926 532.32 30.1526 536.05C35.5678 541.601 41.2725 546.862 47.2427 551.81C64.8027 565.91 114.243 682.26 144.043 725.9C173.843 769.54 180.363 761.82 214.363 770.65C229.963 774.7 244.043 775.18 256.473 776.7C259.213 781.4 263.373 728.22 235.473 660.31C225.753 636.69 237.473 609.12 251.843 572.88C266.213 536.64 247.283 494.49 247.283 494.49C261.193 478.35 273.523 520.2 278.283 535.24C283.043 550.28 265.003 588.24 257.393 604.3C242.393 635.91 256.013 656.13 261.193 674.45C267.963 698.45 273.443 746.63 277.883 766.14C279.643 774.417 283.261 782.186 288.463 788.86C290.219 790.429 291.844 792.138 293.323 793.97C311.393 816.68 313.323 814.41 326.743 828.75C340.163 843.09 362.093 828.59 384.243 839.07').css(miterLimitCss);

  const groupTwo = landscapeRight.group();
  groupTwo.css(commonCss);
  groupTwo.path('M288.583 709.03C288.383 708.6 297.153 677.11 282.843 656.76C268.533 636.41 259.203 631.59 259.203 631.59C259.203 631.59 239.543 649.59 258.203 680.86C276.863 712.13 288.583 709.03 288.583 709.03Z').fill('#A3D380');
  groupTwo.path('M230.833 717.35C230.833 717.35 229.333 693.63 229.113 680.1C228.893 666.57 234.733 627.86 250.523 617.63C266.313 607.4 260.803 696.62 230.833 717.35Z').fill('#A3D380');
  groupTwo.path('M232.823 712.41C232.823 712.41 247.893 647.69 253.003 618.84Z').fill('#A3D380');
  groupTwo.path('M232.823 712.41C232.823 712.41 247.893 647.69 253.003 618.84');
  groupTwo.path('M246.613 620.88C247.223 621.22 268.773 657.34 302.343 671.33C335.913 685.32 349.703 685.81 349.703 685.81C349.703 685.81 351.823 658.34 303.433 635.28C255.043 612.22 246.613 620.88 246.613 620.88Z').fill('#A3D380');
  groupTwo.path('M349.783 684.7C349.783 684.7 269.353 632.79 251.783 620.01Z').fill('#A3D380');
  groupTwo.path('M349.783 684.7C349.783 684.7 269.353 632.79 251.783 620.01')
  groupTwo.path('M164.553 689.21C165.053 688.84 208.023 690.64 230.463 667.56C252.903 644.48 256.653 631.81 256.653 631.81C256.653 631.81 228.223 613 192.823 644.49C157.423 675.98 164.553 689.21 164.553 689.21Z').fill('#A3D380');
  groupTwo.path('M255.463 631.05C255.463 631.05 182.333 675.05 164.853 683.76Z').fill('#A3D380');
  groupTwo.path('M255.463 631.05C255.463 631.05 182.333 675.05 164.853 683.76');
  groupTwo.path('M279.593 583.35C279.2 579.056 277.923 574.888 275.843 571.11C274.423 568.54 271.793 566.5 270.483 564.02C269.633 562.42 270.233 560.87 270.283 559.02C270.387 554.72 269.248 550.48 267.003 546.81C266.003 545.09 264.103 543.34 262.193 543.98C261.304 544.374 260.559 545.034 260.063 545.87C257.743 549.116 256.109 552.802 255.263 556.7L246.113 550.7C243.773 549.15 241.043 547.54 238.333 548.27C234.213 549.38 233.333 554.75 233.273 559.02C230.7 557.362 227.637 556.637 224.594 556.965C221.552 557.294 218.714 558.656 216.554 560.824C214.395 562.993 213.045 565.837 212.73 568.881C212.415 571.925 213.153 574.985 214.823 577.55C212.244 577.528 209.747 578.456 207.808 580.156C205.869 581.857 204.624 584.212 204.31 586.772C203.996 589.332 204.635 591.918 206.105 594.037C207.576 596.155 209.775 597.659 212.283 598.26C213.173 598.5 214.803 598.26 215.533 598.68C216.263 599.1 216.763 600.68 217.223 601.56C218.435 603.771 219.901 605.831 221.593 607.7C225.11 611.513 229.458 614.463 234.3 616.322C239.143 618.181 244.348 618.899 249.513 618.42C267.753 616.72 281.223 601.03 279.593 583.35Z').fill('#FAFBFC');
  groupTwo.path('M364.463 545.81C363.853 546.36 314.253 557.14 285.893 588.74C257.533 620.34 251.893 635.15 251.893 635.15C251.893 635.15 282.893 647.07 327.123 602.53C371.353 557.99 364.463 545.81 364.463 545.81Z').fill('#A3D380');
  groupTwo.path('M253.253 635.65C253.253 635.65 342.463 566.37 363.613 551.81Z').fill('#A3D380');
  groupTwo.path('M253.253 635.65C253.253 635.65 342.463 566.37 363.613 551.81');
  groupTwo.path('M158.283 603.81C158.903 603.94 185.073 636.91 217.643 638.49C250.213 640.07 262.573 634.6 262.573 634.6C262.573 634.6 259.133 601.33 211.623 595.6C164.113 589.87 158.283 603.81 158.283 603.81Z').fill('#A3D380');
  groupTwo.path('M262.463 633.24C262.463 633.24 180.863 607.77 162.803 600.5Z').fill('#A3D380');
  groupTwo.path('M262.463 633.24C262.463 633.24 180.863 607.77 162.803 600.5');
  groupTwo.path('M85.1327 632.81C85.1327 633.81 58.2327 685.81 65.2327 735.05C72.2327 784.29 81.1127 800.67 81.1127 800.67C81.1127 800.67 114.373 782.51 107.653 709.3C100.933 636.09 85.1327 632.81 85.1327 632.81Z').fill('#009D70');
  groupTwo.path('M82.4626 799.91C82.4626 799.91 87.0126 668.04 89.7026 638.21Z').fill('#009D70');
  groupTwo.path('M82.4626 799.91C82.4626 799.91 87.0126 668.04 89.7026 638.21');
  groupTwo.path('M1.8927 711.02C2.3827 710.58 46.7526 709.34 68.1726 682.42C89.5926 655.5 92.5227 641.42 92.5227 641.42C92.5227 641.42 61.8927 622.98 27.7327 660.06C-6.42733 697.14 1.8927 711.02 1.8927 711.02Z').fill('#009D70');
  groupTwo.path('M91.2327 640.62C91.2327 640.62 19.1526 694.23 1.7926 705.04Z').fill('#009D70');
  groupTwo.path('M91.2327 640.62C91.2327 640.62 19.1526 694.23 1.7926 705.04');
  groupTwo.path('M15.7327 582.89C16.2827 583.21 31.5726 625.1 62.5626 636.55C93.5526 648 107.163 646.08 107.163 646.08C107.163 646.08 113.923 610.72 69.7527 590.52C25.5827 570.32 15.7327 582.89 15.7327 582.89Z').fill('#009D70');
  groupTwo.path('M107.463 644.6C107.463 644.6 36.3427 593.74 21.1027 580.8Z').fill('#009D70');
  groupTwo.path('M107.463 644.6C107.463 644.6 36.3427 593.74 21.1027 580.8');
  groupTwo.path('M60.7926 521.7C60.7926 521.7 80.8726 546.92 91.6526 561.8C102.433 576.68 126.453 624.3 117.753 648.02C109.053 671.74 45.2926 568.18 60.7926 521.7Z').fill('#009D70');
  groupTwo.path('M62.5426 528.75C62.5426 528.75 97.0826 612.53 114.193 648.62Z').fill('#009D70');
  groupTwo.path('M62.5426 528.75C62.5426 528.75 97.0826 612.53 114.193 648.62');
  groupTwo.path('M132.063 631.09C151.813 633.53 169.853 618.99 172.373 598.62C173.053 593.16 171.933 588.5 171.153 583.2C170.938 582.342 171.005 581.438 171.343 580.62C172.113 578.93 173.993 577.84 174.903 576.17C175.872 574.336 176.28 572.258 176.077 570.194C175.875 568.13 175.07 566.171 173.763 564.56C169.893 560.08 162.863 559.33 157.403 561.63C156.973 555.63 151.733 550.63 145.833 549.47C139.933 548.31 133.703 550.56 129.093 554.41C128.093 555.26 127.013 556.22 125.703 556.3C124.607 556.237 123.546 555.893 122.623 555.3C119.262 553.661 115.556 552.857 111.818 552.956C108.08 553.056 104.421 554.055 101.153 555.87C99.4364 556.69 98.0327 558.044 97.1526 559.73C95.3126 563.9 99.0426 568.27 102.433 571.31C97.6126 572.19 92.5627 573.16 88.7527 576.25C84.9427 579.34 82.9726 585.25 85.8226 589.25C88.9826 593.69 95.9926 593.49 99.6926 597.5C98.6926 596.5 95.6926 600.02 95.4126 600.62C94.4349 602.402 94.1603 604.486 94.6427 606.46C95.9427 611.67 101.343 613.21 105.323 615.8C108.873 618.1 111.573 621.47 114.923 624.04C119.904 627.878 125.822 630.313 132.063 631.09Z').fill('#FAFBFC');
  groupTwo.path('M86.5827 642.94C87.2227 642.56 131.653 642.55 162.123 619.42C192.593 596.29 200.343 583.94 200.343 583.94C200.343 583.94 176.283 566.94 129.703 598.74C83.1227 630.54 86.5827 642.94 86.5827 642.94Z').fill('#009D70');
  groupTwo.path('M156.143 646.5C156.143 646.5 139.263 649.5 129.673 650.85C120.083 652.2 92.9927 651.92 86.5827 642.94C80.1727 633.96 143.053 629.57 156.143 646.5Z').fill('#009D70');
  groupTwo.path('M152.743 645.69C152.743 645.69 107.743 641.94 87.5626 641.28Z').fill('#009D70');
  groupTwo.path('M152.743 645.69C152.743 645.69 107.743 641.94 87.5626 641.28');
  groupTwo.path('M199.323 583.25C199.323 583.25 109.453 628.6 88.5426 637.68Z').fill('#009D70');
  groupTwo.path('M199.323 583.25C199.323 583.25 109.453 628.6 88.5426 637.68');

  const groupThree = landscapeRight.group();
  groupThree.path('M126.463 629.17C127.221 634.254 126.456 639.448 124.263 644.097C122.071 648.746 118.55 652.641 114.145 655.289C109.74 657.938 104.649 659.221 99.5153 658.978C94.3813 658.734 89.4346 656.975 85.3003 653.921C81.1659 650.867 78.0293 646.657 76.2868 641.821C74.5444 636.986 74.2742 631.742 75.5105 626.753C76.7467 621.765 79.4339 617.254 83.2325 613.792C87.0311 610.329 91.7708 608.07 96.8526 607.3C100.233 606.788 103.682 606.948 107.001 607.77C110.32 608.593 113.444 610.062 116.194 612.093C118.945 614.125 121.267 616.679 123.029 619.609C124.791 622.539 125.958 625.789 126.463 629.17').fill('#FF8C00');
  groupThree.path('M126.463 629.17C127.221 634.254 126.456 639.448 124.263 644.097C122.071 648.746 118.55 652.641 114.145 655.289C109.74 657.938 104.649 659.221 99.5153 658.978C94.3813 658.734 89.4346 656.975 85.3003 653.921C81.1659 650.867 78.0293 646.657 76.2868 641.821C74.5444 636.986 74.2742 631.742 75.5105 626.753C76.7467 621.765 79.4339 617.254 83.2325 613.792C87.0311 610.329 91.7708 608.07 96.8526 607.3C100.233 606.788 103.682 606.948 107.001 607.77C110.32 608.593 113.444 610.062 116.194 612.093C118.945 614.125 121.267 616.679 123.029 619.609C124.791 622.539 125.958 625.789 126.463 629.17').css(miterLimitCss);
  groupThree.path('M284.853 627.34C285.613 632.424 284.85 637.619 282.659 642.269C280.468 646.919 276.948 650.815 272.543 653.465C268.139 656.115 263.048 657.4 257.914 657.158C252.779 656.916 247.832 655.157 243.696 652.104C239.561 649.051 236.423 644.841 234.68 640.005C232.936 635.17 232.665 629.926 233.901 624.937C235.137 619.947 237.824 615.436 241.622 611.973C245.421 608.51 250.16 606.251 255.243 605.48C258.623 604.968 262.07 605.127 265.389 605.949C268.707 606.771 271.831 608.239 274.581 610.27C277.331 612.3 279.654 614.853 281.417 617.782C283.179 620.711 284.347 623.959 284.853 627.34').fill('#FF8C00');
  groupThree.path('M284.853 627.34C285.613 632.424 284.85 637.619 282.659 642.269C280.468 646.919 276.948 650.815 272.543 653.465C268.139 656.115 263.048 657.4 257.914 657.158C252.779 656.916 247.832 655.157 243.696 652.104C239.561 649.051 236.423 644.841 234.68 640.005C232.936 635.17 232.665 629.926 233.901 624.937C235.137 619.947 237.824 615.436 241.622 611.973C245.421 608.51 250.16 606.251 255.243 605.48C258.623 604.968 262.07 605.127 265.389 605.949C268.707 606.771 271.831 608.239 274.581 610.27C277.331 612.3 279.654 614.853 281.417 617.782C283.179 620.711 284.347 623.959 284.853 627.34').css(miterLimitCss);

  const rightTopFlowerGroup = landscapeRight.group();
  const rightTopFlowerCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-miterlimit': 10,
    'stroke-linecap': 'round',
  };
  rightTopFlowerGroup.addClass('right-top-flower');
  rightTopFlowerGroup.path('M401.603 434.14C401.603 434.14 383.603 435.57 359.603 422.38C335.603 409.19 304.703 399.95 290.703 400.65C276.703 401.35 224.623 398.23 216.533 399.05').css(commonCss);
  rightTopFlowerGroup.path('M170.133 382.27C170.133 382.27 185.733 396.37 216.573 399.05').css(commonCss);
  rightTopFlowerGroup.path('M196.093 447.21C196.093 447.21 203.803 423.74 265.633 400.32').css(commonCss);
  rightTopFlowerGroup.path('M190.133 421.48C190.133 421.48 200.973 424.42 229.133 417.1').css(commonCss);
  rightTopFlowerGroup.path('M133.603 345.43C133.603 345.43 152.423 373.96 171.983 384.06').css(commonCss);
  rightTopFlowerGroup.path('M190.263 425.81C186.413 423.047 183.26 419.424 181.053 415.23C177.503 407.88 179.743 395.73 189.793 395.52C192.775 395.585 195.634 396.725 197.843 398.73C202.524 402.592 205.586 408.07 206.423 414.08C206.863 417.88 205.503 430.76 200.033 430.7C196.673 430.7 192.803 427.7 190.263 425.81').fill('#FFF7CE');
  rightTopFlowerGroup.path('M190.263 425.81C186.413 423.047 183.26 419.424 181.053 415.23C177.503 407.88 179.743 395.73 189.793 395.52C192.775 395.585 195.634 396.725 197.843 398.73C202.524 402.592 205.586 408.07 206.423 414.08C206.863 417.88 205.503 430.76 200.033 430.7C196.673 430.7 192.803 427.7 190.263 425.81').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M190.263 425.81C186.413 423.047 183.26 419.424 181.053 415.23C177.503 407.88 179.743 395.73 189.793 395.52C192.775 395.585 195.634 396.725 197.843 398.73C202.524 402.592 205.586 408.07 206.423 414.08C206.863 417.88 205.503 430.76 200.033 430.7C196.673 430.7 192.803 427.81 190.263 425.81Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M190.463 409.01C192.554 416.37 196.319 423.146 201.463 428.81').css(commonCss);
  rightTopFlowerGroup.path('M204.113 429C204.113 429 208.113 404.92 220.283 400C223.943 398.53 228.393 398 231.283 401.12C236.733 406.97 231.733 414.12 227.383 418.84C224.395 422.031 220.786 424.577 216.776 426.319C212.767 428.061 208.444 428.964 204.073 428.97').fill('#FFF7CE');
  rightTopFlowerGroup.path('M204.113 429C204.113 429 208.113 404.92 220.283 400C223.943 398.53 228.393 398 231.283 401.12C236.733 406.97 231.733 414.12 227.383 418.84C224.395 422.031 220.786 424.577 216.776 426.319C212.767 428.061 208.444 428.964 204.073 428.97').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M204.113 429C204.113 429 208.113 404.92 220.283 400C223.943 398.53 228.393 398 231.283 401.12C236.733 406.97 231.733 414.12 227.383 418.84C224.402 422.032 220.8 424.58 216.798 426.327C212.797 428.075 208.479 428.984 204.113 429Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M204.123 431.98C204.123 431.98 213.123 453.55 227.953 450.38C242.783 447.21 245.313 438.45 240.243 431.02C235.173 423.59 225.853 419.76 204.123 432.02').fill('#FFF7CE');
  rightTopFlowerGroup.path('M204.123 431.98C204.123 431.98 213.123 453.55 227.953 450.38C242.783 447.21 245.313 438.45 240.243 431.02C235.173 423.59 225.853 419.76 204.123 432.02').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M204.123 431.98C204.123 431.98 213.123 453.55 227.953 450.38C242.783 447.21 245.313 438.45 240.243 431.02C235.173 423.59 225.853 419.81 204.123 431.98Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M200.203 432.25C197.249 436.975 195.772 442.473 195.961 448.042C196.15 453.611 197.996 458.997 201.263 463.51C201.263 463.51 211.523 474.69 217.993 457.97C224.463 441.25 204.123 431.97 204.123 431.97L200.203 432.25Z').css(rightTopFlowerCss).fill('#FFF7CE');
  rightTopFlowerGroup.path('M200.203 432.25C197.249 436.975 195.772 442.473 195.961 448.042C196.15 453.611 197.996 458.997 201.263 463.51C201.263 463.51 211.523 474.69 217.993 457.97C224.463 441.25 204.123 431.97 204.123 431.97L200.203 432.25Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M201.773 431.28C201.773 431.28 188.073 422.7 174.983 434.15C171.383 437.3 167.793 441.99 169.863 446.97C173.283 455.17 182.473 453.75 189.123 450.92C197.283 447.45 201.853 440.14 201.773 431.28').fill('#FFF7CE');
  rightTopFlowerGroup.path('M201.773 431.28C201.773 431.28 188.073 422.7 174.983 434.15C171.383 437.3 167.793 441.99 169.863 446.97C173.283 455.17 182.473 453.75 189.123 450.92C197.283 447.45 201.853 440.14 201.773 431.28').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M201.773 431.28C201.773 431.28 188.073 422.7 174.983 434.15C171.383 437.3 167.793 441.99 169.863 446.97C173.283 455.17 182.473 453.75 189.123 450.92C197.283 447.45 201.853 440.14 201.773 431.28Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M204.693 428.81C204.693 428.81 215.953 422.75 219.333 410.34').css(commonCss);
  rightTopFlowerGroup.path('M203.743 429.81C203.743 429.81 215.743 439.46 229.743 436.09').css(commonCss);
  rightTopFlowerGroup.path('M206.983 453.96C207.139 447.231 205.703 440.56 202.793 434.49').css(commonCss);
  rightTopFlowerGroup.path('M181.583 441.35C181.583 441.35 186.993 442.35 201.823 432.93').css(commonCss);
  rightTopFlowerGroup.path('M198.313 429.3C197.855 430.155 197.669 431.129 197.78 432.092C197.89 433.056 198.293 433.962 198.933 434.69C199.734 435.442 200.746 435.931 201.833 436.09C203.171 436.37 204.561 436.252 205.833 435.75C206.469 435.491 207.042 435.097 207.513 434.597C207.984 434.097 208.342 433.501 208.563 432.85C209.333 430.41 207.453 426.33 204.903 425.6C202.353 424.87 199.313 427.29 198.283 429.3').fill('#1E2722');
  rightTopFlowerGroup.path('M198.313 429.3C197.855 430.155 197.669 431.129 197.78 432.092C197.89 433.056 198.293 433.962 198.933 434.69C199.734 435.442 200.746 435.931 201.833 436.09C203.171 436.37 204.561 436.252 205.833 435.75C206.469 435.491 207.042 435.097 207.513 434.597C207.984 434.097 208.342 433.501 208.563 432.85C209.333 430.41 207.453 426.33 204.903 425.6C202.353 424.87 199.313 427.29 198.283 429.3').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M253.573 477.59C253.573 477.59 264.403 480.53 292.573 473.21').css(commonCss);
  rightTopFlowerGroup.path('M253.693 481.95C249.844 479.19 246.69 475.571 244.483 471.38C240.943 464.03 243.173 451.88 253.223 451.67C256.188 451.747 259.027 452.886 261.223 454.88C265.904 458.742 268.966 464.22 269.803 470.23C270.243 474.03 268.883 486.91 263.413 486.85C260.053 486.85 256.183 483.85 253.643 481.95').fill('#FFF7CE');
  rightTopFlowerGroup.path('M253.693 481.95C249.844 479.19 246.69 475.571 244.483 471.38C240.943 464.03 243.173 451.88 253.223 451.67C256.188 451.747 259.027 452.886 261.223 454.88C265.904 458.742 268.966 464.22 269.803 470.23C270.243 474.03 268.883 486.91 263.413 486.85C260.053 486.85 256.183 483.85 253.643 481.95').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M253.693 481.95C249.844 479.19 246.69 475.571 244.483 471.38C240.943 464.03 243.173 451.88 253.223 451.67C256.188 451.747 259.027 452.886 261.223 454.88C265.904 458.742 268.966 464.22 269.803 470.23C270.243 474.03 268.883 486.91 263.413 486.85C260.103 486.81 256.233 483.89 253.693 481.95Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M253.853 465.12C255.942 472.487 259.706 479.271 264.853 484.94').css(commonCss);
  rightTopFlowerGroup.path('M265.203 487.39C265.203 487.39 251.463 478.81 238.463 490.26C234.873 493.4 231.273 498.1 233.343 503.08C236.763 511.28 245.963 509.86 252.603 507.08C260.763 503.61 265.333 496.3 265.253 487.44').fill('#FFF7CE');
  rightTopFlowerGroup.path('M265.203 487.39C265.203 487.39 251.463 478.81 238.463 490.26C234.873 493.4 231.273 498.1 233.343 503.08C236.763 511.28 245.963 509.86 252.603 507.08C260.763 503.61 265.333 496.3 265.253 487.44').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M265.203 487.39C265.203 487.39 251.463 478.81 238.463 490.26C234.873 493.4 231.273 498.1 233.343 503.08C236.763 511.28 245.963 509.86 252.603 507.08C260.713 503.56 265.283 496.25 265.203 487.39Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M245.013 497.46C245.013 497.46 250.423 498.46 265.253 489.04').css(commonCss);
  rightTopFlowerGroup.path('M132.583 351.72C132.583 351.72 143.413 354.65 171.583 347.33').css(commonCss);
  rightTopFlowerGroup.path('M132.713 356.08C128.86 353.317 125.703 349.695 123.493 345.5C119.953 338.15 122.183 326 132.233 325.8C135.197 325.871 138.037 327.007 140.233 329C144.912 332.864 147.973 338.341 148.813 344.35C149.253 348.16 147.893 361.04 142.423 360.97C139.063 360.97 135.203 357.97 132.663 356.08').fill('#FFF7CE');
  rightTopFlowerGroup.path('M132.713 356.08C128.86 353.317 125.703 349.695 123.493 345.5C119.953 338.15 122.183 326 132.233 325.8C135.197 325.871 138.037 327.007 140.233 329C144.912 332.864 147.973 338.341 148.813 344.35C149.253 348.16 147.893 361.04 142.423 360.97C139.063 360.97 135.203 357.97 132.663 356.08').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M132.713 356.08C128.86 353.317 125.703 349.695 123.493 345.5C119.953 338.15 122.183 326 132.233 325.8C135.197 325.871 138.037 327.007 140.233 329C144.912 332.864 147.973 338.341 148.813 344.35C149.253 348.16 147.893 361.04 142.423 360.97C139.113 360.93 135.253 358.01 132.713 356.08Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M132.873 339.24C134.957 346.612 138.722 353.4 143.873 359.07').css(commonCss);
  rightTopFlowerGroup.path('M146.553 359.23C146.553 359.23 150.553 335.15 162.733 330.23C166.393 328.76 170.833 328.23 173.733 331.36C179.173 337.2 174.173 344.36 169.823 349.07C166.835 352.262 163.225 354.807 159.216 356.549C155.207 358.291 150.884 359.194 146.513 359.2').fill('#FFF7CE');
  rightTopFlowerGroup.path('M146.553 359.23C146.553 359.23 150.553 335.15 162.733 330.23C166.393 328.76 170.833 328.23 173.733 331.36C179.173 337.2 174.173 344.36 169.823 349.07C166.835 352.262 163.225 354.807 159.216 356.549C155.207 358.291 150.884 359.194 146.513 359.2').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M146.553 359.23C146.553 359.23 150.553 335.15 162.733 330.23C166.393 328.76 170.833 328.23 173.733 331.36C179.173 337.2 174.173 344.36 169.823 349.07C166.842 352.262 163.24 354.81 159.239 356.557C155.237 358.305 150.919 359.214 146.553 359.23Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M146.563 362.22C146.563 362.22 155.563 383.78 170.403 380.61C185.243 377.44 187.753 368.68 182.693 361.25C177.633 353.82 168.293 349.99 146.563 362.25').fill('#FFF7CE');
  rightTopFlowerGroup.path('M146.563 362.22C146.563 362.22 155.563 383.78 170.403 380.61C185.243 377.44 187.753 368.68 182.693 361.25C177.633 353.82 168.293 349.99 146.563 362.25').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M146.563 362.22C146.563 362.22 155.563 383.78 170.403 380.61C185.243 377.44 187.753 368.68 182.693 361.25C177.633 353.82 168.293 349.99 146.563 362.22Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M142.653 362.48C139.688 367.2 138.2 372.697 138.378 378.268C138.556 383.839 140.393 389.23 143.653 393.75C143.653 393.75 153.913 404.92 160.393 388.2C166.873 371.48 146.513 362.2 146.513 362.2L142.653 362.48Z').css(rightTopFlowerCss).fill('#FFF7CE');
  rightTopFlowerGroup.path('M142.653 362.48C139.688 367.2 138.2 372.697 138.378 378.268C138.556 383.839 140.393 389.23 143.653 393.75C143.653 393.75 153.913 404.92 160.393 388.2C166.873 371.48 146.513 362.2 146.513 362.2L142.653 362.48Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M144.213 361.51C144.213 361.51 130.513 352.94 117.433 364.38C113.833 367.53 110.233 372.23 112.303 377.2C115.723 385.41 124.923 383.98 131.563 381.15C139.723 377.68 144.293 370.37 144.213 361.51Z').css(rightTopFlowerCss).fill('#FFF7CE');
  rightTopFlowerGroup.path('M144.213 361.51C144.213 361.51 130.513 352.94 117.433 364.38C113.833 367.53 110.233 372.23 112.303 377.2C115.723 385.41 124.923 383.98 131.563 381.15C139.723 377.68 144.293 370.37 144.213 361.51Z').css(rightTopFlowerCss);
  rightTopFlowerGroup.path('M147.133 359.05C147.133 359.05 158.393 352.99 161.773 340.58').css(commonCss);
  rightTopFlowerGroup.path('M146.193 360.05C146.193 360.05 158.193 369.7 172.193 366.33').css(commonCss);
  rightTopFlowerGroup.path('M149.463 384.19C149.624 377.461 148.188 370.788 145.273 364.72').css(commonCss);
  rightTopFlowerGroup.path('M124.033 371.58C124.033 371.58 129.433 372.58 144.273 363.16').css(commonCss);
  rightTopFlowerGroup.path('M140.753 359.54C140.294 360.393 140.108 361.366 140.219 362.328C140.33 363.29 140.732 364.194 141.373 364.92C142.175 365.67 143.187 366.158 144.273 366.32C145.611 366.6 147.001 366.482 148.273 365.98C148.907 365.718 149.479 365.324 149.949 364.824C150.42 364.324 150.779 363.729 151.003 363.08C151.763 360.65 149.883 356.57 147.333 355.83C144.783 355.09 141.753 357.52 140.713 359.54').fill('#1E2722');
  rightTopFlowerGroup.path('M140.753 359.54C140.294 360.393 140.108 361.366 140.219 362.328C140.33 363.29 140.732 364.194 141.373 364.92C142.175 365.67 143.187 366.158 144.273 366.32C145.611 366.6 147.001 366.482 148.273 365.98C148.907 365.718 149.479 365.324 149.949 364.824C150.42 364.324 150.779 363.729 151.003 363.08C151.763 360.65 149.883 356.57 147.333 355.83C144.783 355.09 141.753 357.52 140.713 359.54').css(rightTopFlowerCss);

  const rightBottomFlowerGroup = landscapeRight.group();
  rightBottomFlowerGroup.addClass('right-bottom-flower');
  rightBottomFlowerGroup.css(commonCss);
  rightBottomFlowerGroup.path('M82.7826 303.47C81.5326 303.31 80.7826 325.86 93.7826 336.12C106.783 346.38 111.913 334.04 109.213 325.12C106.513 316.2 96.8526 305.24 82.7826 303.47Z').fill('#D25582');
  rightBottomFlowerGroup.path('M102.833 305.32C103.303 305.63 118.233 314.67 126.303 301.58C134.373 288.49 109.723 279.58 97.3026 286.29C84.8826 293 82.9827 294.21 82.2227 296.4C81.4627 298.59 102.833 305.32 102.833 305.32Z').fill('#D25582');
  rightBottomFlowerGroup.path('M81.2627 297.55C80.9027 300.09 82.0427 300.07 83.2627 301.75C90.9827 312.58 97.8827 318.75 106.743 322.4C122.633 328.94 121.213 315.64 117.633 312.55C113.107 308.882 108.265 305.623 103.163 302.81C97.7426 299.62 88.4327 297.94 81.5827 298.44C79.6827 298.57 81.5627 295.5 81.2627 297.55Z').fill('#D25582');
  rightBottomFlowerGroup.path('M83.2427 296.86C83.2427 296.86 112.803 292.24 117.973 284.41C123.143 276.58 111.043 277.19 100.163 282.49C89.2826 287.79 83.5227 290.49 80.2227 297.01C80.2227 297.01 81.3827 298.18 83.2427 296.86Z').fill('#222721');
  rightBottomFlowerGroup.path('M83.0727 297.12C83.0727 297.12 90.8327 326.01 85.7627 333.91C80.6927 341.81 76.3527 330.5 76.7627 318.41C77.1727 306.32 77.3427 299.95 81.9627 294.29C81.9927 294.29 83.5327 294.88 83.0727 297.12Z').fill('#222721');
  rightBottomFlowerGroup.path('M96.7227 107.15C97.8527 106.6 86.1327 87.3204 69.6227 85.8804C53.1127 84.4404 55.6227 97.5704 62.7527 103.54C69.8827 109.51 83.9927 113.39 96.7227 107.15Z').fill('#D25582');
  rightBottomFlowerGroup.path('M78.9426 116.6C78.3726 116.6 60.9427 117.24 61.3727 132.6C61.8027 147.96 87.2927 141.87 93.9927 129.46C100.693 117.05 101.623 114.99 101.053 112.74C100.483 110.49 78.9426 116.6 78.9426 116.6Z').fill('#D25582');
  rightBottomFlowerGroup.path('M101.233 111.26C100.143 108.95 99.2326 109.59 97.2326 108.84C84.8426 104.01 75.6926 102.65 66.2326 104.46C49.3626 107.71 57.8426 118.05 62.5426 118.67C68.3249 119.254 74.1473 119.327 79.9426 118.89C86.2326 118.58 94.9427 114.89 100.393 110.7C102.003 109.54 102.113 113.15 101.233 111.26Z').fill('#D25582');
  rightBottomFlowerGroup.path('M99.9627 112.93C99.9627 112.93 77.7827 133.01 77.7627 142.4C77.7427 151.79 87.5127 144.63 93.6927 134.23C99.8727 123.83 103.213 118.4 102.403 111.14C102.403 111.14 100.793 110.81 99.9627 112.93Z').fill('#222721');
  rightBottomFlowerGroup.path('M99.9627 112.63C99.9627 112.63 77.6127 92.7204 77.5227 83.3404C77.4327 73.9604 87.2627 81.0304 93.5227 91.3404C99.7827 101.65 103.173 107.09 102.413 114.34C102.463 114.39 100.813 114.74 99.9627 112.63Z').fill('#222721');
  rightBottomFlowerGroup.path('M276.303 58.6904C276.813 57.5404 255.443 50.2904 241.873 59.8104C228.303 69.3304 238.643 77.8104 247.953 77.7504C257.263 77.6904 270.563 71.6504 276.303 58.6904Z').fill('#D25582');
  rightBottomFlowerGroup.path('M282.403 58.9404C280.073 57.8604 279.763 58.9404 277.823 59.6204C265.223 63.8904 257.333 68.7204 251.283 76.1604C240.453 89.4904 253.593 91.9704 257.583 89.4204C262.443 86.3304 266.843 82.2904 271.063 78.4204C275.683 74.1504 279.973 65.7204 281.463 59.0104C281.883 57.1304 284.283 59.8104 282.403 58.9404Z').fill('#D25582');
  rightBottomFlowerGroup.path('M282.293 60.8104C282.293 60.8104 252.393 59.9204 246.293 52.8104C240.193 45.7004 252.293 44.8104 263.723 48.6904C275.153 52.5704 281.233 54.5604 285.333 60.6104C285.323 60.5804 284.313 61.8804 282.293 60.8104Z').fill('#222721');
  rightBottomFlowerGroup.path('M79.3026 294.34C79.3026 294.34 53.5526 274.73 59.3026 221.57C65.0526 168.41 109.753 158.69 109.753 158.69C109.753 158.69 124.593 152.57 180.933 162.84C237.273 173.11 274.393 170.41 294.763 151.73C315.133 133.05 324.763 102.46 318.873 68.0304C312.983 33.6004 297.283 17.2304 302.563 -10.5396C307.843 -38.3096 320.883 -50.0696 340.563 -51.0996');
  rightBottomFlowerGroup.path('M99.9626 112.63C99.9626 112.63 143.963 105.44 190.673 140.63C190.673 140.63 228.103 170.24 255.333 167.63');
  rightBottomFlowerGroup.path('M19.6526 259.42C19.6526 259.42 46.0326 268.22 57.9026 238C57.9026 238 54.4727 228.77 43.2527 238.94C32.0327 249.11 30.7826 257.08 19.6526 259.42Z').fill('#009D70');
  rightBottomFlowerGroup.path('M78.6926 176.81C78.6926 176.81 72.5526 179.69 62.6926 183.06C52.8326 186.43 34.0327 188.31 27.3527 185.69C27.3627 185.72 59.2126 159.17 78.6926 176.81Z').fill('#009D70');
  rightBottomFlowerGroup.path('M122.463 214.6C122.463 214.6 101.463 222.71 87.4627 200.31C73.4627 177.91 79.7926 176.45 79.7926 176.45C79.7926 176.45 85.7927 170.01 92.4627 179.84C99.1327 189.67 102.743 199.43 107.923 207.71C112.943 215.81 122.463 214.6 122.463 214.6Z').fill('#009D70');
  rightBottomFlowerGroup.path('M132.813 143.21C132.813 143.21 144.203 122.21 174.403 130.75C174.403 130.75 171.293 138.51 157.853 138.7C149.326 138.928 140.883 140.449 132.813 143.21Z').fill('#009D70');
  rightBottomFlowerGroup.path('M167.293 84.1704C167.293 84.1704 147.503 96.3304 172.833 128.93C172.833 128.93 183.693 129.1 175.353 109.09C167.013 89.0804 167.293 84.1704 167.293 84.1704Z').fill('#009D70');
  rightBottomFlowerGroup.path('M243.463 206.81C243.463 206.81 244.463 174.64 273.613 164.38C273.613 164.38 281.383 175.6 259.113 190.78C259.153 190.81 247.733 200.43 243.463 206.81Z').fill('#009D70');
  rightBottomFlowerGroup.path('M220.193 126.33C220.193 126.33 240.293 167.48 275.103 162.64C275.103 162.64 275.473 139.4 251.013 134.98C226.553 130.56 220.193 126.33 220.193 126.33Z').fill('#009D70');
  rightBottomFlowerGroup.path('M99.2627 256.39C99.2627 256.39 84.7326 266.86 69.4126 252.46C54.0926 238.06 58.6926 235.64 58.6926 235.64C58.6926 235.64 62.0227 229.47 69.1327 235.69C76.2427 241.91 81.0026 248.69 86.6626 254.02C92.3226 259.35 99.2627 256.39 99.2627 256.39Z').fill('#009D70');

  const groupFour = landscapeRight.group();
  groupFour.css(commonCss);
  groupFour.path('M166.283 468.64C166.283 468.64 261.993 427.18 297.793 391.08C333.593 354.98 337.913 316.78 337.913 316.78C337.913 316.78 289.263 323.35 241.283 370.21C193.303 417.07 166.283 468.64 166.283 468.64Z').fill('#A3D380');
  groupFour.path('M154.303 213.55C154.303 213.55 241.133 323.08 334.493 316.55C334.493 316.55 327.353 288.83 279.883 257.66C232.413 226.49 167.823 212.19 154.303 213.55Z').fill('#A3D380');
  groupFour.path('M161.013 215.79L334.493 316.52');
  groupFour.path('M171.383 464.91L337.913 316.78');
  groupFour.path('M328.863 104.66C328.863 104.66 320.863 120.95 297.043 134.95C273.223 148.95 249.313 170.95 242.703 183.44C236.093 195.93 206.543 239.44 203.083 246.85C199.623 254.26 184.603 273.16 176.533 300.96L170.193 318.76');
  groupFour.path('M164.623 278.44C164.623 278.44 184.873 272.19 203.083 246.85');
  groupFour.path('M234.243 289.39C234.243 289.39 217.893 270.62 229.473 205.02');
  groupFour.path('M208.893 281.3C208.893 281.3 217.013 273.43 225.173 245.3');
  groupFour.path('M151.563 371.94C152.113 370.38 158.823 367.4 160.443 366.26C163.621 364.032 166.657 361.607 169.533 359C175.316 353.904 180.068 347.746 183.533 340.86C187.533 332.45 191.123 321.3 181.173 315.71C178.501 314.31 175.642 313.299 172.683 312.71C153.233 308.24 148.033 321.92 147.393 325.92C146.753 329.92 147.303 343.25 149.573 354.92C150.734 360.524 151.4 366.22 151.563 371.94V371.94Z').fill('#006571');
  groupFour.path('M152.773 369.25C152.773 369.25 168.923 343.73 172.663 312.67');
  groupFour.path('M183.643 319.21C183.643 319.21 204.273 311.92 212.213 303.42C220.153 294.92 233.603 281.68 212.513 277.22C212.513 277.22 208.893 265.98 199.273 267.96C189.653 269.94 179.143 280.96 180.183 297.32C181.223 313.68 183.643 319.21 183.643 319.21Z').fill('#006571');
  groupFour.path('M183.643 319.21C183.643 319.21 200.093 290.98 212.513 277.21');
  groupFour.path('M111.513 292.08C115.716 292.814 119.853 293.888 123.883 295.29C130.673 297.64 159.153 306.48 165.523 293.59C171.893 280.7 167.133 277.76 167.133 277.76C167.133 277.76 170.233 264.9 155.003 265.32C139.773 265.74 125.943 274.46 119.233 281.9C112.523 289.34 111.513 292.08 111.513 292.08Z').fill('#006571');
  groupFour.path('M113.883 291.11C113.883 291.11 148.283 289.5 167.133 277.76');
  groupFour.path('M296.073 249.37C296.073 249.37 282.563 254.46 271.073 249.65C259.583 244.84 248.913 236.52 246.663 220.31C244.413 204.1 256.983 204.1 256.983 204.1C256.983 204.1 267.693 191.49 278.813 196.67C289.933 201.85 289.523 221.75 289.523 221.75C289.523 221.75 286.843 242.39 296.073 249.37Z').fill('#006571');
  groupFour.path('M203.133 211.52C203.133 211.52 228.453 205.94 239.503 188.96');
  groupFour.path('M163.183 194.74C163.183 194.74 163.183 206.04 169.933 213.08C176.683 220.12 185.723 225.64 198.203 222.76C210.683 219.88 207.203 210.7 207.203 210.7C207.203 210.7 213.443 199.35 206.553 192.7C199.663 186.05 185.233 191.84 185.233 191.84C185.233 191.84 170.863 199.54 163.183 194.74Z').fill('#006571');
  groupFour.path('M163.183 194.74C163.183 194.74 178.033 213.43 207.183 210.74');
  groupFour.path('M220.823 122.94C220.823 122.94 218.263 133.94 223.173 142.34C228.083 150.74 235.653 158.2 248.453 158.27C261.253 158.34 259.973 148.6 259.973 148.6C259.973 148.6 268.653 138.99 263.493 130.89C258.333 122.79 242.933 125.16 242.933 125.16C242.933 125.16 227.193 129.38 220.823 122.94Z').fill('#006571');
  groupFour.path('M220.823 122.94C220.823 122.94 230.983 144.55 259.973 148.6');
  groupFour.path('M279.193 305.97C279.193 305.97 263.193 312.68 246.283 310.55C229.373 308.42 224.003 306.22 223.173 298.32C222.343 290.42 232.173 285.94 232.173 285.94C232.173 285.94 241.073 265.62 255.513 270.56C258.278 271.447 260.8 272.962 262.882 274.987C264.964 277.012 266.549 279.491 267.513 282.23C269.033 286.38 270.003 290.74 271.853 294.77C273.703 298.8 276.693 302.28 279.193 305.97Z').fill('#006571');
  groupFour.path('M279.193 305.97C279.193 305.97 251.123 298.17 232.193 285.97');

  return svgHelper(landscapeRight);
};

export default landscapeRightSvg;
