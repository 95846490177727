<template>
  <div 
    class="product-bop-container"
    :class="{'bop-flipped': isFlipped, 'bop-not-flipped': !isFlipped}"
  >
    <div class="corner-text-container">
      <div class="corner-text text-not-flipped" v-show="!isFlipped">
        {{ flyoutText }}
      </div>
      <svg class="o-fly-type__arrow" xmlns="http://www.w3.org/2000/svg" width="69.564" height="18.446">
        <g fill="none" stroke="#212721" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
          <path class="o-fly-type__arrow-line"
            d="M1.5 4.553c.54 1.1 2.129 1.941 2.957 2.6.7.564 1.419 1.1 2.126 1.657 1.015.8 2.1 1.3 3.089 2.165a12.786 12.786 0 003.387 2.172c1.373.557 2.759.553 4.124 1.154a24.288 24.288 0 007.408 1.721 29 29 0 013.926.763 9.487 9.487 0 002.945.08 52.857 52.857 0 005.92-.792c1.931-.308 3.88-.548 5.819-.859a18.386 18.386 0 005.458-1.714c1.857-.942 3.8-1.51 5.65-2.448s3.675-1.882 5.521-2.808c1.265-.635 2.293-1.613 3.563-2.238a7.515 7.515 0 001.925-1.068 4.2 4.2 0 011.114-.893"
            style="stroke-dashoffset: 0; stroke-dasharray: none;"></path>
          <path class="o-fly-type__arrow-head"
            d="M54.6 1.5a24.538 24.538 0 003.864.361 35.306 35.306 0 013.762.735c.92.194 1.877.207 2.812.341.477.069.922.152 1.412.177.453.023 1.3-.133 1.612.176a16.355 16.355 0 01-.862 2.04c-.22.673-.189 1.341-.449 2.017-.4 1.048-.8 2.1-1.094 3.161a23.458 23.458 0 00-.8 2.369 3.572 3.572 0 01-.316 1.248 3.492 3.492 0 00-.363.756c-.07.29.019.544-.1.839"
            style="stroke-dashoffset: 0; stroke-dasharray: 27.7516px, 37.7516px;"></path>
        </g>
		  </svg>
      <div class="corner-text text-flipped" v-show="isFlipped">
        {{ returnText }}
      </div>
    </div>
    <div 
      class="corner-icon"
      @click="updateFlip"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88">
          <path 
            class="products__corner-path"
            d="M0,88l1.39-7.38a197.76,197.76,0,0,0,0-73.16,5.28,5.28,0,0,1,6-6.19l5,.81a198.12,198.12,0,0,0,63.09,0l12.42-2V.91A87.1,87.1,0,0,1,.83,88Z"
            :style="{'fill': cornerBackgroundFill}">
          </path>
        </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductBop',
  props: {
    flyoutText: {
      type: String,
      required: true
    },
    returnText: {
      type: String,
      required: true
    },
    cornerBackgroundFill: {
      type: String,
      required: true
    },
    isFlipped: {
      type: Boolean,
      required: true
    },
    isUnstick: {
      type: Boolean,
      required: true
    }
  },
  emits: ['flipaction'],
  methods: {
    updateFlip () {
      this.$emit('flipaction', !this.isFlipped);
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: Innocent Super Scrawl;
  src: url("../../../../resources/fonts/innocentsuperscrawl-Regular.woff2") format("woff2"),
  url("../../../../resources/fonts/innocentsuperscrawl-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  }

.product-bop-container {
  &.bop-not-flipped {
    & .corner-text-container {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(5deg) translateY(-130%);

    & .o-fly-type__arrow {
      transform: scale(.75) rotate(70deg) translateY(200%);

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    & .corner-text {
        transform: translate(-50%);
      }
    }
  
    .corner-icon {
      transform-origin: right bottom;
    }
  }

  &.bop-flipped {
    & .corner-icon {
      transform-origin: left top;

      & svg {
        transform: rotate(180deg);
      }
    }

    & .corner-text-container {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: rotate(-5deg) translate(100%, 100%); 
    }

    .o-fly-type__arrow {
      transform: scale(.75) rotate(-140deg) translate(100%, -100%);

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .text-flipped {
    transform: translate(-50%);
  }

  .corner-text {
    font-size: 1.875rem;
    line-height: 1.438rem;
    color: #212721;
    margin-bottom: 20px;
    opacity: 1;
    font-family: Innocent Super Scrawl, sans-serif;
    font-weight: 100;
    font-style: normal;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .corner-icon {
    cursor: grab;
    display: block;
    transform: scale(.9);
    position: relative;
    left: 10px;
    top: 3px;

    &:hover {
      animation: bounce 1.2s ease-out;
      cursor: pointer;
    }

    svg path {
      fill-rule: evenodd;
      transition: fill 1s ease;
    }
  }

}

@keyframes bounce {
  0% {
  transform: scale(.9)
  }

  25% {
  transform: scale(1)
  }

  75% {
  transform: scale(1)
  }

  to {
  transform: scale(.9)
  }
}
</style>