import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const landscapeTopSvg = () => {
  const landscapeTop = SVG();
  landscapeTop.width(1373)
    .height(249)
    .viewbox('0 0 1373 249')
    .css({'fill': 'none'})
    .addClass('landscape-top');

  const groupOne = landscapeTop.group();
  groupOne.css({
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round'
  });
  groupOne.path('M1036.88 -127.7C1031.5 -118.06 1028.3 -107.7 1024.27 -97.2095C1022.04 -91.2212 1020.61 -84.9632 1020.03 -78.5995C1018.11 -51.9195 1014.51 -27.4795 1011.7 -1.06952C1011.33 6.22048 1010.96 13.5105 1010.19 20.8705C1009.89 23.8705 1010.08 27.2505 1009.37 30.3605C1008.38 40.8008 1008.45 51.315 1009.59 61.7405C1010.59 74.2905 1011.97 86.3605 1013.8 98.8905');
  groupOne.path('M987.88 60.5405C987.88 60.5405 1005.41 43.9805 1011.27 8.98047');
  groupOne.path('M1064.45 36.7405C1064.45 36.7405 1038.34 25.8005 1017.2 -46.9595');
  groupOne.path('M1034.52 41.2205C1034.52 41.2205 1038.85 29.1005 1033.05 -3.69952');
  groupOne.path('M1021.54 162.51C1021.33 160.65 1026.68 154.23 1027.75 152.26C1029.88 148.384 1031.76 144.376 1033.38 140.26C1036.71 132.154 1038.46 123.485 1038.54 114.72C1038.44 104.11 1036.46 90.9505 1023.49 90.2405C1020.06 90.1492 1016.63 90.5431 1013.31 91.4105C991.21 96.6205 992.78 113.19 994.13 117.6C995.48 122.01 1002.74 135.32 1010.92 146.08C1014.94 151.232 1018.49 156.73 1021.54 162.51V162.51Z').fill('#509689');
  groupOne.path('M1021.43 159.16C1021.43 159.16 1025.1 125 1013.31 91.4305');
  groupOne.path('M1027.8 92.5905C1027.8 92.5905 1045.19 74.7805 1049.02 62.1205C1052.85 49.4605 1059.93 29.2005 1036.16 35.2405C1036.16 35.2405 1026.82 25.5905 1018 32.4405C1009.18 39.2905 1004 55.8405 1013.27 71.9805C1022.54 88.1205 1027.8 92.5905 1027.8 92.5905Z').fill('#509689');
  groupOne.path('M1027.8 92.5905C1027.8 92.5905 1030.4 55.5205 1036.16 35.2405');
  groupOne.path('M940.57 101.14C945.224 99.7569 949.983 98.7536 954.8 98.1405C962.91 97.1405 996.41 91.8505 996.43 75.5005C996.45 59.1505 990.12 58.5005 990.12 58.5005C990.12 58.5005 986.83 43.8205 971.49 51.9005C956.15 59.9805 946.49 75.8305 943.32 86.7905C940.15 97.7505 940.57 101.14 940.57 101.14Z').fill('#509689');
  groupOne.path('M942.49 98.9604C942.49 98.9604 976.79 80.0404 990.12 58.5804');
  groupOne.path('M925 19.3905C925 19.3905 930 15.3905 951.39 10.7805C972.78 6.17048 990.12 2.95048 982.25 -18.1395C974.38 -39.2295 958 -27.8195 953.78 -24.6895C940.12 -14.5895 934.98 4.81048 925 19.3905Z').fill('#006571');
  groupOne.path('M980.8 -23.0496C980.8 -23.0496 949.87 4.06044 927.99 15.9504');
  groupOne.path('M1118.65 31.0805C1118.65 31.0805 1105.65 45.9805 1087.37 52.2805C1069.09 58.5805 1062.45 59.0605 1057.64 51.4105C1052.83 43.7605 1060.64 34.2505 1060.64 34.2505C1060.64 34.2505 1059.51 9.04049 1076.74 6.83049C1080.01 6.35105 1083.34 6.63224 1086.48 7.65215C1089.62 8.67206 1092.48 10.4031 1094.84 12.7105C1098.47 16.1905 1101.65 20.1505 1105.57 23.3305C1109.49 26.5105 1114.24 28.5705 1118.65 31.0805Z').fill('#509689');
  groupOne.path('M1118.65 31.0805C1118.65 31.0805 1086.09 37.2205 1060.65 34.2505');
  groupOne.path('M794.82 146.72C794.82 146.72 797.4 121.72 823.94 114.25C823.94 114.25 822.69 110.73 812.79 106.84C812.79 106.84 826.24 90.8405 852.27 94.1205C852.27 94.1205 842.96 85.6305 840.02 79.6805L836.7 73.2005C836.7 73.2005 843.86 76.6005 850.7 76.6505C857.54 76.7005 865.36 77.6505 868.2 78.8405C868.2 78.8405 867.89 72.6605 865.36 71.0305C865.36 71.0305 871.7 69.8105 882.77 77.6805C882.77 77.6805 886.9 83.5905 903.4 70.3005C903.4 70.3005 898.89 78.4405 901.34 80.5305C903.51 82.3705 907.5 78.8905 909.27 77.8005C912.72 75.6705 916.97 75.0905 920.92 74.4505C926.791 73.4807 932.793 73.6505 938.6 74.9505C938.6 74.9505 934.47 86.2205 916.22 92.1405C916.22 92.1405 946.14 88.0705 956.29 103.78C952.131 103.495 948.002 104.673 944.62 107.11C944.62 107.11 964.4 113.71 974.75 137.46C974.75 137.46 944.36 148.04 935.09 139.32C935.09 139.32 940.58 145.48 940.09 148.22C940.09 148.22 919 152.92 907.49 143.56C907.49 143.56 914.49 153.29 908.2 176.42C904.828 173.8 901.973 170.575 899.78 166.91C899.78 166.91 901.44 181.33 885.72 195.48C885.72 195.48 870.86 191.64 868.72 166.77C868.72 166.77 863.05 172.11 861.53 177.96C861.53 177.96 849.22 165.16 860.61 143.65C855.466 146.606 849.67 148.242 843.74 148.41C834.56 148.41 829.21 149.34 826.67 151.71C826.67 151.71 824.46 148.25 828.57 142.39C828.57 142.39 817.01 146.45 809.4 145.56C804.512 144.941 799.549 145.336 794.82 146.72V146.72Z').fill('#006571');
  groupOne.path('M885.71 195.48C885.71 195.48 881.53 149.05 881.71 121.53C881.89 94.0105 885.18 59.9805 885.18 59.9805');
  groupOne.path('M882.3 138.19C895.07 147.07 901.3 163.5 901.3 163.5');
  groupOne.path('M893.88 133.81C893.88 133.81 887.05 121.99 882.33 118.09');
  groupOne.path('M952.64 130.68C952.64 130.68 895.21 101.57 883.41 80.6205');
  groupOne.path('M923.77 128.81C923.77 128.81 916.29 114.05 915.77 109.24');
  groupOne.path('M932.25 103.81C932.25 103.81 919.61 106.02 913.91 107.59');
  groupOne.path('M906.75 89.7405L894.13 93.1305C892.77 98.0705 894.5 113.45 894.5 113.45');
  groupOne.path('M865.29 162.15C865.29 162.15 870.42 148 881.9 140.4');
  groupOne.path('M871.88 126.32C871.88 126.32 881.6 122.83 881.88 117.84');
  groupOne.path('M811.46 135.33C811.46 135.33 863.91 96.2005 882.76 77.6805');
  groupOne.path('M833.09 136.81C836.152 129.968 837.942 122.625 838.37 115.14');
  groupOne.path('M824.26 108.97C824.26 108.97 831.33 116.83 839.68 113.61');
  groupOne.path('M858.12 111.42C858.12 111.42 863.65 101.57 865.12 93.1305');
  groupOne.path('M853.56 88.2305C853.56 88.2305 861.41 94.3405 867.09 91.5905');

  const topRightFlowerGroup = landscapeTop.group();
  topRightFlowerGroup.css({
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round'
  });
  topRightFlowerGroup.addClass('top-right-flower');
  topRightFlowerGroup.path('M926 48.0105C926 48.0105 914.33 41.5005 897.2 42.0805C880.07 42.6605 821.55 54.9405 794.88 19.8105C794.88 19.8105 786.46 7.4705 808.17 2.1305C829.88 -3.2095 864.88 -8.0695 890.44 14.6205C916 37.3105 926 48.0105 926 48.0105Z').fill('#509689');
  topRightFlowerGroup.path('M921.3 44.8105C921.3 44.8105 879.16 21.7105 839.82 17.7205C839.82 17.7205 816.82 14.3405 795.06 15.7205');
  topRightFlowerGroup.path('M699.73 43.3105C699.73 43.3105 691.73 -20.5195 727.93 -50.4095C764.13 -80.2995 770.03 -70.5295 770.03 -70.5295C770.03 -70.5295 781.88 -54.6695 767.46 -31.2595C753.04 -7.84954 732.25 7.51045 725.46 13.5205C718.67 19.5305 699.97 42.4505 699.73 43.3105Z').fill('#509689');
  topRightFlowerGroup.path('M700.02 40.6005C700.02 40.6005 719.22 -14.9495 745.56 -41.0895C771.9 -67.2295 770.03 -70.5295 770.03 -70.5295');
  topRightFlowerGroup.path('M835.88 78.8605C822.266 65.5337 810.496 50.4467 800.88 34.0005C786.09 8.81046 781.56 -21.4295 775.74 -49.7195C770.35 -75.9295 769.02 -137.31 812.35 -156.97');
  topRightFlowerGroup.path('M757.8 141.09C757.8 141.09 740.47 106.5 745.19 76.2004C749.91 45.9004 771.19 23.7104 789.78 17.9604C789.78 17.9604 804.04 25.1904 797.78 47.6204C791.52 70.0504 779.14 88.6804 769.88 101.2C760.62 113.72 756.88 134.32 757.8 141.09Z').fill('#509689');
  topRightFlowerGroup.path('M757.07 137.86C757.07 137.86 748.76 85.1004 775.96 53.3904C784.379 43.446 789.242 30.9787 789.78 17.9604');
  topRightFlowerGroup.path('M932.02 159.87C928.016 155.211 923.531 150.988 918.64 147.27C910.18 140.78 900.64 139.03 890.82 135.72C882.976 132.989 875.436 129.453 868.32 125.17C853.39 116.33 837.04 102.74 835.19 84.2505C834.98 82.1056 834.98 79.9453 835.19 77.8005C835.19 77.8005 851.19 68.8005 873.4 79.1405C895.61 89.4805 919.64 110.86 932.02 159.87Z').fill('#509689');
  topRightFlowerGroup.path('M930 156.29C930 156.29 917.5 130.29 888.32 113.99C859.14 97.6904 837.34 84.2105 835.18 77.8105');

  const groupTwo = landscapeTop.group();
  const groupTwoCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round'
  };
  groupTwo.path('M1218.78 9.06048C1223.09 11.1681 1226.86 14.2491 1229.78 18.0605C1234.59 24.8105 1234.44 37.3505 1224.41 39.2805C1221.41 39.7272 1218.36 39.0774 1215.8 37.4505C1210.45 34.3868 1206.45 29.4309 1204.58 23.5605C1203.49 19.8205 1202.58 6.70048 1208.12 5.83048C1211.54 5.29048 1215.88 7.56048 1218.78 9.06048Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1221.51 25.9305C1218.15 18.9135 1213.2 12.77 1207.07 7.98047Z').fill('#7C4181');
  groupTwo.path('M1221.51 25.9305C1218.15 18.9135 1213.2 12.77 1207.07 7.98047').css(groupTwoCss);
  groupTwo.path('M1204.38 8.27045C1204.38 8.27045 1204.47 33.0604 1193.16 40.0204C1189.74 42.1204 1185.39 43.4504 1181.91 40.7904C1175.46 35.8704 1179.23 27.7904 1182.78 22.3904C1185.23 18.6879 1188.41 15.5253 1192.12 13.097C1195.83 10.6687 1200.01 9.02615 1204.38 8.27045V8.27045Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1203.88 5.28044C1203.88 5.28044 1191.18 -14.7196 1176.88 -9.04956C1162.58 -3.37956 1161.55 5.86044 1167.88 12.4304C1174.21 19.0004 1184.2 21.2404 1203.88 5.28044Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1207.73 4.35044C1209.88 -0.882998 1210.42 -6.63977 1209.28 -12.1811C1208.13 -17.7225 1205.36 -22.7956 1201.31 -26.7496C1201.31 -26.7496 1189.12 -36.1896 1185.51 -18.3396C1181.9 -0.489557 1203.88 5.28044 1203.88 5.28044L1207.73 4.35044Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1206.33 5.59048C1206.33 5.59048 1221.52 11.8305 1232.66 -1.86952C1235.72 -5.62952 1238.52 -10.9495 1235.59 -15.5695C1230.76 -23.1995 1221.79 -20.1995 1215.59 -16.2295C1208.06 -11.3595 1204.73 -3.26952 1206.33 5.59048Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1203.88 8.55048C1203.88 8.55048 1193.65 16.5505 1192.39 29.5505Z').fill('#7C4181');
  groupTwo.path('M1203.88 8.55048C1203.88 8.55048 1193.65 16.5505 1192.39 29.5505').css(groupTwoCss);
  groupTwo.path('M1204.61 7.3905C1204.61 7.3905 1190.98 -0.219498 1177.54 5.5405Z').fill('#7C4181');
  groupTwo.path('M1204.61 7.3905C1204.61 7.3905 1190.98 -0.219498 1177.54 5.5405').css(groupTwoCss);
  groupTwo.path('M1197.23 -16.1895C1198.22 -9.42816 1200.8 -2.9984 1204.76 2.57049Z').fill('#7C4181');
  groupTwo.path('M1197.23 -16.1895C1198.22 -9.42816 1200.8 -2.9984 1204.76 2.57049').css(groupTwoCss);
  groupTwo.path('M1224.81 -7.93951C1224.81 -7.93951 1219.23 -7.99951 1206 3.95049Z').fill('#7C4181');
  groupTwo.path('M1224.81 -7.93951C1224.81 -7.93951 1219.23 -7.99951 1206 3.95049').css(groupTwoCss);
  groupTwo.path('M1210.14 6.97049C1210.45 6.03738 1210.47 5.03138 1210.19 4.08756C1209.92 3.14373 1209.36 2.30727 1208.59 1.69049C1207.66 1.07511 1206.56 0.757776 1205.44 0.780485C1204.04 0.70838 1202.64 1.05656 1201.44 1.78049C1200.85 2.14774 1200.34 2.63773 1199.96 3.21737C1199.57 3.79701 1199.31 4.4528 1199.2 5.14049C1198.86 7.71049 1201.43 11.4705 1204.11 11.7805C1206.79 12.0905 1209.45 9.17049 1210.14 6.97049Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1068.65 191.11C1073.75 173.29 1171.97 36.0105 1183.43 21.4305').css(groupTwoCss);
  groupTwo.path('M1077.58 214.25C1081.89 216.358 1085.66 219.439 1088.58 223.25C1093.39 230 1093.24 242.54 1083.21 244.47C1080.21 244.912 1077.16 244.262 1074.6 242.64C1069.25 239.577 1065.25 234.621 1063.38 228.75C1062.29 225.01 1061.38 211.89 1066.92 211.02C1070.34 210.48 1074.71 212.75 1077.58 214.25Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1080.31 231.12C1076.95 224.105 1072.01 217.961 1065.88 213.17Z').fill('white');
  groupTwo.path('M1080.31 231.12C1076.95 224.105 1072.01 217.961 1065.88 213.17').css(groupTwoCss);
  groupTwo.path('M1063.18 213.46C1063.18 213.46 1063.27 238.25 1051.96 245.21C1048.54 247.31 1044.19 248.64 1040.71 245.98C1034.25 241.06 1038.03 232.98 1041.58 227.58C1044.03 223.878 1047.21 220.715 1050.92 218.287C1054.63 215.859 1058.81 214.216 1063.18 213.46V213.46Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1062.66 210.47C1062.66 210.47 1049.96 190.47 1035.66 196.14C1021.36 201.81 1020.33 211.05 1026.66 217.62C1032.99 224.19 1043 226.43 1062.66 210.47Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1066.53 209.54C1068.68 204.305 1069.22 198.547 1068.07 193.004C1066.93 187.462 1064.16 182.387 1060.11 178.43C1060.11 178.43 1047.92 169 1044.31 186.84C1040.7 204.68 1062.66 210.47 1062.66 210.47L1066.53 209.54Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1065.13 210.81C1065.13 210.81 1080.32 217.05 1091.46 203.35C1094.52 199.59 1097.32 194.27 1094.38 189.65C1089.56 182.02 1080.59 185.02 1074.38 188.99C1066.88 193.81 1063.53 201.92 1065.13 210.81Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1062.63 213.74C1062.63 213.74 1052.4 221.74 1051.14 234.74').css(groupTwoCss);
  groupTwo.path('M1063.41 212.58C1063.41 212.58 1049.78 204.96 1036.34 210.73').css(groupTwoCss);
  groupTwo.path('M1056.03 188.98C1057.02 195.742 1059.6 202.172 1063.56 207.74Z').fill('white');
  groupTwo.path('M1056.03 188.98C1057.02 195.742 1059.6 202.172 1063.56 207.74').css(groupTwoCss);
  groupTwo.path('M1083.61 197.25C1083.61 197.25 1078.03 197.19 1064.8 209.14Z').fill('white');
  groupTwo.path('M1083.61 197.25C1083.61 197.25 1078.03 197.19 1064.8 209.14').css(groupTwoCss);
  groupTwo.path('M1068.94 212.16C1069.25 211.227 1069.27 210.221 1068.99 209.278C1068.72 208.334 1068.16 207.497 1067.39 206.88C1066.46 206.265 1065.36 205.948 1064.24 205.97C1062.84 205.898 1061.44 206.247 1060.24 206.97C1059.65 207.337 1059.14 207.826 1058.75 208.406C1058.37 208.986 1058.11 209.642 1058 210.33C1057.65 212.9 1060.23 216.66 1062.91 216.97C1065.59 217.28 1068.24 214.36 1068.94 212.16Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1115.44 116.81C1105.27 121.801 1094.16 124.561 1082.84 124.906C1071.52 125.251 1060.25 123.173 1049.8 118.81').css(groupTwoCss);
  groupTwo.path('M1053.88 121.43C1058.19 123.538 1061.96 126.619 1064.88 130.43C1069.69 137.18 1069.54 149.72 1059.51 151.64C1056.51 152.097 1053.44 151.446 1050.88 149.81C1045.53 146.747 1041.53 141.791 1039.66 135.92C1038.57 132.19 1037.72 119.07 1043.21 118.19C1046.61 117.67 1050.98 119.93 1053.88 121.43Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1056.58 138.31C1053.22 131.292 1048.28 125.148 1042.14 120.36Z').fill('#7C4181');
  groupTwo.path('M1056.58 138.31C1053.22 131.292 1048.28 125.148 1042.14 120.36').css(groupTwoCss);
  groupTwo.path('M1039.45 120.65C1039.45 120.65 1039.54 145.43 1028.23 152.4C1024.82 154.5 1020.46 155.82 1016.98 153.17C1010.53 148.25 1014.3 140.17 1017.85 134.77C1020.29 131.065 1023.47 127.9 1027.19 125.471C1030.9 123.042 1035.08 121.402 1039.45 120.65V120.65Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1038.93 117.66C1038.93 117.66 1026.23 97.6605 1011.93 103.33C997.63 109 996.6 118.23 1002.93 124.8C1009.26 131.37 1019.27 133.61 1038.93 117.66Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1042.81 116.73C1044.96 111.495 1045.49 105.737 1044.35 100.195C1043.21 94.653 1040.43 89.5782 1036.39 85.6205C1036.39 85.6205 1024.2 76.1905 1020.58 94.0305C1016.96 111.87 1038.93 117.66 1038.93 117.66L1042.81 116.73Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1041.41 117.96C1041.41 117.96 1056.59 124.2 1067.73 110.51C1070.79 106.74 1073.59 101.43 1070.66 96.8005C1065.83 89.1805 1056.86 92.1705 1050.66 96.1405C1043.13 101.02 1039.8 109.11 1041.41 117.96Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M1038.88 120.92C1038.88 120.92 1028.65 128.92 1027.4 141.92Z').fill('#7C4181');
  groupTwo.path('M1038.88 120.92C1038.88 120.92 1028.65 128.92 1027.4 141.92').css(groupTwoCss);
  groupTwo.path('M1039.68 119.81C1039.68 119.81 1026.05 112.2 1012.61 117.97Z').fill('#7C4181');
  groupTwo.path('M1039.68 119.81C1039.68 119.81 1026.05 112.2 1012.61 117.97').css(groupTwoCss);
  groupTwo.path('M1032.3 96.1605C1033.29 102.921 1035.87 109.35 1039.83 114.92Z').fill('#7C4181');
  groupTwo.path('M1032.3 96.1605C1033.29 102.921 1035.87 109.35 1039.83 114.92').css(groupTwoCss);
  groupTwo.path('M1059.88 104.43C1059.88 104.43 1054.31 104.37 1041.07 116.32Z').fill('#7C4181');
  groupTwo.path('M1059.88 104.43C1059.88 104.43 1054.31 104.37 1041.07 116.32').css(groupTwoCss);
  groupTwo.path('M1045.21 119.35C1045.52 118.417 1045.54 117.41 1045.27 116.464C1044.99 115.519 1044.44 114.68 1043.67 114.06C1042.73 113.455 1041.64 113.142 1040.52 113.16C1039.12 113.091 1037.73 113.439 1036.52 114.16C1035.93 114.53 1035.42 115.022 1035.04 115.603C1034.65 116.184 1034.39 116.841 1034.28 117.53C1033.94 120.1 1036.52 123.86 1039.19 124.16C1041.86 124.46 1044.52 121.55 1045.21 119.35Z').css(groupTwoCss).fill('white');
  groupTwo.path('M1145.6 73.1105C1144.57 84.3848 1145.97 95.7482 1149.69 106.44C1153.41 117.133 1159.37 126.907 1167.17 135.11').css(groupTwoCss);
  groupTwo.path('M1163.31 132.27C1159.8 128.983 1155.57 126.553 1150.96 125.17C1142.96 123.1 1131.27 127.71 1133.06 137.77C1133.71 140.731 1135.41 143.355 1137.85 145.16C1142.63 149.062 1148.69 151.032 1154.85 150.68C1158.72 150.36 1171.29 146.47 1170.14 141.03C1169.42 137.69 1165.74 134.41 1163.31 132.27Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1146.58 135.75C1154.34 136.387 1161.84 138.811 1168.5 142.83Z').fill('white');
  groupTwo.path('M1146.58 135.75C1154.34 136.387 1161.84 138.811 1168.5 142.83').css(groupTwoCss);
  groupTwo.path('M1169.19 145.44C1169.19 145.44 1146.01 154.21 1143.55 167.26C1142.8 171.2 1143.12 175.74 1146.85 178.05C1153.75 182.32 1159.91 175.91 1163.72 170.66C1166.31 167.053 1168.13 162.954 1169.07 158.617C1170.01 154.279 1170.05 149.795 1169.19 145.44V145.44Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1172.17 144.86C1172.17 144.86 1195.42 149.56 1195.17 164.97C1194.92 180.38 1186.73 184.62 1178.33 181.04C1169.93 177.46 1164.29 168.92 1172.17 144.86Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1171.65 140.91C1175.77 137.032 1180.96 134.472 1186.54 133.56C1192.13 132.647 1197.86 133.424 1203 135.79C1203 135.79 1216.17 143.79 1200.79 153.56C1185.41 163.33 1172.17 144.86 1172.17 144.86L1171.65 140.91Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1171 142.65C1171 142.65 1159.75 130.71 1168.56 115.4C1170.98 111.2 1174.95 106.69 1180.31 107.77C1189.16 109.56 1189.56 119 1188.06 126.18C1186.21 134.99 1179.88 140.99 1171 142.65Z').css(groupTwoCss).fill('#FFF8CF');
  groupTwo.path('M1169.13 146.05C1169.13 146.05 1165.32 158.46 1153.63 164.28').css(groupTwoCss);
  groupTwo.path('M1169.94 144.91C1169.94 144.91 1181.94 154.91 1181.33 169.53').css(groupTwoCss);
  groupTwo.path('M1194.61 143.37C1187.94 144.861 1181.02 144.748 1174.4 143.04Z').fill('white');
  groupTwo.path('M1194.61 143.37C1187.94 144.861 1181.02 144.748 1174.4 143.04').css(groupTwoCss);
  groupTwo.path('M1177.04 120.56C1177.04 120.56 1179.04 125.75 1172.65 142.38Z').fill('white');
  groupTwo.path('M1177.04 120.56C1177.04 120.56 1179.04 125.75 1172.65 142.38').css(groupTwoCss);
  groupTwo.path('M1168.35 139.6C1169.11 138.974 1170.04 138.596 1171.02 138.515C1172 138.435 1172.98 138.656 1173.83 139.15C1174.75 139.798 1175.45 140.713 1175.83 141.77C1176.39 143.054 1176.55 144.475 1176.29 145.85C1176.16 146.535 1175.88 147.183 1175.47 147.752C1175.07 148.32 1174.55 148.797 1173.95 149.15C1171.67 150.39 1167.24 149.32 1165.95 146.93C1164.66 144.54 1166.54 141.03 1168.35 139.6Z').css(groupTwoCss).fill('#7C4181');
  groupTwo.path('M336.6 139.65C336.6 139.65 306.15 39.8805 274.29 0.260475C242.43 -39.3595 204.97 -47.9495 204.97 -47.9495C204.97 -47.9495 206.03 1.14048 247.21 54.0505C288.39 106.96 336.6 139.65 336.6 139.65Z').css(groupTwoCss).fill('#A3D380');
  groupTwo.path('M81.78 122.91C81.78 122.91 200.36 48.9105 204.32 -44.5795C204.32 -44.5795 175.98 -40.5795 139.67 3.08047C103.36 46.7405 81.94 109.33 81.78 122.91Z').css(groupTwoCss).fill('#A3D380');
  groupTwo.path('M84.7601 116.5L204.33 -44.5795').css(groupTwoCss);
  groupTwo.path('M333.46 134.16L204.97 -47.9495').css(groupTwoCss);
  groupTwo.path('M133.29 3.04049C133.51 9.63049 134.6 16.6705 137.36 22.7105C142.05 32.9805 153.79 35.7905 163.29 30.5605C169.17 27.3205 170.05 20.5605 168.77 14.4705C168.054 10.9147 166.64 7.53619 164.61 4.53049C160.04 -2.02951 152.61 -5.46951 145.16 -7.86951C142.16 -8.86951 138.23 -10.7395 135.61 -8.25951C132.99 -5.77951 133.17 -0.18951 133.29 3.04049Z').css(groupTwoCss).fill('#FFE900');
  groupTwo.path('M100.82 38.6305C95.82 45.3605 85.71 50.0205 76.82 48.8005C60.39 46.5305 61.35 26.8705 67.82 16.4105C73.1866 7.77578 77.7939 -1.30828 81.5901 -10.7395C81.2701 -9.9195 92.68 -0.349502 93.92 0.980498C97.3 4.5705 101.38 8.4505 103.39 12.8905C106.79 20.2405 105.94 31.8105 100.82 38.6305Z').css(groupTwoCss).fill('#FFE900');
  groupTwo.path('M156.61 0.89045C160.94 3.96045 166.06 6.50045 171.33 5.89045C178.05 5.17045 186.78 -1.10955 188.25 -7.92955C189.96 -15.8696 183.61 -23.4595 177.67 -27.8595C171.11 -32.7095 162.42 -33.9195 154.32 -32.8595C149.194 -32.1114 144.164 -30.807 139.32 -28.9696C134.13 -27.1096 131.76 -26.6795 135.32 -21.6595C141.29 -13.2895 148.2 -5.08955 156.61 0.89045Z').css(groupTwoCss).fill('#FFE900');
  groupTwo.path('M147.11 14.4604C147.98 17.8304 148.61 21.4004 147.76 24.7704C146.64 29.1804 143.13 32.6004 139.31 35.0804C133.788 38.6474 127.433 40.7152 120.87 41.0804C115.79 41.3704 110.27 40.4104 106.72 36.7604C103.9 33.8404 102.79 29.7104 101.94 25.7604C101.118 22.4928 100.745 19.1287 100.83 15.7604C101.16 10.4504 103.47 5.49044 104.83 0.340435C107.95 -11.7296 104.77 -22.9596 103.92 -34.9596C111.45 -37.9596 124.52 -23.6696 128.68 -19.1796C137.511 -9.66301 143.843 1.89546 147.11 14.4604V14.4604Z').css(groupTwoCss).fill('#FFE900');
  groupTwo.path('M47.26 30.5405C43.26 30.8905 38.99 30.5405 35.55 28.3605C32.7098 26.4189 30.4358 23.7583 28.96 20.6505C26.6298 16.5139 25.4264 11.838 25.47 7.09045C25.65 2.47045 27.38 -1.96955 29.47 -6.07955C33.39 -13.5596 38.88 -20.4396 46.13 -24.6896C49.9111 -26.7311 53.8793 -28.4055 57.98 -29.6896L84.71 -39.0396C83.94 -38.7696 83.04 -20.6795 82.71 -18.7395C82.0568 -13.887 81.0542 -9.08783 79.71 -4.37955C76.52 6.29045 74.13 16.7405 64.71 23.8005C59.6292 27.6186 53.5883 29.9519 47.26 30.5405V30.5405Z').css(groupTwoCss).fill('#FFE900');
  groupTwo.path('M113.66 -14.8395C113.66 -7.32955 112.28 0.770456 106.92 6.01046C103.27 9.59046 98.23 11.3305 93.27 12.5605C88.73 13.6805 83.86 14.4305 79.43 12.9105C73.3 10.8105 69.24 4.55046 68.37 -1.86954C67.5 -8.28955 69.37 -14.8095 72.16 -20.6695C78.74 -34.5495 90.16 -45.4295 101.33 -55.9795C103.76 -58.2795 110.08 -39.6695 110.24 -39.1395C112.544 -31.2465 113.696 -23.062 113.66 -14.8395V-14.8395Z').css(groupTwoCss).fill('#FFE900');
  groupTwo.path('M108.08 -12.0595C110.65 -6.73951 114.08 -1.38951 119.37 1.12049C124.66 3.63049 131.12 2.78049 136.82 1.12049C141.82 -0.369505 146.77 -2.6095 150 -6.6695C155.88 -14.1895 153.88 -25.7095 147.6 -32.8695C141.32 -40.0295 132.02 -43.6695 122.88 -46.1895C117.17 -47.7395 111.42 -49.3495 105.68 -50.7295C100.62 -51.9395 98.9801 -50.0495 99.1001 -44.8295C99.3301 -33.7395 103.31 -21.9195 108.08 -12.0595Z').css(groupTwoCss).fill('#FFE900');
  groupTwo.path('M90.6801 0.450447C86.8525 -16.5252 89.1773 -34.3084 97.2401 -49.7296').css(groupTwoCss);
  groupTwo.path('M455.14 -116.72C460.39 -105.31 467.74 -95.4195 474.68 -84.6695C478.666 -78.6011 483.441 -73.0895 488.88 -68.2795C512.09 -48.5995 532.11 -28.8895 554.48 -8.56954C560.92 -3.33954 567.37 1.89045 573.61 7.55045C576.19 9.85045 579.4 11.9305 581.77 14.6705C590.704 22.5924 600.421 29.585 610.77 35.5405C622.98 43.0305 634.97 49.8905 647.71 56.6205').css(groupTwoCss);
  groupTwo.path('M595.09 54.6905C595.09 54.6905 591.65 27.4805 563.44 -1.40955').css(groupTwoCss);
  groupTwo.path('M512.98 8.31048C512.98 8.31048 513.42 -19.9395 485.16 -72.0095').css(groupTwoCss);
  groupTwo.path('M608.64 -1.08953C608.64 -1.08953 600.43 -13.1895 566.41 -29.8995').css(groupTwoCss);
  groupTwo.path('M711.31 92.2605C709.45 91.2605 707.15 81.9905 706.07 79.6805C703.953 75.1309 701.551 70.7189 698.88 66.4705C693.56 58.0905 686.99 50.3905 678.88 44.5805C669.07 37.5805 655.66 30.5805 646.3 41.9605C643.907 45.0508 641.965 48.4648 640.53 52.1005C630.53 75.8905 646.74 85.5705 651.7 87.2905C656.66 89.0105 673.76 91.2905 689.13 91.0005C696.546 90.7701 703.968 91.1917 711.31 92.2605V92.2605Z').css(groupTwoCss).fill('#509689');
  groupTwo.path('M708.16 90.1205C708.16 90.1205 679.26 63.8205 640.51 52.1205').css(groupTwoCss);
  groupTwo.path('M651.3 39.5705C651.3 39.5705 646.63 11.6405 637.58 -0.379536C628.53 -12.3995 614.67 -32.4995 604.26 -6.61953C604.26 -6.61953 589.12 -4.51953 589.49 8.18047C589.86 20.8805 601.58 36.7505 622.63 39.0705C643.68 41.3905 651.3 39.5705 651.3 39.5705Z').css(groupTwoCss).fill('#509689');
  groupTwo.path('M651.3 39.5705C651.3 39.5705 619.01 12.3005 604.3 -6.61951').css(groupTwoCss);
  groupTwo.path('M600.59 125.41C602.459 120.233 604.747 115.217 607.43 110.41C611.95 102.28 629.59 67.9705 614.6 56.9705C599.61 45.9705 594.78 51.3804 594.78 51.3804C594.78 51.3804 579.09 44.5404 576.21 64.0504C573.33 83.5604 581.36 103.12 589.33 113.34C597.3 123.56 600.59 125.41 600.59 125.41Z').css(groupTwoCss).fill('#509689');
  groupTwo.path('M599.88 122.18C599.88 122.18 605.53 77.9805 594.78 51.3405').css(groupTwoCss);
  groupTwo.path('M515.07 84.8105C515.07 84.8105 514.67 77.5605 524.88 54.8105C535.09 32.0605 543.69 13.9905 519.05 7.0605C494.41 0.130502 493.88 22.8105 493.88 28.8105C494.01 48.1205 508.34 65.8105 515.07 84.8105Z').css(groupTwoCss).fill('#006571');
  groupTwo.path('M513.56 5.08044C513.56 5.08044 517.68 51.6904 513.93 79.7704').css(groupTwoCss);

  const topLeftFlowerGroup = landscapeTop.group();
  topLeftFlowerGroup.css({
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round'
  });
  topLeftFlowerGroup.addClass('top-left-flower');
  topLeftFlowerGroup.path('M201.23 169.4C201.45 170.64 178.98 172.61 168.05 160.14C157.12 147.67 169.18 141.93 178.23 144.14C187.28 146.35 198.73 155.45 201.23 169.4Z').fill('#D25582');
  topLeftFlowerGroup.path('M198.33 149.48C198 149.02 188.19 134.59 200.85 125.84C213.51 117.09 223.66 141.26 217.62 154C211.58 166.74 210.47 168.71 208.32 169.58C206.17 170.45 198.33 149.48 198.33 149.48Z').fill('#D25582');
  topLeftFlowerGroup.path('M207.22 170.62C204.7 171.11 204.66 169.97 202.93 168.87C191.7 161.73 185.19 155.16 181.08 146.49C173.72 130.97 187.08 131.69 190.35 135.11C194.244 139.425 197.747 144.077 200.82 149.01C204.3 154.26 206.46 163.47 206.32 170.34C206.28 172.25 209.25 170.21 207.22 170.62Z').fill('#D25582');
  topLeftFlowerGroup.path('M207.81 168.6C207.81 168.6 210.88 138.81 218.43 133.27C225.98 127.73 226 139.81 221.27 150.95C216.54 162.09 214.14 167.95 207.81 171.63C207.81 171.63 206.59 170.53 207.81 168.6Z').fill('#222721');
  topLeftFlowerGroup.path('M207.56 168.81C207.56 168.81 178.3 162.57 170.67 168.05C163.04 173.53 174.57 177.26 186.67 176.19C198.77 175.12 205.08 174.65 210.49 169.75C210.44 169.72 209.77 168.21 207.56 168.81Z').fill('#222721');
  topLeftFlowerGroup.path('M396.55 145.25C397.04 144.09 416.91 154.78 419.21 171.19C421.51 187.6 408.27 185.8 401.93 178.97C395.59 172.14 390.98 158.28 396.55 145.25Z').fill('#D25582');
  topLeftFlowerGroup.path('M388.04 163.5C388.04 164.06 388.35 181.5 372.97 181.87C357.59 182.24 362.35 156.47 374.39 149.13C386.43 141.79 388.45 140.76 390.72 141.21C392.99 141.66 388.04 163.5 388.04 163.5Z').fill('#D25582');
  topLeftFlowerGroup.path('M392.21 140.95C394.58 141.95 393.99 142.89 394.84 144.77C400.3 156.9 402.14 165.96 400.84 175.46C398.48 192.46 387.71 184.54 386.84 179.89C385.953 174.145 385.578 168.332 385.72 162.52C385.72 156.22 388.94 147.34 392.82 141.67C393.88 140.1 390.29 140.17 392.21 140.95Z').fill('#D25582');
  topLeftFlowerGroup.path('M390.61 142.31C390.61 142.31 371.72 165.51 362.35 166.02C352.98 166.53 359.61 156.39 369.67 149.68C379.73 142.97 384.98 139.35 392.27 139.78C392.27 139.81 392.69 141.37 390.61 142.31Z').fill('#222721');
  topLeftFlowerGroup.path('M390.88 142.3C390.88 142.3 411.88 163.57 421.3 163.17C430.72 162.77 423.09 153.33 412.43 147.61C401.77 141.89 396.2 138.8 388.98 139.93C389.02 139.93 388.76 141.56 390.88 142.3Z').fill('#222721');
  topLeftFlowerGroup.path('M435.58 -36.6195C436.7 -37.1895 445.05 -16.1895 436.26 -2.1895C427.47 11.8105 418.49 1.9805 418.03 -7.3295C417.57 -16.6395 422.94 -30.1895 435.58 -36.6195Z').fill('#D25582');
  topLeftFlowerGroup.path('M435.02 -42.6996C436.21 -40.4296 435.13 -40.0596 434.58 -38.0896C430.97 -25.2796 426.58 -17.1496 419.44 -10.7196C406.69 0.810437 403.53 -12.1896 405.88 -16.3296C408.895 -21.2898 412.342 -25.9746 416.18 -30.3296C420.18 -35.1696 428.4 -39.8896 435.02 -41.7296C436.88 -42.2696 434.04 -44.5296 435.02 -42.6996Z').fill('#D25582');
  topLeftFlowerGroup.path('M210.53 172.4C210.53 172.4 231.45 197.09 284.24 188.58C337.03 180.07 344.41 134.91 344.41 134.91C344.41 134.91 349.74 119.78 336.55 64.0505C323.36 8.32048 324.11 -28.8895 341.7 -50.1895C359.29 -71.4895 389.34 -82.7295 424.03 -78.6295C458.72 -74.5295 475.88 -59.7295 503.35 -66.4595C530.82 -73.1895 541.88 -86.8095 541.88 -106.57');
  topLeftFlowerGroup.path('M390.88 142.3C390.88 142.3 395.76 97.9505 358.2 53.1605C358.2 53.1605 326.68 17.3305 327.88 -9.99951');
  topLeftFlowerGroup.path('M248.51 230.15C248.51 230.15 238.35 204.27 267.88 190.81C267.88 190.81 277.28 193.76 267.7 205.5C258.12 217.24 250.26 218.91 248.51 230.15Z').fill('#009D70');
  topLeftFlowerGroup.path('M327.88 166.89C325.808 172.226 324.006 177.663 322.48 183.18C319.63 193.18 318.72 212.07 321.69 218.61C321.71 218.61 346.56 185.42 327.88 166.89Z').fill('#009D70');
  topLeftFlowerGroup.path('M287.88 125.16C287.88 125.16 280.88 146.55 303.98 159.4C327.08 172.25 328.23 165.81 328.23 165.81C328.23 165.81 334.35 159.47 324.18 153.34C314.01 147.21 304.09 144.09 295.55 139.34C287.19 134.71 287.88 125.16 287.88 125.16Z').fill('#009D70');
  topLeftFlowerGroup.path('M358.66 111.08C358.66 111.08 379.08 98.6105 368.94 68.9005C368.94 68.9005 361.35 72.4105 361.85 85.8405C362.074 94.367 360.999 102.878 358.66 111.08V111.08Z').fill('#009D70');
  topLeftFlowerGroup.path('M415.82 73.5705C415.82 73.5705 404.71 93.9705 370.82 70.3705C370.82 70.3705 370.09 59.5305 390.51 66.8205C410.93 74.1105 415.82 73.5705 415.82 73.5705Z').fill('#009D70');
  topLeftFlowerGroup.path('M289.38 3.86045C289.38 3.86045 321.45 1.24045 330.17 -28.4596C330.17 -28.4596 318.56 -35.6396 304.56 -12.6096C304.56 -12.6096 295.52 -0.699553 289.38 3.86045Z').fill('#009D70');
  topLeftFlowerGroup.path('M370.96 22.9505C370.96 22.9505 328.81 5.02045 331.88 -30.0095C331.88 -30.0095 355.08 -31.5795 360.76 -7.38955C366.44 16.8005 370.96 22.9505 370.96 22.9505Z').fill('#009D70');
  topLeftFlowerGroup.path('M247.39 150.49C247.39 150.49 237.68 165.49 252.87 180.09C268.06 194.69 270.23 189.92 270.23 189.92C270.23 189.92 276.23 186.28 269.63 179.5C263.03 172.72 256.02 168.33 250.41 162.95C244.8 157.57 247.39 150.49 247.39 150.49Z').fill('#009D70');

  const groupThree = landscapeTop.group();
  const groupThreeCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round'
  };
  groupThree.path('M1227.5 151.28C1225.96 152.16 1223.88 152.94 1222.5 151.77C1220.97 150.43 1221.89 147.95 1222.91 146.19C1228.35 136.86 1234.27 127.67 1242.07 120.19C1246.17 116.209 1250.85 112.868 1255.94 110.28C1258.36 109.08 1270.12 103.63 1269.16 108.36C1268.68 110.72 1264.93 114.2 1263.48 116.14C1261.55 118.73 1259.6 121.29 1257.58 123.81C1253.61 128.846 1249.32 133.619 1244.74 138.1C1239.56 143.189 1233.77 147.616 1227.5 151.28V151.28Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1267.24 166.05C1268.15 167.91 1270.3 170.05 1271.9 168.7C1272.49 168.094 1272.85 167.296 1272.9 166.45C1274.4 157.641 1275 148.701 1274.68 139.77C1274.36 130.55 1272.99 121.13 1268.56 113.04C1268.26 112.51 1267.8 111.9 1267.2 112.04C1267.01 112.099 1266.83 112.195 1266.67 112.324C1266.52 112.453 1266.39 112.611 1266.3 112.79C1261.46 119.92 1260.81 130.06 1260.87 138.49C1260.92 148.036 1263.09 157.452 1267.24 166.05V166.05Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1234.78 121.33C1231.18 122.956 1227.38 124.112 1223.48 124.77C1221.98 125.052 1220.44 124.942 1219 124.45C1218.29 124.19 1217.66 123.732 1217.2 123.13C1216.74 122.528 1216.46 121.806 1216.39 121.05C1216.39 119.05 1218.19 117.65 1219.82 116.54C1234.09 106.87 1249.32 96.8605 1266.54 95.9605C1268.73 95.8405 1271.06 95.9605 1272.89 97.1205C1277.17 99.9105 1274.09 102.12 1271.24 103.62C1267.17 105.75 1263.24 108.07 1259.12 110.16C1251.17 114.227 1243.06 117.95 1234.78 121.33Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1273.17 131.93C1276.64 140.41 1281.61 148.17 1286.55 155.87C1287.26 157.206 1288.27 158.362 1289.5 159.25C1290.12 159.68 1290.87 159.894 1291.63 159.86C1292.38 159.826 1293.11 159.545 1293.69 159.06C1294.69 158.06 1294.69 156.5 1294.57 155.11C1294.14 148.07 1293.51 141.047 1292.66 134.04C1291.74 126.33 1290.41 118.28 1285.66 112.09C1283.03 108.63 1279.51 105.97 1276.02 103.38C1268.4 97.7305 1268.54 108.38 1268.75 113.3C1269.15 119.717 1270.64 126.019 1273.17 131.93V131.93Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1231.38 101.06C1225.23 101.51 1218.57 101.62 1213.52 98.0605C1213.06 97.7831 1212.68 97.3858 1212.42 96.9105C1211.75 95.4205 1213.42 93.9805 1214.86 93.2105C1230.68 84.7974 1248.35 80.4826 1266.26 80.6605C1270.1 80.7105 1280.69 82.1605 1278.73 88.3905C1277.73 91.5105 1271.73 94.7805 1268.73 95.7405C1257.1 99.4705 1243.48 100.22 1231.38 101.06Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1297.31 143.61C1298.84 145.15 1301.31 146.67 1302.97 145.32C1303.97 144.52 1304.14 143.06 1304.11 141.77C1303.94 135.1 1300.79 128.92 1297.71 123C1294.93 117.361 1291.77 111.918 1288.25 106.71C1286.25 103.97 1279.06 93.3505 1274.77 95.1305C1269.05 97.5005 1282.55 122.98 1284.77 126.84C1288.18 132.965 1292.4 138.605 1297.31 143.61V143.61Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1297.96 123.67C1300.28 127.67 1302.65 131.607 1305.07 135.48C1308.45 140.94 1312.35 146.69 1318.39 148.91C1319.62 149.37 1321.2 149.57 1322.04 148.56C1322.31 148.123 1322.48 147.633 1322.54 147.124C1322.6 146.615 1322.55 146.098 1322.39 145.61C1319.32 128.61 1311.22 113.09 1301.05 99.3405C1298.49 95.8805 1295.79 92.5205 1292.99 89.2605C1290.89 86.8105 1287.36 81.0605 1284.39 79.8305C1278.48 77.3805 1283.78 93.8305 1284.61 96.2205C1287.94 105.87 1292.88 114.88 1297.96 123.67Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1249.99 82.0705C1241.54 83.9605 1232.99 85.8705 1224.35 85.7805C1221.21 85.7805 1217.47 85.0305 1216.26 82.1405C1215.05 79.2505 1217.12 76.1405 1219.76 74.7405C1222.4 73.3405 1225.5 73.1605 1228.45 72.5905C1231.72 71.8524 1234.94 70.9445 1238.11 69.8705C1251.37 65.931 1265.37 65.1299 1279 67.5305C1281.14 67.9005 1286.61 69.2205 1286.94 72.0605C1287.29 75.1305 1279 75.5605 1276.45 76.1305L1249.99 82.0705Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1316.05 124.27C1320.32 129.22 1325.28 134.09 1331.63 135.66C1332.28 135.886 1332.98 135.974 1333.66 135.919C1334.35 135.864 1335.02 135.667 1335.63 135.34C1337.39 134.11 1337.22 131.49 1336.76 129.4C1334.65 119.78 1330.52 110.73 1325.92 102.02C1318.48 87.9105 1309.38 74.1205 1296.18 65.1805C1295.66 64.765 1295.05 64.4987 1294.39 64.4105C1293.12 64.4105 1292.2 65.5805 1291.59 66.6905C1288.4 72.4105 1288.18 75.3405 1290.41 81.2405C1296.38 96.954 1305.05 111.505 1316.02 124.24L1316.05 124.27Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1237.77 68.8605C1233.68 69.3305 1229.55 69.9505 1225.46 69.4505C1221.37 68.9505 1217.24 67.1905 1214.88 63.8105C1214.4 63.1795 1214.08 62.4365 1213.96 61.6505C1213.92 60.4892 1214.32 59.3561 1215.1 58.4905C1217.86 54.9805 1222.75 54.1605 1227.18 53.6005C1233.9 52.7505 1240.65 51.8905 1247.42 52.1105C1251.52 52.2505 1255.6 52.7805 1259.67 53.3105L1274.67 55.3105C1278.77 55.8405 1282.93 56.3905 1286.76 57.9705C1289.46 59.0805 1292.25 61.2105 1292.24 64.1305C1292.24 74.3705 1274.74 68.4905 1269.04 68.1305C1258.62 67.4445 1248.15 67.6888 1237.77 68.8605V68.8605Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1324.66 120.67C1325.28 122.91 1326.6 125.67 1328.91 125.42C1330.72 125.23 1331.69 123.24 1332.23 121.51C1336.29 108.59 1335.08 94.3805 1330.51 81.6305C1325.94 68.8805 1318.17 57.4805 1309.27 47.2805C1308.27 46.1705 1307.03 44.9805 1305.59 45.2805C1303.59 45.7305 1303.16 47.6405 1301.53 48.5905C1299.66 49.6905 1296.53 49.5905 1294.44 49.8505C1288.39 50.7038 1282.33 51.4771 1276.26 52.1705C1264.12 53.5638 1251.95 54.6438 1239.74 55.4105C1234.61 55.7305 1228.87 55.7905 1225.08 52.3105C1224.39 51.7549 1223.92 50.9771 1223.74 50.1105C1223.51 48.3405 1225.23 47.0005 1226.74 46.1105C1243.81 36.6005 1264.36 36.5305 1283.9 36.7205C1291.33 36.7905 1298.97 36.9005 1305.83 39.7205C1306.49 39.9112 1307.05 40.3571 1307.39 40.9605C1308.45 43.7505 1304.39 46.3705 1303.39 48.5405C1302.26 51.1105 1303.14 53.3105 1304.06 55.8605C1306.9 63.7705 1309.7 71.6705 1312.31 79.6605C1316.76 93.2471 1320.88 106.917 1324.66 120.67Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1319.99 62.2205C1324.4 71.7505 1330.37 80.4505 1336.31 89.1005C1338.88 92.8405 1341.7 96.7805 1345.92 98.4505C1347.42 99.0405 1349.31 99.2405 1350.49 98.1405C1351.86 96.8705 1351.49 94.6405 1351.01 92.8405C1349.52 88.1018 1347.74 83.4596 1345.68 78.9405L1339.38 64.3105C1336.94 58.2358 1334.05 52.3498 1330.74 46.7005C1328.74 43.4305 1314.74 23.7705 1313.47 34.4405C1312.36 43.5505 1316.25 54.1305 1319.99 62.2205Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1324.66 44.9005L1339.04 67.4705C1344.83 76.5605 1351.6 86.3805 1362.04 89.0305C1363.51 89.4005 1365.23 89.5705 1366.41 88.6205C1368.22 87.1705 1367.58 84.3005 1366.72 82.1505C1361.53 68.8406 1353.87 56.6278 1344.16 46.1505C1337.95 39.5805 1330.89 33.9005 1323.85 28.2505C1320.29 25.3805 1316.19 25.8805 1317.38 30.9405C1318.57 36.0005 1321.97 40.6905 1324.66 44.9005Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1268.59 36.3005C1260.1 35.7805 1251.44 35.2205 1243.59 31.9705C1240.59 30.7205 1237.24 28.0305 1238.19 24.9105C1239.19 21.6405 1243.59 21.4305 1246.99 21.2705C1250.72 20.9447 1254.43 20.3431 1258.07 19.4705C1275.07 16.0805 1293.15 16.1105 1309.23 22.5505C1311.78 23.5505 1314.51 24.9905 1315.43 27.5505C1315.75 28.6579 1315.78 29.8275 1315.53 30.9524C1315.28 32.0774 1314.76 33.1218 1314 33.9905C1308.94 40.3205 1302.19 38.3305 1295 37.8905L1268.59 36.3005Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1361.88 58.2304C1363.17 58.5104 1364.74 58.6604 1365.6 57.6604C1366.46 56.6604 1366.03 55.0204 1365.4 53.8004C1362.74 48.6704 1357.99 44.9904 1353.4 41.5304C1345.3 35.4904 1337.13 29.5838 1328.88 23.8104C1327.34 22.7204 1325.67 21.6004 1323.79 21.5404C1313.67 21.2504 1331.58 41.4404 1334.07 43.7104C1341.91 50.9019 1351.5 55.9088 1361.88 58.2304V58.2304Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1332.05 5.91049C1333.79 6.06934 1335.54 5.68134 1337.05 4.80049C1337.79 4.35626 1338.37 3.68205 1338.7 2.87944C1339.03 2.07682 1339.09 1.18927 1338.87 0.350492C1338.4 -1.02951 1337.05 -1.88951 1335.78 -2.64951C1329.23 -6.36111 1322.31 -9.37752 1315.13 -11.6495C1312.86 -12.3695 1310.01 -12.8695 1308.41 -11.1095C1307.94 -10.516 1307.63 -9.80707 1307.53 -9.05625C1307.42 -8.30544 1307.52 -7.54006 1307.81 -6.83951C1308.41 -5.44342 1309.3 -4.19685 1310.44 -3.18951C1312.4 -1.16037 1314.72 0.496387 1317.27 1.70049C1318.89 2.37702 1320.56 2.92537 1322.27 3.34049C1325.96 4.34049 1332.43 4.70049 1334.56 8.34049C1336.3 11.2905 1336.14 15.6905 1337.79 18.9405C1341.14 25.5705 1346.41 31.4805 1352.01 36.3105C1352.35 36.6914 1352.77 36.998 1353.24 37.2113C1353.7 37.4245 1354.21 37.54 1354.72 37.5505C1355.89 37.4205 1356.72 36.2705 1357.07 35.1705C1358.71 30.6805 1356.81 25.7205 1354.64 21.4705C1351.28 14.8738 1347.22 8.65803 1342.53 2.93049C1341.78 2.01049 1340.77 1.02049 1339.61 1.24049C1339.02 1.41105 1338.51 1.76075 1338.13 2.24049C1336.86 3.5378 1335.95 5.14935 1335.51 6.91049').fill('#A3D380');
  groupThree.path('M1332.05 5.91049C1333.79 6.06934 1335.54 5.68134 1337.05 4.80049C1337.79 4.35626 1338.37 3.68205 1338.7 2.87944C1339.03 2.07682 1339.09 1.18927 1338.87 0.350492C1338.4 -1.02951 1337.05 -1.88951 1335.78 -2.64951C1329.23 -6.36111 1322.31 -9.37752 1315.13 -11.6495C1312.86 -12.3695 1310.01 -12.8695 1308.41 -11.1095C1307.94 -10.516 1307.63 -9.80707 1307.53 -9.05625C1307.42 -8.30544 1307.52 -7.54006 1307.81 -6.83951C1308.41 -5.44342 1309.3 -4.19685 1310.44 -3.18951C1312.4 -1.16037 1314.72 0.496387 1317.27 1.70049C1318.89 2.37702 1320.56 2.92537 1322.27 3.34049C1325.96 4.34049 1332.43 4.70049 1334.56 8.34049C1336.3 11.2905 1336.14 15.6905 1337.79 18.9405C1341.14 25.5705 1346.41 31.4805 1352.01 36.3105C1352.35 36.6914 1352.77 36.998 1353.24 37.2113C1353.7 37.4245 1354.21 37.54 1354.72 37.5505C1355.89 37.4205 1356.72 36.2705 1357.07 35.1705C1358.71 30.6805 1356.81 25.7205 1354.64 21.4705C1351.28 14.8738 1347.22 8.65803 1342.53 2.93049C1341.78 2.01049 1340.77 1.02049 1339.61 1.24049C1339.02 1.41105 1338.51 1.76075 1338.13 2.24049C1336.86 3.5378 1335.95 5.14935 1335.51 6.91049').css(groupThreeCss);
  groupThree.path('M1289.36 17.9305C1278.95 15.5805 1267.36 15.9305 1258.71 9.74046C1256.63 8.26046 1254.62 5.86046 1255.36 3.41046C1255.93 1.52046 1258.03 0.410461 1260.01 0.410461C1261.99 0.495943 1263.94 0.950398 1265.75 1.75046C1274.33 4.84046 1283.29 6.82046 1291.94 9.75046C1295.15 10.8205 1321.71 21.7505 1317.45 27.0705C1315.27 29.7805 1306.88 24.3705 1304.8 23.4305C1299.85 21.0818 1294.68 19.2391 1289.36 17.9305V17.9305Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1301.44 15.5105C1291.96 14.8105 1282.38 11.9005 1275.23 5.65047C1273.39 4.05047 1271.59 1.65047 1272.47 -0.619531C1273.57 -3.47953 1277.58 -3.40953 1280.59 -2.81953C1296.03 0.24047 1315.43 3.28047 1328.39 12.8805C1326.99 15.3205 1318.72 15.0205 1315.96 15.2605C1311.13 15.7387 1306.28 15.8223 1301.44 15.5105V15.5105Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1295.41 -2.18953C1293.87 -3.1926 1292.57 -4.50775 1291.57 -6.04953C1291.08 -6.82351 1290.79 -7.70893 1290.74 -8.62338C1290.68 -9.53783 1290.86 -10.4516 1291.25 -11.2795C1291.79 -12.1025 1292.53 -12.7673 1293.41 -13.2051C1294.29 -13.6429 1295.27 -13.8377 1296.25 -13.7695C1298.22 -13.6027 1300.14 -13.0308 1301.88 -12.0895C1305.53 -10.3995 1333.88 6.70047 1330.46 10.6105C1328.57 12.7705 1315.46 7.43047 1312.93 6.61047C1306.68 4.56574 1300.78 1.59969 1295.41 -2.18953V-2.18953Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1255.8 135.891C1255.8 135.891 1290.9 39.9505 1371.03 -29.1095').css(groupThreeCss);
  groupThree.path('M1240.05 163.41C1239.27 165.311 1238.93 167.361 1239.05 169.41C1239.05 170.387 1239.35 171.339 1239.92 172.133C1240.49 172.927 1241.29 173.522 1242.22 173.835C1243.14 174.148 1244.14 174.162 1245.08 173.876C1246.01 173.59 1246.83 173.018 1247.42 172.24C1248.59 170.867 1249.49 169.291 1250.09 167.59C1252.58 161.755 1254.63 155.737 1256.21 149.59C1257.21 145.5 1260.45 134.96 1257.92 131.34C1249.39 139.08 1243.59 152.65 1240.05 163.41Z').css(groupThreeCss).fill('#A3D380');
  groupThree.path('M1351.4 44.6305C1352.97 45.5805 1354.71 46.1967 1356.53 46.4405C1357.44 46.5448 1358.37 46.416 1359.22 46.0663C1360.07 45.7166 1360.81 45.1572 1361.39 44.4405C1361.9 43.6015 1362.18 42.6407 1362.2 41.6577C1362.21 40.6748 1361.97 39.7055 1361.48 38.8505C1360.48 37.1549 1359.15 35.6786 1357.57 34.5105C1354.46 31.9505 1326.81 13.7705 1324.76 18.5105C1323.62 21.1505 1334.11 30.7105 1335.95 32.5905C1340.47 37.3538 1345.68 41.4123 1351.4 44.6305V44.6305Z').css(groupThreeCss).fill('#A3D380');

  return svgHelper(landscapeTop);
};

export default landscapeTopSvg;
