import { createStore } from 'vuex';
import activityCardStore from './actvityCardStore';
import productStore from './productStore';
import allProductsCarouselStore from './allProductsCarouselStore';
import historyTimelineStore from './historyTimelineStore'
import horoscopeStore from './horoscopeStore'
import subscribeFormStore from './subscribeFormStore'

const checkData = (responseData, dataName) => {
  if (!responseData.hasOwnProperty('data')) return false;
  if (!responseData.data.hasOwnProperty(dataName)) return false;
  if (!responseData.data[dataName].hasOwnProperty('items')) return false;
  return true;
}

const getItems = (responseData, dataName) => {
  return responseData.data[dataName].items;
}

const getTranslationItems = (responseData) => {
  if (!responseData.data.hasOwnProperty('translationsData')) return [];
  if (!responseData.data.translationsData.hasOwnProperty('items')) return [];
  return responseData.data.translationsData.items;
}

const adaptQueryForPreviewHosts = (originalQuery) => {
  const preview = 'preview';
  const publish = 'publish';
  const aws = 'aws';
  const previewAdobe = 'adobeaemcloud.com';
  const previewAws = 'tccc-nextgen.com';
  const extraParam = ';env='
  const origin = window.location.origin;
  let hostParam;

  if (origin.includes(preview) && origin.includes(previewAdobe)) {
    hostParam = encodeURIComponent(extraParam.concat(preview));
    return originalQuery.concat(hostParam);
  }

  if (origin.includes(publish) && origin.includes(previewAdobe)) {
    hostParam = encodeURIComponent(extraParam.concat(publish));
    return originalQuery.concat(hostParam);
  }

  if (origin.includes(previewAws)) {
    hostParam = encodeURIComponent(extraParam.concat(aws));
    return originalQuery.concat(hostParam);
  }

  return originalQuery;
}

const globalStore = createStore({
  modules: {
    activityCard: activityCardStore,
    product: productStore,
    allProductsCarousel: allProductsCarouselStore,
    historyTimeline: historyTimelineStore,
    horoscope: horoscopeStore,
    subscribeForm: subscribeFormStore 
  },
  state: {
    
  },
  mutations: {
    
  },
  getters: {

  },
  actions: {
    performApiQuery: ({ dispatch, commit, getters }, { query, store, folder }) => {
      if (!query || !folder) {
        commit(`${store}/setPathError`, true);
        return;
      }

      const folderParam = ";folderPath=".concat(folder);
      const encodedFolderParam = encodeURIComponent(folderParam);
      const completeQuery = adaptQueryForPreviewHosts(query.concat(encodedFolderParam));

      fetch(completeQuery)
        .then((response) => response.json())
        .then((responseData) => {
          let dataName = getters[`${store}/getDataName`];
          if (!checkData(responseData, dataName)) {
            commit(`${store}/setDataError`, true);
            return;
          }
          let payload = { items: getItems(responseData, dataName), translationItems: getTranslationItems(responseData) };
          dispatch(`${store}/loadResults`, payload);
        })
        .catch((error) => commit(`${store}/setApiError`, true));
    },
    sendFormData: ({ dispatch }, { url, store, payload, isViews }) => {
      fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify(payload)
        }
      )
      .then((response) => {
        if (isViews) {
          dispatch(`${store}/updateViews`, { status: !!response.ok })
        }
        if (!isViews) {
          dispatch(`${store}/updateFormStatus`, { status: !!response.ok })
        }
      })
      .catch(() => {
        if (isViews) {
          dispatch(`${store}/updateViews`, {status: false})
        }
        if (!isViews) {
          dispatch(`${store}/updateFormStatus`, {status: false})
        }
      })
    }
  }
});

export default globalStore;