import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import { CustomEase } from "gsap/CustomEase";
import { isAuthorMode } from "../../utils/isAuthorMode";

const initializeComponentAnimations = () => {
    gsap.registerPlugin(ScrollTrigger, CustomEase);

    const createScrollTrigger = (selector) => ScrollTrigger.create({
        trigger: selector,
        start: 'top 80%'
    });

    const createAnimation = (classToAnimate) => {
        const elementsToAnimate = document.querySelectorAll(classToAnimate);
        if (!elementsToAnimate.length) {
            return;
        }
        elementsToAnimate.forEach((element) => {
            const scrollTrigger = createScrollTrigger(element);
            gsap.from(element, {
                duration: 1,
                ease: CustomEase.create('cubic', '0.01, 0.43, 0.4, 0.97'),
                autoAlpha: 0,
                scale: 0.9,
                scrollTrigger
            });
        });
    };

    const classesToAnimate = [
        '.c-fluid .text',
        '.title[class*="t-marker"]',
        '.t-common',
        '.t-olympics',
        '.c-flip-card-grid .teaser',
        '.c-teaser-list .teaser',
        '.t-vertical',
        '.accordion:not(.acc-color-dark-pink)',
        '.ai-mask-header',
    ];

    for (const selectedClass of classesToAnimate) {
        createAnimation(selectedClass);
    }

};


const onDocumentReady = () => {
    if (isAuthorMode()) {
        return;
    }
    initializeComponentAnimations();
}

if (document.readyState !== "loading") {
    onDocumentReady();
} else {
    document.addEventListener("DOMContentLoaded", onDocumentReady);
}
