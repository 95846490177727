import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const portraitRightSvg = () => {
  const portraitRight = SVG();
  portraitRight.width(79)
    .height(613)
    .viewbox('0 0 79 613')
    .addClass('portrait-right')
    .fill('none');

  const styleOne = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };

  const styleTwo = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-miterlimit': 10,
  };

  const styleThree = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-miterlimit': 10,
    'stroke-linecap': 'round',
  };

  const groupOne = portraitRight.group();
  groupOne.path('M248.429 612.007C241.406 601.55 218.948 596.583 213.221 590.021C207.495 583.458 200.594 579.724 190.878 573.423C181.162 567.122 180.517 556.097 174.867 550.907C169.217 545.717 160.988 542.065 150.174 539.933C139.36 537.801 114.827 529.578 101.108 523.475C87.3886 517.372 67.1197 467.533 58.9739 441.806C55.4627 430.755 25.6308 390.799 12.282 382.385C9.38376 380.559 17.2104 373.447 19.6107 375.828C23.0678 379.372 26.7096 382.73 30.5209 385.889C41.731 394.891 73.2929 469.167 92.3169 497.027C111.341 524.886 115.503 519.958 137.209 525.595C147.167 528.18 156.156 528.486 164.091 529.457C165.84 532.457 168.496 498.508 150.685 455.155C144.48 440.076 151.962 422.476 161.135 399.34C170.309 376.205 158.224 349.297 158.224 349.297C167.104 338.993 174.976 365.71 178.014 375.311C181.053 384.913 169.537 409.146 164.678 419.398C155.103 439.578 163.797 452.486 167.104 464.181C171.426 479.503 174.925 510.26 177.759 522.715C178.883 527.999 181.192 532.959 184.513 537.22C185.634 538.221 186.672 539.312 187.616 540.482C199.151 554.98 200.384 553.53 208.951 562.685C217.518 571.839 231.518 562.583 245.658 569.273').fill('#56352A');
  groupOne.path('M248.429 612.007C241.406 601.55 218.948 596.583 213.222 590.021C207.495 583.458 200.594 579.724 190.878 573.423C181.162 567.122 180.517 556.097 174.867 550.907C169.217 545.717 160.988 542.065 150.174 539.933C139.36 537.801 114.827 529.578 101.108 523.475C87.3886 517.372 67.1197 467.533 58.9739 441.806C55.4627 430.755 25.6308 390.799 12.282 382.385C9.38376 380.559 17.2104 373.447 19.6107 375.828C23.0678 379.372 26.7096 382.73 30.5209 385.889C41.731 394.891 73.2929 469.167 92.3169 497.027C111.341 524.886 115.503 519.958 137.209 525.595C147.167 528.18 156.156 528.486 164.091 529.457C165.84 532.457 168.496 498.508 150.685 455.155C144.48 440.076 151.962 422.476 161.135 399.34C170.309 376.205 158.224 349.297 158.224 349.297C167.104 338.993 174.976 365.71 178.014 375.311C181.053 384.913 169.537 409.146 164.678 419.398C155.103 439.578 163.797 452.486 167.104 464.181C171.426 479.503 174.925 510.26 177.759 522.715C178.883 527.999 181.192 532.959 184.513 537.22C185.634 538.221 186.672 539.312 187.616 540.482C199.151 554.98 200.384 553.53 208.951 562.685C217.518 571.839 231.518 562.583 245.658 569.273').css(styleTwo);
  groupOne.path('M54.7094 437.601C54.7094 438.239 37.5367 471.435 42.0054 502.869C46.4742 534.304 52.1431 544.761 52.1431 544.761C52.1431 544.761 73.3758 533.167 69.0859 486.431C64.7959 439.694 54.7094 437.601 54.7094 437.601Z').css(styleOne).fill('#009D70');
  groupOne.path('M53.0048 544.277C53.0048 544.277 55.9095 460.093 57.6268 441.049L53.0048 544.277Z').fill('#009D70');
  groupOne.path('M53.0048 544.277C53.0048 544.277 55.9095 460.093 57.6268 441.049').css(styleOne);
  groupOne.path('M1.56989 487.53C1.8827 487.249 30.208 486.458 43.8823 469.272C57.5566 452.087 59.4271 443.098 59.4271 443.098C59.4271 443.098 39.8732 431.326 18.0659 454.998C-3.74153 478.669 1.56989 487.53 1.56989 487.53Z').css(styleOne).fill('#009D70');
  groupOne.path('M58.6036 442.586C58.6036 442.586 12.5885 476.81 1.50601 483.711L58.6036 442.586Z').fill('#009D70');
  groupOne.path('M58.6036 442.586C58.6036 442.586 12.5885 476.81 1.50601 483.711').css(styleOne);
  groupOne.path('M10.4052 405.732C10.7563 405.936 20.5172 432.678 40.3009 439.988C60.0846 447.298 68.7731 446.072 68.7731 446.072C68.7731 446.072 73.0886 423.498 44.891 410.603C16.6933 397.707 10.4052 405.732 10.4052 405.732Z').css(styleOne).fill('#009D70');
  groupOne.path('M68.9646 445.127C68.9646 445.127 23.5624 412.659 13.8333 404.398L68.9646 445.127Z').fill('#009D70');
  groupOne.path('M68.9646 445.127C68.9646 445.127 23.5624 412.659 13.8333 404.398').css(styleOne);
  groupOne.path('M39.171 366.669C39.171 366.669 51.9899 382.769 58.8717 392.268C65.7536 401.767 81.0877 432.168 75.5338 447.31C69.9798 462.453 29.276 396.341 39.171 366.669Z').css(styleOne).fill('#009D70');
  groupOne.path('M40.2882 371.171C40.2882 371.171 62.3382 424.655 73.261 447.694L40.2882 371.171Z').fill('#009D70');
  groupOne.path('M40.2882 371.171C40.2882 371.171 62.3382 424.655 73.261 447.694').css(styleOne);
  groupOne.path('M84.669 436.503C97.2772 438.06 108.794 428.778 110.403 415.774C110.837 412.288 110.122 409.313 109.624 405.93C109.487 405.382 109.529 404.805 109.745 404.283C110.237 403.204 111.437 402.508 112.018 401.442C112.636 400.271 112.897 398.945 112.768 397.627C112.638 396.309 112.124 395.059 111.29 394.03C108.819 391.17 104.331 390.692 100.846 392.16C100.571 388.33 97.2261 385.138 93.4596 384.397C89.6931 383.657 85.716 385.093 82.773 387.551C82.1346 388.093 81.4451 388.706 80.6088 388.757C79.9094 388.717 79.2322 388.497 78.6426 388.119C76.4973 387.073 74.1311 386.559 71.745 386.623C69.3588 386.686 67.0232 387.324 64.9363 388.483C63.8407 389.006 62.9446 389.871 62.3828 390.947C61.2082 393.609 63.5894 396.399 65.7535 398.34C62.6765 398.901 59.4526 399.521 57.0204 401.493C54.5881 403.466 53.3305 407.239 55.1499 409.792C57.1672 412.627 61.6423 412.499 64.0043 415.059C63.3659 414.421 61.4507 416.668 61.272 417.051C60.6479 418.188 60.4726 419.518 60.7805 420.779C61.6104 424.105 65.0577 425.088 67.5985 426.742C69.8647 428.21 71.5884 430.361 73.727 432.002C76.9071 434.452 80.6853 436.006 84.669 436.503Z').css(styleOne).fill('#FAFBFC');
  groupOne.path('M55.6351 444.068C56.0437 443.826 84.4073 443.819 103.859 429.053C123.311 414.287 128.258 406.403 128.258 406.403C128.258 406.403 112.899 395.551 83.1624 415.851C53.4263 436.152 55.6351 444.068 55.6351 444.068Z').css(styleOne).fill('#009D70');
  groupOne.path('M100.042 446.341C100.042 446.341 89.2654 448.256 83.1433 449.118C77.0211 449.98 59.7272 449.801 55.6351 444.068C51.543 438.335 91.685 435.533 100.042 446.341Z').css(styleOne).fill('#009D70');
  groupOne.path('M97.8708 445.824C97.8708 445.824 69.1433 443.43 56.2606 443.008L97.8708 445.824Z').fill('#009D70');
  groupOne.path('M97.8708 445.824C97.8708 445.824 69.1433 443.43 56.2606 443.008').css(styleOne);
  groupOne.path('M127.607 405.96C127.607 405.96 70.2351 434.911 56.8864 440.708L127.607 405.96Z').fill('#009D70');
  groupOne.path('M127.607 405.96C127.607 405.96 70.2351 434.911 56.8864 440.708').css(styleOne);
  groupOne.path('M81.094 435.278C81.5782 438.523 81.0896 441.839 79.69 444.807C78.2905 447.775 76.0427 450.261 73.2307 451.952C70.4187 453.643 67.1687 454.462 63.8911 454.307C60.6136 454.151 57.4557 453.028 54.8164 451.078C52.177 449.129 50.1747 446.441 49.0623 443.354C47.9499 440.267 47.7775 436.92 48.5667 433.735C49.3559 430.55 51.0713 427.671 53.4963 425.46C55.9213 423.25 58.9471 421.808 62.1913 421.316C64.3495 420.989 66.551 421.091 68.6698 421.616C70.7885 422.141 72.783 423.079 74.5388 424.376C76.2946 425.673 77.7774 427.303 78.9022 429.174C80.0271 431.045 80.7719 433.119 81.094 435.278Z').fill('#FF8C00');
  groupOne.path('M81.094 435.278C81.5782 438.523 81.0896 441.839 79.69 444.807C78.2905 447.775 76.0427 450.261 73.2307 451.952C70.4187 453.643 67.1687 454.462 63.8911 454.307C60.6136 454.151 57.4557 453.028 54.8164 451.078C52.177 449.129 50.1747 446.441 49.0623 443.354C47.9499 440.267 47.7775 436.92 48.5667 433.735C49.3559 430.55 51.0713 427.671 53.4963 425.46C55.9213 423.25 58.9471 421.808 62.1913 421.316C64.3495 420.989 66.551 421.091 68.6698 421.616C70.7885 422.141 72.783 423.079 74.5388 424.376C76.2946 425.673 77.7774 427.303 78.9022 429.174C80.0271 431.045 80.7719 433.119 81.094 435.278').css(styleTwo);
  groupOne.path('M85.0839 260.94C82.6244 259.176 80.6093 256.863 79.198 254.186C76.9381 249.493 78.3617 241.737 84.7775 241.609C86.67 241.654 88.483 242.38 89.8846 243.652C92.8717 246.119 94.826 249.615 95.362 253.451C95.6429 255.884 94.7747 264.106 91.2827 264.061C89.1377 264.061 86.6735 262.146 85.052 260.94').fill('#FFF7CE');
  groupOne.path('M85.0839 260.94C82.6244 259.176 80.6093 256.863 79.198 254.186C76.9381 249.493 78.3617 241.737 84.7775 241.609C86.67 241.654 88.483 242.38 89.8846 243.652C92.8717 246.119 94.826 249.615 95.362 253.451C95.6429 255.884 94.7747 264.106 91.2827 264.061C89.1377 264.061 86.6735 262.146 85.052 260.94').css(styleThree);
  groupOne.path('M85.0839 260.94C82.6244 259.176 80.6093 256.863 79.198 254.186C76.9381 249.493 78.3617 241.737 84.7775 241.609C86.67 241.654 88.483 242.38 89.8846 243.652C92.8717 246.119 94.826 249.615 95.362 253.451C95.6429 255.884 94.7747 264.106 91.2827 264.061C89.1696 264.036 86.7054 262.172 85.0839 260.94Z').css(styleThree);
  groupOne.path('M92.4255 264.407C92.4255 264.407 83.6795 258.936 75.3294 266.239C73.0312 268.25 70.733 271.25 72.0544 274.423C74.2377 279.664 80.1109 278.751 84.3498 276.945C89.5591 274.729 92.4765 270.063 92.4255 264.407Z').css(styleThree).fill('#FFF7CE');
  groupOne.path('M92.4255 264.407C92.4255 264.407 83.6795 258.936 75.3294 266.239C73.0312 268.25 70.733 271.25 72.0544 274.423C74.2377 279.664 80.1109 278.751 84.3498 276.945C89.5591 274.729 92.4765 270.063 92.4255 264.407Z').css(styleThree);
  groupOne.path('M79.5426 270.833C79.5426 270.833 82.99 271.472 92.4637 265.458').css(styleOne);

  const portraitRightFlowerGroup = portraitRight.group();
  portraitRightFlowerGroup.addClass('portrait-right-flower');
  portraitRightFlowerGroup.css(styleOne);
  portraitRightFlowerGroup.path('M53.2091 227.354C52.4111 227.252 51.9323 241.647 60.2314 248.197C68.5305 254.747 71.8054 246.869 70.0818 241.175C68.3581 235.48 62.1912 228.484 53.2091 227.354Z').fill('#D25582');
  portraitRightFlowerGroup.path('M66.0088 228.536C66.3088 228.734 75.84 234.505 80.9918 226.148C86.1436 217.792 70.4073 212.104 62.4785 216.387C54.5497 220.671 53.3368 221.443 52.8516 222.841C52.3664 224.24 66.0088 228.536 66.0088 228.536Z').fill('#D25582');
  portraitRightFlowerGroup.path('M52.2389 223.575C52.0091 225.196 52.7368 225.184 53.5157 226.256C58.444 233.17 62.8489 237.109 68.505 239.439C78.6491 243.614 77.7426 235.123 75.4571 233.151C72.5679 230.809 69.4766 228.728 66.2196 226.933C62.7595 224.896 56.8161 223.824 52.4431 224.143C51.2302 224.226 52.4304 222.266 52.2389 223.575Z').fill('#D25582');
  portraitRightFlowerGroup.path('M53.5028 223.134C53.5028 223.134 72.3736 220.185 75.6741 215.186C78.9746 210.188 71.25 210.577 64.3043 213.96C57.3587 217.344 53.6816 219.068 51.5749 223.23C51.5749 223.23 52.3154 223.977 53.5028 223.134Z').fill('#222721');
  portraitRightFlowerGroup.path('M53.3943 223.302C53.3943 223.302 58.3482 241.745 55.1116 246.788C51.8749 251.831 49.1043 244.611 49.3661 236.893C49.6278 229.175 49.7363 225.108 52.6857 221.495C52.7048 221.495 53.6879 221.872 53.3943 223.302Z').fill('#222721');
  portraitRightFlowerGroup.path('M62.1085 102.026C62.8298 101.675 55.3479 89.3665 44.8081 88.4472C34.2683 87.5279 35.8707 95.9099 40.4224 99.7211C44.9741 103.532 53.9818 106.009 62.1085 102.026Z').fill('#D25582');
  portraitRightFlowerGroup.path('M50.7578 108.058C50.3939 108.058 39.2668 108.467 39.5413 118.273C39.8158 128.078 56.0884 124.191 60.3656 116.268C64.6428 108.346 65.2364 107.031 64.8726 105.594C64.5087 104.158 50.7578 108.058 50.7578 108.058Z').fill('#D25582');
  portraitRightFlowerGroup.path('M64.9874 104.65C64.2916 103.175 63.7106 103.584 62.4338 103.105C54.5242 100.022 48.6829 99.1534 42.6438 100.309C31.8741 102.384 37.2876 108.985 40.2881 109.38C43.9795 109.753 47.6965 109.8 51.3961 109.521C55.4115 109.323 60.972 106.967 64.4512 104.292C65.479 103.552 65.5492 105.856 64.9874 104.65Z').fill('#D25582');
  portraitRightFlowerGroup.path('M64.1768 105.714C64.1768 105.714 50.0173 118.533 50.0046 124.527C49.9918 130.522 56.2288 125.951 60.1741 119.312C64.1193 112.672 66.2515 109.206 65.7344 104.571C65.7344 104.571 64.7066 104.361 64.1768 105.714Z').fill('#222721');
  portraitRightFlowerGroup.path('M64.1767 105.523C64.1767 105.523 49.9088 92.8128 49.8513 86.8248C49.7939 80.8367 56.0692 85.3501 60.0655 91.9319C64.0619 98.5137 66.2259 101.986 65.7408 106.615C65.7727 106.647 64.7194 106.87 64.1767 105.523Z').fill('#222721');
  portraitRightFlowerGroup.path('M50.9875 221.525C50.9875 221.525 34.549 209.006 38.2197 175.07C41.8905 141.133 70.4265 134.928 70.4265 134.928C70.4265 134.928 79.9002 131.021 115.867 137.577C151.834 144.133 175.531 142.41 188.535 130.485C201.539 118.559 207.687 99.0311 203.927 77.0513C200.166 55.0716 190.144 44.6211 193.514 26.8931C196.885 9.165 205.21 1.65754 217.773 1');
  portraitRightFlowerGroup.path('M64.1767 105.524C64.1767 105.524 92.2658 100.934 122.085 123.399C122.085 123.399 145.98 142.302 163.363 140.636');
  portraitRightFlowerGroup.path('M12.9076 199.233C12.9076 199.233 29.7483 204.851 37.326 185.559C37.326 185.559 35.1363 179.667 27.9736 186.159C20.8109 192.652 20.0128 197.74 12.9076 199.233Z').fill('#009D70');
  portraitRightFlowerGroup.path('M50.5982 146.494C50.5982 146.494 46.6785 148.332 40.384 150.484C34.0895 152.635 22.0878 153.835 17.8233 152.163C17.8297 152.182 38.1624 135.233 50.5982 146.494Z').fill('#009D70');
  portraitRightFlowerGroup.path('M78.5404 170.619C78.5404 170.619 65.1343 175.797 56.1968 161.497C47.2594 147.197 51.3003 146.265 51.3003 146.265C51.3003 146.265 55.1307 142.153 59.3888 148.429C63.6468 154.704 65.9514 160.935 69.2582 166.221C72.4629 171.392 78.5404 170.619 78.5404 170.619Z').fill('#009D70');
  portraitRightFlowerGroup.path('M63.7299 197.299C63.7299 197.299 54.454 203.983 44.6739 194.79C34.8938 185.597 37.8304 184.052 37.8304 184.052C37.8304 184.052 39.9563 180.113 44.4952 184.084C49.0342 188.055 52.0729 192.383 55.6861 195.786C59.2994 199.188 63.7299 197.299 63.7299 197.299Z').fill('#009D70');
  portraitRightFlowerGroup.path('M71.5502 220.081C74.2335 220.55 76.8745 221.235 79.447 222.131C83.7817 223.631 101.963 229.274 106.03 221.045C110.096 212.816 107.057 210.94 107.057 210.94C107.057 210.94 109.036 202.73 99.3137 202.998C89.5911 203.266 80.7621 208.833 76.4785 213.583C72.1949 218.332 71.5502 220.081 71.5502 220.081Z').fill('#006571');
  portraitRightFlowerGroup.path('M73.0631 219.463C73.0631 219.463 95.0237 218.435 107.057 210.941');

  return svgHelper(portraitRight);
};

export default portraitRightSvg;
