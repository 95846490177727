<template>
  <div class="all-products-page-root">
    <div class="navigation">
      <all-products-navigation 
        :navTitles="getNavTitles"
        :navTitleColours="getNavTitleColours"
        :navBackgroundColour="getCurrentNavBgColor"
        :currentSlide="currentSlide"
        @updateNav="navigateToSlide"
      />
    </div>
    <div class="swiper" ref="swiper">
      <div class="swiper-wrapper">
          <all-products-slide
          v-for="(slideItem, slideIndex) in slideItems"
          :key="slideIndex"
          :slideItem="slideItem"
          :currentSlideBgColour="getCurrentSlidebgColor"
          class="swiper-slide"
          />
      </div>
      <div
       class="swiper-button-prev"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="39.97" height="28.98" viewBox="0 0 39.97 28.98">
          <path d="M28.88,16.72A79.83,79.83,0,0,1,7.43,17l.78.75c1.59,1.48,3.2,2.94,4.91,4.28.85.67,1.73,1.31,2.63,1.91,1.28.84,3.53,1.43,3.26,3.37A1.85,1.85,0,0,1,17,29a1.75,1.75,0,0,1-.74-.21l-.67-.33C9.81,25.33,5.07,20.5.67,15.72A2,2,0,0,1,.47,13C2.86,10.58,5.3,8.24,7.76,6,9,4.8,10.24,3.66,11.5,2.54c.91-.82,1.7-2,2.9-2.41a2,2,0,0,1,2.26.72,2,2,0,0,1-.3,2.51A25.62,25.62,0,0,1,14,5.52c-.88.79-1.75,1.59-2.62,2.4q-2.59,2.4-5.14,4.93c1.16.17,2.33.32,3.5.43a71.39,71.39,0,0,0,28.36-2.63A2.1,2.1,0,0,1,39.87,12a1.85,1.85,0,0,1-.4,1.89,6.13,6.13,0,0,1-3.58,1.91,31.5,31.5,0,0,1-3.62.5c-1.37.13-2,.28-3.39.47"
            transform="translate(-0.01 -0.02)" style="fill-rule: evenodd" />
        </svg>
      </div>
      <div
       class="swiper-button-next"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29" viewBox="0 0 40 29">
          <path d="M11.12,12.43a79.83,79.83,0,0,1,21.45-.25l-.78-.75C30.2,10,28.59,8.49,26.88,7.15c-.85-.67-1.73-1.32-2.63-1.91C23,4.39,20.72,3.81,21,1.86A1.85,1.85,0,0,1,23.71.37c.23.11.45.22.66.34,5.8,3.11,10.56,8,15,12.72a2,2,0,0,1,.2,2.76q-3.6,3.57-7.3,7c-1.23,1.15-2.48,2.29-3.73,3.42-.91.81-1.7,2-2.9,2.4a2,2,0,0,1-2.26-.73,2,2,0,0,1,.3-2.51A25.65,25.65,0,0,1,26,23.65l2.62-2.41c1.73-1.61,3.44-3.25,5.14-4.9-1.16-.17-2.33-.31-3.5-.43a71.63,71.63,0,0,0-28.36,2.6A2.12,2.12,0,0,1,.13,17.2a1.83,1.83,0,0,1,.4-1.88A6.16,6.16,0,0,1,4.11,13.4a29.16,29.16,0,0,1,3.62-.49c1.37-.14,2-.28,3.39-.48"
            transform="translate(0 -0.15)" style="fill-rule: evenodd" />
        </svg>
      </div>
      <div class="products-ellipse">
      <all-products-ellipse 
        :fill="getCurrentNavBgColor"
      />
    </div>
    </div>
   
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swiper, {EffectFade} from "swiper";
import 'swiper/swiper.scss';
import 'swiper/modules/effect-fade/effect-fade.scss'


import AllProductsSlide from '../components/product/allProductsCarousel/AllProductsSlide';
import AllProductsEllipse from '../components/product/allProductsCarousel/AllProductsEllipse';
import AllProductsNavigation from '../components/product/allProductsCarousel/AllProductsNavigation';

export default {
  name: 'AllProductsCarousel',
  components: {
    AllProductsSlide,
    AllProductsEllipse,
    AllProductsNavigation
  },
  props: {
    query: {
      type: String,
      required: true
    },
    folder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      storeName: 'allProductsCarousel',
      currentSlide: 0,
      swiperInstance: {}
    }
  },
  created () {
    this.$store.dispatch('performApiQuery', { query: this.query, store: this.storeName, folder: this.folder });
  },
  mounted () {
    this.initSwiper();
  },
  computed: {
    ...mapGetters({
      slideItems: 'allProductsCarousel/getSlideItems',
    }),
    getNavTitles () {
      return this.getItems('navigationTitle');
    },
    getNavTitleColours () {
      return this.getItems('navTextColour');
    },
    getCurrentNavBgColor () {
      if (!this.slideItems.length) return '#FFF';
      return this.slideItems[this.currentSlide].navBackgroundColour;
    },
    getCurrentSlidebgColor () {
      if (!this.slideItems.length) return '#FFF';
      return this.slideItems[this.currentSlide].slideBackgroundColour;
    },
  },
  methods: {
    initSwiper() {
      const swiperContainer = this.$refs.swiper;

    new Swiper(swiperContainer, { 
      modules: [EffectFade],
      effect: 'fade',
      preventClicks: false,
      preventClicksPropagation: false, 
      speed: 1500,

      on: {
        slideChangeTransitionStart: (swiper) => {
          this.currentSlide = swiper.realIndex;

            const slides = Array.from(swiper.slides);
            slides.forEach((slide) => {
              slide.classList.remove('is-next', 'is-prev');
            });

            const currentSlideIndex = swiper.activeIndex;
            for (let i = currentSlideIndex; i < slides.length; i++) {
              slides[i].classList.add('is-next');
            }

            for (let i = 0; i < currentSlideIndex; i++) {
              slides[i].classList.add('is-prev');
            }
        }
      },

      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
       },
     });
     this.swiperInstance = this.$refs.swiper.swiper;
    },
    getItems (item) {
      if (!this.slideItems.length) return [];
      const itemsArr = [];
      for (let slideItem of this.slideItems) {
        itemsArr.push(slideItem[item]);
      }
      return itemsArr;
    },
    navigateToSlide (event) {
      this.swiperInstance.slideTo(event);
    }
  }
}
</script>

<style lang="scss" scoped>

  .swiper-wrapper {
    position: relative;
    padding-top: 100px;
  }


.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  z-index: 2;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.63, -0.01, 0.26, 1.49);

  &:hover {
    transform: scale(0.9) translateY(-50%);
  }

  svg {
    fill: #fff;
  }

}
.swiper-button-next {
  right: 10px;
  @media only screen and (min-width: 768px) {
    right: 30px;
  }
}
.swiper-button-prev {
  left: 10px;
  @media only screen and (min-width: 768px) {
    left: 30px;
  }
}

.swiper-button-disabled {
  opacity: 0.25; 
}

.all-products-page-root {
  position: relative;
}

.navigation {
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
}
</style>