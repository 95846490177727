<template>
    <div class="horoscope-message-root">
        <p class="horoscope-message">
            {{ message }}
        </p>
    </div>
</template>

<script>
export default {
    name:"HoroscopeMessage",
    props: {
        message: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.horoscope-message {
    text-align: center;
}
</style>