<template>
  <div 
    class="subscription-button"
    data-cy-form="cta"
  >
    <span class="button-text">{{ buttonText }}</span>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="button-icon" width="38"
      height="13" viewBox="0 0 38 13" fill="none">
      <rect width="38" height="13" fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlink:href="#image0_842_748" transform="matrix(0.00666667 0 0 0.0194872 0 -0.0358974)" />
        </pattern>
        <image id="image0_842_748" width="150" height="55"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAA3CAYAAADuQbOpAAAACXBIWXMAAC4jAAAuIwF4pT92AAADd0lEQVR4nO2dy3XaQBSGP+tkbzowZzZahg6MKzAdBFcQOrDTgV2BcQUhHcgVGJbazIEOcAXOYsY2IYCQ5kpYo/vt0GO45/Azc18jnb29vaHEg0lNHxgBPX9oDcxsbpdN2nGmwooHk5o74HbP6SdgYnO7bsIWFVYkmNRMgR8Fly2Asc3tvG57krq/QKkfk5oxxaIC+A5kJjWjei1SYcXCpMS158Bvv2zWhi6FEWBSU/VHfAZGdfhdOmO1HJOaYcDtl8DcpGYgY80nKqz2swy8/4Ia/C4VVsvx+alV4DDifpcKKw6mQuPcmtTMTGp6xZceRoUVB/e4HJUE17ilsR8yiEaFkeBnmQyXq5LgFRhWTabqjBUJPmUwxJVuJDgHXnzytTQ6Y0WIF8Oj4JAPNrdlkrAqrFjxuakMN/NIUKqIrcKKmBr8rgXO7yoUlwqrAxzZ+XAsK1wZ6KBTr8LqCMJ+V2HEqFFhR7C5nQJXOFGEUhgxqrA6hM1tBgyQS6Y+7hOXCqtj+NriEPgjNOROcf3nY/lIYgT0Nw7PcU3578ZlQkYd0/bR37JFmizw/h5uFthFn/2295CL1r4CN365BbaE5avbE+RyH0q3+BDXh7CEQ1Klu1zZ3GYJlGrGV5QiJvDpvN+dzg4lMq4BEu+sX5zYGCUyEvZHNIpSmYSNNIKiCLACSHy9RyLNryjg++/fnffZ6exQImKB67//ENYEnbWUMBZs7KpO4J9+6dD9aUo3ecC10SzfD2yXdHrAGFcrvGzYuFhZsD9Amh84VycDfL4pkFdcu/J0+0Rho5/fX9bfOLT9OZQlBdvEJYvem/g/kkS6Zd7UA81CEWz4O9jspx2kHUJQVIW979qP1RGaFBWosDqBoKieOHKXzjeBL1O+MCY198BPgaGebG7Hx16swooYwR67UqICFVaU+Gh3hkzKqLSoQH2s6NjYWn8yUYEKKyq8k54hs0mjsqhAl8JoEN6zECQq0BkrCnzkJyWqX6GiAs28tx7vU70IDXezq+5XBZ2x2s9YaBwxUYEKKwYkiuiiogJ13rtO0ANsD6EzVvup2q6zoiZRgQorBqrsV1gAgzrfW6jCaj8zyrWUP9jcDupuTFRhtRwvkBHFm2FecU56qcdqV0WFFQF+SRvi3j+4i2fc0jdtyiZNkEaGT5gOcQ92WwNZE++A3uYvBVZbawhbYewAAAAASUVORK5CYII=" />
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionFormButton',
  props: {
    buttonText: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.subscription-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: #EC9BAD;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
}

.button-text {
  margin-right: 20px;
}

.button-icon {
  margin-top: 3px;
}
</style>
