<template>
  <div class="product-carousel-root" ref="product-carousel-root">
    <div ref="swiper" class="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
          <div 
            v-for="(productItem, productIndex) in productItems" 
            :key="productIndex" 
            class="swiper-slide"
            >
              <product-slide
                :productData="productItem"
                :translations="translationItem"
                :slideIndex="productIndex"
                :currentSlide="swiperCounter"
                :swiperInViewPort="swiperInViewPort"
              />
          </div>
      </div> 

      <!-- If we need navigation buttons -->
      <div class="swiper-navigation">
        <div
          class="swiper-button-prev"
        ></div>
        <div 
          class="swiper-button-next"
        ></div>
      </div>
     
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductSlide from '../components/product/productVideoCarousel/ProductSlide';
import Swiper from 'swiper';
import 'swiper/swiper.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default {
  name: 'KidsProductCarousel',
  components: {
    ProductSlide
  },
  props: {
    query: {
      type: String,
      required: true
    },
    folder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      storeName: 'product',
      swiperCounter: 0,
      swiperInViewPort: false,
      applyScrollTrigger: true, 
    }
  },
  created () {
    this.$store.dispatch('performApiQuery', { query: this.query, store: this.storeName, folder: this.folder });
  },
  computed: {
    ...mapGetters({
      productItems: 'product/getProductItems',
      translationItem: 'product/getFirstTranslationItem'
    })
  },
  mounted() {
    this.initSwiper();
  },
  updated () {
    if (this.applyScrollTrigger && this.productItems.length) {
      this.initScrollTrigger();
    }
  },
  methods: {
    initSwiper() {
      new Swiper(this.$refs.swiper, {                    
      slidesPerView: 1,
      centeredSlides : true,   
  
      breakpoints: {
        1024: {
          slidesPerView: 2,
        }
      },

      on: {
        resize: () => {
          let videoHeight = document.querySelector('.product-carousel-root .product-video').clientHeight;
          let swiperNav = document.querySelector('.product-carousel-root .swiper-navigation');
          swiperNav.style.setProperty("top", videoHeight + 'px');
        },
        slideChangeTransitionStart: (swiper) => {
          this.swiperCounter = swiper.realIndex;
        }
      },

      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
       },
      });
    },
    initScrollTrigger () {
      gsap.registerPlugin(ScrollTrigger);
      ScrollTrigger.create({
        trigger: this.$refs['product-carousel-root'],
        start: 'top bottom',
        end: 'bottom',
        onToggle: ({isActive}) => {this.swiperInViewPort = isActive;},
      });
      this.applyScrollTrigger = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .swiper-navigation {
      justify-content: space-between;
      display: flex;
      margin: 17px auto 0 auto;
      position: absolute;
      left: 20px;
      right: 20px;

      @media only screen and (min-width: 1023px) {
        top: 500px;
        width: 600px;
        left: 50%;
        z-index: 1; 
        margin: 15px 0 0 -300px; 
      }
  }
  .swiper-button-next, .swiper-button-prev {
         display: block;
         width: 43px;
         height: 45px;
         position: relative;
         z-index: 2;
         cursor: pointer;
    }
 
    .swiper-button-next {
        background-image: url('data-url:../../resources/images/kids/icons/arrow-next.svg');
     }
 
     .swiper-button-prev {
        background-image: url('data-url:../../resources/images/kids/icons/arrow-prev.svg');
     }
</style>
