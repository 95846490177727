<template>
  <div class="nutrition-container">
    <table class="nutrition-table">
      <thead>
        <tr>
          <th></th>
          <th>{{ nutritionServingSize }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(nutritionItem, nutritionIndex) in nutritionItems" :key="nutritionIndex">
          <th :class="{'sub-heading': isIndent(nutritionItem)}">
            {{ getTableHeading(nutritionItem) }}
          </th>
          <td>
            {{ getTableData(nutritionItem) }} 
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useIsIndent, useGetTableHeading, useGetTableData } from '../../../composables/useProductNutrition';

export default {
  name: 'ProductTileNutrition',
  setup () {
    const isIndent = useIsIndent;
    const getTableHeading = useGetTableHeading;
    const getTableData = useGetTableData;
    return { isIndent, getTableHeading, getTableData };
  },
  props: {
    nutritionItems: {
      type: Array,
      required: true
    },
    nutritionServingSize: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.nutrition-container {
    max-width: 600px;
    margin: 0 auto; 
    @media only screen and (max-width: 1023px) {
      padding: 0 20px;
    }
}
.nutrition-table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    margin: 0 0 33.75px;
    color: white;
    border-collapse: collapse;
    border-spacing: 0;
    overflow-x: auto;
  th, td {
    border-bottom: 1px solid white;
    padding: 0.45em 0;  
  }

  .sub-heading {
    padding-left: 3.375rem;
  }
}

</style>