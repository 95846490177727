<template>
  <div class="product-description-root">
    <div
    class="product-description"
      v-html="descriptionHtml"
    />
  </div>
</template>

<script>
export default {
  name: 'ProductTileDescription',
  props: {
    descriptionHtml: { 
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.product-description-root {
  max-width: 600px;
  margin: 0 auto;

  .product-description:deep p {
    font-family:  Innocent Light, sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    padding: 0 15px; 
    color: white; 
  }   


  .product-description:deep a {
    color: #ffffff;
    line-height: 1.75rem;
    text-decoration: underline;
  }
}
</style>