<template>
  <div 
    class="product-slide-root"
    :class="{ 'not-active-slide': !isCurrentSlide, 'active-slide': isCurrentSlide }"
  >
    <div class="product-video">
      <product-video
        :webmVideo="getWebmVideo"
        :mp4Video="getMp4Video"
        :isCurrentSlide="isCurrentSlide"
        :swiperInViewPort="swiperInViewPort"
        />
    </div>
    <div class="product-title">
      <product-slide-title
        :productTitle="productData.productTitle"
      />
    </div>
    <div class="product-nutrition" v-if="showNutrition">
      <product-nutrition
        :nutritionItems="productData.nutritionItems"
        :nutritionServingSize="productData.nutritionServingSize"
      />
    </div>
    <div class="product-description">
      <product-slide-description
        :descriptionHtml="currentDescription"
      />
    </div>
    <div class="product-tabs">
        <product-slide-tab
        tabName="about"
        :translatedTitle="translations.aboutTranslation"
        :activeTab="currentTab"
        @update-tab="updateTab"
      />

      <product-slide-tab
        tabName="ingredients"
        :translatedTitle="translations.ingredientsTranslation"
        :activeTab="currentTab"
        @update-tab="updateTab"
      />
      <product-slide-tab
        tabName="nutrition"
        :translatedTitle="translations.nutritionTranslation"
        :activeTab="currentTab"
        @update-tab="updateTab"
      />
      <product-slide-tab
        tabName="sustainability"
        :translatedTitle="translations.sustainabilityTranslation"
        :activeTab="currentTab"
        @update-tab="updateTab"
      />
    </div>
  </div>
</template>

<script>
import ProductVideo from './ProductVideo';
import ProductSlideTitle from './ProductSlideTitle';
import ProductSlideDescription from './ProductSlideDescription';
import ProductSlideTab from './ProductSlideTab';
import ProductNutrition from './ProductNutrition';

export default {
  name: 'ProductSlide',
  components: {
    ProductVideo,
    ProductSlideTitle,
    ProductSlideDescription,
    ProductSlideTab,
    ProductNutrition
  },
  props: {
    productData: {
      type: Object,
      required: true,
      default: {}
    },
    translations: {
      type: Object,
      required: true,
      default: {}
    },
    slideIndex: {
      type: Number,
      required: true
    },
    currentSlide: {
      type: Number, 
      required: true
    },
    swiperInViewPort: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      currentTab: 'about'
    }
  },
  computed: {
    currentDescription () {
      const descriptions = {
        'about': this.productData.aboutText.html,
        'ingredients': this.productData.ingredientsDescription.html,
        'nutrition': this.productData.nutritionDescription.html,
        'sustainability': this.productData.sustainabilityDescription.html
      }
      return descriptions[this.currentTab];
    },
    showNutrition () {
      return this.currentTab === 'nutrition';
    },
    getWebmVideo () {
      return !!this.productData.webmVideoFile ? this.productData.webmVideoFile : {};
    },
    getMp4Video () {
      return !!this.productData.mp4VideoFile ? this.productData.mp4VideoFile : {};
    },
    isCurrentSlide () {
      return this.slideIndex === this.currentSlide;
    }
  },
  methods: {
    updateTab (event) {
      this.currentTab = event;
    }
  }
}
</script>

<style lang="scss" scoped>

.product-slide-root {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;

  @media only screen and (max-width: 1023px) {
    padding: 0 20px;
  }
}

.product-title {
  margin-bottom: 37px;
  padding: 0 50px;
}

.product-slide-root {
  &.not-active-slide {
    .product-title,
    .product-description,
    .product-tabs,
    .product-nutrition {
      display: none; 
    } 
  }

  &.active-slide {
    .product-title,
    .product-description,
    .product-nutrition { 
      display: block; 
    }

    .product-tabs {
      display: flex; 
      justify-content: center; 
      align-items: center; 
      @media only screen and (max-width: 1023px) {
        flex-wrap: wrap;
      }
    }

    .product-description {
      margin-bottom: 60px;
    }
  }
}
</style> 