import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../utils/svgHelper';

const buttonOlympicsSvg = () => {
  const buttonOlympics = SVG();

  buttonOlympics.svg('<path xmlns="http://www.w3.org/2000/svg" stroke="#D55183" d="M112.99 2H275.64C290.21 2 306.39 8.28 308.82 31.81C310.76 50.64 291.83 61.62 275.64 63.19C259.46 64.76 199.57 63.19 199.57 63.19H54.6899C32.0299 63.19 20.4399 57.97 13.4199 52.21C6.39992 46.45 -1.56008 31.12 3.70992 19.26C8.97992 7.39 22.0399 2 32.0299 2H112.99Z" stroke-width="4"/>');
  buttonOlympics.width(311).height(66).viewbox(0, 0, 311, 66);
  buttonOlympics.addClass('button-background');


  return svgHelper(buttonOlympics);
}

export default buttonOlympicsSvg;

