<template>
  <div class="product-icon" :class="getPositionClass">
    <img
      v-if="hasIcon"
      :src="iconImagePath"
      :alt="iconImageAltText"
      loading="lazy"
    />
    <p class="product-icon-title">
      {{ iconTitle }}
    </p>
    <svg
      v-if="useFirstSvg"
      class="icon-svg-arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="69.564"
      height="18.446"
    >
      <g
        :stroke="arrowsColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      >
        <path
          class="icon-svg-arrow-line"
          d="M1.5 4.553c.54 1.1 2.129 1.941 2.957 2.6.7.564 1.419 1.1 2.126 1.657 1.015.8 2.1 1.3 3.089 2.165a12.786 12.786 0 003.387 2.172c1.373.557 2.759.553 4.124 1.154a24.288 24.288 0 007.408 1.721 29 29 0 013.926.763 9.487 9.487 0 002.945.08 52.857 52.857 0 005.92-.792c1.931-.308 3.88-.548 5.819-.859a18.386 18.386 0 005.458-1.714c1.857-.942 3.8-1.51 5.65-2.448s3.675-1.882 5.521-2.808c1.265-.635 2.293-1.613 3.563-2.238a7.515 7.515 0 001.925-1.068 4.2 4.2 0 011.114-.893"
        ></path>
        <path
          class="icon-svg-arrow-head"
          d="M54.6 1.5a24.538 24.538 0 003.864.361 35.306 35.306 0 013.762.735c.92.194 1.877.207 2.812.341.477.069.922.152 1.412.177.453.023 1.3-.133 1.612.176a16.355 16.355 0 01-.862 2.04c-.22.673-.189 1.341-.449 2.017-.4 1.048-.8 2.1-1.094 3.161a23.458 23.458 0 00-.8 2.369 3.572 3.572 0 01-.316 1.248 3.492 3.492 0 00-.363.756c-.07.29.019.544-.1.839"
        ></path>
      </g>
    </svg>
    <svg
      v-if="useSecondSvg"
      class="icon-svg-arrow icon-second-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="89.77"
      height="26.522"
    >
      <g
        :stroke="arrowsColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      >
        <path
          class="icon-svg-arrow-line"
          d="M1.5 13.437c.416.714 1.62 1.292 2.258 1.816.855.7 1.618 1.5 2.461 2.194a22.262 22.262 0 005.221 3.344 19.386 19.386 0 012.36 1.341 17.325 17.325 0 003.147 1.393 19.932 19.932 0 005.431 1.37c.823.061 1.627-.1 2.451-.048a8.985 8.985 0 002.5.008 23.092 23.092 0 004.786-1.247c1.921-.886 3.89-1.645 5.869-2.381a13.209 13.209 0 003.647-2.659 45.5 45.5 0 005.964-5.547 4.151 4.151 0 001.17-2.121 14.49 14.49 0 00.6-4.269 4.858 4.858 0 00-3.223-4.311 14.415 14.415 0 00-4.34-.815 4.829 4.829 0 00-4.078 1.69c-1.413 1.638-3.3 3.847-2.74 6.143a11.709 11.709 0 001.684 3.062 21.67 21.67 0 001.446 2.6 21.3 21.3 0 004.369 4.059 30.073 30.073 0 002.774 1.826c1.244.733 2.688.934 3.932 1.683a30.3 30.3 0 004.93 2.052 9.586 9.586 0 003.143.254c.471.03.943.117 1.416.141 1.019.053 1.972-.284 2.991-.232a12.816 12.816 0 003.714-.128 34.384 34.384 0 003.725-1.076 45.314 45.314 0 008.653-4.292 37.3 37.3 0 005.173-3.212q2.343-1.974 4.684-3.951"
        />
        <path
          class="icon-svg-arrow-head"
          d="M77.731 9.268a31.031 31.031 0 015.486 1.37 17.521 17.521 0 012.454.631c.667.3 1.183.814 1.947.856l.652.28c-.692.766-.529 2.551-.749 3.55-.256 1.159-.426 2.339-.687 3.487-.082.365-.287.711-.351 1.078-.068.4.05.816-.008 1.208a8.04 8.04 0 01-.442 1.139 7.556 7.556 0 00-.141 1.494"
        />
      </g>
    </svg>
    <svg
      v-if="useThirdSvg"
      class="icon-svg-arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="58.054"
      height="18.441"
    >
      <g
        :stroke="arrowsColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      >
        <path
          class="icon-svg-arrow-line"
          d="M1.5 8.746c9.083.609 18.032 1.271 27.082 1.014 1.241-.035 2.451.219 3.686.234 1.064.011 2.028-.282 3.1-.244 1.8.064 3.4-.193 5.191-.059 2.216.164 4.257-.141 6.479.016a26.351 26.351 0 002.833.211c.783-.031 5.264-.582 6.043-.456"
        />
        <path
          class="icon-svg-arrow-head"
          d="M46.153 16.933c.913.1 2-.862 2.769-1.325.946-.57 1.875-1.242 2.773-1.89a20.182 20.182 0 002.012-1.74c.378-.356.634-.836 1-1.185.413-.394 1.352-.707 1.653-1.187.768-1.222-.943-1.776-1.662-2.378-.931-.781-1.837-1.6-2.772-2.367-.876-.718-2.036-1.186-2.859-1.927A4.168 4.168 0 0046.848 1.5"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'ProductTileIcon',
  props: {
    iconPosition: {
      type: Number,
      required: true
    },
    iconImagePath: {
      type: String,
      required: true
    },
    iconImageAltText: {
      type: String,
      required: true
    },
    iconTitle: {
      tyoe: String,
      required: true
    },
    hasIcon: {
      type: Boolean,
      required: true
    },
    arrowsColor: {
      type: String,
      required: true
    }
  },
  computed: {
    getPositionClass () {
      return `icon-position-${this.iconPosition}`;
    },
    useFirstSvg () {
      return [0,1,2,5,6].includes(this.iconPosition);
    },
    useSecondSvg () {
      return this.iconPosition === 3;
    },
    useThirdSvg () {
      return this.iconPosition === 4;
    }
  }
}
</script>
<style lang="scss" scoped>
.product-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 120px;
  @media only screen and (max-width: 768px) {
    max-width: 100px;
  }
  img {
    max-width: 75px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
    margin: 0 auto;
    display: block;
  }
  .icon-svg-arrow {
    position: absolute;
    transform-origin: top left;
    bottom: 0;
    left: 100%;
    &.icon-second-svg {
      left: 0;
    }
  }
  .product-icon-title {
    font-size: 1rem;
    line-height: 1.125rem;
    margin-bottom: 0;
    color: #fff;
    text-align: center;
    @media only screen and (max-width: 768px) {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }
  }
  &.icon-position-0 {
    transform: translate(calc(-50% - 180px), calc(-50% - 270px));
    @media only screen and (max-width: 768px) {
      transform: translate(calc(-50% - 120px), calc(-50% - 170px));
    }
    .icon-svg-arrow {
      transform: scale(0.65) rotate(45deg) translate(30px, 30px);
      @media only screen and (max-width: 768px) {
        transform: scale(0.65) rotate(45deg) translate(40px, 40px);
      }
    }
  }
  &.icon-position-1 {
    transform: translate(calc(-50% + 210px), calc(-50% + 80px));
    @media only screen and (max-width: 768px) {
      transform: translate(calc(-50% + 130px), calc(-50% + 40px));
    }
    .icon-svg-arrow {
      transform: scale(0.65) rotate(180deg) translate(20px, 10px);
      @media only screen and (max-width: 768px) {
        transform: scale(0.65) rotate(180deg) translate(10px);
      }
      left: 0;
    }
  }
  &.icon-position-2 {
    transform: translate(calc(-50% - 190px), calc(-50% + 30px));
    @media only screen and (max-width: 768px) {
      transform: translate(calc(-50% - 140px), calc(-50% + 10px));
    }
    .icon-svg-arrow {
      transform: scale(0.65) rotate(-20deg) translate(20px, -30px);
      @media only screen and (max-width: 768px) {
        transform: scale(0.65) rotate(-20deg) translate(10px, 10px);
      }
    }
  }
  &.icon-position-3 {
    transform: translate(calc(-50% + 220px), calc(-50% - 90px));
    @media only screen and (max-width: 768px) {
      transform: translate(calc(-50% + 130px), calc(-50% - 50px));
    }
    .icon-svg-arrow {
      transform: scale(0.65) rotate(140deg) translateY(10px);
      @media only screen and (max-width: 768px) {
        transform: scale(0.65) rotate(140deg) translate(30px, -30px);
      }
    }
  }
  &.icon-position-4 {
    transform: translate(calc(-50% - 200px), calc(-50% - 110px));
    @media only screen and (max-width: 768px) {
      transform: translate(calc(-50% - 140px), calc(-50% - 75px));
    }
    .icon-svg-arrow {
      transform: scale(0.65) rotate(0) translate(20px, -30px);
      @media only screen and (max-width: 768px) {
        transform: scale(0.65) rotate(0) translate(20px);
      }
    }
  }
  &.icon-position-5 {
    transform: translate(calc(-50% + 150px), calc(-50% - 240px));
    @media only screen and (max-width: 768px) {
      transform: translate(calc(-50% + 100px), calc(-50% - 140px));
    }
    .icon-svg-arrow {
      transform: scale(0.65) rotate(140deg) translate(-10px, 30px);
      @media only screen and (max-width: 768px) {
        transform: scale(0.65) rotate(140deg) translate(30px, -10px);
      }
      left: 0;
    }
  }
  &.icon-position-6 {
    transform: translate(calc(-50% - 200px), calc(-50% + 170px));
    @media only screen and (max-width: 768px) {
      transform: translate(calc(-50% - 120px), calc(-50% + 60px));
    }
    .icon-svg-arrow {
      transform: scale(0.65) rotate(-20deg) translate(20px, -10px);
      @media only screen and (max-width: 768px) {
        transform: scale(0.65) rotate(-20deg) translate(10px);
      }
    }
  }
}
</style>