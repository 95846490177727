<template>
  <div class="horoscope-container">
    <horoscope-message
      :message="messageToDisplay"
      :style="{ color: fontColor }"
      :class="fontSize"
    ></horoscope-message>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import HoroscopeMessage from "../components/horoscope/HoroscopeMessage.vue";

export default {
  name: "Horoscope",
  components: { HoroscopeMessage },
  props: {
    query: {
      type: String,
      required: true,
    },
    folder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      storeName: "horoscope",
      currentDate: new Date().getTime(),
      fontSize: "",
      fontColor: "",
    };
  },
  created() {
    this.$store.dispatch("performApiQuery", {
      query: this.query,
      store: this.storeName,
      folder: this.folder,
    });
  },
  computed: {
    ...mapGetters({
      defaultMessage: "horoscope/getDefaultMessage",
      horoscopeMessages: "horoscope/getHoroscopeMessages",
    }),
    messageToDisplay() {
      if (!this.horoscopeMessages.length && !this.defaultMessage) return;

      const activeMessage = this.horoscopeMessages.find((message) =>
        this.isInDate(message.startDate, message.endDate)
      );

      if (activeMessage) {
        this.applyStyles(activeMessage);
        return activeMessage.message.plaintext;
      }

      this.applyStyles(this.defaultMessage);

      return this.defaultMessage.message.plaintext;
    },
  },
  methods: {
    isoStringToDate(date) {
      const b = date.split(/\D/);

      return new Date(
        Date.UTC(b[0], --b[1], b[2], b[3] || 0, b[4] || 0, b[5] || 0, b[6] || 0)
      );
    },
    convertDateToTime(dateStr, isStartDate) {
      const date = this.isoStringToDate(dateStr);

      if (isStartDate) {
        date.setHours(0, 0, 0, 0);
      } else {
        date.setHours(23, 59, 59);
      }
      return date.getTime();
    },
    isInDate(start, end) {
      if (start === null || end === null) return false;

      const startDate = this.convertDateToTime(start, true),
        endDate = this.convertDateToTime(end, false);

      return this.currentDate >= startDate && this.currentDate <= endDate;
    },
    applyStyles(message) {
      this.fontColor = message.fontColor;
      this.fontSize = message.fontSize;
    },
  },
};
</script>
