import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper.css";

const simpleCarousel = () => {
  Swiper.use([Navigation, Pagination]);
  const swiper = new Swiper(".c-simple-carousel", { 
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 25,
  });
};

const applyGsapToSimpleCarousel = () => {
  gsap.registerPlugin(ScrollTrigger);

  gsap.to(".c-simple-carousel > .cmp-container", { 
      xPercent: window.innerWidth > 768 ? -20 : -30,
      scrollTrigger: {
        trigger: ".c-simple-carousel > .cmp-container",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        onUpdate: (self) => {
          if (self.target){
          gsap.to(self.target, {
            xPercent: window.innerWidth > 768 ? -20 : -30,
          });
        }
        },
      },
    });
}

const initSimpleCarousel = () => {
  if (!document.querySelector('.c-simple-carousel')) return;
  simpleCarousel();
  applyGsapToSimpleCarousel();
}

const onDocumentReady = () => {
  initSimpleCarousel();
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
