import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../utils/svgHelper';

const olympicsMarkerRightMobileSvg = () => {
  const olympicsMarkerRightMobile = SVG();

  olympicsMarkerRightMobile.svg('<path d="M12.5366 2.05527C10.1188 1.76679 6.27233 2.63911 2 3.9373" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/><path d="M16.4777 16.246C14.0599 15.9575 10.4057 16.0194 6.0166 16.8436" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/><path d="M11.9422 31C9.52446 30.7115 6.61214 29.063 2.55273 29.3446" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>');
  olympicsMarkerRightMobile.viewbox(0, 0, 19, 33).addClass('title-marker-right title-marker-mobile'); 

  return svgHelper(olympicsMarkerRightMobile);
}

export default olympicsMarkerRightMobileSvg;