import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../utils/svgHelper';

const olympicsMarkerRightSvg = () => {
  const olympicsMarkerRight = SVG();

  olympicsMarkerRight.svg('<path d="M18.03 2.08C14.51 1.66 8.91 2.93 2.69 4.82" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/><path d="M23.77 22.74C20.25 22.32 14.93 22.41 8.53998 23.61" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/><path d="M17.17 44.22C13.65 43.8 9.41 41.4 3.5 41.81" stroke="#DE7B98" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>');
  olympicsMarkerRight.viewbox(0, 0, 26, 47).addClass('title-marker-right'); 


  return svgHelper(olympicsMarkerRight); 
}

export default olympicsMarkerRightSvg;