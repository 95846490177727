<template>
  <div 
    class="hero-container" 
    v-show="showHero" 
    :data-cy-hero="seasonType"
  >
    <h2 class="hero-title">
      {{ seasonTitle }}
    </h2>
    <p class="hero-description">
      {{ seasonDescription }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ActivityHero',
  props: {
    seasonType: {
      type: String,
      required: true
    },
    seasonTitle: {
      type: String,
      required: true 
    },
    seasonDescription: {
      type: String,
      required: true
    },
    sesaonActive: {
      type: String,
      required: true
    }
  },
  computed: {
    showHero () {
      return this.seasonType === this.sesaonActive
    }
  }
}
</script>

<style lang="scss" scoped>

.hero-container {
  text-align: center;
  margin-bottom: 70px;
}
.hero-title {
  margin-bottom: 15px;
  font-size: 48px;
  line-height: 1.2;
}
.hero-description {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 0;
}
</style>