<template>
  <div class="product-button-root">
    <a 
      :href="shortCtaPath"
      @mouseover="isHover = true" 
      @mouseleave="isHover = false"
      class="button"
    >
      <svg 
        :style="getButtonBgStyle"
        class="button-background" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 165 43.57" 
        preserveAspectRatio="none"
      >
        <path d="M132.92 1.5q5.36 0 11.41.11a19.5 19.5 0 0119.17 19.5h0v1.18a19.5 19.5 0 01-19 19.5q-10.47.27-16.08.28h-.63c-2.09 0-3.62 0-4.57-.1-3.81-.26-11.8-.35-21.26-.36h-2.68c-12.09 0-26.08.14-36.65.24-6.32.06-11.43.11-14.17.12h-.85q-7.35 0-27-.39A19.5 19.5 0 011.5 22.08 19.84 19.84 0 017.1 8.13a19.06 19.06 0 0113.64-5.72c12.69.08 22.1 0 28.37-.25 5.81-.23 14 0 23 .16 12.66.29 26.71.61 37.28-.16q8.66-.65 21.91-.66h1.63z"
              stroke-width="3">
        </path>
      </svg>
      <svg 
        :style="getButtonIconStyle"
        class="button-icon" 
        xmlns="http://www.w3.org/2000/svg" 
        width="34.82" 
        height="13.93" 
        viewBox="0 0 34.82 13.93"
      >
        <path d="M23.3,8.59c-2.82,0-5.08-.06-6.75-.17-3.24-.22-8.28-.22-15,0A1.54,1.54,0,0,1,0,7,1.5,1.5,0,0,1,1.45,5.42c6.82-.22,12-.22,15.3,0s8.8.22,16.53,0a1.5,1.5,0,1,1,.08,3Q27.51,8.58,23.3,8.59Z">
        </path>
        <path d="M28,13.93a1.45,1.45,0,0,1-.92-.32,1.5,1.5,0,0,1-.26-2.11c.67-.84,1.35-1.62,2-2.32.49-.51,1.24-1.26,2.26-2.23-.93-.86-1.69-1.59-2.27-2.2a13.7,13.7,0,0,0-2.39-2A1.5,1.5,0,1,1,28.12.25,17.14,17.14,0,0,1,31,2.68c.76.8,1.86,1.85,3.29,3.12a1.53,1.53,0,0,1,.5,1.1A1.5,1.5,0,0,1,34.35,8c-1.58,1.5-2.7,2.59-3.33,3.25a26.2,26.2,0,0,0-1.82,2.1A1.5,1.5,0,0,1,28,13.93Z">
        </path>
      </svg>
      <span
        :style="getButtonTextColour" 
        class="button-text"
      >{{ ctaText }}</span>
    </a>
  </div>
</template>

<script>
import { usePathShorten } from '../../../composables/usePathShorten.js';

export default {
  name: 'AllProductsButton',
  setup () {
    const pathShorten = usePathShorten;
    return { pathShorten };
  },
  props: {
    ctaText: {
      type: String,
      required: true
    },
    ctaPath: {
      type: String,
      required: true
    },
    ctaAppearance: {
      type: String,
      required: true
    },
    buttonColour: {
      type: String,
      required: true
    },
    buttonTextColour: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    shortCtaPath () {
      return this.pathShorten(this.ctaPath);
    },
    isButtonFilled () {
      return this.ctaAppearance === 'fill';
    },
    getButtonBgStyle () {
      if (this.isButtonFilled) {
        return this.isHover ? `fill: transparent; stroke: ${this.buttonColour};` : `fill: ${this.buttonColour}; stroke: ${this.buttonColour};`;
      }
      return this.isHover ? `fill: ${this.buttonColour}; stroke: ${this.buttonColour};` : `fill: transparent; stroke: ${this.buttonColour};`;
    },
    getButtonIconStyle () {
      return this.isHover ? `fill: #FFFFFF;` : `fill: ${this.buttonTextColour}`;
    },
    getButtonTextColour () {
      return this.isHover ? `color: #FFFFFF;` : `color: ${this.buttonTextColour}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  min-width: 165px;
  height: 44px;
  padding: 0;
  position: relative;
  display: inline-block;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  vertical-align: middle;
  white-space: nowrap;
  padding-right: 55px;

  .button-background {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    transition: fill .5s ease, stroke .5s ease;
  }

  .button-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: fill .5s ease;
  }

  .button-text {
    display: block;
    padding-left: 13px;
    font-size: 19px;
    font-weight: 700;
    line-height: 46px;
    transition: color .5s ease;
    position: relative;
    z-index: 2;
  }
}

</style>