import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../utils/svgHelper';

const stepByStepBlogSvg = () => {
  const stepByStepBlog = SVG();

  stepByStepBlog.svg('<path xmlns="http://www.w3.org/2000/svg" d="M153.189 478.074C20.5418 434.2 -27.739 279.833 16.9029 144.862C61.5447 9.8916 172.55 -25.3835 305.197 18.4898C437.844 62.3631 525.974 236.497 481.332 371.467C424.83 503.158 285.836 521.947 153.189 478.074Z"/>');
  stepByStepBlog.width(494).height(500).viewbox(0, 0, 494, 500);


  return svgHelper(stepByStepBlog);
}

export default stepByStepBlogSvg;
