import { createApp } from "vue";
import { plugin, defaultConfig } from '@formkit/vue'
import globalStore from "./store/globalStore";

// import Root & Pages Only
import Root from './Root.vue';
import KidsActivityAllPage from './pages/KidsActivityAllPage.vue';
import KidsActivitySeasonPage from './pages/KidsActivitySeasonPage.vue';
import KidsProductCarousel from './pages/KidsProductCarousel.vue';
import KidsActivityStepPage from './pages/KidsActivityStepPage.vue';
import Product from './pages/Product.vue';
import AllProductsCarousel from './pages/AllProductsCarousel.vue';
import HistoryTimeline from './pages/HistoryTimeline.vue';
import Horoscope from './pages/Horoscope.vue';
import SubscriptionForm from './pages/SubscriptionForm.vue';
import SubscriptionFormPage from './pages/SubscriptionFormPage.vue';


const getRandomId = () => {
  let result = "innocent-vue-";
  const randomId = Math.floor(Math.random() * 100000000).toString();
  return result.concat(randomId);
}

const createMountPoint = (selector, selectorId) => {
  selector.setAttribute("id", selectorId);
}

const getVueMountPoint = (mountPoint) => {
  return '#'.concat(mountPoint);
}

const findEmbeds = () => {
  const mountSelector = "[data-id='innocent-vue']";
  return document.querySelectorAll(mountSelector);
}

const getStartPage = (foundEmbed) => {
  const pageAttr = 'page';
  if (!foundEmbed) return false;

  const pageAttrCheck = foundEmbed.dataset.hasOwnProperty(pageAttr);
  if (!pageAttrCheck) return false;

  return foundEmbed.dataset[pageAttr];
}

const getQuery = (foundEmbed) => {
  const queryAttr = 'query';
  const queryAttrCheck = foundEmbed.dataset.hasOwnProperty(queryAttr);
  if (!queryAttrCheck) return '';
  return foundEmbed.dataset[queryAttr];
}

const getFolder = (foundEmbed) => {
  const folderAttr = 'folder';
  const folderAttrCheck = foundEmbed.dataset.hasOwnProperty(folderAttr);
  if (!folderAttrCheck) return '';
  return foundEmbed.dataset[folderAttr];
}

const initVueOnEmbed = (foundEmbed) => {
  const startPage = getStartPage(foundEmbed);
  if (!startPage) return;

  const query = getQuery(foundEmbed);
  const folder = getFolder(foundEmbed);

  const embedMountId = getRandomId();
  createMountPoint(foundEmbed, embedMountId);

  const vueMountPoint = getVueMountPoint(embedMountId);
  const app = createApp(Root, { startPage, query, folder });

  // register here Pages (only) as Global components to create starting points  e.g Activity Page, Quiz Page etc
  app.component('KidsActivityAllPage', KidsActivityAllPage);
  app.component('KidsActivitySeasonPage', KidsActivitySeasonPage);
  app.component('KidsProductCarousel', KidsProductCarousel);
  app.component('KidsActivityStepPage', KidsActivityStepPage);
  app.component('Product', Product);
  app.component('AllProductsCarousel', AllProductsCarousel);
  app.component('HistoryTimeline', HistoryTimeline);
  app.component('Horoscope', Horoscope); 
  app.component('SubscriptionForm', SubscriptionForm); 
  app.component('SubscriptionFormPage', SubscriptionFormPage); 



  // register the global Vuex Store & formkit for use for all components
  app.config.devtools = true;
  app.use(globalStore);
  app.use(plugin, defaultConfig);

  // mount the application
  app.mount(vueMountPoint);
}

const initAllEmbeds = () => {
  const foundEmbeds = findEmbeds();
  foundEmbeds.forEach((foundEmbed) => {
    initVueOnEmbed(foundEmbed);
  })
}

initAllEmbeds();