const svgHelper = (svg) => {
  
  const addToId = (selector) => {
    let svgClone = svg.clone();
    svgClone.addTo(selector);
  }

  const addToClass = (selector) => {
    const classItems = document.querySelectorAll(selector);
    classItems.forEach((classItem) => {
      let svgClone = svg.clone();
      svgClone.addTo(classItem);
    });
  }

  return Object.assign({}, svg, { addToId, addToClass });
}

export default svgHelper;