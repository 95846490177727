import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const landscapeBottomSvg = () => {
  const landscapeBottom = SVG();
  landscapeBottom.width(1440)
    .height(355)
    .viewbox('0 0 1440 355')
    .addClass('landscape-bottom')
    .fill('none');
  
  const commonCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };

  const greenStrokeCss = {
    'stroke': '#222721',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };

  const groupOne = landscapeBottom.group();
  groupOne.css(commonCss);
  groupOne.path('M819.28 125.3C819.28 125.3 792.43 187.16 798.28 234.44C804.13 281.72 814.08 327.53 878.81 410C878.81 410 882.42 385.141 886.13 367.901C898.83 308.901 878.13 247.531 854.38 193.901C847.171 177.256 838.745 161.166 829.17 145.76C825.331 139.216 822.023 132.374 819.28 125.3V125.3Z').fill('#006571');
  groupOne.path('M819.31 130.211C819.31 130.211 828.67 247.721 855.43 329.021C882.19 410.321 878.85 410.021 878.85 410.021');
  groupOne.path('M1001.8 151.62C1001.8 151.62 953.91 199.09 941.8 245.18C929.69 291.27 921.92 337.49 951.42 438.09C951.42 438.09 964 416.341 973.85 401.711C1007.55 351.631 1011.07 286.97 1008.96 228.32C1008.44 210.189 1006.59 192.12 1003.42 174.26C1002.28 166.769 1001.74 159.198 1001.8 151.62V151.62Z').fill('#006571');
  groupOne.path('M1000 156.16C1000 156.16 965.08 268.75 959.76 354.16C954.44 439.57 951.44 438.041 951.44 438.041');
  groupOne.path('M830.17 408.24C826.74 396.16 820.99 385.24 815.78 373.57C812.758 366.959 808.87 360.778 804.22 355.19C784.3 332.19 767.54 309.64 748.56 286.12C742.99 279.97 737.43 273.811 732.13 267.271C729.94 264.591 727.13 262.04 725.13 258.98C717.534 249.782 709.02 241.382 699.72 233.91C688.8 224.63 678 216.02 666.44 207.42');
  groupOne.path('M718.18 217.401C718.18 217.401 717.4 244.81 740.84 277.68');
  groupOne.path('M792.2 275.82C792.2 275.82 787.43 303.671 807.37 359.451');
  groupOne.path('M675.75 298.12C675.75 298.12 705.55 285.91 781.09 327.44');
  groupOne.path('M696.22 270.43C696.22 270.43 702.48 283.67 733.54 305.43');
  groupOne.path('M609.1 162.43C610.77 163.76 611.62 173.23 612.34 175.68C613.747 180.502 615.454 185.231 617.45 189.84C621.45 198.93 626.73 207.55 633.84 214.53C642.46 222.99 654.64 231.97 665.64 222.12C668.476 219.435 670.917 216.361 672.89 212.99C686.48 190.99 671.89 178.99 667.25 176.49C662.61 173.99 646.07 169.16 630.84 167.09C623.469 166.188 616.193 164.629 609.1 162.43V162.43Z').fill('#A3D380');
  groupOne.path('M611.88 165.04C611.88 165.04 636.41 195.46 672.88 213.04');
  groupOne.path('M660.31 223.71C660.31 223.71 660.65 252.02 667.74 265.29C674.83 278.56 685.46 300.54 699.74 276.57C699.74 276.57 715.02 276.82 716.6 264.21C718.18 251.6 709.04 234.13 688.6 228.6C668.16 223.07 660.31 223.71 660.31 223.71Z').fill('#A3D380');
  groupOne.path('M660.31 223.711C660.31 223.711 688.03 255.62 699.71 276.62');
  groupOne.path('M723.59 146.62C720.951 151.468 717.917 156.09 714.52 160.44C708.81 167.78 686.11 198.981 699.24 212.151C712.37 225.321 717.97 220.711 717.97 220.711C717.97 220.711 732.43 229.88 738.26 211.04C744.09 192.2 739.17 171.64 732.86 160.32C726.55 149 723.59 146.62 723.59 146.62Z').fill('#A3D380');
  groupOne.path('M723.8 149.961C723.8 149.961 711.43 192.78 717.97 220.75');
  groupOne.path('M801.88 199.901C801.88 199.901 801.16 207.13 787.58 228.06C774 248.99 762.73 265.511 786.02 276.141C809.31 286.771 813.31 264.42 814.2 258.51C817.05 239.4 805.61 219.681 801.88 199.901Z').fill('#A3D380');
  groupOne.path('M791.13 278.93C791.13 278.93 794.2 232.23 802.22 205.05');
  groupOne.path('M699 390.53C699 390.53 698.4 371.861 709.06 359.831C719.72 347.801 734.39 338.15 755.18 342.16C775.97 346.17 770.73 361.55 770.73 361.55C770.73 361.55 781.73 379.92 770.73 391.38C759.73 402.84 735.56 394.06 735.56 394.06C735.56 394.06 711.4 382.14 699 390.53Z').fill('#A3D380');
  groupOne.path('M636.71 346.27C636.71 346.27 635.18 323.81 644.81 304.05C654.44 284.29 659.4 278.55 669.43 280.83C679.46 283.11 680.88 297.04 680.88 297.04C680.88 297.04 702.07 316.41 689.99 332.04C687.751 335.056 684.844 337.512 681.498 339.217C678.151 340.923 674.456 341.831 670.7 341.87C664.98 342 659.24 341.36 653.53 341.96C647.82 342.56 642.27 344.74 636.71 346.27Z').fill('#A3D380');
  groupOne.path('M636.71 346.271C636.71 346.271 657.96 315.13 680.83 297.04');
  groupOne.path('M1240.88 301.91C1240.61 295.38 1239.48 288.421 1236.7 282.451C1231.99 272.321 1220.34 269.61 1210.96 274.86C1205.16 278.12 1204.34 284.86 1205.66 290.86C1206.41 294.361 1207.84 297.678 1209.88 300.621C1214.45 307.091 1221.88 310.501 1229.24 312.771C1232.18 313.691 1236.12 315.56 1238.7 313.09C1241.28 310.62 1241.04 305.07 1240.88 301.91Z').fill('#FFE900');
  groupOne.path('M1272.81 266.42C1277.81 259.72 1287.7 255.03 1296.47 256.18C1312.76 258.31 1311.95 277.79 1305.59 288.18C1300.33 296.773 1295.83 305.807 1292.14 315.18C1292.46 314.37 1281.08 304.97 1279.84 303.67C1276.47 300.13 1272.41 296.32 1270.38 291.94C1267.04 284.68 1267.78 273.23 1272.81 266.42Z').fill('#FFE900');
  groupOne.path('M1217.82 304.2C1213.52 301.2 1208.43 298.72 1203.21 299.32C1196.56 300.09 1187.96 306.32 1186.55 313.13C1184.91 321.01 1191.26 328.48 1197.17 332.79C1203.7 337.55 1212.32 338.69 1220.34 337.61C1225.41 336.821 1230.39 335.479 1235.17 333.61C1240.29 331.72 1242.63 331.29 1239.06 326.33C1233.1 318.14 1226.2 310.06 1217.82 304.2Z').fill('#FFE900');
  groupOne.path('M1227.13 290.69C1226.25 287.36 1225.6 283.84 1226.42 280.49C1227.5 276.12 1230.95 272.7 1234.71 270.22C1240.15 266.642 1246.43 264.543 1252.93 264.13C1257.93 263.81 1263.44 264.72 1266.93 268.31C1269.75 271.18 1270.87 275.26 1271.75 279.19C1272.58 282.423 1272.97 285.753 1272.91 289.09C1272.63 294.35 1270.37 299.28 1269.09 304.39C1266.09 316.39 1269.32 327.46 1270.24 339.39C1262.81 342.47 1249.76 328.39 1245.6 323.95C1236.79 314.562 1230.44 303.134 1227.13 290.69V290.69Z').fill('#FFE900');
  groupOne.path('M1325.88 274.05C1329.88 273.68 1334.07 274.05 1337.49 276.13C1340.32 278.031 1342.59 280.646 1344.08 283.71C1346.41 287.793 1347.64 292.417 1347.63 297.12C1347.48 301.7 1345.81 306.12 1343.72 310.2C1339.93 317.64 1334.57 324.49 1327.39 328.75C1323.66 330.788 1319.74 332.463 1315.69 333.75L1289.29 343.21C1290.05 342.93 1290.81 325.01 1291.08 323.09C1291.7 318.278 1292.67 313.515 1293.97 308.84C1297.05 298.25 1299.35 287.84 1308.59 280.84C1313.61 277.016 1319.6 274.665 1325.88 274.05V274.05Z').fill('#FFE900');
  groupOne.path('M1357.47 309.151C1364.63 310.421 1371.29 316.201 1371.78 323.451C1372.12 328.621 1369.36 333.63 1365.49 337.07C1361.62 340.51 1356.75 342.61 1351.88 344.34C1342.97 347.5 1333.42 349.67 1324.08 348.25C1322.63 348.02 1320.96 347.52 1320.49 346.13C1320.31 345.318 1320.37 344.47 1320.66 343.69C1322.17 338.21 1324.1 332.92 1325.99 327.56C1327.06 324.56 1327.56 321.421 1329.71 318.951C1335.82 311.951 1348.42 307.551 1357.47 309.151Z').fill('#FFE900');
  groupOne.path('M1288.25 338.06C1301.09 329.06 1315.36 320.18 1331 320.89C1336.7 321.14 1342.71 322.97 1346.25 327.45C1350.49 332.83 1350.02 340.85 1346.66 346.82C1343.3 352.79 1337.57 357.05 1331.66 360.54C1324.2 365.033 1316.15 368.453 1307.74 370.7C1302.74 372.01 1297.51 372.85 1292.37 372.18C1285.37 371.27 1273.67 367.18 1272.63 358.9C1271.49 349.8 1281.88 342.53 1288.25 338.06Z').fill('#FFE900');
  groupOne.path('M1260.48 319.47C1260.48 312.04 1261.73 304.01 1267.01 298.77C1270.6 295.21 1275.57 293.44 1280.48 292.19C1284.97 291.05 1289.79 290.27 1294.18 291.74C1300.27 293.74 1304.34 299.95 1305.24 306.31C1306.14 312.67 1304.34 319.13 1301.63 324.95C1295.2 338.74 1283.97 349.61 1272.99 360.13C1270.6 362.43 1264.2 344.04 1264.04 343.52C1261.7 335.718 1260.5 327.617 1260.48 319.47V319.47Z').fill('#FFE900');
  groupOne.path('M1265.98 316.681C1263.4 311.441 1259.98 306.15 1254.71 303.68C1249.44 301.21 1243.06 302.12 1237.43 303.85C1232.52 305.36 1227.59 307.62 1224.43 311.66C1218.62 319.07 1220.76 330.5 1226.99 337.55C1233.22 344.6 1242.5 348.13 1251.6 350.55C1257.26 352.04 1262.97 353.551 1268.67 354.931C1273.67 356.09 1275.3 354.21 1275.14 349.04C1274.81 338.09 1270.78 326.41 1265.98 316.681Z').fill('#FFE900');
  groupOne.path('M1248.56 342.14C1241.13 339.53 1233.56 337.14 1225.74 336.2C1217.92 335.26 1209.74 336 1202.65 339.41C1199.25 341.05 1195.96 343.5 1194.65 347.06C1193.47 350.35 1194.2 353.98 1195.02 357.38C1195.9 361.05 1196.93 364.82 1199.34 367.71C1203.89 373.14 1211.88 373.91 1218.95 373.58C1232.4 372.944 1245.62 369.839 1257.95 364.42C1262.28 362.52 1267.83 359.92 1270.16 355.57C1264.05 349.544 1256.67 344.956 1248.56 342.14V342.14Z').fill('#FFE900');
  groupOne.path('M1281.39 358.18C1281.72 359.409 1281.72 360.702 1281.39 361.93C1281 362.975 1280.37 363.909 1279.54 364.653C1278.71 365.397 1277.71 365.928 1276.63 366.2C1274.46 366.724 1272.2 366.768 1270.01 366.33C1264.09 365.434 1258.42 363.297 1253.38 360.06C1252.64 359.653 1252 359.082 1251.51 358.39C1250.41 356.6 1251.41 354.311 1252.4 352.461C1252.76 351.686 1253.28 351.003 1253.94 350.461C1255.03 349.86 1256.3 349.648 1257.53 349.86C1262.5 350.251 1267.4 351.283 1272.1 352.93C1276.07 354.15 1280.17 352.99 1281.39 358.18Z').fill('#222721');
  groupOne.path('M1323.72 336.9C1310.96 348.368 1295.31 356.134 1278.46 359.36');
  groupOne.path('M1283.13 304.17C1287.04 320.958 1284.86 338.587 1276.99 353.92');
  groupOne.path('M1248.28 319.9C1249 326.9 1252.22 333.52 1256.54 339.1C1260.86 344.68 1266.29 349.32 1271.88 353.62');
  groupOne.path('M1221.16 354.49C1234.75 357.878 1248.96 357.971 1262.6 354.76');

  const flowerBottonGreenGroup = landscapeBottom.group();
  flowerBottonGreenGroup.addClass('flower-bottom-green');
  flowerBottonGreenGroup.css(commonCss);
  flowerBottonGreenGroup.path('M1428.19 97.8605C1428.19 97.8605 1422.19 109.8 1407.72 118.97C1393.25 128.14 1337.94 150.88 1335.59 194.97C1335.59 194.97 1335.59 209.91 1356.53 202.12C1377.47 194.33 1409.22 178.63 1417.53 145.51C1425.84 112.39 1428.19 97.8605 1428.19 97.8605Z').fill('#509689');
  flowerBottonGreenGroup.path('M1426.09 103.12C1426.09 103.12 1404.26 145.95 1373.98 171.37C1373.98 171.37 1356.87 187.11 1338.09 198.22');
  flowerBottonGreenGroup.path('M1243.75 229.021C1243.75 229.021 1273.03 286.311 1319.75 290.651C1366.47 294.991 1365.88 283.651 1365.88 283.651C1365.88 283.651 1366.76 263.871 1341.66 252.651C1316.56 241.431 1290.74 240.4 1281.75 239.24C1272.76 238.08 1244.43 229.621 1243.75 229.021Z').fill('#509689');
  flowerBottonGreenGroup.path('M1245.51 231.1C1245.51 231.1 1292.64 266.231 1329.12 273.021C1365.6 279.811 1365.88 283.62 1365.88 283.62');
  flowerBottonGreenGroup.path('M1336.32 123.04C1332.58 141.713 1331.35 160.802 1332.66 179.8C1334.57 208.98 1347.82 236.52 1358.93 263.18C1369.22 287.88 1402.64 339.38 1449.52 331.26');
  flowerBottonGreenGroup.path('M1236.77 115.51C1236.77 115.51 1241.89 153.86 1262.84 176.25C1283.79 198.64 1313.84 205.021 1332.47 199.331C1332.47 199.331 1340.19 185.331 1322.4 170.331C1304.61 155.331 1283.89 146.87 1269.16 141.75C1254.43 136.63 1239.82 121.62 1236.77 115.51Z').fill('#509689');
  flowerBottonGreenGroup.path('M1237.98 118.62C1237.98 118.62 1260.78 166.92 1301.11 177.84C1313.67 181.328 1324.7 188.9 1332.47 199.36');
  flowerBottonGreenGroup.path('M1370.25 1.98047C1369.55 8.08708 1368.21 14.1033 1366.25 19.9305C1362.9 30.0605 1355.96 36.9305 1349.73 45.1305C1344.78 51.8016 1340.54 58.9664 1337.07 66.5105C1329.69 82.2205 1323.82 102.64 1332.7 118.98C1333.73 120.874 1334.94 122.662 1336.31 124.32C1336.31 124.32 1354.62 122.781 1367.15 101.711C1379.68 80.6405 1387.58 49.4705 1370.25 1.98047Z').fill('#509689');
  flowerBottonGreenGroup.path('M1370.59 6.08051C1370.59 6.08051 1374.88 34.6205 1359.88 64.5005C1344.88 94.3805 1334.48 117.8 1336.3 124.32');

  const groupTwo = landscapeBottom.group();
  groupTwo.css(commonCss);
  groupTwo.path('M238.88 422.291C250.27 417.001 260.13 409.6 270.88 402.62C276.935 398.597 282.426 393.784 287.21 388.31C306.78 365.01 326.41 344.91 346.64 322.451C351.84 315.991 357.04 309.52 362.64 303.26C364.93 300.67 367 297.45 369.72 295.06C377.609 286.11 384.562 276.376 390.47 266.01C397.91 253.77 404.72 241.75 411.39 229.01');
  groupTwo.path('M409.64 281.62C409.64 281.62 382.44 285.17 353.64 313.5');
  groupTwo.path('M275.61 349.4C275.61 349.4 274.61 392.24 250.61 415.59');
  groupTwo.path('M323.24 252.451C323.24 252.451 339.99 279.95 310.89 361.09');
  groupTwo.path('M353.8 268.3C353.8 268.3 341.72 276.62 325.17 310.62');
  groupTwo.path('M446.72 165.24C445.72 167.1 436.46 169.44 434.15 170.53C429.609 172.681 425.208 175.112 420.97 177.81C412.49 183.053 405.09 189.869 399.17 197.891C392.17 207.721 385.23 221.17 396.69 230.48C399.799 232.86 403.23 234.786 406.88 236.201C430.72 246.201 440.33 229.85 442.03 224.88C443.73 219.91 445.93 202.81 445.57 187.44C445.301 180.021 445.686 172.592 446.72 165.24V165.24Z').fill('#509689');
  groupTwo.path('M444.59 168.401C444.59 168.401 418.41 197.401 406.85 236.201');
  groupTwo.path('M394.28 225.47C394.28 225.47 366.38 230.26 354.39 239.36C342.4 248.46 322.39 262.361 348.29 272.711C348.29 272.711 350.46 287.83 363.16 287.41C375.86 286.99 391.67 275.211 393.91 254.141C396.15 233.071 394.28 225.47 394.28 225.47Z').fill('#509689');
  groupTwo.path('M394.28 225.47C394.28 225.47 367.14 257.871 348.28 272.711');
  groupTwo.path('M480.34 275.82C475.14 273.971 470.1 271.7 465.27 269.03C457.12 264.55 422.73 247.03 411.8 262.1C400.87 277.17 406.29 281.94 406.29 281.94C406.29 281.94 399.52 297.66 419.04 300.45C438.56 303.24 458.04 295.14 468.28 287.12C478.52 279.1 480.34 275.82 480.34 275.82Z').fill('#509689');
  groupTwo.path('M477.11 276.54C477.11 276.54 432.89 271.08 406.29 281.94');
  groupTwo.path('M440.1 361.51C440.1 361.51 432.85 361.94 410.05 351.83C387.25 341.72 369.15 333.19 362.32 357.83C355.49 382.47 378.2 382.94 384.18 382.83C403.5 382.72 421.16 368.32 440.1 361.51Z').fill('#006571');
  groupTwo.path('M235.65 289.97C235.65 289.97 253.98 286.44 267.54 295.07C281.1 303.7 292.95 316.62 292.27 337.82C291.59 359.02 275.57 356.24 275.57 356.24C275.57 356.24 259.16 369.98 246.11 360.94C233.06 351.9 237.88 326.62 237.88 326.62C237.88 326.62 245.88 300.89 235.65 289.97Z').fill('#006571');
  groupTwo.path('M235.65 289.97C235.65 289.97 270.65 308.1 275.57 356.24');
  groupTwo.path('M269.54 221.48C269.54 221.48 291.47 216.48 312.54 222.82C333.61 229.16 340.02 233.21 339.35 243.47C338.68 253.73 325.14 257.29 325.14 257.29C325.14 257.29 309.35 281.29 292.01 271.81C288.681 270.073 285.799 267.589 283.589 264.553C281.379 261.518 279.901 258.012 279.27 254.31C278.27 248.68 277.96 242.91 276.47 237.31C274.98 231.71 271.88 226.73 269.54 221.48Z').fill('#509689');
  groupTwo.path('M269.54 221.48C269.54 221.48 303.63 237.56 325.1 257.29');

  const bottomLeftFlowerGroup = landscapeBottom.group();
  bottomLeftFlowerGroup.addClass('bottom-left-flower');
  bottomLeftFlowerGroup.css(commonCss);
  bottomLeftFlowerGroup.path('M499.38 149.98C500.54 149.49 490.01 129.541 473.61 127.121C457.21 124.701 458.91 137.941 465.69 144.331C472.47 150.721 486.3 155.44 499.38 149.98Z').fill('#D25582');
  bottomLeftFlowerGroup.path('M481.06 158.34C480.5 158.34 463.06 157.891 462.57 173.271C462.08 188.651 487.88 184.09 495.32 172.1C502.76 160.11 503.8 158.1 503.37 155.84C502.94 153.58 481.06 158.34 481.06 158.34Z').fill('#D25582');
  bottomLeftFlowerGroup.path('M503.64 154.35C502.69 151.97 501.71 152.55 499.84 151.7C487.76 146.13 478.71 144.22 469.2 145.47C452.2 147.69 460.01 158.53 464.66 159.47C470.398 160.4 476.208 160.821 482.02 160.73C488.32 160.8 497.23 157.63 502.92 153.79C504.5 152.68 504.4 156.28 503.64 154.35Z').fill('#D25582');
  bottomLeftFlowerGroup.path('M502.27 155.94C502.27 155.94 478.88 174.621 478.33 184.021C477.78 193.421 487.94 186.83 494.73 176.83C501.52 166.83 505.19 161.6 504.81 154.3C504.81 154.3 503.22 153.87 502.27 155.94Z').fill('#222721');
  bottomLeftFlowerGroup.path('M502.28 155.62C502.28 155.62 481.18 134.42 481.65 125.04C482.12 115.66 491.5 123.32 497.14 134.04C502.78 144.76 505.82 150.34 504.63 157.55C504.63 157.55 503 157.8 502.28 155.62Z').fill('#222721');
  bottomLeftFlowerGroup.path('M584.96 327.211C584.25 328.211 603.96 339.151 619.04 332.211C634.12 325.271 625.39 315.1 616.22 313.48C607.05 311.86 592.88 315.461 584.96 327.211Z').fill('#D25582');
  bottomLeftFlowerGroup.path('M595.68 310.16C596.17 309.88 610.98 300.66 602.95 287.53C594.92 274.4 575.85 292.38 576.21 306.53C576.57 320.68 576.79 322.88 578.4 324.53C580.01 326.18 595.68 310.16 595.68 310.16Z').fill('#D25582');
  bottomLeftFlowerGroup.path('M578.99 325.89C581.09 327.36 581.59 326.33 583.62 326.03C596.78 324.03 605.39 320.68 612.62 314.42C625.62 303.21 613.13 298.42 608.76 300.26C603.45 302.628 598.36 305.463 593.55 308.73C588.25 312.12 582.55 319.66 579.88 326C579.19 327.76 577.3 324.69 578.99 325.89Z').fill('#D25582');
  bottomLeftFlowerGroup.path('M579.27 323.81C579.27 323.81 588.52 295.35 583.87 287.2C579.22 279.05 574.3 290.12 574.11 302.2C573.92 314.28 573.72 320.67 578.04 326.56C578.04 326.62 579.61 326.06 579.27 323.81Z').fill('#222721');
  bottomLeftFlowerGroup.path('M579.42 324.071C579.42 324.071 608.71 330.22 613.42 338.31C618.13 346.4 606.12 345.16 595.54 339.31C584.96 333.46 579.33 330.451 576.37 323.771C576.41 323.761 577.63 322.621 579.42 324.071Z').fill('#222721');
  bottomLeftFlowerGroup.path('M506.94 156.16C506.94 156.16 539.03 160.37 560.49 209.34C581.95 258.31 548.01 288.99 548.01 288.99C548.01 288.99 538.18 301.67 484.2 320.8C430.22 339.93 399.36 360.73 390.98 387.06C382.6 413.39 389.47 444.731 411.71 471.661C433.95 498.591 455.71 504.991 464.95 531.661C474.19 558.331 468.72 575.071 452.13 585.771');
  bottomLeftFlowerGroup.path('M579.42 324.07C579.42 324.07 544.81 352.21 486.81 344.93C486.81 344.93 439.62 337.87 417.29 353.69');
  bottomLeftFlowerGroup.path('M576.06 156.78C576.06 156.78 548.79 162.271 553.53 194.401C553.53 194.401 561.1 200.69 565.77 186.28C570.44 171.87 567.56 164.35 576.06 156.78Z').fill('#009D70');
  bottomLeftFlowerGroup.path('M565.93 257.78C569.29 253.146 572.881 248.684 576.69 244.41C583.56 236.59 598.94 225.6 606.03 224.55C606.03 224.55 591.61 263.42 565.93 257.78Z').fill('#009D70');
  bottomLeftFlowerGroup.path('M509.19 246.8C509.19 246.8 523.37 229.32 546.68 241.8C569.99 254.28 565.21 258.62 565.21 258.62C565.21 258.62 563.21 267.19 552.53 261.98C541.85 256.77 533.88 250.13 525.25 245.53C516.82 241.02 509.19 246.8 509.19 246.8Z').fill('#009D70');
  bottomLeftFlowerGroup.path('M535.7 313.89C535.7 313.89 536.3 337.8 505.83 345.39C505.83 345.39 504.67 337.1 516.23 330.25C523.514 325.819 530.079 320.302 535.7 313.89Z').fill('#009D70');
  bottomLeftFlowerGroup.path('M535.17 382.26C535.17 382.26 546.29 361.86 508.1 346.18C508.1 346.18 498.59 351.441 515.78 364.651C532.97 377.861 535.17 382.26 535.17 382.26Z').fill('#009D70');
  bottomLeftFlowerGroup.path('M408.05 313.79C408.05 313.79 423.23 342.16 403 365.59C403 365.59 390.68 359.72 402.44 335.48C402.44 335.48 407.55 321.42 408.05 313.79Z').fill('#009D70');
  bottomLeftFlowerGroup.path('M468.31 372C468.31 372 430.4 346.3 402.61 367.82C402.61 367.82 413.86 388.17 437.26 379.82C460.66 371.47 468.31 372 468.31 372Z').fill('#009D70');
  bottomLeftFlowerGroup.path('M508.51 199.021C508.51 199.021 515.88 182.7 536.35 187.62C556.82 192.54 554.02 196.87 554.02 196.87C554.02 196.87 554.2 203.871 544.94 202.021C535.68 200.171 528.17 196.65 520.62 194.84C513.07 193.03 508.51 199.021 508.51 199.021Z').fill('#009D70');

  const groupThree = landscapeBottom.group();
  groupThree.css(greenStrokeCss);
  groupThree.path('M131.06 90.0005C133.578 87.112 135.513 83.7639 136.76 80.1405C138.65 74.0305 134.76 68.4105 128.76 67.0305C125.04 66.1805 122.11 68.7205 120.37 71.7705C119.328 73.5423 118.648 75.5036 118.37 77.5405C117.83 82.0405 119.67 86.3405 121.92 90.1305C122.82 91.6505 123.77 93.9605 125.81 93.9005C127.85 93.8405 129.88 91.3905 131.06 90.0005Z').fill('#B42673');
  groupThree.path('M158.22 87.2005C160.764 86.7633 163.376 86.9678 165.821 87.7955C168.266 88.6232 170.465 90.0479 172.22 91.9405C178.31 99.1005 170.48 107.06 163.78 109.03C158.251 110.654 152.875 112.755 147.71 115.31C148.16 115.09 146.94 106.73 146.92 105.7C146.85 102.91 146.59 99.7004 147.41 97.0904C148.76 92.7304 153.48 88.1605 158.22 87.2005Z').fill('#B42673');
  groupThree.path('M120.38 82.1005C119.71 79.1705 118.5 76.1705 116.06 74.4205C112.94 72.1905 106.88 71.5405 103.69 73.8805C99.96 76.5905 99.79 82.2005 100.69 86.3005C101.63 90.8305 104.85 94.6205 108.69 97.2405C111.144 98.8539 113.766 100.195 116.51 101.24C119.4 102.41 120.51 103.121 120.95 99.6505C121.51 93.8805 121.68 87.8105 120.38 82.1005Z').fill('#B42673');
  groupThree.path('M129.52 79.9505C130.324 78.1394 131.569 76.5582 133.14 75.3504C135.28 73.9004 138.05 73.7805 140.6 74.1705C144.285 74.7586 147.754 76.2918 150.67 78.6205C152.93 80.4205 154.9 82.9104 155.02 85.7904C155.12 88.0904 154.02 90.2605 152.89 92.2605C151.997 93.9471 150.884 95.5075 149.58 96.9005C147.44 99.0305 144.58 100.25 142.08 101.9C136.2 105.82 133.31 111.77 129.14 117.17C124.8 115.62 124.69 104.62 124.62 101.17C124.475 93.8009 126.158 86.5102 129.52 79.9505Z').fill('#B42673');
  groupThree.path('M177.82 110.84C179.734 112.131 181.182 114.003 181.95 116.18C182.415 118.074 182.374 120.058 181.83 121.93C181.251 124.557 179.997 126.988 178.19 128.98C176.28 130.783 174.001 132.148 171.51 132.98C167.07 134.837 162.198 135.406 157.45 134.62C155.08 134.06 152.769 133.273 150.55 132.27L135.71 126.14C136.14 126.32 143.35 119 144.2 118.29C146.314 116.49 148.553 114.843 150.9 113.36C156.28 110.05 161.24 106.53 167.9 107.09C171.49 107.398 174.924 108.696 177.82 110.84V110.84Z').fill('#B42673');
  groupThree.path('M177.72 137.85C180.27 141.13 180.88 146.15 178.3 149.41C177.32 150.528 176.101 151.41 174.733 151.991C173.366 152.573 171.885 152.839 170.4 152.771C167.448 152.608 164.555 151.878 161.88 150.621C156.88 148.541 152.01 145.791 148.59 141.621C148.281 141.337 148.053 140.976 147.928 140.576C147.804 140.176 147.787 139.749 147.88 139.34C148.107 138.915 148.455 138.567 148.88 138.34C151.63 136.6 154.48 135.09 157.34 133.55C158.95 132.67 160.34 131.551 162.22 131.321C167.46 130.721 174.5 133.69 177.72 137.85Z').fill('#B42673');
  groupThree.path('M137.25 123.52C146.14 124.65 155.63 126.35 161.99 132.66C164.31 134.96 166.16 138.04 165.93 141.3C165.67 145.21 162.39 148.43 158.67 149.68C154.95 150.93 150.88 150.52 147.04 149.74C142.168 148.778 137.455 147.135 133.04 144.86C130.341 143.587 127.91 141.809 125.88 139.62C123.24 136.52 119.88 130.3 122.61 126.4C125.63 122.07 132.88 122.96 137.25 123.52Z').fill('#B42673');
  groupThree.path('M132.61 104.97C135.46 101.81 139.08 98.8905 143.33 98.6905C146.23 98.5605 149.02 99.6905 151.58 101.081C153.92 102.321 156.26 103.841 157.58 106.151C159.38 109.351 158.74 113.541 156.68 116.581C154.466 119.543 151.445 121.807 147.98 123.1C139.98 126.48 130.98 126.781 122.32 127.021C120.42 127.081 124.77 116.82 124.91 116.54C126.903 112.331 129.497 108.434 132.61 104.97V104.97Z').fill('#B42673');
  groupThree.path('M136.02 105.901C136.94 102.681 137.53 99.1405 136.22 96.0605C134.91 92.9805 131.88 90.9305 128.82 89.5005C126.16 88.2505 123.2 87.3205 120.31 87.8205C115 88.7305 111.51 94.4005 111.45 99.8205C111.39 105.24 113.97 110.26 116.9 114.82C118.73 117.62 120.56 120.48 122.46 123.23C124.14 125.65 125.55 125.47 127.46 123.23C131.54 118.37 134.32 111.871 136.02 105.901Z').fill('#B42673');
  groupThree.path('M118.88 110C116.838 105.978 114.356 102.195 111.48 98.7204C108.589 95.2363 104.778 92.6342 100.48 91.2105C98.4315 90.4542 96.1694 90.5223 94.17 91.4005C92.4 92.3405 91.32 94.1605 90.36 95.9205C89.199 97.7313 88.467 99.7836 88.22 101.92C88.07 105.92 91.16 109.36 94.29 111.92C100.233 116.821 107.03 120.581 114.34 123.01C116.91 123.87 120.26 124.9 122.92 123.95C122.618 119.059 121.239 114.296 118.88 110V110Z').fill('#B42673');
  groupThree.path('M131.88 126.771C131.974 127.493 131.877 128.227 131.6 128.901C131.3 129.459 130.871 129.937 130.349 130.297C129.828 130.657 129.228 130.888 128.6 130.97C127.328 131.1 126.043 130.957 124.83 130.551C121.526 129.605 118.46 127.973 115.83 125.761C115.44 125.472 115.118 125.099 114.89 124.67C114.4 123.57 115.14 122.351 115.89 121.371C116.146 120.968 116.488 120.627 116.89 120.371C117.555 120.112 118.289 120.088 118.97 120.301C121.746 120.895 124.435 121.843 126.97 123.121C129.12 124.091 131.53 123.731 131.88 126.771Z').fill('#1F2720');
  groupThree.path('M152.75 136.62C142.92 136.603 133.281 133.895 124.88 128.79');
  groupThree.path('M148.1 107.17C143.312 115.796 135.619 122.444 126.39 125.93');
  groupThree.path('M127.27 100.461C124.956 103.802 123.616 107.721 123.4 111.78C123.2 115.824 123.513 119.876 124.33 123.84');
  groupThree.path('M102.48 104.72C106.949 111.367 112.938 116.852 119.95 120.72');
  groupThree.path('M235.88 108.391C238.394 105.5 240.33 102.152 241.58 98.5305C243.47 92.4205 239.58 86.7905 233.58 85.4205C229.87 84.5705 226.94 87.1105 225.2 90.1605C224.158 91.9323 223.478 93.8936 223.2 95.9305C222.65 100.43 224.5 104.731 226.74 108.521C227.64 110.041 228.59 112.35 230.64 112.29C232.69 112.23 234.69 109.781 235.88 108.391Z').fill('#B42673');
  groupThree.path('M263.01 105.62C265.554 105.18 268.167 105.382 270.613 106.21C273.058 107.038 275.257 108.465 277.01 110.36C283.11 117.52 275.28 125.47 268.58 127.45C263.051 129.074 257.675 131.175 252.51 133.73C252.96 133.51 251.74 125.15 251.72 124.12C251.65 121.32 251.39 118.12 252.21 115.51C253.55 111.12 258.27 106.54 263.01 105.62Z').fill('#B42673');
  groupThree.path('M225.17 100.491C224.5 97.5605 223.29 94.5505 220.85 92.8005C217.73 90.5805 211.67 89.9305 208.47 92.2605C204.75 94.9805 204.58 100.591 205.47 104.681C206.41 109.211 209.63 113.011 213.47 115.681C215.92 117.294 218.539 118.635 221.28 119.681C224.18 120.851 225.34 121.56 225.73 118.09C226.3 112.27 226.47 106.201 225.17 100.491Z').fill('#B42673');
  groupThree.path('M234.31 98.3405C235.114 96.5265 236.358 94.9419 237.93 93.7305C240.06 92.2905 242.84 92.1705 245.39 92.5605C249.073 93.1355 252.544 94.6587 255.46 96.9805C257.72 98.7805 259.69 101.261 259.81 104.151C259.91 106.451 258.81 108.611 257.68 110.611C256.79 112.302 255.677 113.866 254.37 115.261C252.23 117.381 249.37 118.611 246.87 120.261C240.99 124.191 238.1 130.14 233.93 135.54C229.59 133.98 229.48 122.99 229.41 119.54C229.267 112.178 230.951 104.894 234.31 98.3405V98.3405Z').fill('#B42673');
  groupThree.path('M282.61 129.22C284.52 130.52 285.966 132.394 286.74 134.57C287.205 136.461 287.164 138.441 286.62 140.31C286.036 142.92 284.781 145.333 282.98 147.31C281.074 149.118 278.793 150.484 276.3 151.31C271.857 153.153 266.984 153.708 262.24 152.91C259.87 152.35 257.559 151.563 255.34 150.56L240.5 144.43C240.93 144.6 248.14 137.29 248.99 136.58C251.104 134.781 253.343 133.133 255.69 131.65C261.07 128.34 266.02 124.82 272.69 125.38C276.289 125.714 279.724 127.044 282.61 129.22Z').fill('#B42673');
  groupThree.path('M282.51 156.23C285.06 159.52 285.67 164.54 283.09 167.8C282.108 168.916 280.889 169.797 279.522 170.378C278.155 170.96 276.674 171.227 275.19 171.16C272.223 171.002 269.313 170.276 266.62 169.02C261.62 166.94 256.75 164.19 253.33 160.02C252.8 159.37 252.33 158.52 252.62 157.75C252.844 157.323 253.193 156.974 253.62 156.75C256.36 155.01 259.22 153.5 262.08 151.96C263.69 151.08 265.08 149.96 266.96 149.73C272.25 149.1 279.29 152.08 282.51 156.23Z').fill('#B42673');
  groupThree.path('M242.04 141.91C250.93 143.04 260.41 144.74 266.77 151.04C269.09 153.35 270.94 156.43 270.72 159.69C270.45 163.6 267.17 166.82 263.46 168.06C259.75 169.3 255.67 168.91 251.83 168.13C246.959 167.165 242.246 165.522 237.83 163.25C235.151 161.963 232.745 160.174 230.74 157.98C228.1 154.88 224.74 148.66 227.47 144.76C230.42 140.45 237.61 141.35 242.04 141.91Z').fill('#B42673');
  groupThree.path('M237.4 123.36C240.25 120.2 243.87 117.271 248.12 117.081C251.01 116.951 253.81 118.08 256.36 119.47C258.71 120.71 261.05 122.23 262.36 124.54C264.16 127.74 263.52 131.92 261.46 134.97C259.246 137.933 256.225 140.197 252.76 141.49C244.76 144.87 235.76 145.16 227.1 145.41C225.2 145.46 229.55 135.21 229.68 134.93C231.681 130.721 234.281 126.824 237.4 123.36V123.36Z').fill('#B42673');
  groupThree.path('M240.81 124.29C241.73 121.07 242.32 117.531 241.01 114.451C239.7 111.371 236.67 109.311 233.61 107.891C230.95 106.641 227.99 105.711 225.1 106.201C219.79 107.121 216.3 112.791 216.24 118.201C216.18 123.611 218.75 128.651 221.69 133.201C223.52 136.001 225.34 138.85 227.25 141.61C228.93 144.03 230.34 143.85 232.25 141.61C236.33 136.76 239.1 130.26 240.81 124.29Z').fill('#B42673');
  groupThree.path('M223.64 128.391C221.601 124.367 219.119 120.583 216.24 117.11C213.349 113.626 209.538 111.024 205.24 109.6C203.188 108.847 200.925 108.911 198.92 109.78C197.16 110.78 196.07 112.55 195.11 114.31C193.955 116.123 193.226 118.175 192.98 120.31C192.82 124.31 195.92 127.75 199.04 130.31C204.99 135.206 211.79 138.965 219.1 141.401C221.67 142.261 225.02 143.291 227.68 142.331C227.377 137.443 225.997 132.683 223.64 128.391V128.391Z').fill('#B42673');
  groupThree.path('M235.6 144.35C235.675 145.075 235.558 145.806 235.26 146.47C234.953 147.029 234.513 147.504 233.98 147.852C233.446 148.201 232.835 148.413 232.2 148.47C230.928 148.579 229.649 148.409 228.45 147.97C225.174 146.946 222.152 145.243 219.58 142.97C219.198 142.673 218.887 142.294 218.67 141.86C218.21 140.76 218.97 139.55 219.67 138.59C219.942 138.183 220.3 137.842 220.72 137.59C221.398 137.36 222.132 137.36 222.81 137.59C225.595 138.24 228.285 139.248 230.81 140.59C232.94 141.62 235.36 141.31 235.6 144.35Z').fill('#1F2720');
  groupThree.path('M257.53 155.04C247.711 155.004 238.088 152.286 229.7 147.18');
  groupThree.path('M252.88 125.56C248.093 134.189 240.395 140.834 231.16 144.31');
  groupThree.path('M232.06 118.85C229.746 122.192 228.406 126.111 228.19 130.17C227.984 134.21 228.297 138.26 229.12 142.22');
  groupThree.path('M207.27 123.11C211.739 129.757 217.728 135.242 224.74 139.11');
  groupThree.path('M224.28 147.29C208.28 169.08 87.57 320.101 -43.61 486.291');
  groupThree.path('M152.64 235.48C127.53 207.92 116.3 168.3 123.2 131.62');
  groupThree.path('M183.88 110.86C183.88 110.86 165.49 155.28 165.21 178.72C164.93 202.16 175.75 216.18 175.75 216.18C175.75 216.18 189.75 198.4 190.61 167.5C191.041 148.398 188.775 129.33 183.88 110.86V110.86Z').fill('#A3D380');
  groupThree.path('M269.62 191.48C269.62 191.48 205.75 183.06 176.94 215.17C176.94 215.17 188.2 222.06 213.94 217.17C239.68 212.28 265.58 196.26 269.62 191.48Z').fill('#A3D380');
  groupThree.path('M266.68 192.89L176.94 215.17');
  groupThree.path('M183.36 113.73L175.72 216.18');

  const bottomRightFlowerGroup = landscapeBottom.group();
  const bottomRightFlowerCss = {
    'stroke': '#222721',
    'stroke-width': 2,
    'stroke-miterlimit': 10,
  };
  bottomRightFlowerGroup.addClass('bottom-right-flower');
  bottomRightFlowerGroup.path('M1118.46 190.23C1124.13 207.08 1121.16 368.93 1120.03 386.67').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1098.88 176.81C1094.32 177.502 1089.65 177.122 1085.26 175.701C1077.8 172.981 1071.09 162.991 1077.97 156.021C1080.09 154.035 1082.86 152.887 1085.77 152.79C1091.66 152.3 1097.52 154.038 1102.19 157.66C1105.08 160.01 1112.89 169.92 1109.02 173.59C1106.6 175.85 1101.88 176.43 1098.88 176.81Z').css(bottomRightFlowerCss).fill('#FF8C00');
  bottomRightFlowerGroup.path('M1087.5 164.961C1093.97 168.675 1101.22 170.841 1108.67 171.29').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1110.63 169.62C1110.63 169.62 1097.09 150.09 1102.24 138.44C1103.8 134.92 1106.51 131.51 1110.71 131.72C1118.48 132.1 1119.87 140.51 1120.02 146.72C1120.1 150.977 1119.31 155.205 1117.7 159.144C1116.08 163.083 1113.68 166.648 1110.63 169.62V169.62Z').css(bottomRightFlowerCss).fill('#FF8C00');
  bottomRightFlowerGroup.path('M1112.67 171.681C1112.67 171.681 1133.6 180.611 1141.8 168.311C1150 156.011 1145.8 148.201 1137.23 146.461C1128.66 144.721 1119.53 148.391 1112.67 171.681Z').css(bottomRightFlowerCss).fill('#FF8C00');
  bottomRightFlowerGroup.path('M1110.12 174.521C1111.27 179.829 1113.97 184.673 1117.89 188.432C1121.81 192.19 1126.77 194.69 1132.12 195.611C1132.12 195.611 1146.88 196.431 1140.03 180.371C1133.18 164.311 1112.69 171.681 1112.69 171.681L1110.12 174.521Z').css(bottomRightFlowerCss).fill('#FF8C00');
  bottomRightFlowerGroup.path('M1110.55 172.79C1110.55 172.79 1095.16 176.11 1093.81 192.99C1093.43 197.62 1094.12 203.341 1098.95 205.401C1106.95 208.801 1112.36 201.561 1115.07 195.081C1118.4 187.111 1116.63 178.91 1110.55 172.79Z').css(bottomRightFlowerCss).fill('#FF8C00');
  bottomRightFlowerGroup.path('M1110.88 169.081C1110.88 169.081 1114.62 157.21 1108.54 146.26').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1110.94 170.42C1110.94 170.42 1125.84 169.03 1133.32 157.17').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1129.59 185.06C1125.13 180.255 1119.6 176.577 1113.44 174.331').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1103.31 193.521C1103.31 193.521 1107.75 190.521 1111.71 173.901').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1106.79 173.761C1107.05 174.666 1107.58 175.47 1108.31 176.065C1109.05 176.66 1109.94 177.018 1110.88 177.09C1111.96 177.064 1113 176.716 1113.88 176.09C1115.01 175.366 1115.91 174.322 1116.45 173.09C1116.72 172.478 1116.85 171.814 1116.84 171.144C1116.83 170.474 1116.68 169.815 1116.39 169.211C1115.27 166.991 1111.19 165.431 1108.9 166.641C1106.61 167.851 1106.14 171.621 1106.79 173.761Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1121.88 269.62C1127.2 260.152 1134.48 251.93 1143.23 245.502C1151.99 239.075 1162.01 234.591 1172.64 232.35').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1168.03 232.49C1163.47 233.181 1158.81 232.801 1154.42 231.38C1146.95 228.66 1140.25 218.671 1147.12 211.701C1149.24 209.715 1152.01 208.567 1154.92 208.47C1160.81 207.983 1166.67 209.721 1171.34 213.34C1174.23 215.7 1182.04 225.601 1178.18 229.271C1175.8 231.531 1171.12 232.12 1168.03 232.49Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1156.7 220.62C1163.17 224.332 1170.42 226.496 1177.87 226.94').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1179.88 225.29C1179.88 225.29 1166.33 205.75 1171.48 194.1C1173.04 190.59 1175.76 187.17 1179.95 187.38C1187.73 187.76 1189.12 196.17 1189.27 202.38C1189.35 206.638 1188.56 210.867 1186.95 214.807C1185.33 218.747 1182.93 222.315 1179.88 225.29V225.29Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1181.88 227.36C1181.88 227.36 1202.8 236.29 1211.01 224C1219.22 211.71 1215.01 203.89 1206.44 202.15C1197.87 200.41 1188.73 204.07 1181.88 227.36Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1179.32 230.201C1180.47 235.508 1183.18 240.351 1187.1 244.109C1191.02 247.866 1195.97 250.367 1201.32 251.29C1201.32 251.29 1216.08 252.12 1209.24 236.05C1202.4 219.98 1181.9 227.36 1181.9 227.36L1179.32 230.201Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1179.75 228.47C1179.75 228.47 1164.37 231.79 1163.01 248.67C1162.64 253.31 1163.32 259.03 1168.15 261.09C1176.15 264.48 1181.56 257.25 1184.28 250.76C1187.61 242.79 1185.88 234.62 1179.75 228.47Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1180.12 224.771C1180.12 224.771 1183.86 212.89 1177.79 201.94').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1180.14 226.11C1180.14 226.11 1195.04 224.71 1202.52 212.85').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1198.8 240.74C1194.34 235.937 1188.81 232.26 1182.65 230.01').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1172.51 249.201C1172.51 249.201 1176.95 246.201 1180.91 229.581').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1176 229.44C1176.26 230.348 1176.79 231.154 1177.52 231.751C1178.25 232.348 1179.15 232.707 1180.09 232.78C1181.17 232.758 1182.21 232.41 1183.09 231.78C1184.23 231.057 1185.12 230.013 1185.67 228.78C1185.93 228.166 1186.07 227.503 1186.06 226.834C1186.05 226.165 1185.89 225.506 1185.61 224.901C1184.48 222.691 1180.4 221.12 1178.12 222.33C1175.84 223.54 1175.35 227.33 1176 229.44Z').css(bottomRightFlowerCss).fill('#FF8C00');
  bottomRightFlowerGroup.path('M1121.62 289.93C1111.21 286.825 1101.59 281.533 1093.39 274.409C1085.19 267.285 1078.61 258.493 1074.08 248.62').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1075.26 253.071C1076.94 257.372 1077.59 262.003 1077.17 266.6C1076.17 274.48 1067.89 283.22 1059.58 278.04C1057.18 276.406 1055.45 273.959 1054.71 271.151C1052.94 265.512 1053.35 259.414 1055.85 254.061C1057.51 250.721 1065.45 240.921 1069.85 243.881C1072.61 245.701 1074.21 250.141 1075.26 253.071Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1066.2 266.73C1068.39 259.611 1068.9 252.082 1067.71 244.73').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1065.63 243.14C1065.63 243.14 1049.56 260.65 1037.06 258.19C1033.29 257.45 1029.36 255.55 1028.63 251.41C1027.29 243.75 1035.19 240.54 1041.23 239.02C1045.36 238.005 1049.66 237.846 1053.86 238.554C1058.05 239.263 1062.06 240.824 1065.63 243.14Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1067.2 240.701C1067.2 240.701 1071.31 218.321 1057.51 213.021C1043.71 207.721 1037.01 213.53 1037.2 222.29C1037.39 231.05 1042.97 239.131 1067.2 240.701Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1070.54 242.561C1075.46 240.275 1079.59 236.571 1082.39 231.924C1085.19 227.278 1086.54 221.9 1086.27 216.48C1086.27 216.48 1083.83 201.9 1069.66 212.11C1055.49 222.32 1067.2 240.701 1067.2 240.701L1070.54 242.561Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1068.75 242.52C1068.75 242.52 1075.38 256.8 1092.14 254.41C1096.75 253.75 1102.14 251.83 1103.14 246.66C1104.7 238.15 1096.44 234.42 1089.51 233.21C1081 231.71 1073.39 235.25 1068.75 242.52Z').css(bottomRightFlowerCss).fill('#FFC841');
  bottomRightFlowerGroup.path('M1065.06 242.98C1065.06 242.98 1052.66 241.98 1043.31 250.28').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1066.36 242.62C1066.36 242.62 1061.72 228.391 1048.51 223.711').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1076.53 221.24C1072.83 226.65 1070.46 232.859 1069.62 239.36').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1090.57 245.021C1090.57 245.021 1086.68 241.351 1069.57 241.151').css(greenStrokeCss);
  bottomRightFlowerGroup.path('M1070.53 245.97C1071.36 245.517 1072.02 244.822 1072.44 243.979C1072.86 243.135 1073.02 242.183 1072.88 241.251C1072.62 240.21 1072.05 239.272 1071.25 238.56C1070.3 237.613 1069.09 236.966 1067.78 236.701C1067.12 236.575 1066.45 236.591 1065.8 236.748C1065.15 236.905 1064.54 237.199 1064.01 237.61C1062.1 239.2 1061.47 243.52 1063.15 245.48C1064.83 247.44 1068.61 247.07 1070.53 245.97Z').css(bottomRightFlowerCss).fill('#FF8C00');

  const groupFour = landscapeBottom.group();
  groupFour.css(commonCss);
  groupFour.path('M222.24 182.42C222.24 182.42 218.53 227.49 192.19 262.32C165.85 297.15 129.13 310.711 129.13 310.711C129.13 310.711 118.25 288.041 135.03 266.711C151.81 245.381 188.15 219.511 199.03 210.961C209.91 202.411 220 186.8 222.24 182.42Z').fill('#009D70');
  groupFour.path('M279.95 234.271C279.95 234.271 233.45 310.541 190.08 335.771C146.71 361.001 100.14 364.771 73.78 350.771C73.78 350.771 101.28 306.22 145.31 283.49C189.34 260.76 233.21 250.49 234.82 249.69C236.43 248.89 274.79 238.501 279.95 234.271Z').fill('#009D70');
  groupFour.path('M161.35 183.07C157.53 184.47 156.35 191.07 155.15 194.42C148.7 212.624 144.071 231.423 141.33 250.54C138.8 268.47 137.79 289.8 130.24 306.54C123.92 320.54 88.12 355.861 73.78 350.771C59.44 345.681 64.3 302.45 100.07 247.43C135.84 192.41 161.35 183.07 161.35 183.07Z').fill('#009D70');
  groupFour.path('M109.33 150.03C109.33 150.03 87.95 204.62 98.27 258.62C108.59 312.62 73.78 350.72 73.78 350.72C73.78 350.72 55.54 342.721 50.7 313.961C45.86 285.201 41.43 245.67 78.14 185.3C78.14 185.3 92.34 164.12 109.33 150.03Z').fill('#009D70');
  groupFour.path('M72.54 349.29C72.54 349.29 117.54 361.76 170.48 325.19C223.42 288.62 251.88 276.47 275.3 236.48');
  groupFour.path('M128.82 310.1C149.61 296.43 173.18 282.32 190.48 276.16C230.84 261.79 261.3 253.16 270.54 243.16');
  groupFour.path('M99.88 339.951C131.74 329.191 183.78 309.45 211.65 287.19C250.52 256.14 267.73 248.19 267.73 248.19');
  groupFour.path('M220.58 188.74C220.58 188.74 177.47 255.32 140.43 270');
  groupFour.path('M137.42 286.28C137.42 286.28 180.03 269.401 219.9 193.391');
  groupFour.path('M159.68 184.98C159.68 184.98 153.53 189.77 145.94 201.98C134.68 220.06 118.43 251.29 107.12 294.91C107.12 294.91 96.82 330.19 77.82 346.31');
  groupFour.path('M142.46 207.701C142.46 207.701 137.46 217.25 131.7 248.18C129.261 260.981 126.008 273.614 121.96 286C117.64 299.29 110.73 311.55 102.96 323.13C96.07 333.42 88.39 345.03 76.05 349.13');
  groupFour.path('M100.12 271.07C100.12 271.07 110.32 228.32 139.01 199.07');
  groupFour.path('M105.88 154.16C105.88 154.16 78.88 208.34 90.32 257.94C101.76 307.54 73.32 348.25 73.32 348.25');
  groupFour.path('M71.95 347.62C69.2 331.3 64.15 315.46 61.37 299.16C58.56 282.65 61.3 265.97 64.47 249.69C70.53 218.69 80.62 182.45 103.74 159.53L104.04 159.23');
  groupFour.path('M73.52 346.49C73.52 346.49 81.36 312.16 74.52 275.88C67.68 239.6 81.73 196.88 99.52 166.81');
  groupFour.path('M10.39 394.45C10.39 394.45 35.51 396.21 73.78 350.72');
  groupFour.path('M136.28 283.62C130.07 286 123.11 287.62 116.44 286.9C104.81 285.68 91.67 273.82 98.99 261.4C101.32 257.806 104.872 255.177 108.99 254C116.99 251.39 126.31 251.39 134.17 255.08C139.17 257.41 153.83 268.76 149.63 275.39C147.03 279.41 140.55 281.92 136.28 283.62Z').fill('#FFF8CF');
  groupFour.path('M115.88 270.701C115.88 270.701 131.39 275.7 148.37 272.16Z').fill('#FFF8CF');
  groupFour.path('M115.88 270.701C115.88 270.701 131.39 275.7 148.37 272.16');
  groupFour.path('M150.56 269.05C150.56 269.05 124.23 246.05 127.42 227.53C128.42 221.95 131.05 216.111 137.11 214.901C148.34 212.661 153.34 224.171 155.78 233.001C157.416 239.048 157.797 245.366 156.899 251.566C156.001 257.765 153.844 263.716 150.56 269.05V269.05Z').fill('#FFF8CF');
  groupFour.path('M154.21 271.28C154.21 271.28 187.29 276.55 194.6 256.06C201.91 235.57 193.14 225.92 180.26 226.5C167.38 227.08 155.67 235.62 154.21 271.28Z').fill('#FFF8CF');
  groupFour.path('M151.58 276.26C151.58 276.26 161.82 299.97 190.5 298.5C190.5 298.5 211.87 294.41 196.36 273.92C180.85 253.43 154.21 271.28 154.21 271.28L151.58 276.26Z').fill('#FFF8CF');
  groupFour.path('M151.58 273.62C151.58 273.62 130.8 283.87 134.88 308.46C136.01 315.21 139.03 323.13 146.66 324.35C159.24 326.35 164.44 314.07 165.99 303.84C167.88 291.27 162.44 280.19 151.58 273.62Z').fill('#FFF8CF');
  groupFour.path('M150.78 268.211C150.78 268.211 151.87 249.921 139.28 236.451Z').fill('#FFF8CF');
  groupFour.path('M150.78 268.211C150.78 268.211 151.87 249.921 139.28 236.451');
  groupFour.path('M151.28 270.11C151.28 270.11 172.06 262.8 178.5 243.19Z').fill('#FFF8CF');
  groupFour.path('M151.28 270.11C151.28 270.11 172.06 262.8 178.5 243.19');
  groupFour.path('M183.16 284.34C175.076 279.075 165.856 275.805 156.26 274.8Z').fill('#FFF8CF');
  groupFour.path('M183.16 284.34C175.076 279.075 165.856 275.805 156.26 274.8');
  groupFour.path('M148.65 305.82C148.65 305.82 153.92 299.97 153.65 274.82Z').fill('#FFF8CF');
  groupFour.path('M148.65 305.82C148.65 305.82 153.92 299.97 153.65 274.82');
  groupFour.path('M146.56 276.36C147.254 277.556 148.295 278.512 149.546 279.101C150.796 279.691 152.196 279.886 153.56 279.66C155.079 279.238 156.438 278.371 157.46 277.17C158.826 275.741 159.738 273.938 160.08 271.99C160.242 271.02 160.194 270.027 159.941 269.077C159.687 268.126 159.234 267.241 158.61 266.48C156.21 263.72 149.82 262.94 147 265.48C144.18 268.02 144.88 273.62 146.56 276.36Z').fill('white');

  const flowerBottonYellowGroup = landscapeBottom.group();
  flowerBottonYellowGroup.addClass('flower-bottom-yellow');
  flowerBottonYellowGroup.css(commonCss);
  flowerBottonYellowGroup.path('M1033.78 266.06C1033.78 266.06 1061.78 313.7 1083.11 330.18C1104.44 346.66 1124.78 346.451 1124.78 346.451C1124.78 346.451 1118.17 321.29 1090.41 299.11C1073.18 285.486 1054.12 274.36 1033.78 266.06V266.06Z').fill('#81A85A');
  flowerBottonYellowGroup.path('M1167.14 243.19C1167.14 243.19 1115.14 295.971 1124.7 344.641C1124.7 344.641 1138.81 339.08 1152.12 312.09C1165.43 285.1 1168.74 250.21 1167.14 243.19Z').fill('#81A85A');
  flowerBottonYellowGroup.path('M1166.4 246.87L1124.7 344.64');
  flowerBottonYellowGroup.path('M1036.08 268.5L1124.78 346.451');

  return svgHelper(landscapeBottom);
};

export default landscapeBottomSvg;
