import { SVG } from "@svgdotjs/svg.js";
import svgHelper from "../../utils/svgHelper";

const potatoOlympicsSvg = () => {
  const potatoOlympics = SVG();

  potatoOlympics.svg(
    '<path d="M334.574 502.572C334.57 502.574 334.572 502.579 334.576 502.579C376.482 499.422 421.76 496.066 456.373 471.094C515.026 430.088 563.868 342.37 561.234 270.338C559.653 227.359 534.747 198.339 500.161 175.04C455.534 145.001 421.294 100.805 384.535 61.2949C372.864 48.7497 361.061 36.0894 347.035 26.2407C318.606 6.28017 282.703 -0.773428 247.969 0.0651111C189.629 1.47912 128.705 17.1483 83.057 54.7675C58.5292 74.9746 39.9276 99.7033 25.82 127.737C12.2392 154.735 3.95902 183.327 1.17701 213.416C-3.64624 265.471 6.34595 314.6 31.3017 360.686C39.8618 376.503 50.5289 390.923 62.8916 404.159C89.3618 432.488 121.528 452.695 156.739 468.677C188.527 483.097 221.614 493.488 255.986 499.835C280.146 504.306 307.318 504.619 334.572 502.565C334.576 502.565 334.577 502.57 334.574 502.572Z"/>'
  );
  potatoOlympics.width(562).height(504).viewbox(0, 0, 562, 504);
  potatoOlympics.addClass("potato-background");

  return svgHelper(potatoOlympics);
};

export default potatoOlympicsSvg;
