import markeLeftSvg from "../../svg/general/icons/markerLeft";
import markeRightSvg from "../../svg/general/icons/markerRight";
import olympicsMarkLeft from "../../svg/olympics/olympicsMarkerLeft"
import olympicsMarkRight from "../../svg/olympics/olympicsMarkerRight"
import olympicsMarkLeftMobile from "../../svg/olympics/olympicsMarkerLeftMobile"
import olympicsMarkRightMobile from "../../svg/olympics/olympicsMarkerRightMobile"

markeLeftSvg().addToClass('[class*="t-marker"] .cmp-title__text');
markeRightSvg().addToClass('[class*="t-marker"] .cmp-title__text');
olympicsMarkLeft().addToClass('[class*="title-olympics"] .cmp-title__text');
olympicsMarkRight().addToClass('[class*="title-olympics"] .cmp-title__text'); 
olympicsMarkLeftMobile().addToClass('[class*="title-olympics"] .cmp-title__text');
olympicsMarkRightMobile().addToClass('[class*="title-olympics"] .cmp-title__text'); 