const allProductsCarouselStore = {
  namespaced: true,
  state: {
    apiError: false,
    pathError: false,
    dataError: false,
    dataName: 'carouselData',
    slideItems: [],
  },
  mutations: {
    setApiError: (state, status) => {
      state.apiError = status;
    },
    setPathError: (state, status) => {
      state.pathError = status;
    },
    setDataError: (state, status) => {
      state.dataError = status;
    },
    setSlideItems: (state, payload) => {
      state.slideItems = payload;
    },
  },
  getters: {
    hasError: state => state.apiError || state.pathError || state.dataError,
    getSlideItems: state => state.slideItems,
    getDataName: state => state.dataName
  },
  actions: {
    loadResults: ({ commit }, { items } ) => {
      commit('setSlideItems', items);
    }
  }
};

export default allProductsCarouselStore;