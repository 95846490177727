//global variable
const mainHeader = document.getElementById("main-header-text");

const mobileMenuNew = () => {
  document.querySelectorAll("#main-nav > ul > li").forEach((navItem) => {
    if (navItem.children.length > 1) {
      navItem.innerHTML += '<span class="navigation-icon"></span>';
    }
  });

  const navigationIcons = document.querySelectorAll(".navigation-icon");

  navigationIcons.forEach((icon) => {
    icon.addEventListener("click", function () {
      const parentNavItem = icon.parentElement;

      if (parentNavItem.classList.contains("is-open")) {
        parentNavItem.classList.remove("is-open");
        document
          .querySelectorAll("#main-nav > ul > li:not(.is-open)")
          .forEach((navItem) => {
            navItem.classList.remove("hide");
          });
      } else {
        parentNavItem.classList.add("is-open");
        document
          .querySelectorAll("#main-nav > ul > li:not(.is-open)")
          .forEach((navItem) => {
            navItem.classList.add("hide");
          });
      }
    });
  });
};

const toggleMobileMenuNew = () => {
  const headerTab = document.getElementById("header-tab");

  headerTab?.addEventListener("click", function () {
    mainHeader.classList.toggle("is-open");
  });
};

const setActivePageClassNew = () => {
  const pageTitleElement = document.querySelector("head title");
  const pageTitleText = pageTitleElement.textContent.trim().toLowerCase();
  const textNavigationLinks = document.querySelectorAll("li > a");

  textNavigationLinks.forEach((link) => {
    let navigationLinkText;

    if (link.getAttribute("title")) {
      navigationLinkText = link.getAttribute("title").trim().toLowerCase();
    }

    if (navigationLinkText && navigationLinkText == pageTitleText) {
      link.parentElement.classList.add("active");
    }
  });
};

const scrollAlign = (element) => {
  if (element.scrollWidth > element.clientWidth) {
    element.classList.add("left-aligned");
  } else {
    element.classList.remove("left-aligned")
  }
};

const showSecondaryNavNew = () => {
  const activeTextElement = document.querySelector(".active");
  if(!activeTextElement) return;

  const subMenu = activeTextElement.querySelector("ul");
  if(!subMenu) return;

  window.onscroll = () => {
    scrollAlign(subMenu);
    
    if (window.scrollY > 100) {
      mainHeader.classList.add("is-scrolled");
    } else {
      mainHeader.classList.remove("is-scrolled");
    }
  };

  mainHeader.onmouseover = () => {
    scrollAlign(subMenu);
  }
  mainHeader.onclick = () => {
    scrollAlign(subMenu);
  }
};

const toggleHeaderOnHoverNew = () => {
  const activeListItemLevel0 = mainHeader.querySelectorAll(
    "#main-nav > ul > li.active"
  );
  const activeListItemLevel1 = mainHeader.querySelectorAll(
    "#main-nav > ul > li > ul li.active"
  );
  let hasSubLists = false;

  activeListItemLevel0.forEach((listItem) => {
    const subList = listItem.querySelector("ul");

    if (subList) {
      hasSubLists = true;
    }
  });

  if (activeListItemLevel1.length > 0) {
    mainHeader.classList.remove("header-clone");
  } else if (hasSubLists) {
    mainHeader.classList.add("header-clone");
  }
};

const hideOuterLinksNew = () => {
  const textListItemsLevel0 = document.querySelectorAll("#main-nav>ul>li>a");
  textListItemsLevel0.forEach((list) => {
    list.classList.add("hide");
  });
};

const onDocumentReady = () => {
  const mainNavNew = document.getElementById("main-header-text");

  if (mainNavNew) {
    toggleMobileMenuNew();
    mobileMenuNew();
    setActivePageClassNew();
    showSecondaryNavNew();
    toggleHeaderOnHoverNew();
    hideOuterLinksNew();
  }
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}