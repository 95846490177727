import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const buttonBackgroundSvg = () => {
  const buttonBackground = SVG();

  buttonBackground.svg('<path d="M132.92 1.5q5.36 0 11.41.11a19.5 19.5 0 0119.17 19.5h0v1.18a19.5 19.5 0 01-19 19.5q-10.47.27-16.08.28h-.63c-2.09 0-3.62 0-4.57-.1-3.81-.26-11.8-.35-21.26-.36h-2.68c-12.09 0-26.08.14-36.65.24-6.32.06-11.43.11-14.17.12h-.85q-7.35 0-27-.39A19.5 19.5 0 011.5 22.08 19.84 19.84 0 017.1 8.13a19.06 19.06 0 0113.64-5.72c12.69.08 22.1 0 28.37-.25 5.81-.23 14 0 23 .16 12.66.29 26.71.61 37.28-.16q8.66-.65 21.91-.66h1.63z" stroke-width="3"></path>');
  buttonBackground.viewbox(0, 0, 165, 43.57);
  buttonBackground.attr('preserveAspectRatio', 'none');
  buttonBackground.addClass('button-background');


  return svgHelper(buttonBackground);
}

export default buttonBackgroundSvg;

