const activityCardStore = {
  namespaced: true,
  state: {
    apiError: false,
    pathError: false,
    dataError: false,
    dataName: 'cardsData',
    cardItems: [],
    translationItems: [],
    shareLink: ''
  },
  mutations: {
    setApiError: (state, status) => {
      state.apiError = status;
    },
    setPathError: (state, status) => {
      state.pathError = status;
    },
    setDataError: (state, status) => {
      state.dataError = status;
    },
    setCardItems: (state, payload) => {
      state.cardItems = payload;
    },
    setTranslationItems: (state, payload) => {
      state.translationItems = payload;
    },
    setShareLink: (state, payload) => {
      state.shareLink = payload
    }
  },
  getters: {
    hasError: state => state.apiError || state.pathError || state.dataError,
    getCardItems: state => state.cardItems,
    getTranslationItems: state => state.translationItems,
    getDataName: state => state.dataName,
    getShareLink: state => state.shareLink
  },
  actions: {
    loadResults: ({ commit }, { items, translationItems } ) => {
      commit('setCardItems', items);
      commit('setTranslationItems', translationItems);
    },
    updateShareLink: ({ commit }, { shareLink }) => {
      commit('setShareLink', shareLink)
    }
  }
};

export default activityCardStore;