<template>
  <div class="side-ellipse-root">
    <svg class="side-ellipse-prev side-ellipse" xmlns="http://www.w3.org/2000/svg" width="124" height="144.38" viewBox="0 0 124 144.38">
      <ellipse cx="62" cy="72.19" rx="62" ry="72.19"  :style="{'fill': fill}" />
    </svg>
    <svg class="side-ellipse-next side-ellipse" xmlns="http://www.w3.org/2000/svg" width="124" height="144.38" viewBox="0 0 124 144.38">
      <ellipse cx="62" cy="72.19" rx="62" ry="72.19" :style="{'fill': fill}" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AllProductsEllipse',
  props: {
    fill: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.side-ellipse {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  z-index: 1;
}

.side-ellipse-prev {
  left: -80px;
  @media only screen and (min-width: 768px) {
    left: -60px;
  }
}

.side-ellipse-next {
  right: -80px;
  @media only screen and (min-width: 768px) {
    right: -60px;
  }
}

</style>