<template>
  <div 
    class="product-container-root"
  >
    <div 
      v-show="showSideNavAndBop"
      class="container-product-nav"
    >
      <product-nav 
        :productDotFills="getProductDotFills"
        :isFlipped="isFlipped"
        :currentTileIndex="currentTileIndex"
        :isUnstick="passedBottomMarker"
        @updatenav="updateNavPoistion"
      />
    </div>
    <div class="container-bottom-wave">
      <wave
        :isBottomWave="true"
        :waveFill="getBottomWaveFill"
      />
    </div>
    <div 
      class="products-container"
      :style="{'background-color': getCurrentBackground}"
      :isFlipped="isFlipped"
    >
      <div 
        v-for="(productItem, productIndex) in productItems"
        :key="productIndex"
        class="product-tiles"
      >
        <product-tile 
          :productData="productItem"
          :translations="translationItem"
          :isFlipped="isFlipped"
        />
      </div>
      <div 
        v-show="showSideNavAndBop"
        :class="['product-bop', { 'flipped': isFlipped }]"
      >
        <product-bop 
          v-if="enableBop"
          :flyoutText="getBopFlyoutText" 
          :returnText="getBopReturnText" 
          :cornerBackgroundFill="getCurrentBopBackground"
          :isFlipped="isFlipped"
          :isUnstick="passedBottomMarker"
          @flipaction="updateFlip"
        />
      </div>
    </div>
    <div 
      class="container-top-wave"
      :style="{'background-color': getTopWaveBackGround}"
    >
      <wave
        :isBottomWave="false"
        :waveFill="defaultTopWaveFill"
      />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import Wave from '../components/core/Wave';
import ProductTile from '../components/product/product/ProductTile';
import ProductNav from '../components/product/product/ProductNav';
import ProductBop from '../components/product/product/ProductBop';

export default {
  name: 'Product',
  components: {
    Wave,
    ProductTile,
    ProductNav,
    ProductBop
  },
  props: {
    query: {
      type: String,
      required: true
    },
    folder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      storeName: 'product',
      currentTileIndex: 0,
      defaultTopWaveFill: '#F8FAFB',
      isFlipped: false,
      navPosition: 0,
      showSideNavAndBop: false,
      passedBottomMarker: false,
      gsapApplied: false,
      tilePositions: [],
    }
  },
  created () {
    this.$store.dispatch('performApiQuery', { query: this.query, store: this.storeName, folder: this.folder });
  },
  computed: {
    ...mapGetters({
      productItems: 'product/getProductItems',
      translationItem: 'product/getFirstTranslationItem'
    }),
    getProductDotFills () {
      if (!this.productItems.length) return [];
      return this.getProductProperty('leadBackgroundColor')
    },
    getBottomWaveFill () {
      if (!this.productItems.length) return '#FFF';
      return this.productItems[0].leadBackgroundColor;
    },
    getTopWaveBackGround () {
      if (!this.productItems.length) return '#FFF';
      const lastProduct = this.productItems[this.productItems.length -1];
      return lastProduct.leadBackgroundColor;
    },
    getBopFlyoutText () {
      return this.getTranslationItem('bopButtonFlyoutText');
    },
    getBopReturnText () {
      return this.getTranslationItem('bopButtonReturnText');
    },
    getCurrentBackground () {
      if (!this.productItems.length) return '#FFF';
      return this.productItems[this.currentTileIndex].leadBackgroundColor;
    },
    getCurrentBopBackground () {
      if (!this.productItems.length) return '#FFF';
      return this.productItems[this.currentTileIndex].bopButtonColor;
    },
    enableBop () {
      if (!this.productItems.length) return false
      for (const productItem of this.productItems) {
        if (!productItem.bopDesktopImage) {
          return false;
        }
      }
      return true;
    }
  },
  updated () {
    if (this.gsapApplied || !this.productItems.length) return;
    this.applyGsapMethods();
    this.gsapApplied = true;
  },
  methods: {
    getProductProperty (property) {
      let propertyArr = [];
      for (const productItem of this.productItems) {
        propertyArr.push(productItem[property])
      }
      return propertyArr;
    },
    getTranslationItem (item) {
      if (!this.translationItem) return '';
      return this.translationItem[item];
    },
    updateFlip (event) {
      this.isFlipped = event;
    },
    updateBackgrounds (event) {
      this.currentTileIndex = event;
      this.navPosition = event;
    },
    updateNavPoistion (event) {
      this.gsapScroll(event);
      this.navPosition = event;
    },
    applyGsapMethods () {
      gsap.registerPlugin(ScrollTrigger);
      const productTiles = gsap.utils.toArray('.product-tiles');

      productTiles.forEach((productTile, productTileIndex) => {
        const isOdd = productTileIndex % 2 === 1;
        const firstProductTile = productTileIndex === 0;
        const lastProductTile = productTileIndex === this.productItems.length -1;

        const st = ScrollTrigger.create({
          trigger: productTile,
          start: 'top center',
          end: 'bottom top',
          onToggle: ({isActive}) => {if (isActive) this.updateBackgrounds(productTileIndex);},
          onUpdate: ({progress}) => {
            if (firstProductTile) this.showSideNavAndBop = progress > 0.4; //nav appears at 40% top tile
            if (lastProductTile) this.passedBottomMarker = progress > 0.3; //nav hides at 30% bottom tile
          } 
        })

        gsap.fromTo(
          productTile.querySelector('.product-tile-images'),
          {
            rotation: 0,
            y: '100px',
          },
          {
            scrollTrigger: {
              trigger: productTile,
              start: 'top center',
              end: 'bottom top',
              scrub: 0.6,
            },
            ease: 'power2.out',
            rotation: isOdd ? -10 : 10,
            y: '-100px',
          }
        );

        this.tilePositions.push(productTile); 
      }); 
    },
    gsapScroll (navIndex) {
      this.tilePositions[navIndex].scrollIntoView({ behavior: "smooth" });
    }
  }
}
</script>

<style lang="scss" scoped>

.products-container {
  transition: background-color 1s ease;
  // padding-bottom: 100px;
}

.products-container[isFlipped=true] .product-tiles {
  transform: scaleX(-1);
}
.product-tiles {
  padding: 4.75rem 20px 8.75rem 20px;
  max-width: 1210px;
  margin: 0 auto;
  transition: transform 0.3s cubic-bezier(0.63, -0.01, 0.26, 1.49);

  @media only screen and (min-width: 1023px) {
    min-height: 1000px;
    height: 100vh;
  }
}

.container-bottom-wave {
  bottom: -2px;
  position: relative;
}

.product-container-root {
  position: relative;
}

.product-bop {
  position: sticky;
  bottom: 0;
  left: 100%;
  width: 100px;
  z-index: 999;

  &.flipped {
    left: inherit;
    right: 100%;
    bottom: calc(100% - 94px);
  }
}

</style>

<style lang="scss">
.aem-AuthorLayer-Edit {
  .product-tiles {
    height: auto;
  }
}
</style>
