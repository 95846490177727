<template>
  <div class="activity-container">
    <div v-for="(translation, translationIndex) in translationItems" :key="translationIndex">
      <div class="activity-heroes">
        <activity-hero
          seasonType="spring"
          :seasonTitle="translation.springHeroTitle"
          :seasonDescription="translation.springHeroDescription.plaintext"
          :sesaonActive="translation.currentSeason"
        />
        <activity-hero
          seasonType="summer"
          :seasonTitle="translation.summerHeroTitle"
          :seasonDescription="translation.summerHeroDescription.plaintext"
          :sesaonActive="translation.currentSeason"
        />
        <activity-hero
          seasonType="autumn"
          :seasonTitle="translation.autumnHeroTitle"
          :seasonDescription="translation.autumnHeroDescription.plaintext"
          :sesaonActive="translation.currentSeason"
        />
        <activity-hero
          seasonType="winter"
          :seasonTitle="translation.winterHeroTitle"
          :seasonDescription="translation.winterHeroDescription.plaintext"
          :sesaonActive="translation.currentSeason"
        />
      </div>
    </div>

      <div class="activity-cards">
          <activity-card v-for="(card, cardIndex) in cardItems" :key="cardIndex" v-show="showCard(card)"
            :card="card"
            :sesaonActive="getCurrentSeason()" 
            :allCardsShown="allSeasonsShown"
            :translations="getTranslations"
          />
        </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivityHero from '../components/kids/ActivityHero';
import ActivityCard from '../components/kids/ActivityCard';

export default {
  name: 'KidsActivitySeasonPage',
  components: {
    ActivityHero,
    ActivityCard
  },
  props: {
    query: {
      type: String,
      required: true,
    },
    folder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      allSeasonsShown: false,
      storeName: 'activityCard'
    }
  },
  created () {
    this.$store.dispatch('performApiQuery', { query: this.query, store: this.storeName, folder: this.folder });
  },
  computed: {
    ...mapGetters({
      cardItems: 'activityCard/getCardItems',
      translationItems: 'activityCard/getTranslationItems'
    }),
    getTranslations () {
      if (!this.translationItems.length) return {};
      return this.translationItems[0];
    }
  },
  methods: {
    getCurrentSeason () {
      return this.translationItems[0]['currentSeason']
    },
    showCard (card) {
      const currentSeason = this.getCurrentSeason();
      return this.allSeasonsShown || currentSeason === card.season
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-container {
  max-width: 1400px;  
  padding: 0 20px;
  margin: 0 auto;
}

.activity-cards {
  margin-top: 50px;
  margin-bottom: 50px;
    @media only screen and (min-width: 1023px) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 90px;
      margin-bottom: 90px;
    }
    .card-container {
      @media only screen and (min-width: 1023px) {
        margin-left: 15px;
        margin-right: 15px;
        flex-basis: calc((100% / 3) - 30px);
        max-width: calc((100% / 3) - 30px);
        display: flex;
        flex-direction: column;
      }
    }
}
</style>