const removeIndent = (itemStr) => {
  return itemStr.replace('>', '');
}

export const useIsIndent = (itemStr) => {
  return itemStr.startsWith('>');
}

export const useGetTableHeading = (itemStr) => {
  let cleanStr = removeIndent(itemStr);
  let splitStrArr = cleanStr.split('|');
  return splitStrArr[0];
}

export const useGetTableData = (itemStr) => {
  let cleanStr = removeIndent(itemStr);
  let splitStrArr = cleanStr.split('|');
  return splitStrArr.length >= 2 ? splitStrArr[1] : '';
}