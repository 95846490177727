import { SVG } from "@svgdotjs/svg.js";
import svgHelper from "../../utils/svgHelper";

const potatoCarouselSvg = () => {
  const potatoCarousel = SVG();

  potatoCarousel.svg(
    '<path d="M548.588 211.668C545.682 171.244 533.3 131.498 511.251 96.7117C450.216 0.370329 330.992 -21.399 228.268 21.6668C188.343 38.3975 152.742 62.0113 119.834 89.5499C103.402 103.309 87.494 117.768 72.2672 132.818C29.8805 174.681 -2.69023 223.193 0.934736 284.841C6.38343 377.707 93.0906 451.224 181.081 470.081C353.788 507.114 562.724 407.842 548.602 211.651L548.588 211.668Z"/>'
  );
  potatoCarousel.width(550).height(478).viewbox(0, 0, 550, 478);
  potatoCarousel.addClass("potato-background");

  return svgHelper(potatoCarousel);
};

export default potatoCarouselSvg;
