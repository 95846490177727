<template>
  <h3 class="title-container">
    {{ titleText }}
  </h3>
</template>

<script>
export default {
  name: 'ActivityTitle',
  props: {
    titleText: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .title-container {  
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
    margin-top: 0;
    @media only screen and (min-width: 1023px) {
      font-size: 38px;
      line-height: 46px;
    }
  }
</style>