import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../../utils/svgHelper';

const landscapeLeftSvg = () => {
  const landscapeLeft = SVG();
  landscapeLeft.width(231)
    .height(903)
    .viewbox('0 0 231 903')
    .addClass('landscape-left')
    .fill('none');

  const commonCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-linejoin': 'round',
  };

  const groupOne = landscapeLeft.group();
  groupOne.css(commonCss).fill('#A3D380');
  groupOne.path('M117.38 886.701C118.26 888.251 119.04 890.321 117.87 891.701C116.53 893.231 114.05 892.311 112.3 891.291C102.96 885.851 93.77 879.931 86.3 872.131C82.3187 868.034 78.9809 863.357 76.4 858.261C75.19 855.841 69.74 844.081 74.48 845.051C76.83 845.531 80.31 849.271 82.25 850.721C84.8434 852.647 87.4 854.614 89.92 856.621C94.9529 860.591 99.7261 864.88 104.21 869.461C109.296 874.641 113.72 880.432 117.38 886.701V886.701Z');
  groupOne.path('M132.15 846.961C134.01 846.051 136.15 843.901 134.8 842.301C134.193 841.709 133.395 841.355 132.55 841.301C123.743 839.811 114.807 839.222 105.88 839.541C96.66 839.861 87.24 841.231 79.15 845.671C78.62 845.961 78.01 846.421 78.15 847.021C78.2082 847.213 78.3047 847.392 78.4335 847.547C78.5624 847.701 78.721 847.829 78.9 847.921C86.03 852.771 96.17 853.411 104.6 853.361C114.144 853.296 123.555 851.11 132.15 846.961V846.961Z');
  groupOne.path('M87.44 879.431C89.0667 883.031 90.2229 886.825 90.88 890.72C91.1616 892.22 91.0514 893.766 90.56 895.211C90.2977 895.921 89.8389 896.543 89.237 897.004C88.6351 897.464 87.9148 897.744 87.16 897.811C85.16 897.811 83.76 896.021 82.66 894.391C72.98 880.111 62.97 864.88 62.07 847.66C61.95 845.48 62.07 843.141 63.23 841.311C66.02 837.031 68.23 840.111 69.73 842.961C71.86 847.031 74.18 850.961 76.27 855.081C80.3367 863.034 84.06 871.151 87.44 879.431Z');
  groupOne.path('M98.03 841.03C106.51 837.56 114.27 832.6 121.98 827.66C123.312 826.923 124.463 825.898 125.35 824.66C125.78 824.037 125.994 823.29 125.96 822.534C125.925 821.777 125.645 821.052 125.16 820.47C124.16 819.47 122.6 819.47 121.21 819.6C114.17 820.02 107.147 820.654 100.14 821.501C92.43 822.431 84.38 823.761 78.19 828.451C74.73 831.081 72.07 834.601 69.48 838.091C63.83 845.721 74.48 845.57 79.4 845.36C85.8118 844.992 92.113 843.528 98.03 841.03V841.03Z');
  groupOne.path('M67.16 882.811C67.61 888.951 67.72 895.611 64.16 900.661C63.8776 901.124 63.4773 901.503 63 901.761C61.51 902.431 60.07 900.761 59.3 899.321C50.887 883.509 46.5721 865.84 46.75 847.931C46.75 844.081 48.25 833.491 54.48 835.451C57.6 836.451 60.88 842.451 61.83 845.451C65.57 857.101 66.32 870.721 67.16 882.811Z');
  groupOne.path('M109.71 816.891C111.25 815.361 112.77 812.891 111.42 811.231C110.62 810.231 109.16 810.061 107.87 810.091C101.2 810.261 95.02 813.421 89.1 816.491C83.46 819.265 78.0202 822.43 72.82 825.961C70.07 827.961 59.46 835.141 61.23 839.441C63.6 845.151 89.08 831.651 92.94 829.441C99.0645 826.024 104.705 821.803 109.71 816.891V816.891Z');
  groupOne.path('M89.77 816.241C93.77 813.921 97.7067 811.554 101.58 809.141C107.05 805.751 112.79 801.851 115.01 795.811C115.47 794.591 115.67 793.001 114.67 792.161C114.225 791.888 113.726 791.716 113.208 791.656C112.69 791.595 112.166 791.648 111.67 791.811C94.67 794.881 79.15 802.991 65.4 813.151C61.94 815.711 58.59 818.411 55.32 821.211C52.88 823.311 47.16 826.811 45.93 829.811C43.48 835.711 59.93 830.411 62.32 829.581C71.97 826.261 80.98 821.371 89.77 816.241Z');
  groupOne.path('M48.17 864.211C50.06 872.661 51.97 881.211 51.88 889.851C51.88 892.991 51.14 896.741 48.24 897.951C45.34 899.161 42.24 897.081 40.84 894.441C39.44 891.801 39.26 888.701 38.7 885.761C37.9539 882.493 37.0427 879.266 35.97 876.091C32.0352 862.825 31.2342 848.828 33.63 835.201C34 833.061 35.32 827.591 38.16 827.261C41.23 826.911 41.66 835.211 42.24 837.761L48.17 864.211Z');
  groupOne.path('M90.37 798.151C95.37 793.881 100.19 788.921 101.76 782.571C101.986 781.918 102.074 781.226 102.019 780.537C101.964 779.849 101.767 779.179 101.44 778.571C100.21 776.811 97.6 776.991 95.5 777.441C85.89 779.561 76.83 783.691 68.12 788.281C54.02 795.721 40.22 804.821 31.28 818.031C30.8639 818.54 30.6005 819.157 30.52 819.811C30.52 821.081 31.68 822.001 32.79 822.621C38.51 825.801 41.44 826.031 47.34 823.791C63.0594 817.832 77.6129 809.164 90.34 798.181L90.37 798.151Z');
  groupOne.path('M34.96 876.441C35.43 880.531 36.05 884.651 35.56 888.741C35.07 892.831 33.29 896.971 29.88 899.311C29.249 899.794 28.506 900.111 27.72 900.23C26.5605 900.274 25.4288 899.87 24.56 899.1C21.05 896.34 20.24 891.441 19.67 887.011C18.82 880.291 17.96 873.541 18.19 866.771C18.32 862.671 18.85 858.591 19.38 854.521L21.38 839.521C21.91 835.421 22.46 831.261 24.04 827.431C25.16 824.731 27.28 821.941 30.21 821.951C40.44 821.951 34.56 839.451 34.21 845.151C33.5303 855.582 33.7812 866.053 34.96 876.441Z');
  groupOne.path('M86.77 789.541C89.01 788.921 91.77 787.601 91.53 785.291C91.33 783.481 89.34 782.51 87.61 781.97C74.69 777.91 60.48 779.121 47.73 783.691C34.98 788.261 23.58 796.031 13.38 804.931C12.28 805.931 11.08 807.181 11.38 808.611C11.83 810.611 13.74 811.041 14.69 812.671C15.79 814.541 15.69 817.671 15.95 819.761C16.8033 825.807 17.5767 831.867 18.27 837.941C19.6633 850.081 20.7433 862.254 21.51 874.461C21.83 879.591 21.89 885.331 18.41 889.121C17.8584 889.815 17.0793 890.293 16.21 890.47C14.44 890.69 13.1 888.97 12.21 887.47C2.74 870.35 2.68 849.811 2.88 830.261C2.95 822.841 3.06 815.201 5.88 808.331C5.97509 808.007 6.13376 807.706 6.34669 807.445C6.55962 807.184 6.82253 806.969 7.12 806.811C9.9 805.751 12.53 809.811 14.69 810.811C17.26 811.941 19.46 811.061 22.01 810.141C29.92 807.301 37.82 804.501 45.81 801.901C59.3633 797.441 73.0167 793.321 86.77 789.541Z');
  groupOne.path('M28.32 794.211C37.88 789.811 46.55 783.811 55.2 777.891C58.94 775.321 62.88 772.511 64.55 768.281C65.14 766.781 65.34 764.891 64.24 763.711C62.97 762.341 60.74 762.711 58.94 763.201C54.2027 764.68 49.5634 766.457 45.05 768.521L30.42 774.811C24.3411 777.251 18.4517 780.139 12.8 783.451C9.53 785.451 -10.13 799.451 0.540006 800.721C9.65001 801.811 20.23 797.951 28.32 794.211Z');
  groupOne.path('M11 789.541L33.57 775.171C42.66 769.371 52.48 762.611 55.13 752.171C55.5 750.701 55.67 748.991 54.72 747.801C53.27 745.991 50.4 746.631 48.25 747.491C34.9432 752.69 22.7315 760.343 12.25 770.051C5.68999 776.261 0.00999296 783.321 -5.65001 790.361C-8.52001 793.921 -8.02001 798.031 -2.96001 796.831C2.09999 795.631 6.78999 792.231 11 789.541Z');
  groupOne.path('M2.4 845.621C1.88 854.101 1.32 862.761 -1.93 870.621C-3.18 873.621 -5.87 876.971 -8.99 876.031C-12.11 875.091 -12.47 870.631 -12.63 867.221C-12.9557 863.488 -13.5574 859.784 -14.43 856.141C-17.82 839.141 -17.79 821.061 -11.35 804.981C-10.35 802.431 -8.9 799.711 -6.35 798.791C-5.24155 798.473 -4.07139 798.436 -2.94526 798.684C-1.81912 798.932 -0.77251 799.457 0.1 800.211C6.42 805.271 4.43 812.021 3.99 819.211L2.4 845.621Z');
  groupOne.path('M24.33 752.331C24.61 751.041 24.76 749.471 23.76 748.611C22.76 747.751 21.12 748.181 19.9 748.811C14.77 751.471 11.09 756.221 7.63 760.811C1.59 768.911 -4.32667 777.077 -10.12 785.311C-11.21 786.851 -12.33 788.521 -12.39 790.411C-12.68 800.531 7.51 782.611 9.79 780.131C16.9867 772.297 22.0005 762.71 24.33 752.331V752.331Z');
  groupOne.path('M129.51 874.16C131.413 874.927 133.461 875.268 135.51 875.16C136.489 875.165 137.445 874.862 138.241 874.293C139.038 873.724 139.635 872.919 139.948 871.992C140.262 871.064 140.275 870.062 139.986 869.126C139.697 868.191 139.121 867.37 138.34 866.78C136.966 865.613 135.39 864.708 133.69 864.11C127.853 861.62 121.836 859.574 115.69 857.99C111.6 856.99 101.06 853.75 97.44 856.29C105.18 864.81 118.75 870.61 129.51 874.16Z');
  groupOne.path('M10.73 762.811C11.6801 761.24 12.2962 759.49 12.54 757.671C12.6443 756.757 12.5155 755.833 12.1658 754.983C11.8161 754.133 11.2567 753.386 10.54 752.811C9.70118 752.297 8.73954 752.018 7.75596 752.004C6.77239 751.99 5.80317 752.241 4.95 752.731C3.25004 753.722 1.77264 755.053 0.609995 756.641C-1.94001 759.741 -20.13 787.391 -15.39 789.441C-12.75 790.581 -3.19001 780.091 -1.31001 778.251C3.45088 773.733 7.50913 768.529 10.73 762.811V762.811Z');
  groupOne.path('M94.57 622.091C95.45 620.541 96.23 618.461 95.06 617.091C93.72 615.561 91.24 616.471 89.49 617.501C80.15 622.941 70.96 628.861 63.49 636.661C59.5112 640.756 56.1736 645.429 53.59 650.521C52.37 652.991 46.88 664.751 51.66 663.811C54.01 663.331 57.49 659.581 59.43 658.141C62.0233 656.214 64.58 654.247 67.1 652.241C72.1329 648.27 76.906 643.982 81.39 639.401C86.4839 634.199 90.9112 628.385 94.57 622.091Z');
  groupOne.path('M109.34 661.811C111.2 662.711 113.34 664.86 111.99 666.47C111.383 667.062 110.585 667.416 109.74 667.47C100.929 668.962 91.9906 669.559 83.06 669.251C73.84 668.921 64.42 667.551 56.33 663.121C55.8 662.831 55.19 662.371 55.33 661.771C55.3872 661.577 55.4832 661.398 55.6122 661.243C55.7411 661.088 55.9003 660.962 56.08 660.871C63.21 656.021 73.35 655.371 81.78 655.431C91.3261 655.487 100.74 657.667 109.34 661.811Z');
  groupOne.path('M64.63 629.361C66.2533 625.76 67.4061 621.966 68.06 618.071C68.3417 616.571 68.2314 615.025 67.74 613.581C67.4777 612.87 67.019 612.248 66.417 611.788C65.8151 611.327 65.0948 611.047 64.34 610.981C62.34 610.981 60.94 612.771 59.84 614.401C50.16 628.681 40.15 643.901 39.25 661.131C39.13 663.311 39.25 665.641 40.41 667.481C43.2 671.761 45.41 668.671 46.91 665.831C49.04 661.761 51.36 657.831 53.46 653.711C57.52 645.777 61.2433 637.661 64.63 629.361Z');
  groupOne.path('M75.23 667.811C83.7 671.271 91.46 676.241 99.17 681.181C100.504 681.915 101.656 682.94 102.54 684.181C102.97 684.804 103.184 685.551 103.15 686.308C103.115 687.064 102.835 687.789 102.35 688.371C101.35 689.371 99.79 689.371 98.35 689.241C91.31 688.821 84.2867 688.187 77.28 687.341C69.57 686.411 61.52 685.081 55.28 680.341C51.82 677.711 49.16 674.191 46.57 670.691C40.92 663.071 51.57 663.211 56.49 663.431C62.9424 663.8 69.2825 665.281 75.23 667.811V667.811Z');
  groupOne.path('M44.35 625.961C44.8 619.821 44.91 613.151 41.35 608.101C41.0685 607.639 40.6678 607.263 40.19 607.011C38.7 606.341 37.26 608.011 36.49 609.451C28.0958 625.257 23.7948 642.914 23.98 660.811C23.98 664.651 25.48 675.251 31.71 673.291C34.83 672.291 38.11 666.291 39.06 663.291C42.76 651.691 43.51 638.071 44.35 625.961Z');
  groupOne.path('M86.88 691.901C88.42 693.431 89.94 695.901 88.59 697.561C87.79 698.561 86.33 698.731 85.04 698.701C78.37 698.521 72.19 695.371 66.27 692.291C60.6345 689.516 55.2011 686.348 50.01 682.811C47.26 680.861 36.65 673.631 38.42 669.331C40.79 663.621 66.27 677.121 70.13 679.331C76.2509 682.752 81.8846 686.98 86.88 691.901V691.901Z');
  groupOne.path('M66.96 692.551C70.96 694.857 74.8967 697.224 78.77 699.651C84.24 703.041 89.98 706.941 92.2 712.981C92.66 714.201 92.86 715.791 91.86 716.631C91.4151 716.903 90.9165 717.075 90.3985 717.135C89.8805 717.196 89.3556 717.143 88.86 716.981C71.86 713.901 56.34 705.801 42.59 695.631C39.13 693.081 35.78 690.381 32.51 687.581C30.06 685.481 24.31 681.951 23.08 678.971C20.63 673.061 37.08 678.371 39.47 679.201C49.16 682.521 58.17 687.421 66.96 692.551Z');
  groupOne.path('M25.36 644.571C27.25 636.131 29.16 627.571 29.07 618.931C29.07 615.791 28.33 612.051 25.43 610.841C22.53 609.631 19.43 611.711 18.03 614.351C16.63 616.991 16.45 620.091 15.89 623.031C15.1452 626.295 14.234 629.519 13.16 632.691C9.22544 645.959 8.42442 659.96 10.82 673.591C11.2 675.731 12.51 681.201 15.35 681.521C18.42 681.881 18.85 673.581 19.43 671.031L25.36 644.571Z');
  groupOne.path('M67.56 710.631C72.51 714.911 77.38 719.87 78.95 726.22C79.1758 726.873 79.264 727.566 79.2089 728.254C79.1538 728.942 78.9567 729.612 78.63 730.22C77.4 731.97 74.79 731.801 72.69 731.341C63.08 729.231 54.02 725.101 45.31 720.511C31.21 713.071 17.41 703.971 8.47001 690.761C8.05392 690.251 7.79046 689.634 7.71001 688.981C7.71001 687.711 8.87 686.791 9.98 686.171C15.7 682.991 18.63 682.761 24.53 685.001C29.7266 686.976 34.805 689.25 39.74 691.811C49.7147 696.979 59.0513 703.295 67.56 710.631V710.631Z');
  groupOne.path('M12.15 632.351C12.62 628.261 13.24 624.131 12.75 620.051C12.26 615.971 10.48 611.811 7.1 609.481C6.46898 608.997 5.72599 608.68 4.94 608.561C3.78052 608.517 2.64875 608.921 1.78 609.691C-1.73 612.451 -2.54 617.351 -3.11 621.771C-3.96 628.491 -4.82 635.251 -4.59 642.011C-4.46 646.121 -3.93 650.201 -3.4 654.271L-1.4 669.271C-0.870002 673.381 -0.320003 677.541 1.26 681.371C2.38 684.071 4.5 686.861 7.43 686.851C17.66 686.851 11.78 669.341 11.43 663.651C10.7397 653.217 10.9807 642.742 12.15 632.351V632.351Z');
  groupOne.path('M63.96 719.251C66.2 719.861 68.96 721.191 68.72 723.501C68.52 725.301 66.53 726.281 64.8 726.821C51.88 730.881 37.67 729.671 24.88 725.101C12.09 720.531 0.770001 712.811 -9.43 703.861C-10.53 702.861 -11.73 701.611 -11.43 700.181C-10.98 698.181 -9.07 697.751 -8.12 696.121C-7.02 694.251 -7.12 691.121 -6.86 689.031C-6.00667 682.977 -5.23333 676.914 -4.54 670.841C-3.14667 658.701 -2.06667 646.531 -1.3 634.331C-0.979999 629.201 -0.92 623.461 -4.4 619.671C-4.95164 618.976 -5.73066 618.498 -6.6 618.321C-8.37 618.101 -9.71 619.821 -10.6 621.321C-20.07 638.441 -20.12 658.991 -19.95 678.521C-19.88 685.951 -19.77 693.591 -16.95 700.461C-16.8585 700.789 -16.7015 701.096 -16.4884 701.362C-16.2753 701.629 -16.0105 701.849 -15.71 702.011C-12.93 703.071 -10.3 699.011 -8.14 698.011C-5.57 696.881 -3.37 697.761 -0.82 698.681C7.09 701.511 14.99 704.321 22.98 706.921C36.5467 711.361 50.2067 715.471 63.96 719.251Z');
  groupOne.path('M5.50999 714.581C15.04 718.981 23.74 724.951 32.39 730.891C36.13 733.471 40.07 736.281 41.74 740.511C42.33 742.011 42.53 743.891 41.43 745.081C40.16 746.451 37.93 746.081 36.13 745.591C31.3957 744.103 26.757 742.326 22.24 740.271L7.60999 733.971C1.53093 731.527 -4.35855 728.635 -10.01 725.321C-13.28 723.321 -32.94 709.321 -22.27 708.061C-13.12 706.951 -2.58001 710.811 5.50999 714.581Z');
  groupOne.path('M-11.81 719.251L10.76 733.621C19.85 739.421 29.67 746.181 32.32 756.621C32.69 758.091 32.86 759.801 31.91 760.991C30.46 762.801 27.6 762.161 25.44 761.291C12.1295 756.099 -0.0835676 748.445 -10.56 738.731C-17.12 732.551 -22.76 725.491 -28.42 718.451C-31.29 714.891 -30.79 710.781 -25.73 711.981C-20.67 713.181 -16.02 716.561 -11.81 719.251Z');
  groupOne.path('M1.52 756.461C1.8 757.751 1.96 759.321 0.949997 760.181C-0.060003 761.041 -1.69 760.611 -2.91 759.981C-8.04 757.311 -11.72 752.561 -15.18 747.981C-21.22 739.867 -27.1367 731.701 -32.93 723.481C-34.02 721.931 -35.14 720.271 -35.2 718.381C-35.49 708.261 -15.3 726.171 -13.02 728.661C-5.82328 736.495 -0.809478 746.081 1.52 756.461V756.461Z');
  groupOne.path('M106.7 634.631C108.602 633.86 110.651 633.518 112.7 633.631C113.679 633.626 114.635 633.929 115.431 634.498C116.228 635.067 116.825 635.872 117.138 636.799C117.452 637.727 117.465 638.73 117.176 639.665C116.887 640.6 116.311 641.421 115.53 642.011C114.159 643.188 112.583 644.103 110.88 644.711C105.044 647.199 99.0264 649.238 92.88 650.811C88.79 651.811 78.25 655.061 74.63 652.521C82.37 643.981 95.94 638.181 106.7 634.631Z');
  groupOne.path('M131.32 299.881C131.32 299.881 52.73 331.811 22.94 360.431C-6.85 389.051 -11.06 420.061 -11.06 420.061C-11.06 420.061 28.62 415.651 68.51 378.471C108.4 341.291 131.32 299.881 131.32 299.881Z');
  groupOne.path('M136.22 507.461C136.22 507.461 67.72 416.811 -8.3 420.341C-8.3 420.341 -3.02 443.011 34.98 469.251C72.98 495.491 125.21 508.311 136.22 507.461Z');
  groupOne.path('M-27.99 782.151C-27.8275 780.41 -28.212 778.662 -29.09 777.151C-29.5418 776.411 -30.2184 775.835 -31.0209 775.508C-31.8234 775.18 -32.7097 775.118 -33.55 775.331C-34.93 775.801 -35.79 777.151 -36.55 778.421C-40.2677 784.972 -43.2843 791.897 -45.55 799.081C-46.28 801.341 -46.78 804.191 -45.02 805.791C-44.4265 806.262 -43.7176 806.567 -42.9667 806.672C-42.2159 806.778 -41.4506 806.681 -40.75 806.391C-39.3659 805.806 -38.1267 804.926 -37.12 803.811C-35.0886 801.848 -33.4286 799.534 -32.22 796.981C-31.5497 795.358 -31.0048 793.686 -30.59 791.981C-29.59 788.301 -29.22 781.821 -25.59 779.691C-22.59 777.951 -18.24 778.111 -14.99 776.471C-8.36 773.121 -2.45 767.841 2.38 762.241C2.76093 761.898 3.0675 761.48 3.28078 761.014C3.49405 760.548 3.6095 760.043 3.62 759.53C3.49 758.36 2.34001 757.531 1.24001 757.181C-3.24999 755.541 -8.21 757.441 -12.46 759.611C-19.0472 762.972 -25.2558 767.028 -30.98 771.711C-31.9 772.461 -32.89 773.471 -32.67 774.631C-32.4947 775.214 -32.1459 775.73 -31.67 776.111C-30.3712 777.38 -28.7602 778.284 -27 778.731');

  const groupTwo = landscapeLeft.group();
  groupTwo.css(commonCss);
  groupTwo.path('M-27.99 782.151C-27.8275 780.41 -28.212 778.662 -29.09 777.151C-29.5418 776.411 -30.2184 775.835 -31.0209 775.508C-31.8234 775.18 -32.7097 775.118 -33.55 775.331C-34.93 775.801 -35.79 777.151 -36.55 778.421C-40.2677 784.972 -43.2843 791.897 -45.55 799.081C-46.28 801.341 -46.78 804.191 -45.02 805.791C-44.4265 806.262 -43.7176 806.567 -42.9667 806.672C-42.2159 806.778 -41.4506 806.681 -40.75 806.391C-39.3659 805.806 -38.1267 804.926 -37.12 803.811C-35.0886 801.848 -33.4286 799.534 -32.22 796.981C-31.5497 795.358 -31.0048 793.686 -30.59 791.981C-29.59 788.301 -29.22 781.821 -25.59 779.691C-22.59 777.951 -18.24 778.111 -14.99 776.471C-8.36 773.121 -2.45 767.841 2.38 762.241C2.76093 761.898 3.0675 761.48 3.28078 761.014C3.49405 760.548 3.6095 760.043 3.62 759.53C3.49 758.36 2.34001 757.531 1.24001 757.181C-3.24999 755.541 -8.21 757.441 -12.46 759.611C-19.0472 762.972 -25.2558 767.028 -30.98 771.711C-31.9 772.461 -32.89 773.471 -32.67 774.631C-32.4947 775.214 -32.1459 775.73 -31.67 776.111C-30.3712 777.38 -28.7602 778.284 -27 778.731');
  groupTwo.path('M101.99 858.401C101.99 858.401 6.04999 823.301 -63.01 743.171');
  groupTwo.path('M130.81 505.521L-8.3 420.341');
  groupTwo.path('M127.1 302.811L-11.08 420.061');
  groupTwo.path('M79.18 650.391C79.18 650.391 -16.76 685.491 -85.82 765.621');
  groupTwo.path('M94.01 99.5505C94.01 99.5505 49.04 94.8105 14.88 67.6305C-19.28 40.4505 -32.06 3.45052 -32.06 3.45052C-32.06 3.45052 -9.12 -6.88948 11.73 10.3905C32.58 27.6705 57.67 64.6005 65.97 75.6705C74.27 86.7405 89.68 97.2105 94.01 99.5505Z').fill('#009D70');
  groupTwo.path('M94.01 99.5505C94.01 99.5505 49.04 94.8105 14.88 67.6305C-19.28 40.4505 -32.06 3.45052 -32.06 3.45052C-32.06 3.45052 -9.12 -6.88948 11.73 10.3905C32.58 27.6705 57.67 64.6005 65.97 75.6705C74.27 86.7405 89.68 97.2105 94.01 99.5505Z').fill('#009D70');

  const leftLeavesGroup = landscapeLeft.group();
  leftLeavesGroup.css(commonCss);
  leftLeavesGroup.addClass('left-leaves');
  leftLeavesGroup.path('M114.13 406.281C114.13 406.281 103.77 414.721 97.97 430.851C92.17 446.981 81.97 505.901 39.48 517.751C39.48 517.751 24.9 521.011 27.96 498.861C31.02 476.711 39.45 442.331 69.96 426.991C100.47 411.651 114.13 406.281 114.13 406.281Z').fill('#509689');
  leftLeavesGroup.path('M109.45 409.481C109.45 409.481 72.38 440.081 54.14 475.161C54.14 475.161 42.49 495.281 35.73 516.021');
  leftLeavesGroup.path('M26.15 614.811C26.15 614.811 -36.12 598.661 -50.52 553.961C-64.92 509.261 -53.66 507.401 -53.66 507.401C-53.66 507.401 -34.54 502.251 -18.12 524.301C-1.7 546.351 4.88 571.301 7.98 579.881C11.08 588.461 25.44 614.261 26.15 614.811Z').fill('#509689');
  leftLeavesGroup.path('M23.74 613.53C23.74 613.53 -20.79 575.16 -35.34 541.03C-49.89 506.9 -53.66 507.401 -53.66 507.401');
  leftLeavesGroup.path('M109.49 501.431C92.077 509.142 73.7108 514.489 54.88 517.331C25.99 521.811 -3.76999 514.811 -32.21 509.811C-58.55 505.111 -116.12 483.671 -118.34 436.141');
  leftLeavesGroup.path('M138.46 596.97C138.46 596.97 99.92 600.291 73.46 584.711C47 569.131 34.3 541.141 35.82 521.711C35.82 521.711 47.82 511.141 66.34 525.231C84.86 539.321 97.65 557.811 105.88 571.041C114.11 584.271 131.88 595.31 138.46 596.97Z').fill('#509689');
  leftLeavesGroup.path('M135.19 596.461C135.19 596.461 83.1 584.681 63.68 547.691C57.5529 536.195 47.7714 527.068 35.88 521.751');
  leftLeavesGroup.path('M220.3 442.021C214.488 444.024 208.904 446.635 203.64 449.811C194.48 455.271 189.32 463.531 182.64 471.401C177.199 477.674 171.127 483.371 164.52 488.401C150.79 499.011 132.13 509.181 114.26 504.061C112.188 503.468 110.178 502.674 108.26 501.691C108.26 501.691 105.79 483.491 123.64 466.691C141.49 449.891 170.18 435.411 220.3 442.021Z').fill('#509689');
  leftLeavesGroup.path('M216.22 442.581C216.22 442.581 187.41 444.581 161.51 465.681C135.61 486.781 115 502.041 108.24 501.681');

  const leftTopFlowerGroup = landscapeLeft.group();
  leftTopFlowerGroup.css(commonCss);
  leftTopFlowerGroup.addClass('left-top-flower');
  leftTopFlowerGroup.path('M40.82 156.021C40.82 156.021 -34.34 107.811 -58.55 63.8106C-82.76 19.8106 -85.39 -26.7894 -70.76 -52.8094C-70.76 -52.8094 -26.87 -24.2794 -5.19 20.2806C16.49 64.8406 25.73 108.921 26.51 110.551C27.29 112.181 36.71 150.811 40.82 156.021Z').fill('#009D70');
  leftTopFlowerGroup.path('M94.78 38.6605C93.47 34.8005 86.94 33.4705 83.58 32.2005C65.5343 25.3202 46.849 20.2519 27.8 17.0705C9.93999 14.1105 -11.36 12.6105 -27.88 4.66055C-41.67 -1.97945 -76.2 -38.5895 -70.76 -52.8095C-65.32 -67.0295 -22.24 -61.1895 31.93 -24.1095C86.1 12.9705 94.78 38.6605 94.78 38.6605Z').fill('#009D70');
  leftTopFlowerGroup.path('M-69.31 -54.0195C-69.31 -54.0195 -82.83 -9.31947 -47.51 44.4505C-12.19 98.2205 -0.710007 126.931 38.71 151.331');
  leftTopFlowerGroup.path('M-31.45 3.16052C-18.27 24.2705 -4.72001 48.1605 1.02999 65.6005C14.46 106.291 22.42 136.941 32.19 146.411');
  leftTopFlowerGroup.path('M-60.62 -26.4894C-50.62 5.61056 -32.09 58.1106 -10.48 86.5106C19.65 126.091 27.15 143.511 27.15 143.511');
  leftTopFlowerGroup.path('M87.73 97.7405C87.73 97.7405 22.17 53.0805 8.36 15.7405');
  leftTopFlowerGroup.path('M-7.83 12.3206C-7.83 12.3206 8.03999 55.3206 83.09 96.9506');
  leftTopFlowerGroup.path('M92.88 36.9505C92.88 36.9505 88.27 30.6905 76.25 22.8105C58.45 11.1305 27.61 -5.84945 -15.75 -18.1895C-15.75 -18.1895 -50.75 -29.3195 -66.44 -48.6895');
  leftTopFlowerGroup.path('M70.6 19.1905C70.6 19.1905 61.17 14.0305 30.39 7.49051C17.6501 4.75384 5.0979 1.20663 -7.19 -3.12949C-20.38 -7.75949 -32.48 -14.9595 -43.87 -22.9695C-54 -30.0895 -65.42 -38.0495 -69.25 -50.4795');
  leftTopFlowerGroup.path('M8.24001 -24.6194C8.24001 -24.6194 50.74 -13.4094 79.24 15.9506');

  const leftBottomFlowerGroup = landscapeLeft.group();
  const leftBottomFlowerCss = {
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-miterlimit': 10,
  };
  leftBottomFlowerGroup.addClass('left-bottom-flower');
  leftBottomFlowerGroup.path('M-89.85 194.811C-89.85 194.811 -78.36 180.931 -52.17 172.911C-25.98 164.891 2.18002 149.171 11.43 138.641C20.68 128.111 59.21 92.9305 64.26 86.5605').css(commonCss);
  leftBottomFlowerGroup.path('M108.65 64.9105C108.65 64.9105 87.65 66.2705 64.26 86.5305').css(commonCss);
  leftBottomFlowerGroup.path('M2.75 110.121C2.75 110.121 -0.119999 142.871 -19.64 159.551').css(commonCss);
  leftBottomFlowerGroup.path('M43.99 38.2705C43.99 38.2705 55.44 60.1605 29.16 120.811').css(commonCss);
  leftBottomFlowerGroup.path('M66.59 51.9305C66.59 51.9305 56.94 57.6605 42.59 82.9305').css(commonCss);
  leftBottomFlowerGroup.path('M160.53 64.3806C160.53 64.3806 126.96 58.0006 106.08 64.9906').css(commonCss);
  leftBottomFlowerGroup.path('M-24.89 62.6406C-24.89 62.6406 1.03 78.2606 2.38 115.361').css(commonCss);
  leftBottomFlowerGroup.path('M63.37 48.9805C68.0329 48.1513 72.8243 48.4114 77.37 49.7405C85.11 52.3205 92.27 62.3905 85.37 69.7405C83.247 71.8351 80.4368 73.0892 77.46 73.2705C71.4245 73.9429 65.3567 72.3224 60.46 68.7305C57.46 66.4005 49.14 56.4505 53 52.5705C55.37 50.1805 60.16 49.4505 63.31 48.9705').fill('#FFF7CE');
  leftBottomFlowerGroup.path('M63.37 48.9805C68.0329 48.1513 72.8243 48.4114 77.37 49.7405C85.11 52.3205 92.27 62.3905 85.37 69.7405C83.247 71.8351 80.4368 73.0892 77.46 73.2705C71.4245 73.9429 65.3567 72.3224 60.46 68.7305C57.46 66.4005 49.14 56.4505 53 52.5705C55.37 50.1805 60.16 49.4505 63.31 48.9705').css(commonCss);
  leftBottomFlowerGroup.path('M63.37 48.9805C68.0329 48.1513 72.8243 48.4114 77.37 49.7405C85.11 52.3205 92.27 62.3905 85.37 69.7405C83.247 71.8351 80.4368 73.0892 77.46 73.2705C71.4245 73.9429 65.3567 72.3224 60.46 68.7305C57.46 66.4005 49.14 56.4505 53 52.5705C55.43 50.1905 60.22 49.4605 63.37 48.9805Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M75.34 60.8105C68.5892 57.181 61.0905 55.1618 53.43 54.9105').css(commonCss);
  leftBottomFlowerGroup.path('M51.47 56.7205C51.47 56.7205 65.92 76.3905 60.97 88.5005C59.47 92.1505 56.78 95.7305 52.47 95.6405C44.47 95.4705 42.81 86.8805 42.47 80.4905C42.267 76.1238 42.9609 71.7618 44.5088 67.6736C46.0567 63.5854 48.4258 59.8578 51.47 56.7205').fill('#FFF7CE');
  leftBottomFlowerGroup.path('M51.47 56.7205C51.47 56.7205 65.92 76.3905 60.97 88.5005C59.47 92.1505 56.78 95.7305 52.47 95.6405C44.47 95.4705 42.81 86.8805 42.47 80.4905C42.267 76.1238 42.9609 71.7618 44.5088 67.6736C46.0567 63.5854 48.4258 59.8578 51.47 56.7205').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M51.47 56.7205C51.47 56.7205 65.92 76.3905 60.97 88.5005C59.47 92.1505 56.78 95.7305 52.47 95.6405C44.47 95.4705 42.81 86.8805 42.47 80.4905C42.267 76.1238 42.9609 71.7618 44.5088 67.6736C46.0567 63.5854 48.4258 59.8578 51.47 56.7205V56.7205Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M49.31 54.6506C49.31 54.6506 27.58 46.0606 19.51 58.9206C11.44 71.7806 15.97 79.6806 24.83 81.2206C33.69 82.7606 42.94 78.7506 49.31 54.6506Z').css(leftBottomFlowerCss).fill('#FFF7CE');
  leftBottomFlowerGroup.path('M49.31 54.6506C49.31 54.6506 27.58 46.0606 19.51 58.9206C11.44 71.7806 15.97 79.6806 24.83 81.2206C33.69 82.7606 42.94 78.7506 49.31 54.6506Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M51.88 51.6506C50.5456 46.2439 47.6293 41.358 43.5038 37.6172C39.3783 33.8765 34.2311 31.4509 28.72 30.6506C28.72 30.6506 13.55 30.2206 21.03 46.5206C28.51 62.8206 49.33 54.6806 49.33 54.6806L51.88 51.6506Z').css(leftBottomFlowerCss).fill('#FFF7CE');
  leftBottomFlowerGroup.path('M51.88 51.6506C50.5456 46.2439 47.6293 41.358 43.5038 37.6172C39.3783 33.8765 34.2311 31.4509 28.72 30.6506C28.72 30.6506 13.55 30.2206 21.03 46.5206C28.51 62.8206 49.33 54.6806 49.33 54.6806L51.88 51.6506Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M51.46 53.4505C51.46 53.4505 67.16 49.6005 68.06 32.2405C68.32 27.4605 67.45 21.6105 62.44 19.6305C54.17 16.3605 48.79 23.9505 46.18 30.6905C42.99 38.9605 45.05 47.3305 51.46 53.4505').fill('#FFF7CE');
  leftBottomFlowerGroup.path('M51.46 53.4505C51.46 53.4505 67.16 49.6005 68.06 32.2405C68.32 27.4605 67.45 21.6105 62.44 19.6305C54.17 16.3605 48.79 23.9505 46.18 30.6905C42.99 38.9605 45.05 47.3305 51.46 53.4505').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M51.46 53.4505C51.46 53.4505 67.16 49.6005 68.06 32.2405C68.32 27.4605 67.45 21.6105 62.44 19.6305C54.17 16.3605 48.79 23.9505 46.18 30.6905C42.99 38.9605 45.05 47.3305 51.46 53.4505Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M51.19 57.2606C51.19 57.2606 47.69 69.5606 54.19 80.6306').css(commonCss);
  leftBottomFlowerGroup.path('M51.13 55.8806C51.13 55.8806 35.88 57.7306 28.54 70.1206').css(commonCss);
  leftBottomFlowerGroup.path('M31.57 41.3806C36.2804 46.1884 42.066 49.8079 48.45 51.9406').css(commonCss);
  leftBottomFlowerGroup.path('M58.3 31.9506C58.3 31.9506 53.88 35.1406 50.24 52.3406').css(commonCss);
  leftBottomFlowerGroup.path('M55.29 52.3405C54.997 51.4157 54.4279 50.6028 53.6592 50.011C52.8905 49.4193 51.959 49.0771 50.99 49.0305C49.9019 49.0939 48.8574 49.4804 47.99 50.1405C46.8473 50.9093 45.9569 51.9981 45.43 53.2705C45.171 53.9111 45.0529 54.5999 45.084 55.2901C45.1151 55.9804 45.2945 56.6558 45.61 57.2705C46.82 59.5105 51.06 61.0105 53.36 59.7005C55.66 58.3905 56.04 54.5105 55.36 52.3605').fill('#1E2722');
  leftBottomFlowerGroup.path('M55.29 52.3405C54.997 51.4157 54.4279 50.6028 53.6592 50.011C52.8905 49.4193 51.959 49.0771 50.99 49.0305C49.9019 49.0939 48.8574 49.4804 47.99 50.1405C46.8473 50.9093 45.9569 51.9981 45.43 53.2705C45.171 53.9111 45.0529 54.5999 45.084 55.2901C45.1151 55.9804 45.2945 56.6558 45.61 57.2705C46.82 59.5105 51.06 61.0105 53.36 59.7005C55.66 58.3905 56.04 54.5105 55.36 52.3605').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M-21.07 55.3805C-16.4075 54.5447 -11.6146 54.8049 -7.07 56.1406C0.669996 58.7206 7.83 68.7906 0.98 76.1406C-1.14304 78.2365 -3.95252 79.4938 -6.93 79.6806C-12.965 80.346 -19.0303 78.7262 -23.93 75.1406C-26.95 72.8106 -35.25 62.8106 -31.39 58.9706C-29.02 56.5906 -24.23 55.8105 -21.07 55.3805Z').css(leftBottomFlowerCss).fill('#FFF7CE');
  leftBottomFlowerGroup.path('M-21.07 55.3805C-16.4075 54.5447 -11.6146 54.8049 -7.07 56.1406C0.669996 58.7206 7.83 68.7906 0.98 76.1406C-1.14304 78.2365 -3.95252 79.4938 -6.93 79.6806C-12.965 80.346 -19.0303 78.7262 -23.93 75.1406C-26.95 72.8106 -35.25 62.8106 -31.39 58.9706C-29.02 56.5906 -24.23 55.8105 -21.07 55.3805Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M156.74 59.2706C156.74 59.2706 147.08 65.0006 132.74 90.2706').css(commonCss);
  leftBottomFlowerGroup.path('M153.52 56.3206C158.182 55.4844 162.975 55.7446 167.52 57.0806C175.27 59.6606 182.43 69.7306 175.57 77.0806C173.447 79.1765 170.637 80.4339 167.66 80.6206C161.624 81.2959 155.555 79.6752 150.66 76.0806C147.66 73.7406 139.33 63.8006 143.19 59.9206C145.57 57.5306 150.35 56.8006 153.51 56.3206').fill('#FFF7CE');
  leftBottomFlowerGroup.path('M153.52 56.3206C158.182 55.4844 162.975 55.7446 167.52 57.0806C175.27 59.6606 182.43 69.7306 175.57 77.0806C173.447 79.1765 170.637 80.4339 167.66 80.6206C161.624 81.2959 155.555 79.6752 150.66 76.0806C147.66 73.7406 139.33 63.8006 143.19 59.9206C145.57 57.5306 150.35 56.8006 153.51 56.3206').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M153.52 56.3206C158.182 55.4844 162.975 55.7446 167.52 57.0806C175.27 59.6606 182.43 69.7306 175.57 77.0806C173.447 79.1765 170.637 80.4339 167.66 80.6206C161.624 81.2959 155.555 79.6752 150.66 76.0806C147.66 73.7406 139.33 63.8006 143.19 59.9206C145.58 57.5306 150.36 56.8106 153.52 56.3206Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M165.48 68.1706C158.733 64.5402 151.237 62.5208 143.58 62.2706').css(commonCss);
  leftBottomFlowerGroup.path('M141.61 64.0505C141.61 64.0505 156.07 83.7305 151.11 95.8405C149.62 99.4905 146.93 103.071 142.62 102.981C134.62 102.811 132.96 94.2105 132.62 87.8305C132.42 83.4626 133.115 79.0999 134.663 75.0105C136.21 70.9211 138.578 67.1914 141.62 64.0505').fill('#FFF7CE');
  leftBottomFlowerGroup.path('M141.61 64.0505C141.61 64.0505 156.07 83.7305 151.11 95.8405C149.62 99.4905 146.93 103.071 142.62 102.981C134.62 102.811 132.96 94.2105 132.62 87.8305C132.42 83.4626 133.115 79.0999 134.663 75.0105C136.21 70.9211 138.578 67.1914 141.62 64.0505').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M141.61 64.0505C141.61 64.0505 156.07 83.7305 151.11 95.8405C149.62 99.4905 146.93 103.071 142.62 102.981C134.62 102.811 132.96 94.2105 132.62 87.8305C132.418 83.4633 133.112 79.1011 134.658 75.0117C136.204 70.9223 138.57 67.1922 141.61 64.0505V64.0505Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M139.46 61.9806C139.46 61.9806 117.73 53.4006 109.66 66.2606C101.59 79.1206 106.12 87.0206 114.98 88.5606C123.84 90.1006 133.09 86.0906 139.46 61.9806').fill('#FFF7CE');
  leftBottomFlowerGroup.path('M139.46 61.9806C139.46 61.9806 117.73 53.4006 109.66 66.2606C101.59 79.1206 106.12 87.0206 114.98 88.5606C123.84 90.1006 133.09 86.0906 139.46 61.9806').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M139.46 61.9806C139.46 61.9806 117.73 53.4006 109.66 66.2606C101.59 79.1206 106.12 87.0206 114.98 88.5606C123.84 90.1006 133.09 86.0906 139.46 61.9806Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M142 58.9905C140.666 53.5839 137.749 48.6979 133.624 44.9572C129.498 41.2165 124.351 38.7909 118.84 37.9905C118.84 37.9905 103.68 37.5305 111.16 53.8105C118.64 70.0905 139.46 61.9705 139.46 61.9705L142 58.9905Z').css(leftBottomFlowerCss).fill('#FFF7CE');
  leftBottomFlowerGroup.path('M142 58.9905C140.666 53.5839 137.749 48.6979 133.624 44.9572C129.498 41.2165 124.351 38.7909 118.84 37.9905C118.84 37.9905 103.68 37.5305 111.16 53.8105C118.64 70.0905 139.46 61.9705 139.46 61.9705L142 58.9905Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M141.61 60.8105C141.61 60.8105 157.3 56.9705 158.21 39.6005C158.46 34.8305 157.6 28.9805 152.59 27.0005C144.32 23.7305 138.93 31.3205 136.33 38.0605C133.14 46.3305 135.2 54.7005 141.61 60.8105').fill('#FFF7CE');
  leftBottomFlowerGroup.path('M141.61 60.8105C141.61 60.8105 157.3 56.9705 158.21 39.6005C158.46 34.8305 157.6 28.9805 152.59 27.0005C144.32 23.7305 138.93 31.3205 136.33 38.0605C133.14 46.3305 135.2 54.7005 141.61 60.8105').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M141.61 60.8105C141.61 60.8105 157.3 56.9705 158.21 39.6005C158.46 34.8305 157.6 28.9805 152.59 27.0005C144.32 23.7305 138.93 31.3205 136.33 38.0605C133.14 46.3005 135.2 54.6705 141.61 60.8105Z').css(leftBottomFlowerCss);
  leftBottomFlowerGroup.path('M141.33 64.6005C141.33 64.6005 137.84 76.8905 144.38 87.9705').css(commonCss);
  leftBottomFlowerGroup.path('M141.28 63.2205C141.28 63.2205 126.02 65.0705 118.69 77.4605').css(commonCss);
  leftBottomFlowerGroup.path('M121.71 48.7206C126.424 53.5242 132.208 57.143 138.59 59.2805').css(commonCss);
  leftBottomFlowerGroup.path('M148.45 39.2905C148.45 39.2905 143.98 42.4805 140.39 59.6805').css(commonCss);
  leftBottomFlowerGroup.path('M145.43 59.6805C145.14 58.7558 144.573 57.9424 143.806 57.3504C143.039 56.7584 142.108 56.4163 141.14 56.3705C140.051 56.4321 139.006 56.8188 138.14 57.4805C136.997 58.2493 136.107 59.3381 135.58 60.6105C135.321 61.2508 135.203 61.939 135.232 62.629C135.261 63.3189 135.438 63.9945 135.75 64.6105C136.97 66.8505 141.2 68.3505 143.51 67.0305C145.82 65.7105 146.19 61.8505 145.51 59.7005').fill('#1E2722');
  leftBottomFlowerGroup.path('M145.43 59.6805C145.14 58.7558 144.573 57.9424 143.806 57.3504C143.039 56.7584 142.108 56.4163 141.14 56.3705C140.051 56.4321 139.006 56.8188 138.14 57.4805C136.997 58.2493 136.107 59.3381 135.58 60.6105C135.321 61.2508 135.203 61.939 135.232 62.629C135.261 63.3189 135.438 63.9945 135.75 64.6105C136.97 66.8505 141.2 68.3505 143.51 67.0305C145.82 65.7105 146.19 61.8505 145.51 59.7005').css(leftBottomFlowerCss);

  const twigGroup = landscapeLeft.group();
  twigGroup.css({
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-miterlimit': 10,
  });
  twigGroup.path('M-167.8 70.5506C-151.34 78.3306 -139.41 109.391 -128.8 116.171C-118.19 122.951 -111.36 132.001 -100.22 144.541C-89.08 157.081 -73.22 155.631 -64.59 162.591C-55.96 169.551 -48.96 180.531 -43.59 195.531C-38.22 210.531 -21.12 243.811 -9.5 262.141C2.12 280.471 77.79 298.711 116.33 304.871C132.88 307.511 196.43 341.561 211.33 358.871C214.57 362.631 223.09 349.871 219.13 346.981C213.319 342.786 207.735 338.286 202.4 333.501C187.12 319.401 74.13 290.201 30.22 269.041C-13.69 247.881 -7.56 240.831 -20.28 211.041C-26.12 197.291 -28.51 184.511 -31.6 173.371C-36.26 171.521 11.71 160.421 77.52 176.591C100.41 182.211 123.97 167.761 155.07 149.651C186.17 131.541 227.25 143.051 227.25 143.051C240.06 128.131 200.17 122.621 185.79 120.311C171.41 118.001 139.28 139.701 125.63 148.861C98.82 166.861 78.51 157.231 61.08 155.021C38.26 152.131 -6.49 153.741 -24.92 152.381C-32.7213 151.896 -40.3113 149.649 -47.12 145.811C-48.7887 144.42 -50.5717 143.172 -52.45 142.081C-75.66 128.711 -73.85 126.611 -88.78 116.351C-103.71 106.091 -93.51 84.1006 -106.12 65.3206').fill('#56352A');
  
  const groupThree = landscapeLeft.group();
  groupThree.css(commonCss);
  groupThree.path('M83.76 290.611C83.76 290.611 69.27 253.891 47.26 236.811C25.25 219.731 2.19001 204.811 -58.74 197.471C-58.74 197.471 -49.94 209.261 -44.34 217.931C-25.12 247.571 8.80001 264.381 40.88 276.811C50.7553 280.709 60.9023 283.881 71.24 286.301C75.5213 287.402 79.7079 288.843 83.76 290.611V290.611Z').fill('#006571');
  groupThree.path('M81.72 288.591C81.72 288.591 29.2 243.811 -15.53 221.251C-60.26 198.691 -58.74 197.421 -58.74 197.421');
  groupThree.path('M42.28 374.571C42.28 374.571 42.45 335.101 28.38 311.021C14.31 286.941 -1.62002 264.581 -55.46 235.151C-55.46 235.151 -51.66 249.361 -49.68 259.491C-42.9 294.171 -17.59 322.391 7.55998 345.791C15.2785 353.084 23.5242 359.799 32.23 365.881C35.8077 368.503 39.1686 371.409 42.28 374.571V374.571Z').fill('#006571');
  groupThree.path('M41.13 371.941C41.13 371.941 8.98001 310.871 -24.18 273.311C-57.34 235.751 -55.46 235.151 -55.46 235.151');
  groupThree.path('M25.71 134.811C26.13 134.941 53.71 122.611 74.2 132.881C94.69 143.151 100.42 151.001 100.42 151.001C100.42 151.001 86.72 171.411 55.59 158.631C24.46 145.851 25.71 134.811 25.71 134.811Z').fill('#A3D380');
  groupThree.path('M99.88 151.811C99.88 151.811 40.88 140.041 27.3 138.501Z').fill('#A3D380');
  groupThree.path('M99.88 151.811C99.88 151.811 40.88 140.041 27.3 138.501');
  groupThree.path('M26.05 188.631C26.05 188.631 47.88 186.741 60.28 185.091C72.68 183.441 107.19 172.791 114.35 156.981C121.51 141.171 40.88 158.431 26.05 188.631Z').fill('#A3D380');
  groupThree.path('M30.28 186.141C30.28 186.141 87.28 163.501 112.92 154.871Z').fill('#A3D380')
  groupThree.path('M30.28 186.141C30.28 186.141 87.28 163.501 112.92 154.871');
  groupThree.path('M111.93 160.991C111.54 160.481 75.61 145.771 58.24 117.051C40.87 88.3306 38.48 75.8106 38.48 75.8106C38.48 75.8106 63.26 70.1106 90.95 111.111C118.64 152.111 111.93 160.991 111.93 160.991Z').fill('#A3D380');
  groupThree.path('M39.52 75.5905C39.52 75.5905 97.94 141.871 112 156.101Z').fill('#A3D380');
  groupThree.path('M39.52 75.5905C39.52 75.5905 97.94 141.871 112 156.101');
  groupThree.path('M60.88 245.271C61.15 244.761 53.61 205.781 71.59 182.131C89.57 158.481 100.59 153.331 100.59 153.331C100.59 153.331 121.66 176.691 97.78 213.331C73.9 249.971 60.88 245.271 60.88 245.271Z').fill('#A3D380');
  groupThree.path('M101.43 154.311C101.43 154.311 71.3 227.111 65.76 244.251Z').fill('#A3D380');
  groupThree.path('M101.43 154.311C101.43 154.311 71.3 227.111 65.76 244.251');
  groupThree.path('M141.65 125.731C145.626 125.503 149.605 126.099 153.34 127.481C155.89 128.421 158.1 130.541 160.55 131.401C162.13 131.951 163.45 131.191 165.12 130.901C169.032 130.213 173.06 130.668 176.72 132.211C178.43 132.941 180.28 134.381 179.96 136.211C179.721 137.073 179.221 137.841 178.53 138.411C175.885 140.973 172.744 142.967 169.3 144.271L176.07 151.801C177.8 153.721 179.65 155.991 179.36 158.561C178.9 162.481 174.12 164.011 170.24 164.661C172.116 166.777 173.208 169.473 173.332 172.298C173.456 175.124 172.605 177.906 170.922 180.178C169.239 182.451 166.826 184.076 164.088 184.781C161.349 185.486 158.451 185.228 155.88 184.051C156.253 186.4 155.748 188.805 154.462 190.807C153.176 192.808 151.199 194.267 148.907 194.905C146.614 195.542 144.168 195.314 142.033 194.264C139.898 193.214 138.224 191.415 137.33 189.211C136.98 188.441 136.99 186.911 136.5 186.301C136.01 185.691 134.5 185.461 133.63 185.161C131.447 184.357 129.366 183.3 127.43 182.011C123.468 179.326 120.179 175.764 117.819 171.602C115.458 167.439 114.09 162.788 113.82 158.011C112.82 141.121 125.3 126.671 141.65 125.731Z').fill('#FAFBFC');
  groupThree.path('M164.25 43.0806C163.84 43.7206 160.8 90.4706 135.85 120.691C110.9 150.911 98.16 158.081 98.16 158.081C98.16 158.081 83.03 131.421 117.6 84.9306C152.17 38.4406 164.25 43.0806 164.25 43.0806Z').fill('#A3D380');
  groupThree.path('M97.54 156.951C97.54 156.951 148.54 66.0106 158.92 44.7206Z').fill('#A3D380');
  groupThree.path('M97.54 156.951C97.54 156.951 148.54 66.0106 158.92 44.7206');
  groupThree.path('M139.62 239.261C139.41 238.721 105.73 219.361 99.81 189.851C93.89 160.341 97.19 148.311 97.19 148.311C97.19 148.311 128.03 146.881 139.76 189.461C151.49 232.041 139.62 239.261 139.62 239.261Z').fill('#A3D380');
  groupThree.path('M98.48 148.261C98.48 148.261 132.92 219.261 142.04 234.721Z').fill('#A3D380');
  groupThree.path('M98.48 148.261C98.48 148.261 132.92 219.261 142.04 234.721');
  groupThree.path('M123.19 310.011C122.31 310.121 78.55 341.831 32.61 342.211C-13.33 342.591 -29.47 336.721 -29.47 336.721C-29.47 336.721 -17.47 303.871 50.29 299.961C118.05 296.051 123.19 310.011 123.19 310.011Z').fill('#009D70');
  groupThree.path('M-28.95 335.351C-28.95 335.351 90.79 313.101 117.64 306.551Z').fill('#009D70');
  groupThree.path('M-28.95 335.351C-28.95 335.351 90.79 313.101 117.64 306.551');
  groupThree.path('M63.25 396.721C63.59 396.221 58.63 355.551 80.25 332.301C101.87 309.051 114.36 304.441 114.36 304.441C114.36 304.441 135.36 329.871 106.25 366.131C77.14 402.391 63.25 396.721 63.25 396.721Z').fill('#009D70');
  groupThree.path('M115.24 305.511C115.24 305.511 76.24 378.661 68.72 395.991Z').fill('#009D70');
  groupThree.path('M115.24 305.511C115.24 305.511 76.24 378.661 68.72 395.991');
  groupThree.path('M178.3 366.501C177.93 366.041 137.6 357.831 122.9 331.121C108.2 304.411 108.08 291.731 108.08 291.731C108.08 291.731 139.42 280.731 163.92 318.251C188.42 355.771 178.3 366.501 178.3 366.501Z').fill('#009D70');
  groupThree.path('M109.39 291.281C109.39 291.281 165.57 349.211 179.47 361.341Z').fill('#009D70');
  groupThree.path('M109.39 291.281C109.39 291.281 165.57 349.211 179.47 361.341');
  groupThree.path('M227.96 316.971C227.96 316.971 202.19 302.111 187.13 294.311C172.07 286.511 125.3 271.131 104.85 282.311C84.4 293.491 187.66 337.501 227.96 316.971Z').fill('#009D70');
  groupThree.path('M221.28 316.341C221.28 316.341 140.08 296.341 104.79 285.661Z').fill('#009D70');
  groupThree.path('M221.28 316.341C221.28 316.341 140.08 296.341 104.79 285.661');
  groupThree.path('M118.34 266.941C113.4 249.251 124.19 230.781 142.44 225.691C147.32 224.331 151.73 224.691 156.68 224.691C157.494 224.77 158.311 224.585 159.01 224.161C160.44 223.221 161.18 221.361 162.58 220.301C164.123 219.166 165.964 218.509 167.876 218.409C169.788 218.31 171.688 218.772 173.34 219.741C177.95 222.671 179.6 228.971 178.26 234.271C183.79 233.841 189.08 237.941 190.94 243.171C192.8 248.401 191.61 254.381 188.73 259.171C187.968 260.127 187.527 261.299 187.47 262.521C187.679 263.511 188.141 264.431 188.81 265.191C190.757 268.027 191.994 271.29 192.415 274.705C192.836 278.12 192.43 281.586 191.23 284.811C190.705 286.485 189.653 287.944 188.23 288.971C184.68 291.221 180.17 288.421 176.93 285.741C176.79 290.261 176.59 295.001 174.3 298.901C172.01 302.801 166.88 305.421 162.88 303.381C158.4 301.101 157.61 294.671 153.45 291.851C154.52 292.581 151.69 295.801 151.19 296.181C149.695 297.316 147.83 297.854 145.96 297.691C141.03 297.211 138.88 292.501 135.96 289.221C133.37 286.291 129.96 284.291 127.12 281.591C122.937 277.58 119.905 272.521 118.34 266.941V266.941Z').fill('#FAFBFC');
  groupThree.path('M113.77 310.081C114.02 309.441 107.93 268.891 124.86 237.911C141.79 206.931 151.99 198.151 151.99 198.151C151.99 198.151 170.78 217.781 148.19 264.651C125.6 311.521 113.77 310.081 113.77 310.081Z').fill('#009D70');
  groupThree.path('M100.96 247.081C100.96 247.081 100.52 262.901 100.63 271.831C100.74 280.761 104.69 305.461 113.77 310.081C122.85 314.701 118.21 256.701 100.96 247.081Z').fill('#009D70');
  groupThree.path('M102.17 250.071C102.17 250.071 111.77 290.641 115.17 308.951Z').fill('#009D70');
  groupThree.path('M102.17 250.071C102.17 250.071 111.77 290.641 115.17 308.951');
  groupThree.path('M152.77 198.981C152.77 198.981 123.71 287.231 118.3 307.571Z').fill('#009D70');
  groupThree.path('M152.77 198.981C152.77 198.981 123.71 287.231 118.3 307.571');

  const groupFour = landscapeLeft.group();
  groupFour.css({
    'stroke': '#1F2720',
    'stroke-width': 2,
    'stroke-linecap': 'round',
    'stroke-miterlimit': 10,
  });
 
  groupFour.path('M127.46 157.551C127.46 162.693 125.935 167.72 123.078 171.995C120.221 176.271 116.161 179.604 111.41 181.571C106.659 183.539 101.431 184.054 96.3877 183.051C91.3441 182.048 86.7114 179.571 83.0752 175.935C79.4391 172.299 76.9628 167.666 75.9596 162.623C74.9564 157.579 75.4713 152.352 77.4391 147.601C79.407 142.85 82.7395 138.789 87.0152 135.932C91.2909 133.075 96.3177 131.551 101.46 131.551C108.356 131.551 114.969 134.29 119.845 139.166C124.721 144.042 127.46 150.655 127.46 157.551').fill('#FF8C00');
  groupFour.path('M144.35 296.691C144.35 301.833 142.825 306.86 139.968 311.135C137.111 315.411 133.051 318.744 128.3 320.711C123.549 322.679 118.321 323.194 113.278 322.191C108.234 321.188 103.601 318.711 99.9652 315.075C96.329 311.439 93.8528 306.806 92.8496 301.763C91.8464 296.719 92.3612 291.492 94.3291 286.741C96.297 281.99 99.6295 277.929 103.905 275.072C108.181 272.215 113.208 270.691 118.35 270.691C125.246 270.691 131.859 273.43 136.735 278.306C141.611 283.182 144.35 289.795 144.35 296.691Z').fill('#FF8C00');

  return svgHelper(landscapeLeft);
};

export default landscapeLeftSvg;
