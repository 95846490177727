<template>
  <div class="product-video-root">
    <video 
      muted
      playsinline
      poster
      class="product-video"
      ref="product-video-player"
      >
      <source 
        v-if="hasWebmFile"
        :src="getWebmPath" 
        type="video/webm">
      <source 
        v-if="hasMp4File"
        :src="getMp4Path" 
        type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  name: 'ProductVideo',
  props: {
    webmVideo: {
      type: Object,
      required: true
    },
    mp4Video: {
      type: Object,
      required: true
    },
    isCurrentSlide: {
      type: Boolean,
      required: true
    },
    swiperInViewPort: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    hasWebmFile () {
      if (!( Object.hasOwn(this.webmVideo, '_path') && (Object.hasOwn(this.webmVideo, 'format')) )) return false;
      return this.webmVideo.format === 'video/webm';
    },
    hasMp4File () {
      if (!( Object.hasOwn(this.mp4Video, '_path') && (Object.hasOwn(this.mp4Video, 'format')) )) return false;
      return this.mp4Video.format === 'video/mp4';
    },
    getWebmPath () {
      return this.hasWebmFile ? this.webmVideo._path : '';
    },
    getMp4Path () {
      return this.hasMp4File ? this.mp4Video._path : '';
    }
  },
  updated () {
    if (this.isCurrentSlide && this.swiperInViewPort) {
      this.playVideo();
    }
  },
  methods: {
    playVideo () {
      let videoPlayer = this.$refs['product-video-player'];
      videoPlayer.play();
    }
  }
}
</script>

<style lang="scss" scoped> 
  .product-video {
    max-width: 100%; 
    clip-path: inset(1px 1px);
  } 
</style>