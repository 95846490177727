<template>
  <div class="adaptive-image-container">
    <div 
      v-if="isMissingImagePath"
      class="missing-image-path"
    >
      <p>MISSING DATA: IMAGE PATH</p>
    </div>
    <img 
      :src="imagePath" 
      :alt="imageAltText"
      loading="lazy"
    >
  </div>
</template>

<script>
export default {
  name: 'AdaptiveImage',
  props: {
    imagePath: {
      type: String,
      required: true
    },
    imageAltText: {
      type: String,
      required: true
    },
    isMissingImagePath: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .adaptive-image-container {
    img {
      max-width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .missing-image-path {
      color: red;
    }
  }

</style>