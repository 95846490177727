import { SVG } from '@svgdotjs/svg.js';
import svgHelper from '../../utils/svgHelper';

const blockBlobSvg = () => {
  const blockBlog = SVG();

  blockBlog.svg('<path xmlns="http://www.w3.org/2000/svg" d="M325.796 464.118C195.722 511.071 63.7585 420.434 15.9833 288.082C-31.7919 155.729 33.0444 60.4009 163.119 13.4481C293.193 -33.5046 468.259 48.1529 516.034 180.506C552.572 317.557 455.871 417.165 325.796 464.118Z" />');
  blockBlog.width(524).height(477).viewbox(0, 0, 524, 477);


  return svgHelper(blockBlog);
}

export default blockBlobSvg;
