<template>
  <div class="activity-page-root">
    <div v-for="(translation, translationIndex) in translationItems" :key="translationIndex">
      <div class="season-heroes">
        <activity-hero
          seasonType="spring"
          :seasonTitle="translation.springHeroTitle"
          :seasonDescription="translation.springHeroDescription.plaintext"
          :sesaonActive="getCurrentSeason"
        />
        <activity-hero
          seasonType="summer"
          :seasonTitle="translation.summerHeroTitle"
          :seasonDescription="translation.summerHeroDescription.plaintext"
          :sesaonActive="getCurrentSeason"
        />
        <activity-hero
          seasonType="autumn"
          :seasonTitle="translation.autumnHeroTitle"
          :seasonDescription="translation.autumnHeroDescription.plaintext"
          :sesaonActive="getCurrentSeason"
        />
        <activity-hero
          seasonType="winter"
          :seasonTitle="translation.winterHeroTitle"
          :seasonDescription="translation.winterHeroDescription.plaintext"
          :sesaonActive="getCurrentSeason"
        />
      </div>
      <div class="season-selectors">
        <activity-single-selector 
          v-show="!hideSeason('spring')"
          seasonType="spring" 
          :seasonText="translation.springFilter"
          :sesaonActive="getCurrentSeason"
          @update-season="updateCurrentSeason"
        />
        <activity-single-selector 
          v-show="!hideSeason('summer')"
          seasonType="summer" 
          :seasonText="translation.summerFilter"
          :sesaonActive="getCurrentSeason" 
          @update-season="updateCurrentSeason"
        />
        <activity-single-selector 
          v-show="!hideSeason('autumn')"
          seasonType="autumn" 
          :seasonText="translation.autumnFilter"
          :sesaonActive="getCurrentSeason" 
          @update-season="updateCurrentSeason"
        />
        <activity-single-selector 
          v-show="!hideSeason('winter')"
          seasonType="winter" 
          :seasonText="translation.winterFilter"
          :sesaonActive="getCurrentSeason"
          @update-season="updateCurrentSeason"
        />
      </div>
    </div>

      <div class="season-cards">
        <activity-card v-for="(card, cardIndex) in cardItems" :key="cardIndex"  v-show="showCard(card)"
          :card="card"
          :sesaonActive="getCurrentSeason" 
          :allCardsShown="allSeasonsShown"
          :translations="getTranslations"
        />
      </div>

    <div v-for="(translation, translationIndex) in translationItems" :key="translationIndex">
      <div class="all-seasons-button">
        <activity-all-selector
          :btnText="translation.allSeasonsFilter"
          @show-all-seasons="showAllSeasons" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivityCard from '../components/kids/ActivityCard';
import ActivitySingleSelector from '../components/kids/ActivitySingleSelector';
import ActivityAllSelector from '../components/kids/ActivityAllSelector';
import ActivityHero from '../components/kids/ActivityHero';

export default {
  name: 'KidsActivityAllPage',
  components: {
    ActivityCard,
    ActivitySingleSelector,
    ActivityAllSelector,
    ActivityHero
  },
  props: {
    query: {
      type: String,
      required: true
    },
    folder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentSeason: '',
      allSeasonsShown: false,
      storeName: 'activityCard'
    }
  },
  created () {
    this.$store.dispatch('performApiQuery', { query: this.query, store: this.storeName, folder: this.folder });
  },
  computed: {
    ...mapGetters({
      cardItems: 'activityCard/getCardItems',
      translationItems: 'activityCard/getTranslationItems'
    }),
    getTranslations () {
      if (!this.translationItems.length) return {};
      return this.translationItems[0];
    },
    getCurrentSeason () {
      if (this.currentSeason || this.allSeasonsShown) return this.currentSeason;
      const translations = this.getTranslations;
      const hideSeasons = translations.hideSeason;
      if (!hideSeasons) return 'spring';
      return this.getStartSeason(hideSeasons);
    }
  },
  methods: {
    updateCurrentSeason (season) {
      this.currentSeason = season;
      this.allSeasonsShown = false;
    },
    showAllSeasons (event) {
      this.currentSeason = '';
      this.allSeasonsShown = event;
    },
    hideSeason (season) {
      const translations = this.getTranslations;
      const hideSeasons = translations.hideSeason;
      if (!hideSeasons) return false;
      return hideSeasons.includes(season);
    },
    showCard (card) {
      if (this.hideSeason(card.season)) return false;
      return this.allSeasonsShown || this.getCurrentSeason === card.season
    },
    getStartSeason (hiddenSeasons) {
      const allSeasons = ['spring', 'summer', 'autumn', 'winter'];
      const possibleSeasons = allSeasons.filter(season => !hiddenSeasons.includes(season));
      return possibleSeasons.length > 0 ? possibleSeasons[0] : '';
    }
  },
}
</script>
<style lang="scss" scoped>
  .activity-page-root {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .season-cards {
    margin-top: 50px;
    margin-bottom: 50px;
    @media only screen and (min-width: 1023px) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 90px;
      margin-bottom: 90px;
    }
    .card-container {
      @media only screen and (min-width: 1023px) {
        margin-left: 15px;
        margin-right: 15px;
        flex-basis: calc((100% / 3) - 30px);
        max-width: calc((100% / 3) - 30px);
        display: flex;
        flex-direction: column;
      }
    }
  }

  .season-selectors {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button {
      margin: 10px 10px 20px;
      &:focus {
        outline: none;
      }
    }
    .button-text {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
    }
  }
</style>