import underlineSvg from "../../svg/general/underline/underline";

const addUnderlineIfNoTitle= () => {
  const listTeasers = document.querySelectorAll('.c-teaser-list .teaser');

  listTeasers.forEach((teaser) => {
    const titleElement = teaser.querySelector('.cmp-teaser__title');
    const descElement = teaser.querySelector('.cmp-teaser__description');

    if (!titleElement) {
      descElement.classList.add('cmp-teaser__description-underline'); 
    }
  });

  underlineSvg().addToClass('.c-teaser-list .cmp-teaser__description-underline'); 
  underlineSvg().addToClass('.c-teaser-list .cmp-teaser__title');
}


const onDocumentReady = () => {
  addUnderlineIfNoTitle();
}
if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}