import underlineSvg from "../../svg/general/underline/underline";

const headingTags = document.querySelectorAll('.txt-list h2, .txt-list h3, .txt-list h4');

headingTags.forEach((h) => {
  const spanElement = document.createElement('span');
  const content = h.innerHTML;
  spanElement.innerHTML = content;
  h.innerHTML = '';
  h.appendChild(spanElement);
  spanElement.classList.add('o-underline');
});

underlineSvg().addToClass('.o-underline');
underlineSvg().addToClass('.text u')