<template>
  <div>
    <subscription-form-container
      :formItems="formItems"  
      :imageLogoUrl="getLogoUrl" 
      :imageLogoAltText="getLogoAltText"    
      :storeName="storeName"
      :isNav="false"
    />
  </div>
</template>   

<script>   
import { mapGetters } from "vuex";      
import SubscriptionFormContainer from "../components/subscriptionForm/SubscriptionFormContainer";

export default { 
  name: "SubscriptionFormPage", 
  components: { 
    SubscriptionFormContainer,
  },  
  props: {
    query: {
      type: String,  
      required: true,
    },
    folder: { 
      type: String,
      required: true, 
    },
  },
  data() { 
    return {
      storeName: 'subscribeForm'
    }
  },
  created() {
    this.$store.dispatch("subscribeForm/performApiQuery", {
      query: this.query,
      store: this.storeName,
      folder: this.folder,
      isNav: false
    });
  },
  computed: {
    ...mapGetters({
      formItems: 'subscribeForm/getSubsFormItems', 
    }),
    getLogoUrl() {
      return this.formItems.imageUrl?._path ? this.formItems.imageUrl._path : '';
    },
    getLogoAltText() { 
      return this.formItems.imageUrlAltText ? this.formItems.imageUrlAltText : '';
    }
  }
}; 
</script>
<style lang="scss" scoped>
::v-deep {
  .name-inputs {
    display: block; 
    margin-bottom: 0;
  }

  .subscription-form { 
    position: initial; 
    transform: translate(0); 
    max-width: 500px; 
    margin: 0 auto;
  }

  .first-name-input,  
  .last-name-input {
    background-image: url("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"317\" height=\"45\" viewBox=\"0 0 317 45\" fill=\"none\"><path d=\"M274.858 43.7274C228.856 43.7274 182.854 43.7274 136.852 43.7274C113.318 43.7274 89.6969 44.043 66.1794 43.7274C50.943 43.5301 22.3187 43.8711 11.1798 39.8767C0.0408174 35.8822 0 29.091 0 22.2364C0 10.4433 5.08171 0.114243 43.2129 0.755322C104.882 0.755322 169.081 0.419989 231.198 0.114243C248.793 0.0254782 262.216 0.114298 284.121 1.4753C300.837 0.755323 303.39 2.30988 309.984 6.45223C313.066 8.38534 315.041 13.1331 316.418 15.6382C319.639 34.3891 309.984 46.362 274.858 43.7274Z\" fill=\"white\"/></svg>");
    width: 317px; 
  }

  .first-name-input, 
  .last-name-input, 
  .email-input { 
    margin-bottom: 5px;
    margin-top: 15px; 
  }

  .markers:before,
  .markers:after { 
    display: none;
  }

  .checkbox-section {
    .slider.round {
      background: transparent;
      border: 1px solid white; 
      width: 20px; 
      height: 20px;

      &:before {
        background-color: transparent; 
        border-radius: 50%; 
        width: 10px;
        height: 10px;
        bottom: 4px;
        left: 4px;
        transition: all .2s; 
      }
    } 

    input:checked + .slider:before {
      background-color: #fff;  
      transform: translate(0); 
    } 

    .formkit-inner {
      padding-left: 35px;
    }
  }
}
</style>


