import buttonBackgroundSvg from "../../svg/general/icons/buttonBackground";
import buttonIconSvg from "../../svg/general/icons/buttonIcon";
import potatoOlympicsSvg from "../../svg/olympics/potatoOlympics";
import potatoCarouselSvg from "../../svg/olympics/potatoCarousel";
import blockBlogSvg from "../../svg/general/icons/blockBlob";
import markeLeftSvg from "../../svg/general/icons/markerLeft";
import markeRightSvg from "../../svg/general/icons/markerRight";
import buttonLinkListSvg from "../../svg/general/icons/buttonLinkList";
import underlineSvg from "../../svg/general/underline/underline";


buttonBackgroundSvg().addToClass('.teaser .cmp-teaser__action-link');
buttonIconSvg().addToClass('.teaser .cmp-teaser__action-link');
blockBlogSvg().addToClass('[class*="t-potato"] .cmp-teaser__image');

potatoOlympicsSvg().addToClass('.t-olympics .cmp-teaser__image');
potatoCarouselSvg().addToClass('.c-teaser-slider.olympics .t-common .cmp-teaser__image');

markeLeftSvg().addToClass('[class*="t-marker"] .cmp-teaser__title');
markeRightSvg().addToClass('[class*="t-marker"] .cmp-teaser__title'); 

buttonLinkListSvg().addToClass('.teaser.t-btn-link-list .cmp-teaser__description ul li a');
underlineSvg().addToClass('.t-common .cmp-teaser__description u');

const bloomingFlowers  = () => { 
    const btnsBlooming = document.querySelectorAll(".t-btn-blooming .cmp-teaser__action-link");

    btnsBlooming.forEach(function(btnBlooming) {
        btnBlooming.addEventListener('mouseover', function hover() {
            this.classList.remove('zoom-out');
            this.classList.add('zoom-in');
        });
      
        btnBlooming.addEventListener('mouseleave', function leave() {
            this.classList.add('zoom-out');
            this.classList.remove('zoom-in');
        });
    });
}

const onDocumentReady = () => {
    bloomingFlowers();
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}

