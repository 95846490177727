const moveSecondaryImage = () => {
  const teaserElementsGrid = document.querySelectorAll(".c-flip-card-grid .teaser");
  const teaserElementsFullWidth = document.querySelectorAll(".c-flip-card-grid-full-width .teaser");

  const teaserElements = teaserElementsGrid.length > 0 ? teaserElementsGrid : teaserElementsFullWidth;

  teaserElements.forEach((teaser) => {
    const descriptionElement = teaser.querySelector(".cmp-teaser__description");
    const secondaryImageElement = teaser.querySelector(".secondary-image");
    if (descriptionElement && secondaryImageElement) {
      const firstParagraph = descriptionElement.querySelector('p');
      descriptionElement.insertBefore(secondaryImageElement, firstParagraph);
    }
  });
}; 

const onDocumentReady = () => {
  moveSecondaryImage();
};

if (document.readyState !== "loading") {
  onDocumentReady();
} else {
  document.addEventListener("DOMContentLoaded", onDocumentReady);
}
