<template>
  <div 
    class="product-tile-root"
    :data-cy-lead-background="productData.leadBackgroundColor"
    :data-cy-bop-color="productData.bopButtonColor"
  >
    <div class="product-section product-display">
      <product-tile-display
        :productData="productData"
        :isFlipped="isFlipped"
        :activeTab="activeTab"
      />
    </div>
    <div class="product-section product-details">
      <div class="product-title">
        <product-tile-title 
         :productTitle="productData.productTitle"
        />
      </div>
     <div class="product-nutrition" v-if="showNutrition">
      <product-tile-nutrition 
        :nutritionItems="productData.nutritionItems"
        :nutritionServingSize="productData.nutritionServingSize"
      />
     </div>
     <div class="product-description">
      <product-tile-description 
       :descriptionHtml="currentDescription"
      />
     </div>
     <div class="product-tabs">
      <product-tile-tab
        tabName="about"
        :translatedTitle="translations.aboutTranslation"
        :activeTab="activeTab"
        :textColor="productData.navigationTextColor"
        @update-tab="updateTab"
      />
      <product-tile-tab
        tabName="ingredients"
        :translatedTitle="translations.ingredientsTranslation"
        :activeTab="activeTab"
        :textColor="productData.navigationTextColor"
        @update-tab="updateTab"
      />
      <product-tile-tab
        tabName="nutrition"
        :translatedTitle="translations.nutritionTranslation"
        :activeTab="activeTab"
        :textColor="productData.navigationTextColor"
        @update-tab="updateTab"
      />
      <product-tile-tab
        tabName="sustainability"
        :translatedTitle="translations.sustainabilityTranslation"
        :activeTab="activeTab"
        :textColor="productData.navigationTextColor"
        @update-tab="updateTab"
      />
     </div>
    </div>
  </div>
</template>

<script>
import ProductTileTitle from './ProductTileTitle';
import ProductTileDisplay from './ProductTileDisplay';
import ProductTileNutrition from './ProductTileNutrition';
import ProductTileDescription from './ProductTileDescription';
import ProductTileTab from './ProductTileTab';

export default {
  name: 'ProductTile',
  components: {
    ProductTileTitle,
    ProductTileDisplay,
    ProductTileNutrition,
    ProductTileDescription,
    ProductTileTab
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
    isFlipped: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      activeTab: 'about'
    }
  },
  computed: {
    currentDescription () {
      const descriptions = {
        'about': this.productData.aboutText.html,
        'ingredients': this.productData.ingredientsDescription.html,
        'nutrition': this.productData.nutritionDescription.html,
        'sustainability': this.productData.sustainabilityDescription.html
      }
      return descriptions[this.activeTab];
    },
    showNutrition () {
      return this.activeTab === 'nutrition';
    }
  },
  methods: {
    updateTab (event) {
      this.activeTab = event;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-tile-root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (min-width: 1023px) {
    height: 100%;
  }

  .product-title,
  .product-description,
  .product-nutrition { 
      display: block; 
      text-align: center;
    }

  & .product-section {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;

    @media only screen and (max-width: 1023px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .product-display {
    @media only screen and (max-width: 1023px) {
      padding: 120px 0;
      min-height: 360px;
    }
  }

  .product-tabs {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin-top: 30px;
    margin-right: 20px;
    @media only screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
}
</style>