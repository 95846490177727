<template>
  <div class="all-selector-container">
    <button @click="showall">
      <span class="btn-text">{{ btnText }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ActivityAllSelector',
  props: {
    btnText: {
      type: String,
      required: true
    }
  },
  emits: ['show-all-seasons'],
  methods: {
    showall () {
      this.$emit('showAllSeasons', true);
    }   
  }
}
</script>

<style lang="scss" scoped>
.all-selector-container {
  display: flex;
  justify-content: center;

  button {
      font-size: 20px;
      text-decoration: none;
      display: inline-block;
      border-radius: 60px;
      padding: 10px 25px;
      position: relative;
      cursor: pointer;

      &:before,
      &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 60px;
      transition: background-color .3s ease-in-out;
    }

    &:before {
      background-color: #a2d45e;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:after {
      top: 6px;
      left: 6px;
    }

    &:hover {
      &:after {        
        background-color: #74aa50;
      }
    }

    .btn-text {
      color: #212721;
      position: relative;
      z-index: 3;
      display: block;
    }
  }    
}
</style>